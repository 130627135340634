import {Breadcrumb, Layout, message, Table, Popconfirm, Button} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect} from "react-redux";
import {useEffect} from "react";

import {selectLoading, selectError, selectReportParameters, selectCurrentReportParameter}
from "../../redux/reportParameter/reportParameterSelector";
import {listReportParameters, deleteReportParameter, clearReportParameterError}
from '../../redux/reportParameter/reportParameterActions';
import {Link} from "react-router-dom";

const {Content, Footer} = Layout;

const ReportParameters = (props) => {

    const intl = useIntl();
    const {reportParameters, count, max, offset} = props.reportParameters;

    const columns = [
        {
            title: intl.formatMessage({id: 'label.reportParameter.id'}),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: "5%",
            fixed: true,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: intl.formatMessage({id: 'label.reportParameter.actions'}),
            dataIndex: 'actions',
            align: 'center',
            width: "7%",
            fixed: true,
            render: (_, record) =>
                count >= 1 ? (
                    <div className="actionsIcons">
                        <Popconfirm title={intl.formatMessage({id: 'msg.confirm-delete'})}
                                    onConfirm={() => handleDelete(record.id)}>
                            <DeleteTwoTone twoToneColor="red" title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.reportParameter'})} />
                        </Popconfirm>
                        <Link to={{
                            pathname: `/configuration/reports/reportParameters/${record.id}`,
                            state: {'fromReportParameters': true}
                        }} >
                            <EditTwoTone title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.reportParameter'})} />
                        </Link>
                    </div>
                    ) : null
        },
        {
            title: intl.formatMessage({id: 'label.reportParameter.name'}),
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            fixed: false,
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('name')
        },
        {
            title: intl.formatMessage({id: 'label.reportParameter.description'}),
            dataIndex: 'description',
            key: 'description',
            width: '28%',
            fixed: false,
            sorter: (a, b) => a.description - b.description,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('description')
        },
        {
            title: intl.formatMessage({id: 'label.reportParameter.type'}),
            dataIndex: 'type',
            key: 'type',
            width: '10%',
            fixed: false,
            sorter: (a, b) => a.type - b.type,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('type')
        },
        {
            title: intl.formatMessage({id: 'label.reportParameter.report'}),
            dataIndex: 'reportname',
            key: 'reportname',
            width: '25%',
            fixed: false,
            sorter: (a, b) => a.report - b.report,
            sortDirections: ['ascend', 'descend']
        }
    ];

    useEffect(() => { //para cargar datos

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearReportParameterError());
            };
            error();
        } else {
            props.listReportParameters(props.history);
        }
        // eslint-disable-next-line
    }, [props.error]);

    const handleChange = (pagination, filters, sorter) => {
        props.listReportParameters(props.history, pagination, filters, sorter);
    };

    const handleDelete = (id) => {
        props.deleteReportParameter(props.history, id);
    };

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.configuration" defaultMessage="Configuration"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports" defaultMessage="Reports"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reportParameters" defaultMessage="ReportParameters"/></span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <>
                        <Link to='/configuration/reports'><Button type="primary" style={{marginBottom: 16,
                        marginRight: 10}}>
                            <FormattedMessage id="label.report.reports" defaultMessage="Reports"/>
                        </Button></Link>
                        <Link to='/configuration/reports/reportFiles'><Button type="primary" style={{marginBottom: 16,
                        marginRight: 10}}>
                            <FormattedMessage id="label.reportFile.reportFiles" defaultMessage="Report Files"/>
                        </Button></Link>
                    </>
                    <Table
                        bordered={true}
                        loading={props.loading}
                        rowKey={record => record.id}
                        responsive={true}
                        pagination={{
                            current: offset / max + 1,
                            showLessItems: true, pageSizeOptions: [10, 20, 50, 100],
                            pageSize: max,
                            defaultPageSize: max, total: count, showSizeChanger: true,
                            // showTotal: ((total, range) => `${range[0]}-${range[1]} of ${total}`)
                            showTotal: ((total, range) => intl.formatMessage({
                                id: 'label.range-total'
                            }, {one: range[0], two: range[1], total: total}))
                        }}
                        size={'default'}
                        showHeader
                        columns={columns}
                        dataSource={reportParameters}
                        scroll={{y: '65vh', x: '100vw'}}
                        onChange={handleChange}
                    />
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    reportParameters: selectReportParameters(state),
    reportParameter: selectCurrentReportParameter(state),
});

export default connect(mapStateToProps, {listReportParameters, deleteReportParameter, clearReportParameterError})(ReportParameters);