import axiosInstance from "../../utils/axiosInstance";
import {JOB_ACTION_TYPES} from "../typesAction";


export const setLoading = () => {
    return {type: JOB_ACTION_TYPES.LOAD_JOB}
};

export const clearLoading = () => {
    return {type: JOB_ACTION_TYPES.CLEAR_LOAD_JOB}
};

export const clearJobError = () => {
    return {type: JOB_ACTION_TYPES.CLEAR_JOB_ERRORS}
};

export const listJobs = (history, pagination, filters = {}, sorter) => async dispatch => {
    try {
        dispatch(setLoading());
        // Prepare all query parameters
        let queryParameters = {}
        Object.keys(filters).map(key => (
            queryParameters[key] = filters[key] == null ? filters[key] : filters[key][0])
        );
        if (sorter !== undefined) {
            queryParameters['sort'] = sorter['field']
            queryParameters['order'] = sorter['order'] === 'descend' ? 'desc' : 'asc'
        }
        if (pagination !== undefined) {
            queryParameters['offset'] = (pagination.current - 1) * pagination.pageSize
            queryParameters['max'] = pagination.pageSize
        }
        // Spread the parameters
        const params = {
            ...queryParameters
        };
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).get('/job', {params});
        // Update state
        dispatch({
            type: JOB_ACTION_TYPES.LIST_JOB,
            payload: data
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: JOB_ACTION_TYPES.ERROR_JOB,
            payload: err.message
        });
    }
};

export const getJob = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        const {data} = await axiosInstance(history, dispatch).get(`/job/${id}`);
        // Update state
        dispatch({
            type: JOB_ACTION_TYPES.SHOW_JOB,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: JOB_ACTION_TYPES.ERROR_JOB,
            payload: err.message
        });
    }
};