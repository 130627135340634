import {createSelector} from "reselect";

const selectUser = state => state.user;

export const selectAppLoading = createSelector(
    [selectUser],
    (user) => user.appLoading
);

export const selectLoading = createSelector(
    [selectUser],
    (user) => user.loading
);

export const selectError = createSelector(
    [selectUser],
    (user) => user.error
);

export const selectUsers = createSelector(
    [selectUser],
    (user) => user.users
);

export const selectCurrentUser = createSelector(
    [selectUser],
    (user) => user.currentUser
);

export const selectGeoArea = createSelector(
    [selectUser],
    (user) => user.geoArea
);

export const selectGeoRegions = createSelector(
    [selectUser],
    (user) => user.geoRegions
);