// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as turf from '@turf/turf';
import {MAPBOX_STYLES} from "../../Plots/CustomMapboxControls/StylesControl/MAPBOX_STYLES";
import {COLOR_SCHEMES} from "../../Plots/CustomMapboxControls/ColorSchemesControl/COLOR_SCHEMES.js";
import LegendControl from "../../Plots/CustomMapboxControls/LegendControl/LegendControl";
import ActiveLayerLabelControl from "../../Plots/CustomMapboxControls/ActiveLayerLabelControl/ActiveLayerLabelControl";
import CustomMapboxControlByRadio from "../../Plots/CustomMapboxControls/CustomMapboxControlByRadio";

import {getPopupDOMContent} from "../../utils/helpersForPopup";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

/**
  * This abstract class represents a {@link MapboxGLMap} class instance.
  * Any map's instance depends on several parameters, which are used to customize it and this way different types of maps
  * can be represented.
  * The customization process is implemented by children classes.
  *
  * @abstract
  */

class MapboxGLMapInstance {
    /**
     * The mapboxgl.Map's instance
     */
    map;

    /**
     * An object that represents the params used to customize the mapboxgl.Map's instance.
     * @typedef {Object} CustomizationParams
     * @property {String} mapTitle The map's title.
     * @property {String} mapPlotHeight The `height` of the div html element that contains the map.
     * @property {String} container The `id` of the div html element that contains the map. Its default value is 'map-container-id'.
     * @property {LngLatBoundsLike} bounds It specifies the bounds of map.
     * @property {ParamsStyle} style Specifies the part of the `style` of map that contains its `url`, `sources` and `layers`.
     * @property {Array<ParamsControl>} controls An array of {@link ParamsControl} objects.
     * @property {Array<ParamsEvent>} events An array of {@link ParamsEvent} to be registered on map.
     * @property {ParamsPopup} popup Data used to render a popup on map.
     * @property {Function} setIsMapLoaded `useState` hook for state `isMapLoaded` of the react component that
     * contains the map.
     * @property {Array<ParamsMessage>} messagesList An array with messages to be showed to user.
     * @example
     * This shows only the data type structure. Actual data must be overridden in children classes.
        * {
        * 	mapTitle: "",
        * 	mapPlotHeight: "",
        * 	container: 'map-container-id',
        * 	bounds: [],
        * 	style: {
        * 		url: '',
        * 		sources: [
        * 		{
        * 			'id': '',
        * 			'source': {
        * 					'type': 'geojson',
        * 					'data': null
        * 				}
        * 			}
        * 		],
        * 		layers: [
        * 			{
        * 				'id': '',
        * 				'type': '',
        * 				'source': ''
        * 			}
        * 		]
        * 	},
        * 	controls: [
        * 		{name: 'layersControl', control: null, position: 'top-right', custom: true,
        *        handleChange: handleLayersControlChange},
        * 		{name: 'stylesControl', control: null, position: 'top-right', custom: true,
        *        handleChange: handleStylesControlChange},
        * 		{name: 'colorSchemesControl', control: null, position: 'top-right', custom: true,
        *        handleChange: handleColorSchemesControlChange},
        * 		{name: 'navigationControl', control: null, position: 'top-right', custom: false,
        *        handleChange: handleNavigationControlChange},
        * 		{name: 'fullScreenControl', control: null, position: 'top-right', custom: false,
        *        handleChange: handleFullScreenControlChange},
        * 		{name: 'legendControl', control: null, position: 'bottom-left', custom: true,
        *        handleChange: handleLegendControlChange},
        * 		{name: 'activeLayerControl', control: null, position: 'top-left', custom: true,
        *        handleChange: handleActiveLayerLabelControl}
        * 	],
        * 	events: [
        * 		{
        * 			name: 'load',
        * 			callback: () => {
        *
        * 				this._addStyleSources(this._getStyleSourcesIds());
        *
        * 				this._addStyleLayers(this._getStyleLayersIds());
        *
        * 				this._addControls();
        *
        * 				this.params.setIsMapLoaded(true);
        * 			}
        * 		}
        * 	],
        * 	popup: {
        * 		data: [],
        *       currentIndex: -1
        * 	},
        * 	setIsmapLoaded: null,
        *   messagesList: []
        * }
     */

    /**
     * An object used to customize the {@link MapboxGLMapInstance#map|mapboxgl.Map's instance}.
     * @type {CustomizationParams}
     */
    params = {
        mapTitle: "",
        mapPlotHeight: "",
        container: 'map-container-id',

        /**
          * LngLatBoundsLike type specification.
          * @typedef LngLatBoundsLike
          * @see This type is defined on {@link https://docs.mapbox.com/mapbox-gl-js/api/geography/#lnglatboundslike|LngLatBoundsLike}.
          * It can be calculated using the function {@link http://turfjs.org/docs/#bbox|turf.bbox}.
          */
        bounds: [],

        /**
          * An object that represents the customizable part of map's style.
          * @typedef {Object} ParamsStyle
          * @property {String} url The mapbox style url.
          * @property {Array<StyleSource>} sources An array of StyleSource objects.
          * @property {Array<StyleLayer>} layers An array of StyleLayer objects.
          * @example
            * The following object represents the structure of this data type, but the actual data must be customized by
            * overridden it in children classes.
            * {
            * 	url: '',
            * 	sources: [
            * 		{
            * 			'id': '',
            * 			'source': {
            * 				'type': 'geojson',
            * 				'data': null
            * 			}
            * 		}
            * 	],
            * 	layers: [
            * 		{
            * 			'id': '',
            * 			'type': '',
            * 			'source': ''
            * 		}
            * 	]
            * }
          */

        style: {
            url: '',

            /**
              * An object that represents a style source.
              * @typedef {Object} StyleSource
              * @property {String} id The source id.
              * @property {GeoJSONSource} source The source object.
              */
            sources: [
                {
                    'id': '',

                    /**
                      * An object that represents a geoJSON source.
                      * @typedef {Object} GeoJSONSource
                      * @property {String} type The source type.
                      * @property {GeoJSON} data The source data.
                      * @see More information can be found on
                      * {@link https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#geojson|MapboxGLJs-GeoJSONSource}.
                      * @example
                        * {
                        * 	"type": "geojson",
                        * 	"data": {
                        * 		"type": "Feature",
                        * 		"geometry": {
                        * 			"type": "Point",
                        * 			"coordinates": [-77.0323, 38.9131]
                        * 		},
                        * 		"properties": {
                        * 			"title": "Mapbox DC",
                        * 			"marker-symbol": "monument"
                        * 		}
                        * 	}
                        * }
                      */
                    'source': {
                        /*  @defaultvalue 'geojson' */
                        'type': 'geojson',

                        /**
                          * GeoJSON type specification
                          * @typedef GeoJSON
                          * @see A geoJSON object type is defined on {@link https://geojson.org/|GeoJSON}.
                          */
                        'data': null
                    }
                }
            ],

            /**
              * An object that represents a style layer.
              * @typedef {Object} StyleLayer
              * @see It is specified on {@link https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/|MapboxGLJs-Layer}.
              */
            layers: [
                {
                    'id': '',
                    'type': '',
                    'source': ''
                }
            ]
        },

        /**
          * MapControl data type is specified as both custom or not custom
          * {@link https://docs.mapbox.com/mapbox-gl-js/api/markers/|MapboxGLJS´s control},
          * @typedef MapControl
          */

        /**
          * ParamsControl type specification
          *
          * @typedef {Object} ParamsControl
          * @property {String} name The name of the control. Non custom controls have
          * {@link https://docs.mapbox.com/mapbox-gl-js/api/markers/|predefined names}.
          * @property {MapControl} control A {@link https://docs.mapbox.com/mapbox-gl-js/api/markers/|MapboxGLJS´s control}.
          * @property {String} position The `position` of control in the map.
          * @property {Boolean} custom Specifies if `control` is custom or not.
          * @example
          * const paramsControl = {name: 'layersControl', control: layersControl, position: 'top-right', custom: true};
          */
        /**
          * `this.params.controls` data type specification.
          *
          * @type {Array<ParamsControl>}
          */
        controls: [
            {name: 'layersControl', control: null, position: 'top-right', custom: true},
            {name: 'stylesControl', control: null, position: 'top-right', custom: true},
            {name: 'colorSchemesControl', control: null, position: 'top-right', custom: true},
            {name: 'navigationControl', control: null, position: 'top-right', custom: false},
            {name: 'fullScreenControl', control: null, position: 'top-right', custom: false},
            {name: 'legendControl', control: null, position: 'bottom-left', custom: true},
            {name: 'activeLayerLabelControl', control: null, position: 'top-left', custom: true}
        ],

        /**
          * ParamsEvent type specification
          * @typedef {Object} ParamsEvent
          * @property {String} name The name of the event.
          * @property {Function} callback The function to be called when the event is triggered.
          * @example
            * {
            * 	name: 'load',
            * 	callback: () => {
            *
            * 	  this._addStyleSources(this._getStyleSourcesIds());
            *
            * 	  this._addStyleLayers(this._getStyleLayersIds());
            *
            * 	  this._addControls();
            *
            *     this._registerMapEvents(this._getEventsNames(['load']));
            *
            * 	  this.params.setIsMapLoaded(true);
            * 	}
            * }
          */
        events: [
            {
                id: 'eventOnLoad',
                name: 'load',
                callback: () => {

                  this._addStyleSources(this._getStyleSourcesIds());

                  this._addStyleLayers(this._getStyleLayersIds());

                  this._addControls(this._getControlsNames());

                  this._registerMapEvents(this._getEventsNames(['load']));

                  this.params.setIsMapLoaded(true);
                }
            },
            {
                id: 'eventOnStyledata',
                name: 'styledata',
                callback: () => {
                                this._addStyleSources(this._getStyleSourcesIds());
                                this._addStyleLayers(this._getStyleLayersIds());
                            }
            },
            {
                id: 'eventOnClick',
                name: 'click',
                layerId: null,
                callback: null
            },
            {
                id: 'eventOnMouseenter',
                name: 'mouseenter',
                layerId: null,
                callback: () => {
                    if (this._hasPopup(this.params.popup)) {
                        this.map.getCanvas().style.cursor = 'pointer';
                    } else {
                        this.map.getCanvas().style.cursor = '';
                    };
                }
            },{
                id: 'eventOnMouseleave',
                name: 'mouseleave',
                layerId: null,
                callback: () => {this.map.getCanvas().style.cursor = ''}
            },
        ],

        /**
          * ParamsPopup type specification.
          * @typedef {Object} ParamsPopup
          * @property {Array<DataSeriesPopup>} data The length of this array is equal to the length of the array given by the
           property `dataSeries` of the object {@link ConfigEdit}. Each item of the `data` array is equal to the
           {@link DataSeriesPopup|`popup`} property of the {@link ConfigEditDataSeries} item of the property `dataSeries`
           of the object {@link ConfigEdit} if the property {@link DataSeriesPopup|`popup`}  is present.
           Otherwise it is set to `null`.
          *@example
          * //The "dataSeries" property of the object ConfigEdit has length 2.
          *
          * //The first item of "dataSeries" has a defined "popup" property, but the second item is null.
          *
          * //The "currentIndex" property is set to 0.
          *
            * popup = {
			* 	data: [
			* 		{
			* 			"title":	"Neighbourhood	Data",
			* 			"popupVariables":	[
			* 				{
			* 					"popupVariable":	"zlnxhbszms_7226725094",
			* 					"popupVariableAlias":	"Cont.	Indice",
			* 					"popupVariableFunction":	"index_one_var"
			* 				},
			* 				{
			* 					"popupVariable":	"joghsqgtip_3704132170",
			* 					"popupVariableAlias":	"Cont.	w/water	Indice",
			* 					"popupVariableFunction":	"index_one_var"
			* 				},
			* 				{
			* 					"popupVariable":	"oqofmcerdr_4564981581",
			* 					"popupVariableAlias":	"Cont.w/Larvae	indice",
			* 					"popupVariableFunction":	"index_one_var"
			* 				},
			* 				{
			* 					"popupVariable":	"mmkoavsoly_9219334559",
			* 					"popupVariableAlias":	"Cont.	treated	Indice",
			* 					"popupVariableFunction":	"index_one_var"
			* 				}
			* 			]
			* 		},
			* 		null
			* 	],
			* 	currentIndex = 0;
			* };
          */
        popup: {
            data: [],
            currentIndex: -1
        },
        setIsMapLoaded: null,

        /**
          * Represents messages that are issued to users if there is any problem with
          the creation of the map's instance.
          * @typedef {Object} ParamsMessage
          * @property {String} title The title of message.
          * @property {String} content The content of message
          */
        messagesList: [],
    };

    /**
      * MAPBOX_STYLE data type specification.
      * @typedef {Object} MAPBOX_STYLE_ITEM
      * @property {String} label The label of mapbox style. It is showed to user.
      * @property {String} value The name of mapbox style. It is used in coding to access the mapbox style.
      * @property {String} url The url of mapbox style. It is used to initialize the map.
      * @example
      * {label: 'Streets', value: 'streets', url: "mapbox://styles/mapbox/streets-v11"}
      */

    /**
     * A constant array of {@link MAPBOX_STYLE_ITEM|objects with MapboxStyles as urls}.
     * @constant
     * @type {Array<MAPBOX_STYLE_ITEM>}
     * @see You can find more information about mapbox styles on
     * {@link https://docs.mapbox.com/api/maps/styles/#mapbox-styles|MapboxGLJs styles}.
     * @private
     */
    _MAPBOX_STYLES = MAPBOX_STYLES;

    /**
      * Contains the concrete information of a given color scheme. Its property
      * 'scale' represents the ordered list of colors.
      * @typedef {Object} COLOR_SCHEMES_ITEM
      * @property {String} label The label showed to user.
      * @property {String} name Internal name of color scheme, used when coding.
      * @property {Array<String>} scale The colors that make the color scheme scale.
      * @property {String} url The url that points to information about the color scheme.
      * @property {String} img The image's filename generated for the color scheme on {@link https://colorbrewer2.org/|ColorBrewer}
      * @example
      * {
      *    label: 'YellowOrangeRed',
      *    name: '9-class-YlOrRd',
      *    scale: ['#ffffcc','#ffeda0','#fed976','#feb24c',
      *        '#fd8d3c','#fc4e2a','#e31a1c','#bd0026',
      *        '#800026'],
      *    url: 'https://colorbrewer2.org/#type=sequential&scheme=YlOrRd&n=9',
      *    img: '9-class-YlOrRd.svg'
      * }
      */

    /**
      * An object that represents a {@link COLOR_SCHEMES_ITEM} object and the `natureOfData` property of the object
      {@link COLOR_SCHEMES_NATURE_OF_DATA} to which it belongs.
      * @typedef {Object} COLOR_SCHEMES_ITEM_WITH_NATURE_OF_DATA
      * @property {String} natureOfData `natureOfData` property of the object {@link COLOR_SCHEMES_NATURE_OF_DATA}.
      * @property {COLOR_SCHEMES_ITEM} colorScheme A {@link COLOR_SCHEMES_ITEM} object.
      * @example
        * {
        *     "natureOfData": "diverging",
        *     "colorScheme": {
        *         "label": "Spectral",
        *         "name": "9-class-Spectral",
        *         "scale": [
        *             "#d53e4f",
        *             "#f46d43",
        *             "#fdae61",
        *             "#fee08b",
        *             "#ffffbf",
        *             "#e6f598",
        *             "#abdda4",
        *             "#66c2a5",
        *             "#3288bd"
        *         ],
        *         "url": "https://colorbrewer2.org/?type=diverging&scheme=Spectral&n=9",
        *         "img": "9-class-Spectral.svg"
        *     }
        * }
      */

    /**
      * The JSON representation of the object {@link COLOR_SCHEMES_ITEM_WITH_NATURE_OF_DATA}
      * @typedef {JSONStringify} COLOR_SCHEMES_ITEM_WITH_NATURE_OF_DATA_JSON
      * @example
      * "{\"natureOfData\":\"diverging\",\"colorScheme\":{\"label\":\"Spectral\",\"name\":\"9-class-Spectral\",
      * \"scale\":[\"#d53e4f\",\"#f46d43\",\"#fdae61\",\"#fee08b\",\"#ffffbf\",\"#e6f598\",\"#abdda4\",\"#66c2a5\",
      * \"#3288bd\"],\"url\":\"https://colorbrewer2.org/?type=diverging&scheme=Spectral&n=9\",\"img\":\"9-class-Spectral
      * .svg\"}}"
      */

    /**
      * COLOR_SCHEMES_NATURE_OF_DATA data type. Contains information about sequential, diverging and qualitative color schemes. The
      * original data schemes definition are based on color schemes generated on
      * {@link https://colorbrewer2.org/|ColorBrewer},
      * but some custom color schemes are also included.
      * @typedef {Object} COLOR_SCHEMES_NATURE_OF_DATA
      * @property {String} natureOfData It can be sequential, diverging or qualitative.
      * @property {Array<COLOR_SCHEMES_ITEM>} colorSchemes An array with different
      * {@link COLOR_SCHEMES_ITEM|color schemes items}.
      * @example
        *{
        *	'natureOfData': 'sequential',
        *	'colorSchemes': [
        *		{
        *			label: 'YellowOrangeRed',
        *			name: '9-class-YlOrRd',
        *			scale: ['#ffffcc','#ffeda0','#fed976','#feb24c',
        *				'#fd8d3c','#fc4e2a','#e31a1c','#bd0026',
        *				'#800026'],
        *			url: 'https://colorbrewer2.org/#type=sequential&scheme=YlOrRd&n=9',
        *			img: '9-class-YlOrRd.svg'
        *		},
        *		{
        *			label: 'YellowOrangeBrown',
        *			name: '9-class-YlOrBr',
        *			scale: ['#ffffe5','#fff7bc','#fee391','#fec44f',
        *				'#fe9929','#ec7014','#cc4c02','#993404',
        *				'#662506'],
        *			url: 'https://colorbrewer2.org/#type=sequential&scheme=YlOrBr&n=9',
        *			img: '9-class-YlOrBr.svg'
        *		}
        *	]
        *}
      */

    /**
      * A constant with predefined color schemes that are used in map.
      * @constant
      * @type {Array<COLOR_SCHEMES_NATURE_OF_DATA>}
      * @private
      */
    _COLOR_SCHEMES = COLOR_SCHEMES;

    /**
      * Make `mapboxgl` available for children classes.
      * @private
      */
    _mapboxgl = mapboxgl;

    /**
      * Making turf available for children classes.
      * @see More information can be found on {@link http://turfjs.org|Turf}.
      * @private
      */
    _turf = turf;

    /**
      * The useIntl function from react-intl.
      * @typedef {Function} UseIntl
      */

    /**
      * Making `useIntl` from "react-intl" available for children classes.
      * Since useIntl is a hook it can only be called from within a function. So, it is
      * initialized when a new instance of MapboxGLMapInstance' children classes is created.
      * @type {UseIntl}
      * @private
      */
    _intl;

    /**
      * Represents an object with the parameters required by all methods of class.
      * It is set by method {@link MapboxGLMapInstance#_setArgs|_setArgs}. This field does not contain actual
      arguments to be passed to methods because the access to it is by using `this._args`.
      * @type {Object}
      * @private
      * @example
       * //This example shows how to access `_args` from a given method:
       * this._args.source.data
      */
    _args = {};

    /**
      * Constant with gray color with opacity 0, to be assigned to data that will not be showed on maps.
      */
    _GRAY_WITH_OPACITY_0 = "hsla(0, 0%, 100%, 0)";

    /**
      * Custom control class that shows several options to select one.
      *
      * It also updates the state `lastControlEvent` when the option is selected.
      *
      * @see More information is available at {@link CustomMapboxControlByRadio}.
      */
    _CustomMapboxControlByRadio = CustomMapboxControlByRadio;

    /**
      * {@link LegendControl} class.
      * @private
      */
    _LegendControl = LegendControl;

    /**
      * {@link ActiveLayerLabelControl} class.
      * @private
      */
    _ActiveLayerLabelControl = ActiveLayerLabelControl;

    /**
      * Making `getPopupDOMContent` available for children classes.
      *
      * @private
      */
    _getPopupDOMContent = getPopupDOMContent;

    /**
      * Argument of {@link MapboxGLMapInstance}'s constructor.
      * @typedef {Object} ConstArg
      * @property {UseIntl} intl React hook useIntl.
      */

    /**
      * MapboxGLMapInstance class' constructor.
      * This constructor can be called only from the constructors' of children classes.
      * @constructor
      * @param {ConstArg} constArg Argument to pass to constructor.
      *
      */
    constructor(constArg) {
        this._intl = constArg.intl;
    };

    /**
     * The method used to initialize the {@link MapboxGLMapInstance#map|map}.
     * It is intended to be called only in children classes' constructors.
     * @private
     */
    initMap() {
        this.map = new MapboxGLMap({
            container: this.params.container,
            style: this.params.style.url,
            bounds: this.params.bounds
        });

        this.map.fitBounds(this.params.bounds, {padding: 20});

        this.map.setIntl(this._intl);

    };

    /**
      * Data type of the argument used by method {@link MapboxGLMapInstance#setParams|setParams}.
      * @typedef {Object} ParamsArg
      * @property {RawData} rawData The data {@link RawData|sent back by the server}.
      * @property {Number} mapPlotHeight The height of the `div` html element that contains the map.
      * @property {UseIntl} intl `useIntl` from react-intl. Used when setting messages.
      */

    /**
     * The method used to set {@link MapboxGLMapInstance#params|params}.
     * It must be extended for each particular child class.
     * @param {ParamsArg} paramsArg The argument used to set {@link MapboxGLMapInstance#params|params}.
     */
    setParams() {

        this.params.mapTitle = this._args.general.mapTitle;

        this.params.mapPlotHeight = this._args.general.mapPlotHeight;

        this.params.container = this._args.general?.container
                                    ? this._args.general.container
                                    : 'map-container-id';

        this.params.style.url = this._getMapboxStyleUrl(this._args.style.defaultValue);

        this.params.setIsMapLoaded = this._args.paramsSetIsMapLoaded;

    };

    /**
     * The Mapbox style as url.
     * @param {String} value The value of the property `value` of any of the {@link MAPBOX_STYLE} object that is present
     * in the `MAPBOX_STYLES` member of the class.
     * @return {String} The value of the property `url` of the {@link MAPBOX_STYLE} object, whose property `value` is
     * equal to argument `value` of the method.
     * @private
     */
    _getMapboxStyleUrl(value) {
        return this._MAPBOX_STYLES.find(s => s.value === value).url
    };

    /**
     * Get the value of the property `id` of all {@link StyleSource} objects that are present in property `sources` of
     * {@link ParamsStyle} object.
     * @return {Array<String>} The ids  of style's sources.
     * @private
     */
    _getStyleSourcesIds() {
        return this.params.style.sources
            .map(sourceItem => sourceItem.id)
            .filter(id => id !== null || id !== '')
    };

    /**
     * Add sources to map's style.
     * @param {Array<String>} sourcesIds Must be any of the values of property `id` of object {@link StyleSource}.
     * @private
     */
    _addStyleSources(sourcesIds) {

        if (!sourcesIds || sourcesIds?.length === 0) return;

        if (this.params.style.sources?.length === 0) return;

        this.params.style.sources.forEach(sourceObj => {

            if (!sourceObj?.id || !sourceObj?.source) return;

            if (!this.map.getSource(sourceObj.id) && sourcesIds.includes(sourceObj.id))
                this.map.addSource(sourceObj.id, sourceObj.source);

        });
    };

    /**
     * Remove sources from map's style.
     * @param {Array<String>} sourcesIds The ids  of sources that are going to be removed from style.
     * @private
     */
    _removeStyleSources(sourcesIds) {

        if (!sourcesIds || sourcesIds?.length === 0) return;

        sourcesIds.forEach(sourceId => {

            if (this.map.getSource(sourceId)) this.map.removeSource(sourceId);

        });
    };

    /**
     * Get the value of the property `id` of all {@link StyleLayer} objects that are present in property `layers` of
     * {@link ParamsStyle} object.
     * @return {Array<String>} The ids of style's layers.
     * @private
     */
    _getStyleLayersIds() {
        return this.params.style.layers
            .map(layerItem => layerItem.id)
            .filter(id => id !== null || id !== '')
    };

    /**
     * Add layers to map's style.
     * @param {Array<String>} layersIds Must be any of the values of property `id` of object {@link StyleLayer}.
     * @private
     */
    _addStyleLayers(layersIds) {
        if (!layersIds || layersIds?.length === 0) return;

        if (this.params.style.layers?.length === 0) return;

        this.params.style.layers.forEach(layerObj => {

            if (!layerObj?.id || !layerObj.source || !layerObj.type) return;

            if (!this.map.getLayer(layerObj.id) && layersIds.includes(layerObj.id))
                this.map.addLayer(layerObj);

        });
    };

    /**
     * Remove layers from map's style.
     * @param {Array<String>} layersIds The ids  of layers that are going to be removed from style.
     * @private
     */
    _removeStyleLayers(layersIds) {
        if (!layersIds || layersIds?.length === 0) return;

        layersIds.forEach(layerId => {

            if (this.map.getLayer(layerId)) this.map.removeLayer(layerId);

        });
    }

    /**
      * Get the value of the  property `name` of object {@link MapControl} if the value of its property `control` is not null,
      * for all item present in `this.params.controls`.
      * @return {Array<String>} An array with {@link MapControl|MapControls'} names.
      * @private
      */
    _getControlsNames() {
        return this.params.controls
            .filter(controlItem => controlItem.control !== null)
            .map(controlItem => controlItem.name)
    };

    /**
     * Add controls to map.
     * @param {Array<String>} controlsNames Must be any of the values of property `name` of object {@link ParamsControl}.
     * The default values are showed in property `controls` in the Example section of {@link CustomizationParams}.
     * These values can be overridden on demand in children classes.
     * @private
     */
    _addControls(controlsNames) {

        if (!controlsNames || !Array.isArray(controlsNames) || controlsNames?.length === 0) return;

        if (!this.params?.controls || !Array.isArray(this.params.controls) || this.params.controls?.length === 0) return;

        this.params.controls.forEach(controlObj => {

            if (controlsNames.includes(controlObj?.name) && controlObj?.control !== null && !this.map.hasControl(controlObj.control)) {
                this.map.addControl(controlObj.control, controlObj.position);
            }

        });

    };

    /**
     * Remove controls from map and updates `this.params.controls[controlIndex].control`.
     * @param {Array<String>} controlsNames The names  of controls that are going to be removed from map.
     * @private
     */
    _removeControls(controlsNames) {

        if (!controlsNames || !Array.isArray(controlsNames) || controlsNames?.length === 0) return;

        if (!this.params?.controls || !Array.isArray(this.params.controls) || this.params.controls?.length === 0) return;

        this.params.controls.forEach((controlObj, controlIndex) => {

            if (controlsNames.includes(controlObj?.name) && controlObj?.control !== null && this.map.hasControl(controlObj.control)) {
                this.map.removeControl(controlObj.control);
                this.params.controls[controlIndex].control = null;
            }

        });

    };

    /**
      * The values of all (except those specified in argument `excludedEventsNames`) `name` property of the 
      * {@link _setParamsEvent|event} object that is present in property `events` of 
      * {@link CustomizationParams} object.
      *
      * @return {Array<String>} All map events names that are no equal included in `excludedEventsNames`.
      * @private
    */
    _getEventsNames(excludedEventsNames = ['load']) {
        return (
            this.params.events
            .filter(event => excludedEventsNames.length > 0 ? !excludedEventsNames.includes(event.name) : true)
            .map(event => event.name)
        );
    };

    /**
      * Gets the index of the event item of `this.params.events` array whose `name` property is equal to 'eventName'
      * argument.
      *
      * @param {String} eventName The event's name.
      * @return {Number} The index of the event item whose `name` property is equal to 'eventName'.
      * @private
    */
    _getParamsEventIndex(eventName) {
        const eventIndex = this.params.events.findIndex(event => event.name === eventName);

        if (eventIndex === -1) {
            throw new Error(this._intl.formatMessage({id: "MapboxGLMapInstance.params.events.name.notImplemented"},
                {eventName: eventName}));
        };

        return eventIndex;
    };

    /**
      * Set all (except `id` and `name`) properties of the `event` item that is present in property `events` of {@link
      * CustomizationParams} object and its property `name` is equal to argument `eventName`.
      *
      * Important note: If any of the properties to be set are set equal to `undefined` or `null`, then an error is
      * thrown.
      *
      * So, this method must be called after setting the values to be assigned to these properties!
      *
      * @param {String} eventName The event's name.
      * @private
      */
    _setParamsEvent(eventName) {
        const eventIndex = this._getParamsEventIndex(eventName);

        const event = this.params.events[eventIndex];
        const eventKeys = Object.keys(event).filter(key => !['load','styledata'].includes(key));
        const thisArgsEventId = this._args[event.id];
        const argsEventKeys = Object.keys(thisArgsEventId);
        argsEventKeys.forEach(argEventKey => {
            if (!eventKeys.includes(argEventKey)) {
                const errorMessage = this._intl.formatMessage(
                    {id: "MapboxGLMapInstance._setParamsEvent.argsEventKeys.invalidValue"},
                    {eventName: event.name, argEventKey: argEventKey, eventKeys: '['.concat(eventKeys.join(","),']')});
                console.log(errorMessage);
                console.log({event: event, argEventKey: argEventKey, eventKeys: eventKeys});
                throw new Error(errorMessage);
            };
            if (!!thisArgsEventId[argEventKey]) {
                this.params.events[eventIndex][argEventKey] = thisArgsEventId[argEventKey];
            } else {
                const errorMessage = this._intl.formatMessage(
                    {id: "MapboxGLMapInstance._setParamsEvent.thisArgsEventId[argEventKey].invalidValue"},
                    {eventName: event.name, invalidValue: thisArgsEventId[argEventKey], argEventKey: argEventKey});
                console.log(errorMessage);
                console.log({event: event, argEventKey: argEventKey, thisArgsEventId: thisArgsEventId});
                throw new Error(errorMessage);
            };
        });
    };

    /**
      * Set all {@link ParamsEvent|`event`} object defined in property `events` of {@link CustomizationParams}, except
      * those whose names are included in `excludedEventsNames`.
      *
      * Set means to assign  values to all (except `id` and `name`) properties of each event.
      *
      * @param {Array<String>} excludedEventsNames The excluded events names. Default values are `['load','styledata']`.
      * @private
      */
    _setParamsEvents(excludedEventsNames = ['load','styledata']) {
        const eventsNames = this._getEventsNames(excludedEventsNames);
        eventsNames.forEach(eventName => this._setParamsEvent(eventName));
    };

    /**
     * Register on map each `event` present in property `events` of {@link CustomizationParams} object that fulfill
     * the following conditions:
     *
     * 1.- The value of the `name` property of the `event` is included in argument `eventsNames`.
     *
     * 2.- The `layerId` property (if present) is not equal to `undefined` nor `null`.
     *
     * 3.- The  `callback` property is not equal to `undefined` nor `null`.
     *
     * Important note: If in a child class any of the two last conditions (2 or 3) is not fulfilled, then
     * an exception is thrown and application is stopped.
     *
     * So, all declared `event` in property `events` of {@link CustomizationParams} object must have all its properties
     * with valid values. If a given `event` is not necessary in a child class, then it must be removed
     * from `events`
     *
     * @param {Array<String>} eventsNames The names of events to be registered on map.
     * @private
     */
    _registerMapEvents(eventsNames) {

        this.params.events.forEach(event => {
            if (!!!event.callback) {
                const errorMessage = this._intl.formatMessage(
                    {id: "MapboxGLMapInstance._registerMapEvents.callback.invalidValue"},{eventName: event.name});
                console.log(errorMessage);
                console.log({event: event});
                throw new Error(errorMessage);
            };
            
            if (eventsNames?.includes(event.name) &&!!event.callback) {
                const eventKeys = Object.keys(event);
                if (eventKeys.includes('layerId')) {
                    if (!!event.layerId) {
                        this.map.on(event.name, event.layerId, event.callback);
                    } else {
                        const errorMessage = this._intl.formatMessage(
                            {id: "MapboxGLMapInstance._registerMapEvents.layerId.invalidValue"},
                            {eventName: event.name});
                        console.log(errorMessage);
                        console.log({event: event});
                        throw new Error(errorMessage);
                    }                    
                } else {
                    this.map.on(event.name, event.callback);
                }                
            };
        });
    };

    /**
      * Get the item with index `i` of `data` property of {@link ParamsPopup}.
      * @param {Number} i Integer that represents the index of the item of the property
      `data` of the object {@link ParamsPopup}.
      * @return {DataSeriesPopup} The {@link DataSeriesPopup|item} with index `i` in the
      `data` property of {@link ParamsPopup}. If `i` is not of type `number` or if it is
      greater than the `length` of property `data` then it returns `-1`.
      * @private
      */
    _getParamsPopupDataItem(i) {

        if (typeof(i) !== 'number' || i > this.params.popup.data.length) return -1;

        return this.params.popup.data[i];
    };

    /**
      * Set {@link MapboxGLMapInstance#params}' `style.sources` property. Must be overridden in children classes.
      * @private
      */
    _setParamsStyleSources() {};

    /**
      * Creates an object that represents a {@link StyleSource|map's  style source}.
      * @param {String} id The `id` property of the object {@link StyleSource}.
      * @param {GeoJson} data The `data` property of a {@link GeoJsonSource} object
      * @return {StyleSource} A {@link StyleSource} object.
      * @private
    */
    _createGeoJsonSource(id, data) {
        return (
            {
                id: id,
                source: {
                    type: 'geojson',
                    data: data
                }
            }
        )
    };

    /**
      * Set {@link MapboxGLMapInstance#params}' `style.layers` property. Must be overridden in children classes.
      * @private
      */
    _setParamsStyleLayers() {};

    /**
      * Set {@link MapboxGLMapInstance#params}' `style.controls` property. Must be overridden in children classes.
      * @private
      */
    _setParamsControls() {

        this._setNavigationControl();

        this._setFullscreenControl();
    };

    /**
      * Sets messages to be sent to user when it is necessary to call its attention.
      *
      * @private
    */
    _setMessagesList() {

    };
    
    /**
      * Set field {@link MapboxGLMapInstance#_args|_args}. The logic of this method is to spread the arguments passed
      * to the constructors of the children classes and then the obtained variables are organized into objects according
      * to the methods where they are used.
      * This method is the interface that connects the arguments to the constructor of any child class to the
      * parameters used by its methods.
      * @private
    */
    _setArgs() {};

    /**
      * Gets the property `control` of the {@link ParamsControl} object, whose its property `name` is equal to the
      * argument `controlName`.
      *
      * @param {String} controlName The value of the map control `name` property.
      * @return {MapControl} A {@link MapControl} object.
    */
    _getControlByName(controlName) {

        return this.params.controls.find(controlObj => controlObj.name === controlName).control;

    };

    /**
      * Get the index of controlObj in `this.params.controls` by `name` property.
      * @param {String} name
      * @return {Number} An integer value with the index of controlObj.
      * @private
    */
    _getParamsControlIndexByName(name) {

        return this.params.controls.findIndex(controlObj => controlObj.name === name);

    };

    /**
      * Sets `control` property of the {@link ParamsControl} object using as arguments the values of  the `name` and
      * the `control` properties.
      *
      * @param {String} controlName The value of `name` property of {@link ParamsControl}.
      * @param {IControl} control The value to be assigned to the `control` property of {@link ParamsControl}.
      * @private
    */
    _setParamsControlByNameAndControl(controlName, control) {

        const controlIndex = this._getParamsControlIndexByName(controlName);

        this.params.controls[controlIndex].control = control;

    };

    /**
      * Sets `control` property of all the {@link ParamsControl} objects whose `name` properties are included in the
      * `controlsNames` argument.
      *
      * This method throws an error if any of the values in the `controlsNames` argument is not valid.
      *
      * @param {Array<String>} controlsNames The values of `name` property of the {@link ParamsControl} objects whose
      * `control` properties are going to be set.
      * Valid values must be any of the following: ['layersControl', 'stylesControl', 'colorSchemesControl'].
      * @param this._args[controlName]
      * @private
    */
    _setParamsControlsByName(controlsNames) {

        const validControlsNames = ['layersControl', 'stylesControl', 'colorSchemesControl'];

        let invalidControlsNames = [];
        controlsNames.forEach(controlName => {
            if (!validControlsNames.includes(controlName)) invalidControlsNames.push(controlName);
        });

        if (invalidControlsNames.length !== 0) {
            const msg = this._intl.formatMessage({id: "MapboxGLMapInstance._setControlsByName.invalidControlsNames"},
                {invalidControlsNames: '['.concat(invalidControlsNames.join(','),']')});
            throw new Error(msg);
        };

        controlsNames.forEach(controlName => {
            let control = null;
            const radioGroupProps = this._args[controlName].radioGroupProps;

            //condition to keep control = null
            if (radioGroupProps.options.length < 1) return;

            const maxHeight = this._args[controlName].maxHeight;

            control = new this._CustomMapboxControlByRadio(radioGroupProps, maxHeight);

            this._setParamsControlByNameAndControl(controlName, control);

        });

    };

    /**
      * Set `control` of the item of array `this.params.controls` with `name = 'fullScreenControl'`.
      *
      * @private
      */
    _setFullscreenControl() {

        const fullScreenControl = new this._mapboxgl.FullscreenControl();
        
        this._setParamsControlByNameAndControl('fullScreenControl', fullScreenControl);

    };

    /**
      * Set `control` of the item of array `this.params.controls` with `name = 'navigationControl'`.
      *
      * @private
    */
    _setNavigationControl() {

        const navigationControl = new this._mapboxgl.NavigationControl();
        
        this._setParamsControlByNameAndControl('navigationControl', navigationControl);
        
    };

    /**
      * Test if a given item of property `popup.data` of {@link MapboxGLMapInstance#params} is null or not. This is
      used to assert if is there data for popup (not null case) or not (null case).
      *
      * @param {Number} this.params.popup.currentIndex An integer representing the index of the current popup data item
      * that is being used in map now.
      * @return {Boolean} Return `true` if the given item is `not null`, `false` otherwise.
      * @private
    */
    _hasPopup(popup) {
        const currentIndex = popup.currentIndex;
        const currentPopupData = popup.data.find((_, itemIndex) => itemIndex === currentIndex);
        return currentPopupData?.title && currentPopupData.title !== null;
    };

    /**
      * Test if a given {@link https://docs.mapbox.com/help/glossary/features/|feature} has associated a `popup.data`
      * item or not. The function takes the `popupDataIndex` property of the `properties` property of the `feature` and
      * passes it as argument to method {@link _hasPopup}.
      *
      * @param {} [feature] The feature to test.
      * @return {Boolean} Return `true` if the given feature has associated a `popup.data` item, `false` otherwise.
      * @private
    */
    _hasFeaturePopup(feature) {
        const index = feature.properties.popupDataIndex;
        return this._hasPopup(index);
    };


    /**
      * The handler for the event that is fired when a change is made in {@link ParamsControl} object with
      * `name = 'layersControl'`.
      * Must be overridden by subclasses.
    */
    handleLayersControlChange() {

    };

    /**
      * The handler for the event that is fired when a change is made in {@link ParamsControl} object with
      * `name = 'stylesControl'`.
      * Must be overridden by subclasses.
    */
    handleStylesControlChange() {

    };

    /**
      * The handler for the event that is fired when a change is made in {@link ParamsControl} object with
      * `name = 'colorSchemesControl'`.
      * Must be overridden by subclasses.
    */
    handleColorSchemesControlChange() {

    };

    /**
      * Gets `index` of {@link StyleLayer} object with  `name = layerName'` that is present in `this.params.style
      * .layers`.
      *
      * @return {Number} `index` of style layer object.
    */
    _getStyleLayerIndexByName(layerName) {
        return this.params.style.layers.findIndex(layer => layer.id === layerName)
    };

    /**
      * Handles map controls change.
      *
      * @param {lastControlEvent} lastControlEvent The object with information about the map control that was changed.
    */
    handleControlsChange(lastControlEvent) {

        switch(lastControlEvent.controlName) {
            case 'layersControl':
                this.handleLayersControlChange(lastControlEvent.data);
                break;

            case 'stylesControl':
                this.handleStylesControlChange(lastControlEvent.data);
                break;

            case 'colorSchemesControl':
                this.handleColorSchemesControlChange(lastControlEvent.data);
                break;

            default:
                const msg = this._intl.formatMessage({id: "PlotMaps.event.name.notImplemented"},
                {controlName: lastControlEvent.controlName});
                throw new Error(msg);
        };

    };

    /**
      * Set `this.params.bounds`.
      *
      * @param {}
      * @return {}
    */
    _setParamsBounds() {
        //setting map bounds
        let boundsArg = this.params.style.sources[0].source.data;

        if (boundsArg?.features?.length === 0) {//if no data available
            boundsArg = this._args.bounds.user;
        } else {//filtering only not null values
            const featuresWithNotNullData = boundsArg.features
                .filter(feature => feature.properties.value !== null);

            if (featuresWithNotNullData.length > 0) {
                boundsArg = this._turf.featureCollection(featuresWithNotNullData);
            };
        };

        this.params.bounds = this._turf.bbox(boundsArg);
    };

};

export default MapboxGLMapInstance;

/**
  * Extends {@link https://docs.mapbox.com/mapbox-gl-js/api/map/|mapboxgl.Map class} by adding the properties:
  * `mapInstanceData` and `intl`.
  *
  * `mapInstanceData` property is useful to pass data to mapboxgl.Map instances' methods.
  * See the {@link MapboxGLMapInstanceChoropleth#_handleClick|Source section (line) of method _handleClick} to get more
  * information on how to use it.
  *
  * `intl` property is useful for managing messages in different languages inside the map instances.
*/
class MapboxGLMap extends mapboxgl.Map {
    mapInstanceData;
    intl;

    setMapInstanceData(mapInstanceData) {
        this.mapInstanceData = mapInstanceData;
    };

    getMapInstanceData() {
        return this.mapInstanceData;
    };

    setIntl(intl) {
        this.intl = intl;
    };

    getIntl() {
        return this.intl;
    };
};