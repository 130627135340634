import axiosInstance from "../../utils/axiosInstance";
import {REPORT_ACTION_TYPES} from "../typesAction";


export const setLoading = () => {
    return {type: REPORT_ACTION_TYPES.LOAD_REPORT}
};

export const clearLoading = () => {
    return {type: REPORT_ACTION_TYPES.CLEAR_LOAD_REPORT}
};

export const clearReportError = () => {
    return {type: REPORT_ACTION_TYPES.CLEAR_REPORT_ERRORS}
};

export const listReports = (history, pagination, filters = {}, sorter) => async dispatch => {
    try {
        dispatch(setLoading());
        // Prepare all query parameters
        let queryParameters = {};
        Object.keys(filters).map(key => (
            queryParameters[key] = filters[key] == null ? filters[key] : filters[key][0])
        );
        if (sorter !== undefined) {
            queryParameters['sort'] = sorter['field'];
            queryParameters['order'] = sorter['order'] === 'descend' ? 'desc' : 'asc'
        }
        if (pagination !== undefined) {
            queryParameters['offset'] = (pagination.current - 1) * pagination.pageSize;
            queryParameters['max'] = pagination.pageSize
        }
        // Spread the parameters
        const params = {
            ...queryParameters
        };
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).get('/report', {params});
        // Update state
        dispatch({
            type: REPORT_ACTION_TYPES.LIST_REPORT,
            payload: data
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: REPORT_ACTION_TYPES.ERROR_REPORT,
            payload: err.message
        });
    }
};

export const addReport = (history, report, setReportCreated, setId, setAddReportFile) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).post('/report', report);
        // Update state
        dispatch({
            type: REPORT_ACTION_TYPES.ADD_REPORT,
            payload: data
        });
        setReportCreated(true);
        setId(data.id);
        setAddReportFile(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: REPORT_ACTION_TYPES.ERROR_REPORT,
            payload: error
        });
    }
};

export const getReport = (history, id) => async dispatch => {

    try {
        dispatch(setLoading());

        // Send requests and get responses
        const {data} = await axiosInstance(history, dispatch).get(`/report/${id}`);

        // Update state
        dispatch({
            type: REPORT_ACTION_TYPES.SHOW_REPORT,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: REPORT_ACTION_TYPES.ERROR_REPORT,
            payload: err.message
        });
    }
};

export const editReport = (history, id, report, setReportCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).patch(`/report/${id}`, report);

        // Update state
        dispatch({
            type: REPORT_ACTION_TYPES.EDIT_REPORT,
            payload: data
        });
        setReportCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: REPORT_ACTION_TYPES.ERROR_REPORT,
            payload: error
        });
    }
};

export const deleteReport = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        await axiosInstance(history, dispatch).delete(`/report/${id}`);
        // Update state
        dispatch({
            type: REPORT_ACTION_TYPES.DELETE_REPORT,
            payload: id
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: REPORT_ACTION_TYPES.ERROR_REPORT,
            payload: err.message
        });
    }
};

export const viewReport = (history, id, filters) => async dispatch => {
    try {
        dispatch(setLoading());

        //setting params
        let queryParameters = {};
        Object.keys(filters).map(key => (
            queryParameters[key] = filters[key] == null ? filters[key] : filters[key][0])
        );
        // Spread the parameters
        const params = {
            ...queryParameters
        };

        // Send requests and get responses
        const {data} = await axiosInstance(history, dispatch).get(`/report/view/${id}`, {params});

        // Update state
        dispatch({
            type: REPORT_ACTION_TYPES.VIEW_REPORT,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: REPORT_ACTION_TYPES.ERROR_REPORT,
            payload: err.message
        });
    }
};

export const getReportCode = (history, id) => async dispatch => {

    try {
        dispatch(setLoading());

        // Send requests and get responses
        const {data} = await axiosInstance(history, dispatch).get(`/report/get_code/${id}`);

        // Update state
        dispatch({
            type: REPORT_ACTION_TYPES.SHOW_REPORT_CODE,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: REPORT_ACTION_TYPES.ERROR_REPORT,
            payload: err.message
        });
    }
};

export const editReportCode = (history, id, reportCode, setReportCodeUpdated) => async dispatch => {
    try {
        dispatch(setLoading());

        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).patch(`/report/update_code/${id}`, reportCode);

        // Update state
        dispatch({
            type: REPORT_ACTION_TYPES.EDIT_REPORT_CODE,
            payload: data
        });
        setReportCodeUpdated(true)
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: REPORT_ACTION_TYPES.ERROR_REPORT,
            payload: error
        });
    }
};

export const getReportOutput = (history, id, fileExtension, fileName) => async dispatch => {

    try {
        dispatch(setLoading());

        //query parameters
        let queryParameters = {};

        if (fileExtension) queryParameters['fileExtension'] = fileExtension;
        if (fileName) queryParameters['fileName'] = fileName;
        //params
        const params = {
            ...queryParameters
        };

        // Send requests and get responses
        const {data} = await axiosInstance(history, dispatch).get(`/report/get_report_output/${id}`,
        {params});

        // Update state
        dispatch({
            type: REPORT_ACTION_TYPES.GET_REPORT_OUTPUT,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: REPORT_ACTION_TYPES.ERROR_REPORT,
            payload: err.message
        });
    }
};

export const updateEditedReportOutput = (history, id, report) => async dispatch => {
    try {
        dispatch(setLoading());

        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).patch(`/report/${id}`, report);

        // Update state
        dispatch({
            type: REPORT_ACTION_TYPES.UPDATE_EDITED_REPORT_OUTPUT,
            payload: data
        });
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: REPORT_ACTION_TYPES.ERROR_REPORT,
            payload: error
        });
    }
};
