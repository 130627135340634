import {createSelector} from "reselect";

const selectReport = state => state.report;

export const selectLoading = createSelector(
    [selectReport],
    (report) => report.loading
);

export const selectError = createSelector(
    [selectReport],
    (report) => report.error
);

export const selectReports = createSelector(
    [selectReport],
    (report) => report.reports
);

export const selectCurrentReport = createSelector(
    [selectReport],
    (report) => report.current
);

export const selectView = createSelector(
    [selectReport],
    (report) => report.view
);

export const selectCode = createSelector(
    [selectReport],
    (report) => report.code
);

export const selectReportOutput = createSelector(
    [selectReport],
    (report) => report.reportOutput
);
