import axiosInstance from "./axiosInstance";
import {
    REPORT_ACTION_TYPES
} from "../redux/typesAction";

/* Action to get regions for component ReportForm
* */
export const getUserRegionsForReports = (history, setLoading, clearLoading) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).get('/geolevel/user_regions',);
        // Clear loading
        dispatch(clearLoading());
        return data['geoRegions'];
    } catch (err) {
        dispatch({
            type: REPORT_ACTION_TYPES.ERROR_REPORT,
            payload: err.message
        });
    }
};

