import {Breadcrumb, Layout, message, Table, Popconfirm, Button, Card, Row, Modal} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone, EyeTwoTone} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect} from "react-redux";
import {useEffect, useState} from "react";

import {selectLoading, selectError, selectRoles, selectCurrentRole} from "../../redux/role/roleSelector";
import {listRoles, deleteRole, clearRoleError, getRole} from '../../redux/role/roleActions';
import {Link} from "react-router-dom";
import Moment from "react-moment";


const {Content, Footer} = Layout;

const labelStyle = {width: '25%', textAlign: 'center'}
const valueStyle = {width: '75%', textAlign: 'left'}

const Roles = (props) => {

    const intl = useIntl();
    const {roles, count, max, offset} = props.roles;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [roleDetails, setRoleDetails] = useState(<Card loading={true}> </Card>);

    const showModal = (id) => {
        setIsModalVisible(true);
        props.getRole(props.history, id).then((data) => {
            setRoleDetails(<Card>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.name" defaultMessage="Name"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.authority}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="menu.permissions" defaultMessage="Permissions"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        <ul>
                        {(data.permissions ? data.permissions.map((permission) => (<li key={permission.id}>{permission.name}</li>)) : '')}
                        </ul>
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.last-updated" defaultMessage="Last Updated"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        <Moment format="YYYY-MM-DD HH:MM:SS">{data.lastUpdated}</Moment>
                    </Card.Grid></Row>


            </Card>)
        })
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'label.id'}),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '10%',
            fixed: false,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: intl.formatMessage({id: 'label.actions'}),
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            fixed: false,
            render: (_, record) =>
                count >= 1 ? (
                    <div className="actionsIcons">
                        <Popconfirm title={intl.formatMessage({id: 'msg.confirm-delete'})}
                                    onConfirm={() => handleDelete(record.id)}>
                            <DeleteTwoTone twoToneColor="red" title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.role'})} />
                        </Popconfirm>
                        <Link to={`/admin/roles/${record.id}`}><EditTwoTone title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.role'})} /></Link>
                        <EyeTwoTone onClick={() => {
                            showModal(record.id)
                        }} twoToneColor="green" title={intl.formatMessage({id: 'title.label.actions.show'}) +' '+ intl.formatMessage({id: 'title.label.actions.role'}) +' '+ intl.formatMessage({id: 'title.label.actions.details'})} />
                    </div>
                ) : null,
        },
        {
            title: intl.formatMessage({id: 'label.name'}),
            dataIndex: 'authority',
            key: 'authority',
            fixed: false,
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('authority'),
        }
    ];

    useEffect(() => {

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearRoleError());
            };
            error();
        } else {
            props.listRoles(props.history);
        }
        // eslint-disable-next-line
    }, [props.error]);

    const handleChange = (pagination, filters, sorter) => {
        props.listRoles(props.history, pagination, filters, sorter);
    };

    const handleDelete = (id) => {
        props.deleteRole(props.history, id);
    };

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.admin" defaultMessage="Admin"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.roles" defaultMessage="Roles"/></span>
                    </Breadcrumb.Item>

                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Link to='/admin/roles/new'><Button type="primary" style={{marginBottom: 16}}>
                        <FormattedMessage id="label.add-role" defaultMessage="Add a Role"/>
                    </Button></Link>
                    <Table
                        bordered={true}
                        loading={props.loading}
                        rowKey={record => record.id}
                        responsive={true}
                        pagination={{
                            current: offset / max + 1,
                            showLessItems: true, pageSizeOptions: [10, 20, 50, 100],
                            pageSize: max,
                            defaultPageSize: max, total: count, showSizeChanger: true,
                            showTotal: ((total, range) => intl.formatMessage({
                                id: 'label.range-total'
                            }, {one: range[0], two: range[1], total: total}))
                        }}
                        size={'default'}
                        showHeader
                        columns={columns}
                        dataSource={roles}
                        onChange={handleChange}
                    />
                </div>
                <Modal title={intl.formatMessage({id: 'label.user-details'})} visible={isModalVisible}
                       onOk={handleOk} onCancel={handleCancel} centered width={720}>
                    {roleDetails}
                </Modal>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    roles: selectRoles(state),
    role: selectCurrentRole(state),
});

export default connect(mapStateToProps, {listRoles, deleteRole, clearRoleError, getRole})(Roles);