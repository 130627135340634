import {Form, Input, Radio, Select, DatePicker, message, Breadcrumb, Layout, Card, Button} from 'antd';
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {getUserRegions} from '../../utils/utilData'
import {useDispatch} from "react-redux";
import {
    setLoading,
    clearLoading,
    addProject,
    getProject,
    editProject,
    clearProjectError
} from "../../redux/project/projectActions";
import {HomeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import moment from 'moment';
import {selectError, selectLoading, selectCurrentProject} from "../../redux/project/projectSelector";

const {Content, Footer} = Layout;


const ProjectForm = (props) => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const {Option} = Select;
    const [projectCreated, setProjectCreated] = useState(false);
    const [edit, setEdit] = useState(props.location.pathname !== '/projects/new');

    const [form] = Form.useForm();

    const onFinish = (values) => {
        values.dateStart = values.dateStart.format('YYYY-MM-DD');
        values.dateEnd = values.dateEnd.format('YYYY-MM-DD');
        values.idLevel = 1;
        if (edit) {
            props.editProject(props.history, props.match.params.id, values, setProjectCreated);
        } else {
            props.addProject(props.history, values, setProjectCreated);
        }

    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const [geoRegions, setGeoRegions] = useState([]);

    useEffect(() => {
        if (geoRegions.length === 0) {
            dispatch(setLoading());
            dispatch(getUserRegions(props.history)).then(
                (data) => {
                    if (data !== undefined && geoRegions.length === 0) {
                        const loadedRegions = [...geoRegions];
                        data.map(geoRegion => loadedRegions.push({label: geoRegion.name, value: geoRegion.regionCode})
                        );
                        setGeoRegions(loadedRegions);
                    }
                    dispatch(clearLoading());
                }
            );
        }
        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearProjectError());
                } else {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearProjectError())));
                }

            };
            error();
        }

        if (edit && !projectCreated && props.error === null) {
            props.getProject(props.history, props.match.params.id).then((data) => {
                if (data) {
                    form.setFieldsValue({
                        'name': data.name,
                        'regionCode': data.regionCode,
                        'language': data.language,
                        'dateStart': moment(data.dateStart),
                        'dateEnd': moment(data.dateEnd),
                        'active': data.active,
                    });
                } else {
                    setEdit(false);
                }
            })
        }

        if (projectCreated) {
            if (edit) {
                message.info(intl.formatMessage({id: 'label.project-updated'}));
            } else {
                form.resetFields();
                message.info(intl.formatMessage({id: 'label.project-created'}));
            }
            setProjectCreated(false);
        }
        // eslint-disable-next-line
    }, [props.error, projectCreated, edit])

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.projects" defaultMessage="Projects"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{edit ? (<FormattedMessage
                            id="label.edit" defaultMessage="Edit"/>) : (
                            <FormattedMessage id="label.new" defaultMessage="New"/>)}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Card type="inner"
                          title={edit ? <FormattedMessage id="label.edit-project" defaultMessage="Edit Project"/> :
                              <FormattedMessage id="label.create-project" defaultMessage="Create a Project"/>}
                          extra={<Link to='/projects'><FormattedMessage id="label.return"
                                                                        defaultMessage="Return"/></Link>}>
                        <Form layout="horizontal" name="project_form" size="large"
                              labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                              initialValues={{active: true}} form={form}
                              onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Form.Item
                                name="name"
                                label={intl.formatMessage({id: 'label.name'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name="regionCode" label={intl.formatMessage({id: 'label.region'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {geoRegions.map(region => {
                                        return(
                                            <Option key={region.value} value={region.value}>
                                                {region.label}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="language" label={intl.formatMessage({id: 'label.language'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>
                                <Select>
                                    <Select.Option value="EN"><FormattedMessage id="label.english"
                                                                                defaultMessage="English"/></Select.Option>
                                    <Select.Option value="ES"><FormattedMessage id="label.spanish"
                                                                                defaultMessage="Spanish"/></Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="dateStart"
                                label={intl.formatMessage({id: 'label.date-start'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <DatePicker format={'YYYY-MM-DD'}/>
                            </Form.Item>
                            <Form.Item
                                name="dateEnd"
                                label={intl.formatMessage({id: 'label.date-end'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <DatePicker format={'YYYY-MM-DD'}/>
                            </Form.Item>
                            <Form.Item name="active" label={intl.formatMessage({id: 'label.active'})}>
                                <Radio.Group>
                                    <Radio value={true}><FormattedMessage id="label.yes" defaultMessage="Yes"/></Radio>
                                    <Radio value={false}><FormattedMessage id="label.no" defaultMessage="No"/></Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading}>
                                    {edit ? (<FormattedMessage id="label.update" defaultMessage="Update"/>) : (
                                        <FormattedMessage id="label.submit" defaultMessage="Submit"/>)}

                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    project: selectCurrentProject(state)
});

export default connect(mapStateToProps, {addProject, getProject, editProject, clearProjectError})(ProjectForm);