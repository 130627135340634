import {AUTH_ACTION_TYPES} from "../typesAction";


const initialState = {
    accessToken: null,
    isAuthenticated: false,
    loading: false,
    error: null
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_ACTION_TYPES.LOGIN_SUCCESS:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                isAuthenticated: true,
            }
        case AUTH_ACTION_TYPES.LOGIN_FAIL:
            return {
                ...state,
                accessToken: null,
                isAuthenticated: false,
                loading: false,
                error: action.payload
            }
        case AUTH_ACTION_TYPES.AUTH_ERROR:
            return {
                ...state,
                accessToken: null,
                isAuthenticated: false,
                loading: false,
                error: action.payload
            }
        case AUTH_ACTION_TYPES.CLEAR_AUTH_ERRORS:
            return {
                ...state,
                error: null
            }
        case AUTH_ACTION_TYPES.LOGOUT:
            return {
                ...state,
                accessToken: null,
                isAuthenticated: false,
                loading: false,
                error: null
            }
        default:
            return state;
    }
};

export default authReducer;