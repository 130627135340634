import {Form, Input, message, Breadcrumb, Layout,
    Card, Button, Select, DatePicker, InputNumber} from 'antd';
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState, useRef} from "react";
import {
    addReportParameter,
    getReportParameter,
    editReportParameter,
    clearReportParameterError
} from "../../redux/reportParameter/reportParameterActions";
import {HomeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    selectError,
    selectLoading,
    selectCurrentReportParameter
} from "../../redux/reportParameter/reportParameterSelector";
import ReactQuill from "react-quill";
import moment from "moment";

const {Content, Footer} = Layout;

const ReportParameterForm = (props) => {
    //local variables
    //---------------
    const intl = useIntl();
    const {Option} = Select;
    const [form] = Form.useForm();
    const { RangePicker } = DatePicker;

    //local states
    //------------
    const [reportParameterCreated, setReportParameterCreated] = useState(false);
    const pat = RegExp(/\/configuration\/reports\/[0-9]{1,1000}\/reportParameters\/new/);
    const [edit, setEdit] = useState(!pat.test(props.location.pathname));
    const dateFormat = 'YYYY-MM-DD';

    const outputFilesAvailableExtensions = ['pdf','html','xlsx','csv','png'];
    const outputFilesNamePattern = `^output(${outputFilesAvailableExtensions.join('|')})[0-9]*$`;
    const isOutputFilePattern = `^output(${outputFilesAvailableExtensions.join('|')}).*$`;


    //Handlers
    //--------

    //output comments
    const [parameterType, setParameterType] = useState();
    const [parameterName, setParameterName] = useState();
    const outputCommentsRef = useRef();
    useEffect(() => {
        outputCommentsRef.current?.getEditor().root.setAttribute('spellcheck', false)
    }, []);
    const [valueOutputComments, setValueOutputComments] = useState();

    //form onFinish
    const onFinish = (values) => {
        let actual_values = {
                    type: values.type,
                    name: values.name,
                    description: values.description,
                    report: values.report
        };

        switch(values.type) {
            case 'date':
                actual_values.value = values.value.format(dateFormat);
                break;
            case 'daterange':
                actual_values.value = values.value.map(v => v.format(dateFormat)).join("-");
                break;
            default:
            actual_values.value = values.value;
        };

        if (edit) {
            props.editReportParameter(props.history, props.match.params.id, actual_values, setReportParameterCreated);
        } else {
            props.addReportParameter(props.history, actual_values, setReportParameterCreated);
        }
    };

    //form onFinishFailed
    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };


    //Effects
    //--------

    //populating form fields
    useEffect(() => {
        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearReportParameterError());
                } else {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearReportParameterError())));
                }
            };
            error();
        }

        if (!edit && !reportParameterCreated && props.error === null) {
            //filling fields with default values for new reportParameters
            form.setFieldsValue({
                'report': props.location.state.report,
                'reportname': props.location.state.reportname
            });
        }

        if (edit && !reportParameterCreated && props.reportParameter === null && props.error === null) {
            props.getReportParameter(props.history, props.match.params.id).then((data) => {
                if (data) {

                    setParameterType(data.type);
                    setParameterName(data.name);

                    form.setFieldsValue({
                        'name': data.name,
                        'description': data.description,
                        'type': data.type,
                        'report': data.report,
                        'reportname': data.reportname,
                    });

                    //setting values
                    switch(data.type) {
                        case 'string':
                            if (data.name === 'outputcomments') {
                                const editorDelta = { //inserting a Delta as value to show text with format
                                                "ops": [
                                                    {"insert": data.value}
                                                ]
                                            };
                                setValueOutputComments(editorDelta);
                            } else {
                                form.setFieldsValue({value: data.value});
                            };
                            break;
                        case 'date':
                            const dateValue = moment(data.value, dateFormat);
                            form.setFieldsValue({value: dateValue});
                            break;
                        case 'daterange':
                            const dateRangeValue = data.value.split('-').map(dateString =>
                                moment(dateString, dateFormat));
                            form.setFieldsValue({value: dateRangeValue});
                            break;
                        default:
                            form.setFieldsValue({value: data.value})
                    };

                } else {
                    setEdit(false);
                }
            })
        }

        if (reportParameterCreated) {
            if (edit) {
                message.info(intl.formatMessage({id: 'label.reportParameter.reportParameter-updated'}));
            } else {
                form.resetFields();
                message.info(intl.formatMessage({id: 'label.reportParameter.reportParameter-created'}));
            }
            setReportParameterCreated(false);
        }

        // eslint-disable-next-line
    }, [props.error, reportParameterCreated, edit]);

    const onFieldsChange = (changedFields) => {
        if (changedFields.length !== 1) return;

        if (changedFields[0].name.includes('type')) {
            form.setFieldsValue({'value': undefined});
            setParameterType(changedFields[0].value);
        };

        if (changedFields[0].name.includes('name')) {
            form.setFieldsValue({'value': undefined});
            setParameterName(changedFields[0].value);
        };

    };


    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.configuration" defaultMessage="Configuration"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports" defaultMessage="Reports"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reportParameters" defaultMessage="Report Parameters"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{edit ? (<FormattedMessage
                            id="label.edit" defaultMessage="Edit"/>) : (
                            <FormattedMessage id="label.new" defaultMessage="New"/>)}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Card type="inner"
                          title={edit ? <FormattedMessage id="label.reportParameter.edit-reportParameter" defaultMessage="Edit Report Parameter"/> :
                              <FormattedMessage id="label.reportParameter.create-reportParameter" defaultMessage="Create a Report Parameter"/>}
                          extra={
                              <Link to={
                                  props.location.state?.fromReportParameters ? `/configuration/reports/reportParameters` :
                                      ({
                                          state: {'creatingNewReport': props.location.state.creatingNewReport},
                                          pathname: `/configuration/reports/${props.location.state.report}`
                                      })
                              } >
                                  <FormattedMessage id="label.return" defaultMessage="Return"/>
                              </Link>
                          }>
                        <Form layout="horizontal" name="reportParameter_form" size="large"
                              labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                              form={form}
                              onFinish={onFinish} onFinishFailed={onFinishFailed}
                              onFieldsChange={onFieldsChange}
                          >
                            <Form.Item
                                name="type"
                                label={intl.formatMessage({id: 'label.reportParameter.type'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required-type'})}]}
                            >
                                <Select>
                                    <Option key={'date'} value={'date'}>{intl.formatMessage({id: 'label.reportParameter.type.date'})}</Option>
                                    <Option key={'daterange'} value={'date_range'}>{intl.formatMessage({id: 'label.reportParameter.type.date_range'})}</Option>
                                    <Option key={'string'} value={'string'}>{intl.formatMessage({id: 'label.reportParameter.type.string'})}</Option>
                                    <Option key={'numeric'} value={'numeric'}>{intl.formatMessage({id: 'label.reportParameter.type.numeric'})}</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                               name="name"
                               label = {intl.formatMessage({id: 'label.reportParameter.name'})}
                               hasFeedback
                               rules={[
                                    {required: true, message: intl.formatMessage({id: 'msg.input-required-name'})},
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (getFieldValue('type') === 'string' &&
                                                new RegExp(isOutputFilePattern, 'g').test(getFieldValue('name')) &&
                                                !new RegExp(outputFilesNamePattern, 'g').test(getFieldValue('name'))) {
                                                const parameterNamePatternForOutputFiles = 'output+fileExtension+optionalNumber';
                                                return (
                                                    Promise.reject(
                                                        new Error(
                                                            intl.formatMessage(
                                                                {id: "msg.input-badParameterNameForFileName"},
                                                                {
                                                                    parameterNamePatternForOutputFiles: parameterNamePatternForOutputFiles,
                                                                    outputFilesAvailableExtensions: outputFilesAvailableExtensions.join(', ')
                                                                 }
                                                            )
                                                        )
                                                    )
                                                );
                                            };

                                            return Promise.resolve();
                                        }
                                    })
                                ]}
                            >
                                <Input type="text"/>
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={intl.formatMessage({id: 'label.reportParameter.description'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required-description'})}]}
                            >
                                <Input.TextArea/>
                            </Form.Item>
                            <Form.Item
                                name="value"
                                label={intl.formatMessage({id: 'label.reportParameter.value'})}
                                hasFeedback
                                rules={[
                                    {required: true, message: intl.formatMessage({id: 'msg.input-required-value'})},
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (getFieldValue('type') === 'string' &&
                                                new RegExp(outputFilesNamePattern, 'g').test(getFieldValue('name')) &&
                                                new RegExp('^([^.]*?)[.](.*)$','g').test(value)) {
                                                  return Promise.reject(new Error(intl.formatMessage(
                                                      {id: 'msg.input-badFileNameValue'})));
                                            };

                                            return Promise.resolve();
                                        }
                                    })
                                ]}
                            >
                                {
                                    (() => {
                                        switch(parameterType) {
                                            case 'date':
                                                return <DatePicker />;
                                            case 'date_range':
                                                return <RangePicker />;
                                            case 'string':
                                                if (parameterName !== 'outputcomments') {
                                                    return (
                                                        <Input
                                                            placeholder={intl.formatMessage({id:'label.report.view.input-string'})}
                                                            size={'large'}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <ReactQuill
                                                            theme="snow"
                                                            id={'editorOutputComments'}
                                                            value={valueOutputComments}
                                                            onChange={setValueOutputComments}
                                                            spellCheck={false}
                                                            modules={{toolbar: null}}
                                                            format={null}
                                                            style={{width: '100%', height: '150px' }}
                                                            ref={outputCommentsRef}
                                                        />
                                                    )
                                                };
                                            case 'numeric':
                                                return (<InputNumber
                                                   placeholder={intl.formatMessage({id:'label.report.view.input-number'})}
                                                   style={{width: "100%"}}
                                               />);
                                            default:
                                                return null
                                        };
                                    })()
                                }
                            </Form.Item>
                            <Form.Item
                                name="report"
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required-report'})}]}
                                hidden
                            >
                                <Input hidden />
                            </Form.Item>
                            <Form.Item
                                name="reportname"
                                label={intl.formatMessage({id: 'label.reportFile.report'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required-report-name'})}]}
                            >
                                <Input disabled={true} />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading}>
                                    {edit ? (<FormattedMessage id="label.update" defaultMessage="Update"/>) : (
                                        <FormattedMessage id="label.submit" defaultMessage="Submit"/>)}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    reportParameter: selectCurrentReportParameter(state)
});

export default connect(mapStateToProps,
    {addReportParameter, getReportParameter,
    editReportParameter, clearReportParameterError})(ReportParameterForm);