import axiosInstance from "../../utils/axiosInstance";
import {STAFF_ACTION_TYPES} from "../typesAction";


export const setLoading = () => {
    return {type: STAFF_ACTION_TYPES.LOAD_STAFF}
};

export const clearLoading = () => {
    return {type: STAFF_ACTION_TYPES.CLEAR_LOAD_STAFF}
};

export const clearStaffError = () => {
    return {type: STAFF_ACTION_TYPES.CLEAR_STAFF_ERRORS}
};

export const listStaff = (history, pagination, filters = {}, sorter) => async dispatch => {
    try {
        dispatch(setLoading());
        // Prepare all query parameters
        let queryParameters = {}
        Object.keys(filters).map(key => (
            queryParameters[key] = filters[key] == null ? filters[key] : filters[key][0])
        );
        if (sorter !== undefined) {
            queryParameters['sort'] = sorter['field']
            queryParameters['order'] = sorter['order'] === 'descend' ? 'desc' : 'asc'
        }
        if (pagination !== undefined) {
            queryParameters['offset'] = (pagination.current - 1) * pagination.pageSize
            queryParameters['max'] = pagination.pageSize
        }
        // Spread the parameters
        const params = {
            ...queryParameters
        };
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).get('/staff', {params});
        // Update state
        
        dispatch({
            type: STAFF_ACTION_TYPES.LIST_STAFF,
            payload: data
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: STAFF_ACTION_TYPES.ERROR_STAFF,
            payload: err.message
        });
    }
};

export const addStaff = (history, staff, setStaffCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).post('/staff', staff);
        // Update state
        dispatch({
            type: STAFF_ACTION_TYPES.ADD_STAFF,
            payload: data
        });
        setStaffCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: STAFF_ACTION_TYPES.ERROR_STAFF,
            payload: error
        });
    }
};

export const getStaff = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        const {data} = await axiosInstance(history, dispatch).get(`/staff/${id}`);
        // Update state
        dispatch({
            type: STAFF_ACTION_TYPES.SHOW_STAFF,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: STAFF_ACTION_TYPES.ERROR_STAFF,
            payload: err.message
        });
    }
};

export const editStaff = (history, id, staff, setStaffCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).patch(`/staff/${id}`, staff);
        // Update state
        dispatch({
            type: STAFF_ACTION_TYPES.EDIT_STAFF,
            payload: data
        });
        setStaffCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: STAFF_ACTION_TYPES.ERROR_STAFF,
            payload: error
        });
    }
};

export const deleteStaff = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        await axiosInstance(history, dispatch).delete(`/staff/${id}`);
        // Update state
        dispatch({
            type: STAFF_ACTION_TYPES.DELETE_STAFF,
            payload: id
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: STAFF_ACTION_TYPES.ERROR_STAFF,
            payload: err.message
        });
    }
};
