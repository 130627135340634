import {createSelector} from "reselect";

const selectStaffState = state => state.staff;

export const selectLoading = createSelector(
    [selectStaffState],
    (staff) => staff.loading
);

export const selectError = createSelector(
    [selectStaffState],
    (staff) => staff.error
);

export const selectStaff = createSelector(
    [selectStaffState],
    (staff) => staff.staff
);

export const selectCurrentStaff = createSelector(
    [selectStaffState],
    (staff) => staff.current
);