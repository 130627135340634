import axiosInstance from "../../../utils/axiosInstance";
import {SURVEY_FORM_ACTION_TYPES} from "../../typesAction";

export const setLoading = () => {
    return {type: SURVEY_FORM_ACTION_TYPES.LOAD_SURVEY_FORM}
};

export const clearLoading = () => {
    return {type: SURVEY_FORM_ACTION_TYPES.CLEAR_LOAD_SURVEY_FORM}
};

export const clearSurveyFormError = () => {
    return {type: SURVEY_FORM_ACTION_TYPES.CLEAR_SURVEY_FORM_ERRORS}
};

export const listSurveyForms = (history, pagination, filters = {}, sorter, entityUrl = 'form', forConfiguration = true) => async dispatch => {
    try {
        dispatch(setLoading());
        // Prepare all query parameters
        let queryParameters = {}
        Object.keys(filters).map(key => (
            queryParameters[key] = filters[key] == null ? filters[key] : filters[key][0])
        );
        if (sorter !== undefined) {
            queryParameters['sort'] = sorter['field']
            queryParameters['order'] = sorter['order'] === 'descend' ? 'desc' : 'asc'
        }
        if (pagination !== undefined) {
            queryParameters['offset'] = (pagination.current - 1) * pagination.pageSize
            queryParameters['max'] = pagination.pageSize
        }

        if (forConfiguration) {
            queryParameters['forConfiguration'] = true
        }
        // Spread the parameters
        const params = {
            ...queryParameters
        };

        // Send request and get data
        const urlArg = '/' + entityUrl;
        const {data} = await axiosInstance(history, dispatch).get(urlArg, {params});

        dispatch({
            type: SURVEY_FORM_ACTION_TYPES.LIST_SURVEY_FORM,
            payload: data
        });
        // Update state
    } catch (err) {
        console.log(err);
        dispatch({
            type: SURVEY_FORM_ACTION_TYPES.ERROR_SURVEY_FORM,
            payload: err.message
        });
    }
};

export const addSurveyForm = (history, form, setSurveyFormCreated, entityUrl = 'form') => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const urlArg = '/' + entityUrl;
        const {data} = await axiosInstance(history, dispatch).post(urlArg, form);
        // Update state
        dispatch({
            type: SURVEY_FORM_ACTION_TYPES.ADD_SURVEY_FORM,
            payload: data
        });
        setSurveyFormCreated(true);
    } catch (err) {
        console.log(err)
        const error = await err.response.data;
        dispatch({
            type: SURVEY_FORM_ACTION_TYPES.ERROR_SURVEY_FORM,
            payload: error
        });
        //return { result: "ko", error: error };
    }
};

export const editSurveyForm = (history, id, form, setSurveyFormCreated, entityUrl = 'form') => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const urlArg = '/' + entityUrl + `/${id}`;
        const {data} = await axiosInstance(history, dispatch).patch(urlArg, form);
        // Update state
        dispatch({
            type: SURVEY_FORM_ACTION_TYPES.EDIT_SURVEY_FORM,
            payload: data
        });
        setSurveyFormCreated(true);
    } catch (err) {
        const error = await err.response.data;
        dispatch({
            type: SURVEY_FORM_ACTION_TYPES.ERROR_SURVEY_FORM,
            payload: error
        });
        //return { result: "ko", error: error };
    }
};

export const getSurveyForm = (history, id, entityUrl = 'form') => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        const urlArg = `/` + entityUrl + `/${id}`;
        const {data} = await axiosInstance(history, dispatch).get(urlArg);
        // Update state
        dispatch({
            type: SURVEY_FORM_ACTION_TYPES.SHOW_SURVEY_FORM,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: SURVEY_FORM_ACTION_TYPES.ERROR_PROJECT,
            payload: err.message
        });
    }
};

export const deleteSurveyForm = (history, id, entityUrl = 'form') => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        const urlArg = `/` + entityUrl + `/${id}`;
        await axiosInstance(history, dispatch).delete(urlArg);
        // Update state
        dispatch({
            type: SURVEY_FORM_ACTION_TYPES.DELETE_SURVEY_FORM,
            payload: id
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: SURVEY_FORM_ACTION_TYPES.ERROR_SURVEY_FORM,
            payload: err.message
        });
    }
};
