import {Breadcrumb, Layout, message, Card, InputNumber,
    Form, Button, Space, DatePicker, Input, Tooltip} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import {connect} from "react-redux";
import React, {useEffect, useState, useRef} from "react";
import {viewReport, clearReportError, getReport} from "../../redux/report/reportActions";
import {selectError, selectView, selectLoading, selectCurrentReport} from "../../redux/report/reportSelector";
import './ReportView.css'
import {listReportParameters} from "../../redux/reportParameter/reportParameterActions";
import {selectReportParameters} from "../../redux/reportParameter/reportParameterSelector";
import {Link} from "react-router-dom";
import moment from "moment";
import {selectCurrentUser} from "../../redux/user/userSelector";
import ReactQuill from "react-quill";

const {Content, Footer} = Layout;

const ReportView = (props) => {

    const intl = useIntl();
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    const dateFormat = 'YYYY-MM-DD';

    //local states
    const [isConfig] = useState(() => {
        const regExp = new RegExp('^/configuration/reports/view/[0-9]*$', 'g');
        return regExp.test(props.location.pathname)
    });
    const [hiddenErrors, setHiddenErrors] = useState(true);
    const [hiddenWarnings, setHiddenWarnings] = useState(true);
    const [hiddenProperties, setHiddenProperties] = useState(true);
    const [reportName, setReportName] = useState("");
    const [cardLoading, setCardLoading] = useState(true);
    const [updatedParameters, setUpdatedParameters] = useState(false);

    //Handlers
    //--------
    const onFinish = (values) => {
        let p;
        const names = Object.keys(values);
        names.forEach(name => {
            p = props.reportReportParameters.reportParameters.find(rP => {
                return rP.name === name
            });
            switch (p.type) {
                case 'date':
                        values[name] = [values[name].format(dateFormat)];
                    break;
                case 'date_range':
                        values[name] = [values[name][0].format(dateFormat)+","+values[name][1].format(dateFormat)];
                    break;
                default:
                    values[name] = [values[name]]
            }
        });
        values['up'] = [1];
        values['userId'] = [props.currentUser.id];
        props.viewReport(props.history, props.match.params.id, values).then((data) => {
            setUpdatedParameters(true)
        })
    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const outputParameters = ['outputpdf', 'outputhtml', 'outputxlsx', 'outputtitle', 'outputcomments'];
    const mustBeHidden = (pName) =>  !isConfig && outputParameters.includes(pName) && props.report?.subscription ===
    'no';

    //Effects
    //-------

    //effect - loading reportParameters
    useEffect(() => {
        const filters = {report: [props.match.params.id]};
        props.getReport(props.history, props.match.params.id);
        props.listReportParameters(props.history, undefined, filters, undefined)
        //eslint-disable-next-line
    }, []);

    //effect - show/hide parameters,  populate parameters form fields
    useEffect(() => {
        if (props.reportReportParameters) {
            setHiddenProperties(props.reportReportParameters.count === 0);
            //populating parameters values in form
            if (props.reportReportParameters.count > 0) {
                props.reportReportParameters.reportParameters.forEach(p => {
                    switch (p.type) {
                        case 'date':
                            form.setFieldsValue({[p.name]: p.value !== null ? moment(p.value, dateFormat) : null});
                            break;
                        case 'date_range':
                            form.setFieldsValue({[p.name]: p.value !== null ? [moment(p.value.split(',')[0], dateFormat),
                                    moment(p.value.split(',')[1], dateFormat)] : null});
                            break;
                        default:
                            form.setFieldsValue({[p.name]: p.value});
                    }
                })
            }
        }
        //eslint-disable-next-line
    }, [props.reportReportParameters]);

    //effect - load props.view without updating parameters
    useEffect(() => {
        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearReportError());
            };
            error();
        }

        if (props.error === null && !updatedParameters) {
            const filters = {'userId': [props.currentUser.id]};
            props.viewReport(props.history, props.match.params.id, filters)
        }
        // eslint-disable-next-line
    }, [props.error, updatedParameters]);

    //effects - show/hide errors and warnings, and reportName
    useEffect(() => {
        if (props.view) {
            setHiddenErrors(props.view?.error_message === "null" );
            setHiddenWarnings(props.view?.warning_message === "null");
            setReportName(props.view?.reportName);
            setCardLoading(false);
        }
        // eslint-disable-next-line
    }, [props.view]);

    //output comments
    const outputCommentsRef = useRef();
    useEffect(() => {
        outputCommentsRef.current?.getEditor().root.setAttribute('spellcheck', false)
    }, []);
    const [valueOutputComments, setValueOutputComments] = useState();
    const [onlyOnce, setOnlyOnce] = useState(true);
    useEffect(() => {
        if (!onlyOnce) return;

        if (!props.reportReportParameters.reportParameters) return;

        setOnlyOnce(false);

        let textToInsert = props.reportReportParameters.reportParameters.filter(rP =>
            rP.name === 'outputcomments').map(rP => rP.value)[0];
        if (!textToInsert) textToInsert = '';
        const editorDelta = { //inserting a Delta as value to show text with format
                "ops": [
                    {"insert": textToInsert}
                ]
            };
            setValueOutputComments(editorDelta);

        //eslint-disable-next-line
    }, [props.reportReportParameters.reportParameters, onlyOnce]);

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    {
                        isConfig
                            ? <Breadcrumb.Item>
                                  <span><FormattedMessage id="menu.configuration" defaultMessage="Configuration"/></span>
                              </Breadcrumb.Item>
                            : null
                    }
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports" defaultMessage="Reports"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports.view" defaultMessage="View"/></span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Card
                    title={intl.formatMessage({id:'label.report.view.loading'})}
                    hidden={!cardLoading}
                >
                </Card>
                <Card
                    title={reportName}
                    extra={
                        <Link  to={isConfig ? '/configuration/reports' : '/reports'}>
                            <FormattedMessage id="label.return" defaultMessage="Return" />
                        </Link>
                    }
                    hidden={cardLoading}
                >
                    <Card
                        type={"inner"}
                        title={intl.formatMessage({id: 'label.report.view.errors'})}
                        hidden={hiddenErrors || cardLoading}
                    >
                        <label className="error">
                            {
                                isConfig
                                    ? props.view?.error_message
                                    : intl.formatMessage({id: "label.report.view.toUser.contact.admin"})
                            }
                        </label>
                    </Card>
                    {
                        isConfig
                            ? <Card
                                type={"inner"}
                                title={intl.formatMessage({id: 'label.report.view.warnings'})}
                                hidden={hiddenWarnings || cardLoading }
                            >
                                <label className="warning">
                                    {props.view?.warning_message}
                                </label>
                            </Card>
                            :null
                    }

                    {
                        props.view?.htmlContent === 'parametersMustBeUpdatedFirst'
                            ? null
                            : <Card
                                type={'inner'}
                                hidden={cardLoading || props.view?.error_message !== "null"}
                            >
                                {(!props.view?.updatedCustomParameters &&
                                    (props.view?.reportType !== 'html') &&
                                    props.view?.existCustomParameters) ? (
                                    <label className="message">
                                        {props.view?.htmlContent}
                                    </label>
                                ) : (
                                    <iframe srcDoc={props.view?.htmlContent}
                                            className={"iframe-html"}
                                            title={"htmlContent"}

                                    >
                                        <FormattedMessage id="label.report.view.renderingHtml.notCompatibleBrowserForIframe"/>
                                    </iframe>
                                )
                                }
                            </Card>
                    }
                    <Card
                        type={'inner'}
                        title={intl.formatMessage({id: 'label.report.view.parameters'})}
                        hidden={hiddenProperties || cardLoading}
                    >
                        <Form
                            layout="vertical" name="reportParameter_form" size="large"
                            form={form}
                            onFinish={onFinish} onFinishFailed={onFinishFailed}
                        >
                            <Space direction={"horizontal"} align={"start"} wrap={true}  >
                                {props.reportReportParameters?.reportParameters?.sort(
                                    (a, b) => a.type === b.type ? (
                                        a.name === b.name ?
                                            0 :
                                            (a.name < b.name ? -1 : 1)): (a.type < b.type ? -1 : 1))?.map(
                                    p => {
                                        switch(p.type) {
                                            case 'date':
                                                return (
                                                    <Form.Item
                                                        name={p.name}
                                                        label={
                                                            <Tooltip title={p.description}>
                                                                {p.name}
                                                            </Tooltip>
                                                        }
                                                        rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                                                        key={p.id}
                                                        wrapperCol={{span: 20,}}
                                                        hidden={mustBeHidden(p.name)}
                                                    >
                                                        <DatePicker />
                                                    </Form.Item>);
                                            case 'date_range':
                                                return (
                                                    <Form.Item
                                                        name={p.name}
                                                        label={
                                                            <Tooltip title={p.description}>
                                                                {p.name}
                                                            </Tooltip>
                                                        }
                                                        rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                                                        key={p.id}
                                                        wrapperCol={{span: 20,}}
                                                        hidden={mustBeHidden(p.name)}
                                                    >
                                                        <RangePicker />
                                                    </Form.Item>);
                                            case 'string':
                                                if (p.name !== 'outputcomments') {
                                                    return (
                                                        <Form.Item
                                                            name={p.name}
                                                            label={
                                                                <Tooltip title={p.description}>
                                                                    {p.name}
                                                                </Tooltip>
                                                            }
                                                            rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                                                            key={p.id}
                                                            wrapperCol={{span: 80,}}
                                                            hidden={mustBeHidden(p.name)}
                                                        >
                                                            <Input
                                                                placeholder={intl.formatMessage({id:'label.report.view.input-string'})}
                                                                size={'large'}
                                                            />
                                                        </Form.Item>)
                                                } else {
                                                    return null;
                                                };
                                            case 'numeric':
                                                return (
                                                    <Form.Item
                                                        name={p.name}
                                                        label={
                                                            <Tooltip title={p.description}>
                                                                {p.name}
                                                            </Tooltip>
                                                        }
                                                        rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                                                        key={p.id}
                                                        wrapperCol={{span: 50, }}
                                                        hidden={mustBeHidden(p.name)}
                                                    >
                                                        <InputNumber
                                                            placeholder={intl.formatMessage({id:'label.report.view.input-number'})}
                                                            className={"input-number"}
                                                        />
                                                    </Form.Item>);
                                            default:
                                                return null
                                        }
                                    })
                                }
                            </Space>
                            {
                                (() => {
                                    const isComments = props.reportReportParameters?.reportParameters?.filter(rP =>
                                        rP.name === 'outputcomments')?.length === 1;

                                    if (!isComments) {
                                        return null
                                    } else {
                                        const p = props.reportReportParameters?.reportParameters?.filter(rP =>
                                             rP.name === 'outputcomments')[0];
                                        return (
                                            <>
                                                <Form.Item
                                                    name={p.name}
                                                    label={
                                                        <Tooltip title={p.description}>
                                                            {p.name}
                                                        </Tooltip>
                                                    }
                                                    rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                                                    key={p.id}
                                                    wrapperCol={{span: 80,}}
                                                    hidden={mustBeHidden(p.name)}
                                                >
                                                    <ReactQuill
                                                        theme="snow"
                                                        id={'editorOutputComments'}
                                                        value={valueOutputComments}
                                                        onChange={setValueOutputComments}
                                                        spellCheck={false}
                                                        modules={{toolbar: null}}
                                                        format={null}
                                                        style={{width: '100%', height: '150px' }}
                                                        ref={outputCommentsRef}
                                                    />
                                                </Form.Item>

                                            </>
                                        )
                                    };
                                })()
                            }
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading} id='submitBtn'
                                >
                                    <FormattedMessage id="label.report.view.view-report" defaultMessage="View Report"/>
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Card>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    view: selectView(state),
    reportReportParameters: selectReportParameters(state),
    currentUser: selectCurrentUser(state),
    report: selectCurrentReport(state)
});

export default connect(mapStateToProps,
    {viewReport, clearReportError,
        listReportParameters, getReport})(ReportView);