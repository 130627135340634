import {Breadcrumb, Layout, message, Table, Popconfirm, Button, Modal, Form, Select, Input} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect, useDispatch} from "react-redux";
import {useEffect, useState} from "react";

import {selectLoading, selectError, selectAlertContacts} from "../../redux/alertContact/alertContactSelector";
import {
    listAlertContacts,
    deleteAlertContact,
    clearAlertContactError,
    getAlertContact,
    setLoading
} from '../../redux/alertContact/alertContactActions';
import {Link} from "react-router-dom";
import {selectProjects} from "../../redux/project/projectSelector";
import {listProjects, notifyAlertContacts} from "../../redux/project/projectActions";
import {selectCurrentUser} from "../../redux/user/userSelector";
import {selectIsAuthenticated} from "../../redux/auth/authSelector";
import {getAlertContactByProject} from "../../utils/utilData";
import TextArea from "antd/lib/input/TextArea";


const {Content, Footer} = Layout;

const AlertContact = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const alertContactEmptyList = [{label: intl.formatMessage({id: 'label.all'}), value: 'All'}]
    const {alertContacts, count, max, offset} = props.alertContacts;
    const {projects} = props.projects;
    const [projectFilter, setProjectFilter] = useState([]);
    const [isNotifyModalVisible, setIsNotifyVisible] = useState(false);
    const [notifyLoading, setNotifyLoading] = useState(false);
    const [projectList, setProjectList] = useState([{label: intl.formatMessage({id: 'label.select'}), value: null}])
    const [alertContactList, setAlertContactList] = useState(alertContactEmptyList)
    const [form] = Form.useForm();

    const checkPermissions = (requiredPermission) => {
        return props.isAuthenticated && requiredPermission && props.currentUser && props.currentUser?.permissions?.find((permission) => permission === requiredPermission)
    }

    const handleNotifyOk = () => {
        setIsNotifyVisible(false);
    };

    const handleNotifyCancel = () => {
        setIsNotifyVisible(false);
    };

    const onNotifyFinish = (values) => {
        notifyAlertContact(values.project, values)
    };

    const onNotifyProjectSelected = async (selected) => {
        setNotifyLoading(true);
        let pagination = {}
        pagination['current'] = 1;
        pagination['pageSize'] = 2000;
        let sorter = {}
        sorter['field'] = 'name';
        let filter = {}
        filter['project'] = selected;
        if (selected !== null) {
            const alertContactRecords = await dispatch(getAlertContactByProject(props.history, selected, 'notifications'))
            const loadedAlertContact = [...alertContactEmptyList];
            alertContactRecords?.map(alertContact => loadedAlertContact.push({
                label: alertContact.name,
                value: alertContact.email
            }))
            setAlertContactList(loadedAlertContact)
        } else {
            setAlertContactList(alertContactEmptyList)
        }
        setNotifyLoading(false)
    }

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const columns = [{
        title: intl.formatMessage({id: 'label.id'}),
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '10%',
        fixed: true,
        sorter: (a, b) => a.id - b.id,
        sortDirections: ['ascend', 'descend'],
    }, {
        title: intl.formatMessage({id: 'label.actions'}),
        dataIndex: 'actions',
        align: 'center',
        width: '10%',
        fixed: true,
        render: (_, record) => count >= 1 ? (<div className="actionsIcons">
            <Popconfirm title={intl.formatMessage({id: 'msg.confirm-delete'})}
                        onConfirm={() => handleDelete(record.id)}>
                <DeleteTwoTone twoToneColor="red" title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.contact'})} />
            </Popconfirm>
            <Link to={`/contacts/${record.id}`}><EditTwoTone title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.contact'})} /></Link>
        </div>) : 'null',
    }, {
        title: intl.formatMessage({id: 'label.name'}),
        dataIndex: 'name',
        key: 'name',
        width: '25%',
        fixed: false,
        sorter: (a, b) => a.name - b.name,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false, ...columnSearchProps('name'),
    }, {
        title: intl.formatMessage({id: 'label.email'}),
        dataIndex: 'email',
        key: 'email',
        width: '15%',
        fixed: false,
        sorter: (a, b) => a.email.length - b.email.length,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false, ...columnSearchProps('email'),
    }, {
        title: intl.formatMessage({id: 'label.phone'}),
        dataIndex: 'phone',
        key: 'phone',
        width: '15%',
        fixed: false,
        sorter: (a, b) => a.phone.length - b.phone.length,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false, ...columnSearchProps('phone'),
    }, {
        title: intl.formatMessage({id: 'label.notifications'}),
        dataIndex: 'notifications',
        key: 'notifications',
        width: '12%',
        fixed: false,
        sorter: (a, b) => a.notifications - b.notifications,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false,
        filters: [
            {
                text: intl.formatMessage({id: 'label.yes'}),
                value: true,
            },
            {
                text: intl.formatMessage({id: 'label.no'}),
                value: false,
            },
        ],
        render: (text) => (text ? intl.formatMessage({id: 'label.yes'}) : intl.formatMessage({id: 'label.no'}))
    }, {
        title: intl.formatMessage({id: 'label.subscriptions'}),
        dataIndex: 'subscriptions',
        key: 'subscriptions',
        width: '12%',
        fixed: false,
        sorter: (a, b) => a.subscriptions - b.subscriptions,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false,
        filters: [
            {
                text: intl.formatMessage({id: 'label.yes'}),
                value: true,
            },
            {
                text: intl.formatMessage({id: 'label.no'}),
                value: false,
            },
        ],
        render: (text) => (text ? intl.formatMessage({id: 'label.yes'}) : intl.formatMessage({id: 'label.no'}))
    }, {
        title: intl.formatMessage({id: 'label.project'}),
        dataIndex: 'project',
        key: 'project',
        width: '30%',
        fixed: false,
        sorter: (a, b) => a.project - b.project,
        sortDirections: ['ascend', 'descend'],
        filters: projectFilter,
        render: (projectId) => (projectId ? projects?.find((project) => project.id === projectId)?.name : ''),
    }];

    useEffect(() => {

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearAlertContactError());
            };
            error();
        } else {
            if (props.projects === null || props.projects.length === 0) {
                dispatch(setLoading());
                let pagination = {}
                pagination['current'] = 1;
                pagination['pageSize'] = 9999999;
                let sorter = {}
                sorter['field'] = 'name';
                dispatch(listProjects(props.history, pagination, {}, sorter));
            } else {
                setProjectFilter(projects?.map(project => ({text: project.name, value: project.id})));
                const loadedProjects = [...projectList];
                projects?.map(project => loadedProjects.push({label: project.name, value: project.id}))
                setProjectList(loadedProjects);
                props.listAlertContacts(props.history);
            }

        }
        // eslint-disable-next-line
    }, [props.error, props.projects]);

    const handleChange = (pagination, filters, sorter) => {
        props.listAlertContacts(props.history, pagination, filters, sorter);
    };

    const handleDelete = (id) => {
        props.deleteAlertContact(props.history, id);
    };

    const notifyAlertContact = (projectId, configuration) => {
        setNotifyLoading(true);
        props.notifyAlertContacts(props.history, projectId, configuration).then((result) => {
            setNotifyLoading(false);
            if (result) {
                message.info(intl.formatMessage({id: 'label.contact-notified'})).then(() => {
                    setIsNotifyVisible(false);
                    form.resetFields();
                });
            } else {
                setIsNotifyVisible(false);
                form.resetFields();
            }

        });
    };

    return (<Layout className="site-layout">
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '10px 0'}}>
                <Breadcrumb.Item>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span><FormattedMessage id="label.contacts" defaultMessage="Contacts"/></span>
                </Breadcrumb.Item>

            </Breadcrumb>
            <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                <Link to='/contacts/new'><Button type="primary" size={"large"} style={{marginBottom: 16}}>
                    <FormattedMessage id="label.add-contact" defaultMessage="Add Contact"/>
                </Button></Link>
                {checkPermissions('ROLE_ADMIN') ? <>
                    <Button style={{marginLeft: 16}} type="primary" size={"large"} loading={notifyLoading}
                            disabled={alertContacts?.length === 0} onClick={() => setIsNotifyVisible(true)}>
                        <FormattedMessage id="label.notify-contacts" defaultMessage="Notify Contacts"/>
                    </Button>
                </> : <></>}


                <Table
                    bordered={true}
                    loading={props.loading}
                    rowKey={record => record.id}
                    responsive={true}
                    pagination={{
                        current: offset / max + 1,
                        showLessItems: true,
                        pageSizeOptions: [10, 20, 50, 100],
                        pageSize: max,
                        defaultPageSize: max,
                        total: count,
                        showSizeChanger: true,
                        showTotal: ((total, range) => intl.formatMessage({
                            id: 'label.range-total'
                        }, {one: range[0], two: range[1], total: total}))
                    }}
                    showHeader
                    columns={columns}
                    dataSource={alertContacts}
                    scroll={{y: '65vh', x: '100vw'}}
                    onChange={handleChange}
                />
            </div>
            <Modal title={intl.formatMessage({id: 'label.notify-contacts'})} visible={isNotifyModalVisible}
                   onOk={handleNotifyOk} onCancel={handleNotifyCancel} centered width={720}
                   confirmLoading={notifyLoading}
                   footer={null}>
                <Form layout="horizontal" name="alertContact_form" size="large"
                      labelCol={{span: 4,}} wrapperCol={{span: 14,}}
                      initialValues={{active: true}} form={form}
                      onFinish={onNotifyFinish} onFinishFailed={onFinishFailed}>
                    <Form.Item key={"project"} name="project" label={intl.formatMessage({id: 'label.project'})}
                               rules={[{
                                   required: true, message: intl.formatMessage({id: 'msg.input-required'})
                               }]}>
                        <Select options={projectList} onSelect={onNotifyProjectSelected}>

                        </Select>
                    </Form.Item>
                    <Form.Item key={"members"} name="members" label={intl.formatMessage({id: 'label.contacts'})}
                               rules={[{
                                   required: true, message: intl.formatMessage({id: 'msg.input-required'})
                               }]}>

                        <Select options={alertContactList} allowClear={true} mode={'multiple'} loading={notifyLoading}>

                        </Select>
                    </Form.Item>
                    <Form.Item key={"header"} name="header" label={intl.formatMessage({id: 'label.subject'})}
                               rules={[{
                                   required: true, message: intl.formatMessage({id: 'msg.input-required'}),
                               },
                                   {
                                       min: 3, max: 100
                                   }]}>

                        <Input type={"text"}
                        />
                    </Form.Item>
                    <Form.Item key={"message"} name="message" label={intl.formatMessage({id: 'label.message'})}
                               rules={[{
                                   required: true, message: intl.formatMessage({id: 'msg.input-required'})
                               }, {
                                   min: 3, max: 255
                               }]}>

                        <TextArea allowClear size={"large"} showCount={true}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="danger" htmlType="submit" loading={notifyLoading}>
                            <FormattedMessage id="label.notify" defaultMessage="Notify"/>
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Content>
        <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
    </Layout>);
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    alertContacts: selectAlertContacts(state),
    projects: selectProjects(state),
    currentUser: selectCurrentUser(state),
    isAuthenticated: selectIsAuthenticated(state)
});

export default connect(mapStateToProps, {
    listAlertContacts,
    deleteAlertContact,
    clearAlertContactError,
    getAlertContact,
    notifyAlertContacts,
})(AlertContact);
