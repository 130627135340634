import {Breadcrumb, Layout, message, Table, Popconfirm, Button, Tabs} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone, EyeTwoTone} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect} from "react-redux";
import {useEffect, useRef, useState} from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import {selectLoading, selectCurrentLayer} from "../../redux/layer/layerSelector";
import {selectError} from "../../redux/pointsOfInterest/pointsOfInterestSelector";
import {
    getLayer
} from '../../redux/layer/layerActions';
import {
    getPointsOfInterest,
    deletePointsOfInterest,
    clearPointsOfInterestError
} from "../../redux/pointsOfInterest/pointsOfInterestActions";
import {Link} from "react-router-dom";
import {selectCurrentUser, selectGeoArea} from "../../redux/user/userSelector";
import {selectIsAuthenticated} from "../../redux/auth/authSelector";
import * as turf from "@turf/turf";
import {getUserGeoArea} from "../../utils/utilData";
import StylesControlByRadio
    from "../../utils/dataAnalysis/Plots/CustomMapboxControls/StylesControl/StylesControlByRadio";
import {getPopupDOMContentForPointsOfInterest} from "../surveyForms/getPopupDOMContentForPointsOfInterest";
import GoogleMapReact from 'google-map-react';

const optionsValuesStylesControl = [
    "streets",
    "satellite-streets"
]
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
const {Content, Footer} = Layout;

const PointsOfInterest = (props) => {
    const intl = useIntl();
    const mapContainerRef = useRef();
    const [map, setMap] = useState(null);
    const [googleMap, setGoogleMap] = useState(null);
    const [googleMaps, setGoogleMaps] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(null);
    const [mapStyle] = useState({
        label: 'Satellite streets',
        value: 'satellite-streets',
        url: "mapbox://styles/mapbox/satellite-streets-v11"
    });
    const [stylesControl, setStylesControl] = useState(new StylesControlByRadio(optionsValuesStylesControl, mapStyle, null, null));


    const checkPermissions = (requiredPermission) => {
        return props.isAuthenticated && requiredPermission && props.currentUser && props.currentUser?.permissions?.find((permission) => permission === requiredPermission)
    }

    const fillMap = (data) => {
        let {lat, lon, geojson} = props.geoArea;
        let map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: mapStyle.url,
            center: [lon, lat],
            zoom: 2
        });

        // Add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl(), 'top-right');
        map.addControl(new mapboxgl.FullscreenControl(), 'top-right');
        map.addControl(stylesControl, 'top-right');
        const geolocate = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        })
        map.addControl(geolocate);
        map.on('load', () => {
            map.resize();
            map.addSource('region', {type: 'geojson', data: geojson});
            map.addLayer({
                'id': 'region',
                'type': 'line',
                'source': 'region',
                'layout': {
                    'visibility': 'visible'
                },
                'paint': {
                    'line-color': 'green',
                    'line-width': 2,
                }
            });

            data.data.forEach(point => {
                const feature = {
                    properties: {
                        name: point?.name,
                        description: point?.description
                    }
                }
                new mapboxgl.Marker({
                    color: data.color, scale: 1,
                    draggable: false,
                    pitchAlignment: 'auto',
                    rotationAlignment: 'auto'
                })
                    .setLngLat([point?.lon, point?.lat])
                    .setPopup(
                        new mapboxgl.Popup({closeButton: false}) // add popups
                            .setDOMContent(getPopupDOMContentForPointsOfInterest(feature))
                    )
                    .addTo(map);
            })


            const bbox = turf.bbox(geojson);
            map.fitBounds(bbox, {padding: 20});
        });
        setMap(map);

        return () => map.remove();
    }

    function createMapOptions(maps) {
        return {
            zoomControlOptions: {
                style: maps.ZoomControlStyle.SMALL
            },
            mapTypeControlOptions: {
                position: maps.ControlPosition.TOP_RIGHT
            },
            mapTypeControl: true

        };
    }

    const handleApiLoaded = (map, maps, layer) => {
        map.setMapTypeId('hybrid')
        let {geojson} = props.geoArea;
        const bounds = new maps.LatLngBounds();
        const bbox = turf.bbox(geojson);
        bounds.extend(new maps.LatLng(
            bbox[1],
            bbox[0],
        ));
        bounds.extend(new maps.LatLng(
            bbox[3],
            bbox[2],
        ));

        map.fitBounds(bounds, {padding: 20})


        layer.data.forEach(point => {
            const feature = {
                properties: {
                    name: point?.name,
                    description: point?.description
                }
            }
           const marker = new maps.Marker({
                position: new maps.LatLng(
                    point?.lat,
                    point?.lon,
                ),
                map,
                title: point?.name,
                anchor: map,
                icon: {
                    path: "M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z",
                    fillColor: layer.color,
                    fillOpacity: 1,
                    strokeWeight: 0.3,
                    scale: 0.8,
                    anchor: new maps.Point(0, 20),
                },
            })
            const infoWindow = new maps.InfoWindow({
                content: getPopupDOMContentForPointsOfInterest(feature)
            });

            marker.addListener("click", () => {
                infoWindow.open({
                    anchor: marker,
                    map,
                });
            });
        })
        setGoogleMaps(maps)
        setGoogleMap(map)
    };


    const showPointOfInterest = (record) => {
        map.flyTo({
            center: [record.lon, record.lat],
            zoom: 15,
            essential: true // this animation is considered essential with respect to prefers-reduced-motion
        })

        if (googleMaps){
            const bounds = new googleMaps.LatLngBounds();
            bounds.extend(new googleMaps.LatLng(
                record.lat,
                record.lon,
            ));
            googleMap.fitBounds(bounds, {padding: 20})
        }
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'label.id'}),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '5%',
            fixed: true,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: intl.formatMessage({id: 'label.actions'}),
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            fixed: true,
            render: (_, record) =>
                props.layer?.data?.length >= 1 ? (
                    <div className="actionsIcons">
                        {checkPermissions('ROLE_PERMISSION_LAYER_EDIT') ? <><Popconfirm
                            title={intl.formatMessage({id: 'msg.confirm-delete'})}
                            onConfirm={() => handleDelete(record.id)}>
                            <DeleteTwoTone twoToneColor="red"
                                           title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.pointOfInterest'})}/>
                        </Popconfirm>
                            <Link
                                to={`/layers/points-of-interest/${props.layer.id}/data/${record.id}`}><EditTwoTone
                                title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.pointOfInterest'})}/></Link></> : <></>}

                        <EyeTwoTone onClick={() => {
                            showPointOfInterest(record)
                        }} twoToneColor="green"
                                    title={intl.formatMessage({id: 'title.label.actions.show'}) +' '+ intl.formatMessage({id: 'title.label.actions.pointOfInterest'}) +' '+ intl.formatMessage({id: 'title.label.actions.details'})}/>
                    </div>
                ) : null,
        },
        {
            title: intl.formatMessage({id: 'label.name'}),
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            fixed: false,
            sorter: (a, b) => a.name > b.name,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('name'),
        },
        {
            title: intl.formatMessage({id: 'label.description'}),
            dataIndex: 'description',
            key: 'description',
            width: '20%',
            fixed: false,
            sorter: (a, b) => a.description - b.description,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('description'),
        }, {
            title: intl.formatMessage({id: 'label.latitude'}),
            dataIndex: 'lat',
            key: 'lat',
            width: '30%',
            fixed: false,
            sorter: (a, b) => a.lat - b.lat,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false
        },
        {
            title: intl.formatMessage({id: 'label.longitude'}),
            dataIndex: 'lon',
            key: 'lon',
            width: '30%',
            fixed: false,
            sorter: (a, b) => a.lon - b.lon,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false
        }

    ];

    useEffect(() => {
        setStylesControl(new StylesControlByRadio(optionsValuesStylesControl, mapStyle, null, null))
        //eslint-disable-next-line
    }, [mapStyle]);

    useEffect(() => {
        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearPointsOfInterestError());
            };
            error();
        } else {
            if (!props.layer || !map || forceRefresh) {
                setForceRefresh(false)
                props.getLayer(props.history, props.match.params.id)
            }
            if (props.layer || forceRefresh) {
                setForceRefresh(false)
                fillMap(props.layer);
            }

        }
        if (props.geoArea === undefined || props.geoArea === null) {
            props.getUserGeoArea(props.history)
        }

        // eslint-disable-next-line
    }, [props.error, props.geoArea, props.layer, forceRefresh, stylesControl]);

    const handleDelete = async (id) => {
        props.deletePointsOfInterest(props.history, id);
        setForceRefresh(true);
    };


    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={'/layers/points-of-interest'}><FormattedMessage id="menu.layers"
                                                                                  defaultMessage="Layers"/></Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="label.points-of-interest"
                                                defaultMessage="Points of Interest"/></span>
                    </Breadcrumb.Item>

                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    {checkPermissions('ROLE_PERMISSION_LAYER_EDIT') ?
                        <Link to={`/layers/points-of-interest/${props.match.params.id}/new`}><Button type="primary"
                                                                                                     size={"large"}
                                                                                                     style={{marginBottom: 16}}>
                            <FormattedMessage id="label.add-points-of-interest"
                                              defaultMessage="Add Points of Interest"/>
                        </Button></Link> : <></>}
                    <Table
                        bordered={true}
                        loading={props.loading}
                        rowKey={record => record.id}
                        responsive={true}
                        size={'default'}
                        showHeader
                        columns={columns}
                        dataSource={props.layer?.data}
                        scroll={{y: '65vh', x: '100vw'}}
                    />
                </div>
                <Tabs
                    type="card"
                    items={[
                        {
                            label: `Mapbox`,
                            key: 'mapboxTab',
                            children: <div className="map-container" ref={mapContainerRef}
                                style={{ height: 500, width: '100%' }} />,
                        },
                        {
                            label: `GoogleMaps`,
                            key: 'googleMapTab',
                            children: <div style={{ height: 500, width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
                                    defaultCenter={{
                                        lat: 0,
                                        lng: 0
                                    }}
                                    options={createMapOptions}
                                    defaultZoom={9}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, props.layer)}
                                >

                                </GoogleMapReact>
                            </div>,
                        }
                    ]}
                />


            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    layer: selectCurrentLayer(state),
    currentUser: selectCurrentUser(state),
    isAuthenticated: selectIsAuthenticated(state),
    geoArea: selectGeoArea(state)
});

export default connect(mapStateToProps, {
    clearPointsOfInterestError,
    getLayer,
    getUserGeoArea,
    getPointsOfInterest,
    deletePointsOfInterest
})(PointsOfInterest);
