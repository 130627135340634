import {STAFF_ACTION_TYPES} from "../typesAction";


const initialState = {
    staff: [],
    current: null,
    loading: false,
    error: null
}

const staffReducer = (state = initialState, action) => {
    switch (action.type) {
        case STAFF_ACTION_TYPES.LOAD_STAFF:
            return {
                ...state, loading: true
            }
        case STAFF_ACTION_TYPES.CLEAR_LOAD_STAFF:
            return {
                ...state, loading: false
            }
        case STAFF_ACTION_TYPES.ERROR_STAFF:
            return {
                ...state, error: action.payload, loading: false, current: null
            }
        case STAFF_ACTION_TYPES.CLEAR_STAFF_ERRORS:
            return {
                ...state,
                error: null, loading: false
            }
        case STAFF_ACTION_TYPES.LIST_STAFF:
            return {
                ...state, staff: action.payload, loading: false, current: null
            }
        case STAFF_ACTION_TYPES.ADD_STAFF:
            return {
                ...state,
                loading: false
            }
        case STAFF_ACTION_TYPES.SHOW_STAFF:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case STAFF_ACTION_TYPES.EDIT_STAFF:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case STAFF_ACTION_TYPES.DELETE_STAFF:
            const updatedStaff = state.staff.staff.filter(staff => action.payload !== staff.id)
            const updatedCount = state.staff.count - 1
            return {
                ...state, staff: {...state.staff, staff: updatedStaff, count: updatedCount}, loading: false
            }
        default:
            return state;
    }

};

export default staffReducer;