import {
    Form,
    Input,
    Radio,
    Select,
    message,
    Breadcrumb,
    Layout,
    Card,
    Button,
    Space,
    Popconfirm, Tooltip
} from 'antd';
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    setLoading,
    clearLoading,
    addReport,
    getReport,
    editReport,
    clearReportError,
    deleteReport
} from "../../redux/report/reportActions";
import {DeleteTwoTone, EditTwoTone, FileUnknownTwoTone, HomeOutlined} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    selectError,
    selectLoading,
    selectCurrentReport
} from "../../redux/report/reportSelector";
import Divider from "antd/es/divider";
import {getUserRegionsForReports} from "../../utils/utilReports";
import {selectReportFiles} from "../../redux/reportFile/reportFileSelector";
import {selectReportParameters} from "../../redux/reportParameter/reportParameterSelector";
import {deleteReportFile, listReportFiles} from "../../redux/reportFile/reportFileActions";
import {deleteReportParameter, listReportParameters, addReportParameter}
    from "../../redux/reportParameter/reportParameterActions";

const { Content, Footer } = Layout;

const ReportForm = (props) => {

    //Local constants
    //----------------
    const intl = useIntl();
    const dispatch = useDispatch();
    const {Option} = Select;
    const [form] = Form.useForm();

    //Local states
    //------------
    const [reportCreated, setReportCreated] = useState(false);
    const [edit, setEdit] = useState(props.location.pathname !== '/configuration/reports/new');
    const [disabledButtonAddRP, setDisabledButtonAddRP] = useState(true);
    const [geoRegions, setGeoRegions] = useState([{
        label: intl.formatMessage({ id: 'label.report.area.global' }), value: null }]);
    const [id, setId] = useState(undefined);
    const [disabledButtonCreateReport, setDisabledButtonCreateReport] = useState(true);
    const [addReportFile, setAddReportFile] = useState(false);
    const [creatingNewReport] = useState(() => {
        if (props.location.state?.creatingNewReport) {
            return props.location.state?.creatingNewReport
        } else {
            return props.location.pathname === '/configuration/reports/new'
        }
    });
    const [reportFileDeleted, setReportFileDeleted] = useState(0);
    const [reportParameterDeleted, setReportParameterDeleted] = useState(0);

    //Handlers
    //---------
    const onFinish = (values) => {
        if (edit) {
            props.editReport(props.history, props.match.params.id, values, setReportCreated).then(() => {
                props.history.push(`/configuration/reports`)
            });
        } else {
            props.addReport(props.history, values, setReportCreated, setId, setAddReportFile)
        }
    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const handleAddReportFile = () => {
        if (!edit && !addReportFile) {
            document.getElementById('submitBtn').click();
        } else {
            setAddReportFile(false);
            const location = {
                pathname: `/configuration/reports/${props.report.id}/reportFiles/new`,
                state: {
                    'report': props.report.id,
                    'reportname': props.report.name,
                    'type': props.reportReportFiles.count === 0 ? 'report' : 'associated',
                    'creatingNewReport': creatingNewReport
                }
            };
            props.history.push(location)
        }
    };

    const handleDeleteReportFile = (id) => {
        props.deleteReportFile(props.history, id).then(() => {
            setReportFileDeleted(count => count + 1)
        });
    };

    const handleAddReportParameter = () => {
        const location = {
            pathname: `/configuration/reports/${props.report.id}/reportParameters/new`,
            state: {
                'report': props.report.id,
                'reportname': props.report.name,
                'creatingNewReport': creatingNewReport
            }
        };
        props.history.push(location)
    };

    const handleDeleteReportParameter = (id) => {
        props.deleteReportParameter(props.history, id).then(() => {
            setReportParameterDeleted(count => ++count)
        });
    };

    const handleReportParameterType = (type) => {
        switch (type) {
            case 'date':
                return intl.formatMessage({id: 'label.reportParameter.type.date'});
            case 'date_range':
                return intl.formatMessage({ id: 'label.reportParameter.type.date_range'});
            case 'location':
                return intl.formatMessage({ id: 'label.reportParameter.type.location'});
            case 'string':
                return intl.formatMessage({ id: 'label.reportParameter.type.string'});
            case 'numeric':
                return intl.formatMessage({ id: 'label.reportParameter.type.numeric'});
            default:
                return intl.formatMessage({ id: 'label.reportParameter.type.missing'})
        }
    };

    const handleCreateReport = () => {
        document.getElementById("submitBtn").click();
    };

    //Effects
    //--------

    //loading initial values for geoRegions
    useEffect(() => {
        //geoRegions
        if (geoRegions.length === 1) {
            dispatch(getUserRegionsForReports(props.history, setLoading, clearLoading)).then(
                (data) => {
                    if (data !== undefined && geoRegions.length === 1) {
                        const loadedRegions = [...geoRegions];
                        data.forEach(geoRegion => loadedRegions.push({ label: geoRegion.name, value: geoRegion.regionCode })
                        );
                        setGeoRegions(loadedRegions);
                    }
                }
            );
        }
    },[dispatch, props.history, geoRegions]);

    //checking errors in props and populating fields
    useEffect(() => {
        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearReportError());
                } else {
                    if (props.error?.errors) {
                        props.error.errors?.map(errorMessage =>
                            (message.error(errorMessage.message).then(props.clearReportError())));
                    } else {
                        message.error(props.error).then(props.clearReportError());
                    }
                }
            };
            error();
        }

        if (!edit && !reportCreated && props.error === null) {
            form.resetFields();
            //default values for fields: type, output, access, status and subscription
            form.setFieldsValue({
                'type': 'standard',
                'output': 'html',
                'access': 'private',
                'status': 'draft',
                'subscription': 'no'
            });

        }

        if ((edit && !reportCreated && props.error === null)) {
            props.getReport(props.history, props.match.params.id).then((data) => {
                if (data) {
                    form.setFieldsValue({
                        'name': data.name,
                        'description': data.description,
                        'area': data.area,
                        'type': data.type,
                        'output': data.output,
                        'access': data.access,
                        'status': data.status,
                        'subscription': data.subscription
                    });
                    
                } else {
                    setEdit(false);
                }
            })
        }

        if (reportCreated) {
            if (edit) {
                creatingNewReport ? message.info(intl.formatMessage({ id: 'label.report.report-created' })) :
                message.info(intl.formatMessage({ id: 'label.report.report-updated' }))
            }
            setReportCreated(false);
        }

        // eslint-disable-next-line
    }, [props.error, reportCreated, edit]);

    //effect after adding new basic data
    useEffect(() => {
        if (id !== undefined) {
            props.history.push(`/configuration/reports/${id}`);
            setEdit(true);
        }
        // eslint-disable-next-line
    }, [id]);

    //updating reportReportFiles and reportReportParameters
    useEffect(() => {
        if (props.error === null) {
            //update reportReportFiles and reportReportParameters
            let filters;
            if (edit) {
                filters = {report: [props.match.params.id]};
            } else {
                filters = {report: [-1]}; //reset to [] reportReportFiles and reportReportParameters
            }

            props.listReportFiles(props.history, undefined, filters, undefined);
            props.listReportParameters(props.history, undefined, filters, undefined)
        }
        // eslint-disable-next-line
    }, [edit, props.match.params, props.error, reportFileDeleted, reportParameterDeleted]);


    //Add RPs button and Create Report button
    useEffect(() => {
        setDisabledButtonAddRP(props.reportReportFiles.count === 0);
        setDisabledButtonCreateReport(props.reportReportFiles.count === 0)
    }, [props.reportReportFiles]);

    //adding reportFile after creating the report
    useEffect(() => {
        if (props.report) {
            if (props.report.id) {
                if (edit && addReportFile) {
                    document.getElementById('addReportFileBtn').click()
                }
            }
        }
        // eslint-disable-next-line
    }, [props.report]);

    //the UI
    return (
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '10px 0' }}>
                    <Breadcrumb.Item>
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.configuration" defaultMessage="Configuration"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports" defaultMessage="Reports" /></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{edit ? (creatingNewReport ? (
                            <FormattedMessage id="label.new" defaultMessage="New" />
                        ):(
                            <FormattedMessage
                                id="label.edit" defaultMessage="Edit" />
                        )): (
                            <FormattedMessage id="label.new" defaultMessage="New" />)}
                        </span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Card type="inner"
                        title={edit ? (creatingNewReport) ? (
                            <FormattedMessage id="label.create-report" defaultMessage="Create Report" />) : (
                                <FormattedMessage id="label.edit-report" defaultMessage="Edit Report" />):
                            <FormattedMessage id="label.create-report" defaultMessage="Create Report" />}
                          extra={
                                  <Link  to={'/configuration/reports'}>
                                      <FormattedMessage id="label.return" defaultMessage="Return" />
                                  </Link>
                          }
                    >
                        <Divider orientation={'left'}>
                            <FormattedMessage id={'label.report.basic-data'}/>
                        </Divider>
                        <Form
                            layout="horizontal" name="report_form" size="large"
                            labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                            form={form}
                            onFinish={onFinish} onFinishFailed={onFinishFailed}
                            initialValues={{status: 'draft'}}
                        >
                            <Form.Item
                                name="status"
                                label={intl.formatMessage({id: 'label.report.status'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required-status'})}]}
                            >
                                <Select
                                    options={[
                                        {label: intl.formatMessage({id: "Report.status.draft"}), value: 'draft'},
                                        {label: intl.formatMessage({id: "Report.status.published"}), value: 'published'}
                                    ]}
                                    style={{width: '15ch'}}
                                />
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label={intl.formatMessage({id: 'label.report.name'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required-name'})}]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={intl.formatMessage({id: 'label.report.description'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required-description'})}]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item
                                name="area"
                                label={intl.formatMessage({ id: 'label.report.area' })}
                                rules={[{
                                    required: true,
                                    message: intl.formatMessage({ id: 'msg.input-required-area' })
                                }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: 200 }}
                                    placeholder={intl.formatMessage({ id: 'label.report.area.select' })}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {geoRegions.map(gR => <Option key={gR.value} value={gR.label}>{gR.label}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="type"
                                label={intl.formatMessage({id: 'label.report.type'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required-type'})}]}
                            >
                                <Radio.Group>
                                    <Radio value={'standard'}>
                                        <FormattedMessage id="label.report.type.standard" defaultMessage="Standard"/>
                                    </Radio>
                                    <Radio value={'customized'}>
                                        <FormattedMessage id="label.report.type.customized" defaultMessage="Customized"/>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="output"
                                label={intl.formatMessage({id: 'label.report.output'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required-output'})}]}
                            >
                                <Radio.Group>
                                    <Radio value={'html'} >html</Radio>
                                    <Radio value={'pdf'} >pdf</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="access"
                                label={intl.formatMessage({id: 'label.report.access'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required-access'})}]}
                            >
                                <Radio.Group>
                                    <Radio value={'private'}><FormattedMessage id="label.report.access.private" defaultMessage="Private"/></Radio>
                                    <Radio value={'public'}><FormattedMessage id="label.report.access.public" defaultMessage="Public"/></Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="subscription"
                                label={intl.formatMessage({id: 'label.report.subscription'})}
                                rules={[{required: true, message: intl.formatMessage(
                                    {id: 'msg.input-required-subscription'})}]}
                            >
                                <Radio.Group>
                                    <Radio value={'no'}>
                                        {intl.formatMessage({id: 'label.report.subscription.no'})}
                                    </Radio>
                                    <Radio value={'yes'}>
                                        {intl.formatMessage({id: 'label.report.subscription.yes'})}
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item hidden={true}>
                                <Button type="primary" htmlType="submit" loading={props.loading} id='submitBtn'
                                >
                                    {edit ? (<FormattedMessage id="label.report.update.basic-data" defaultMessage="Update Basic data"/>) : (
                                        <FormattedMessage id="label.report.create.basic-data" defaultMessage="Create Basic data"/>)}
                                </Button>
                            </Form.Item>
                        </Form>
                        <Divider orientation={'left'}>
                            <FormattedMessage id={'label.report.files'}/>
                        </Divider>
                        <div id={'reportFilesCollection'}>
                            {props.reportReportFiles.reportFiles?.sort(
                                (a,b) => a.type === b.type ? 0 : a.type > b.type ? -1 : 1).map(rF => {
                                return(
                                    <div key={rF.id}>
                                        <ul>
                                            <Space>
                                                {rF.type === 'report' ? <DeleteTwoTone twoToneColor={'gray'} style={{opacity: 0.0}} /> :
                                                    <Popconfirm title={intl.formatMessage({id: 'msg.confirm-delete'})}
                                                                onConfirm={() => handleDeleteReportFile(rF.id)}
                                                    >
                                                        <DeleteTwoTone twoToneColor={'red'} />
                                                    </Popconfirm>
                                                }
                                                <Link
                                                    to={
                                                        {
                                                            pathname: `/configuration/reports/reportFiles/${rF.id}`,
                                                            state: {
                                                                'report': rF.report,
                                                                'reportname': rF.reportname
                                                            }
                                                        }
                                                    }
                                                >
                                                    <EditTwoTone />
                                                </Link>
                                                <label id={'reportFile-filename'} >{rF.filename}</label>
                                                <label id={'reportFile-type'} >
                                                    ({rF.type === 'report' ?
                                                    intl.formatMessage({ id: 'label.reportFile.type.report' }) :
                                                    intl.formatMessage({ id: 'label.reportFile.type.associated' })})
                                                </label>
                                                <label>
                                                    {rF.exists ? null :
                                                        <Tooltip
                                                            title={intl.formatMessage({id:'label.reportFile.missing.file'})}>
                                                            <FileUnknownTwoTone twoToneColor={'red'} />
                                                        </Tooltip>
                                                    }
                                                </label>
                                            </Space>
                                        </ul>
                                    </div>
                                )})
                            }
                            <Button onClick={handleAddReportFile}
                                    className="ant-btn ant-btn-primary ant-btn-sm"
                                    id={'addReportFileBtn'}
                            >
                                {intl.formatMessage({id:'label.report.reportFiles.add'})}
                            </Button>
                        </div>
                        <Divider orientation={'left'}>
                            <FormattedMessage id={'label.report.parameters'}/>
                        </Divider>
                        <div id={'reportParametersCollection'}>
                            {props.reportReportParameters.reportParameters?.sort(
                                (a,b) => a.type === b.type ? 0 : a.type > b.type ? -1 : 1).map(rP => {
                                return(
                                    <div key={rP.id}>
                                        <ul>
                                            <Space>
                                                <Popconfirm title={intl.formatMessage({id: 'msg.confirm-delete'})}
                                                            onConfirm={() => handleDeleteReportParameter(rP.id)}
                                                >
                                                    <DeleteTwoTone twoToneColor={'red'} />
                                                </Popconfirm>
                                                <Link
                                                    to={
                                                        {
                                                            pathname: `/configuration/reports/reportParameters/${rP.id}`,
                                                            state: {
                                                                'report': rP.report,
                                                                'reportname': rP.reportname
                                                            }
                                                        }
                                                    }
                                                >
                                                    <EditTwoTone />
                                                </Link>
                                                <label id={'reportParameter-name'} >{rP.name}</label>
                                                <label id={'reportParameter-type'} >
                                                    ({handleReportParameterType(rP.type)})
                                                </label>
                                            </Space>
                                        </ul>
                                    </div>
                                )})}
                            <Button onClick={handleAddReportParameter}
                                    className={"ant-btn ant-btn-primary ant-btn-sm"}
                                    disabled={disabledButtonAddRP}
                            >
                                {intl.formatMessage({id:'label.report.reportParameter.add'})}
                            </Button>
                        </div>
                        <Divider>

                        </Divider>
                        <div id="div-create-report">
                            <Button
                                className={'ant-btn ant-btn-primary ant-btn-lg'}
                                disabled={disabledButtonCreateReport}
                                onClick={handleCreateReport}
                            >
                                {
                                    creatingNewReport ? intl.formatMessage({id:'label.report.button.create-report'}) :
                                        intl.formatMessage({id:'label.report.button.update-report'})
                                }
                            </Button>
                        </div>
                    </Card>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    report: selectCurrentReport(state),
    reportReportFiles: selectReportFiles(state),
    reportReportParameters: selectReportParameters(state)
});

export default connect(mapStateToProps, {
    addReport, getReport, editReport, clearReportError, deleteReport,
    listReportFiles, deleteReportFile,
    listReportParameters, deleteReportParameter,
    addReportParameter})(ReportForm);