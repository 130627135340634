import Icon from '@ant-design/icons';

const TitleSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 512 512" fill="black">
        <g>
            <path d="M293.1,447.6V64.4h-51.7v162.3H51.7V64.4H0v383.2h51.7V272.2h189.8v175.5H293.1z M512,447.6V64.4h-51.1L369.6,132v52
		l88.7-64.3h2.5v327.9H512z"/>
        </g>
    </svg>
);

const TextSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 512 512" fill="black">
        <g>
            <g>
                <rect x="192" width="42.667" height="341.333"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="64" width="298.667" height="42.667"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="42.667" y="384" width="341.333" height="42.667"/>
            </g>
        </g>
    </svg>
);

const TextAreaSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 1024 1024" fill="black">
        <g>
            <path
                d="M688.5,763.8l188.5-188.5v188.5H688.5z M990,236.2v527.7c0,62.6-50.5,113.1-113.1,113.1H123.1C60.5,876.9,10,826.4,10,763.8V236.2c0-62.6,50.5-113.1,113.1-113.1h753.8C939.5,123.1,990,173.6,990,236.2z M915,802.3l-0.4-604.6H84.3v603.8L915,802.3z"/>
        </g>
    </svg>
);

const NumberIntegerSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 496 496" fill="black">
        <path
            d="m328 344h-24c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h24c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0"/>
        <path
            d="m376 344h-24c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h24c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0"/>
        <path
            d="m424 344h-24c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h24c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0"/>
        <path
            d="m456 0h-416c-22.082031.0273438-39.9726562 17.917969-40 40v416c.0273438 22.082031 17.917969 39.972656 40 40h416c22.082031-.027344 39.972656-17.917969 40-40v-416c-.027344-22.082031-17.917969-39.9726562-40-40zm24 456c0 13.253906-10.746094 24-24 24h-416c-13.253906 0-24-10.746094-24-24v-416c0-13.253906 10.746094-24 24-24h416c13.253906 0 24 10.746094 24 24zm0 0"/>
        <path
            d="m456 240h-200v-200c0-4.417969-3.582031-8-8-8s-8 3.582031-8 8v200h-200c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h200v200c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-200h200c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8zm0 0"/>
        <path
            d="m108.054688 127.257812 19.945312-19.945312v92.6875c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-112c0-3.234375-1.949219-6.152344-4.9375-7.390625s-6.429688-.550781-8.71875 1.734375l-33.601562 33.601562c-3.03125 3.136719-2.988282 8.128907.097656 11.214844 3.085937 3.085938 8.078125 3.128906 11.214844.097656zm0 0"/>
        <path
            d="m176 324c-1.183594-20.949219-19.042969-37.023438-40-36-20.957031-1.023438-38.816406 15.050781-40 36 0 4.417969 3.582031 8 8 8s8-3.582031 8-8c1.214844-12.089844 11.886719-20.984375 24-20 12.113281-.984375 22.785156 7.910156 24 20-1.214844 12.089844-11.886719 20.984375-24 20-4.417969 0-8 3.582031-8 8s3.582031 8 8 8c12.113281-.984375 22.785156 7.910156 24 20-1.214844 12.089844-11.886719 20.984375-24 20-12.113281.984375-22.785156-7.910156-24-20 0-4.417969-3.582031-8-8-8s-8 3.582031-8 8c1.183594 20.949219 19.042969 37.023438 40 36 20.957031 1.023438 38.816406-15.050781 40-36-.164062-11.1875-5.707031-21.609375-14.886719-28 9.179688-6.390625 14.722657-16.8125 14.886719-28zm0 0"/>
        <path
            d="m326.398438 129.601562c4.417968 0 8-3.582031 8-8 0-14.140624 11.464843-25.601562 25.601562-25.601562 8.246094-.191406 15.929688 4.164062 20 11.335938 4.367188 7.789062 4.230469 17.320312-.351562 24.984374-11.738282 17.066407-25.761719 32.449219-41.671876 45.710938-6.046874 5.601562-11.9375 11.015625-17.234374 16.3125-2.285157 2.289062-2.96875 5.730469-1.730469 8.71875s4.152343 4.9375 7.386719 4.9375h65.601562c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-45.601562l2.402343-2.207031c17.101563-14.273438 32.113281-30.878907 44.589844-49.328125 7.503906-12.609375 7.6875-28.269532.480469-41.050782-6.917969-12.148437-19.890625-19.582031-33.871094-19.414062-22.964844.023438-41.578125 18.632812-41.601562 41.601562 0 4.417969 3.582031 8 8 8zm0 0"/>
    </svg>
);

const NumberDecimalSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 24 24" fill="black">
        <path
            d="M9 5A3 3 0 0 0 6 8V11A3 3 0 0 0 12 11V8A3 3 0 0 0 9 5M10 11A1 1 0 0 1 8 11V8A1 1 0 0 1 10 8M16 14A3 3 0 0 0 19 11V8A3 3 0 0 0 13 8V11A3 3 0 0 0 16 14M15 8A1 1 0 0 1 17 8V11A1 1 0 0 1 15 11M19 20V18H13V16H19V14L22 17M5 13V16H4L3 13A1 1 0 0 1 5 13Z"/>
    </svg>
);

const DateSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 512 512" fill="black">
        <g>
            <g>
                <path d="M452,40h-24V0h-40v40H124V0H84v40H60C26.916,40,0,66.916,0,100v352c0,33.084,26.916,60,60,60h392
			c33.084,0,60-26.916,60-60V100C512,66.916,485.084,40,452,40z M472,452c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V188
			h432V452z M472,148H40v-48c0-11.028,8.972-20,20-20h24v40h40V80h264v40h40V80h24c11.028,0,20,8.972,20,20V148z"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="76" y="230" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="156" y="230" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="236" y="230" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="316" y="230" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="396" y="230" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="76" y="310" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="156" y="310" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="236" y="310" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="316" y="310" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="76" y="390" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="156" y="390" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="236" y="390" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="316" y="390" width="40" height="40"/>
            </g>
        </g>
        <g>
            <g>
                <rect x="396" y="310" width="40" height="40"/>
            </g>
        </g>
    </svg>
);

const DateTimeSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 512 512" fill="black">
        <g>
            <g>
                <g>
                    <circle cx="386" cy="210" r="20"/>
                    <path d="M432,40h-26V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v20h-91V20c0-11.046-8.954-20-20-20
				c-11.046,0-20,8.954-20,20v20h-90V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v20H80C35.888,40,0,75.888,0,120v312
				c0,44.112,35.888,80,80,80h153c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H80c-22.056,0-40-17.944-40-40V120
				c0-22.056,17.944-40,40-40h25v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h90v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h91
				v20c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80h26c22.056,0,40,17.944,40,40v114c0,11.046,8.954,20,20,20
				c11.046,0,20-8.954,20-20V120C512,75.888,476.112,40,432,40z"/>
                    <path d="M391,270c-66.72,0-121,54.28-121,121s54.28,121,121,121s121-54.28,121-121S457.72,270,391,270z M391,472
				c-44.663,0-81-36.336-81-81s36.337-81,81-81c44.663,0,81,36.336,81,81S435.663,472,391,472z"/>
                    <path d="M420,371h-9v-21c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v41c0,11.046,8.954,20,20,20h29
				c11.046,0,20-8.954,20-20C440,379.954,431.046,371,420,371z"/>
                    <circle cx="299" cy="210" r="20"/>
                    <circle cx="212" cy="297" r="20"/>
                    <circle cx="125" cy="210" r="20"/>
                    <circle cx="125" cy="297" r="20"/>
                    <circle cx="125" cy="384" r="20"/>
                    <circle cx="212" cy="384" r="20"/>
                    <circle cx="212" cy="210" r="20"/>
                </g>
            </g>
        </g>

    </svg>
);

const TimeSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 443.294 443.294" fill="black">
        <path
            d="m221.647 0c-122.214 0-221.647 99.433-221.647 221.647s99.433 221.647 221.647 221.647 221.647-99.433 221.647-221.647-99.433-221.647-221.647-221.647zm0 415.588c-106.941 0-193.941-87-193.941-193.941s87-193.941 193.941-193.941 193.941 87 193.941 193.941-87 193.941-193.941 193.941z"/>
        <path d="m235.5 83.118h-27.706v144.265l87.176 87.176 19.589-19.589-79.059-79.059z"/>
    </svg>
);

const CheckboxSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 474.8 474.8" fill="black">
        <g>
            <g>
                <path d="M396.283,257.097c-1.14-0.575-2.282-0.862-3.433-0.862c-2.478,0-4.661,0.951-6.563,2.857l-18.274,18.271
			c-1.708,1.715-2.566,3.806-2.566,6.283v72.513c0,12.565-4.463,23.314-13.415,32.264c-8.945,8.945-19.701,13.418-32.264,13.418
			H82.226c-12.564,0-23.319-4.473-32.264-13.418c-8.947-8.949-13.418-19.698-13.418-32.264V118.622
			c0-12.562,4.471-23.316,13.418-32.264c8.945-8.946,19.7-13.418,32.264-13.418H319.77c4.188,0,8.47,0.571,12.847,1.714
			c1.143,0.378,1.999,0.571,2.563,0.571c2.478,0,4.668-0.949,6.57-2.852l13.99-13.99c2.282-2.281,3.142-5.043,2.566-8.276
			c-0.571-3.046-2.286-5.236-5.141-6.567c-10.272-4.752-21.412-7.139-33.403-7.139H82.226c-22.65,0-42.018,8.042-58.102,24.126
			C8.042,76.613,0,95.978,0,118.629v237.543c0,22.647,8.042,42.014,24.125,58.098c16.084,16.088,35.452,24.13,58.102,24.13h237.541
			c22.647,0,42.017-8.042,58.101-24.13c16.085-16.084,24.134-35.45,24.134-58.098v-90.797
			C402.001,261.381,400.088,258.623,396.283,257.097z"/>
                <path d="M467.95,93.216l-31.409-31.409c-4.568-4.567-9.996-6.851-16.279-6.851c-6.275,0-11.707,2.284-16.271,6.851
			L219.265,246.532l-75.084-75.089c-4.569-4.57-9.995-6.851-16.274-6.851c-6.28,0-11.704,2.281-16.274,6.851l-31.405,31.405
			c-4.568,4.568-6.854,9.994-6.854,16.277c0,6.28,2.286,11.704,6.854,16.274l122.767,122.767c4.569,4.571,9.995,6.851,16.274,6.851
			c6.279,0,11.704-2.279,16.274-6.851l232.404-232.403c4.565-4.567,6.854-9.994,6.854-16.274S472.518,97.783,467.95,93.216z"/>
            </g>
        </g>
    </svg>
);

const CheckboxGroupSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 74 74" fill="black">
        <path d="m63 72h-52a1 1 0 0 1 -1-1v-68a1 1 0 0 1 1-1h52a1 1 0 0 1 1 1v68a1 1 0 0 1 -1 1zm-51-2h50v-66h-50z"/>
        <path
            d="m28.312 23.625h-9.624a2 2 0 0 1 -2-2v-9.625a2 2 0 0 1 2-2h9.624a2 2 0 0 1 2 2v9.625a2 2 0 0 1 -2 2zm-9.624-11.625v9.625h9.624v-9.625z"/>
        <path
            d="m28.312 43.812h-9.624a2 2 0 0 1 -2-2v-9.624a2 2 0 0 1 2-2h9.624a2 2 0 0 1 2 2v9.624a2 2 0 0 1 -2 2zm-9.624-11.624v9.624h9.624v-9.624z"/>
        <path
            d="m28.312 64h-9.624a2 2 0 0 1 -2-2v-9.625a2 2 0 0 1 2-2h9.624a2 2 0 0 1 2 2v9.625a2 2 0 0 1 -2 2zm-9.624-11.625v9.625h9.624v-9.625z"/>
        <path d="m56.312 15.021h-21a1 1 0 0 1 0-2h21a1 1 0 0 1 0 2z"/>
        <path d="m48.312 20.6h-13a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2z"/>
        <path d="m56.312 35.208h-21a1 1 0 0 1 0-2h21a1 1 0 0 1 0 2z"/>
        <path d="m48.312 40.792h-13a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2z"/>
        <path d="m56.312 55.4h-21a1 1 0 0 1 0-2h21a1 1 0 0 1 0 2z"/>
        <path d="m48.312 60.979h-13a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2z"/>
    </svg>
);

const RadioGroupSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 60 60" fill="black">
        <g>
            <path d="M0,0.5v59h60v-59H0z M58,57.5H2v-55h56V57.5z"/>
            <path d="M15,19.5c2.757,0,5-2.243,5-5s-2.243-5-5-5s-5,2.243-5,5S12.243,19.5,15,19.5z M15,11.5c1.654,0,3,1.346,3,3s-1.346,3-3,3
		s-3-1.346-3-3S13.346,11.5,15,11.5z"/>
            <path d="M15,35.5c2.757,0,5-2.243,5-5s-2.243-5-5-5s-5,2.243-5,5S12.243,35.5,15,35.5z M15,27.5c1.654,0,3,1.346,3,3s-1.346,3-3,3
		s-3-1.346-3-3S13.346,27.5,15,27.5z"/>
            <path d="M15,51.5c2.757,0,5-2.243,5-5s-2.243-5-5-5s-5,2.243-5,5S12.243,51.5,15,51.5z M15,43.5c1.654,0,3,1.346,3,3s-1.346,3-3,3
		s-3-1.346-3-3S13.346,43.5,15,43.5z"/>
            <path d="M25,15.5h23c0.552,0,1-0.447,1-1s-0.448-1-1-1H25c-0.552,0-1,0.447-1,1S24.448,15.5,25,15.5z"/>
            <path d="M25,31.5h23c0.552,0,1-0.447,1-1s-0.448-1-1-1H25c-0.552,0-1,0.447-1,1S24.448,31.5,25,31.5z"/>
            <path d="M25,47.5h23c0.552,0,1-0.447,1-1s-0.448-1-1-1H25c-0.552,0-1,0.447-1,1S24.448,47.5,25,47.5z"/>
        </g>
    </svg>
);

const ListSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 512 512" fill="black">
        <g>
            <g>
                <path d="M448,0H64C28.715,0,0,28.715,0,64v384c0,35.285,28.715,64,64,64h384c35.285,0,64-28.715,64-64V64
			C512,28.715,483.285,0,448,0z M469.333,448c0,11.776-9.579,21.333-21.333,21.333H64c-11.755,0-21.333-9.557-21.333-21.333V64
			c0-11.755,9.579-21.333,21.333-21.333h384c11.755,0,21.333,9.579,21.333,21.333V448z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M147.627,119.893c-1.067-2.773-2.56-4.907-4.48-7.04c-1.067-0.853-2.133-1.707-3.2-2.56c-1.28-0.853-2.56-1.493-3.84-1.92
			c-1.28-0.64-2.56-1.067-3.84-1.28c-4.267-0.853-8.533-0.427-12.373,1.28c-2.56,1.067-4.907,2.56-7.04,4.48
			c-1.92,2.133-3.413,4.267-4.48,7.04c-1.067,2.56-1.707,5.333-1.707,8.107s0.64,5.547,1.707,8.107
			c1.067,2.773,2.56,4.907,4.48,7.04c4.053,3.84,9.6,6.187,15.147,6.187c1.28,0,2.773-0.213,4.267-0.427
			c1.28-0.213,2.56-0.64,3.84-1.28c1.28-0.427,2.56-1.067,3.84-1.92c1.067-0.853,2.133-1.707,3.2-2.56
			c3.84-4.053,6.187-9.6,6.187-15.147C149.333,125.227,148.693,122.453,147.627,119.893z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M384,106.667H213.333C201.557,106.667,192,116.224,192,128s9.557,21.333,21.333,21.333H384
			c11.776,0,21.333-9.557,21.333-21.333S395.776,106.667,384,106.667z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M148.907,251.733c-0.213-1.28-0.64-2.56-1.28-3.84c-0.427-1.28-1.067-2.56-1.92-3.84c-0.853-1.067-1.707-2.133-2.56-3.2
			c-2.133-1.92-4.48-3.413-7.04-4.48c-5.12-2.133-11.093-2.133-16.213,0c-2.56,1.067-4.907,2.56-7.04,4.48
			c-0.853,1.067-1.707,2.133-2.56,3.2c-0.853,1.28-1.493,2.56-1.92,3.84c-0.64,1.28-1.067,2.56-1.28,3.84
			c-0.213,1.493-0.427,2.987-0.427,4.267c0,5.547,2.347,11.093,6.187,15.147c4.053,3.84,9.6,6.187,15.147,6.187
			s11.093-2.347,15.147-6.187c3.84-4.053,6.187-9.6,6.187-15.147C149.333,254.72,149.12,253.227,148.907,251.733z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M384,234.667H213.333C201.557,234.667,192,244.224,192,256s9.557,21.333,21.333,21.333H384
			c11.776,0,21.333-9.557,21.333-21.333S395.776,234.667,384,234.667z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M147.627,375.893c-1.067-2.773-2.56-4.907-4.48-7.04c-5.973-5.781-15.36-7.68-23.253-4.48
			c-2.56,1.067-4.907,2.56-7.04,4.48c-1.92,2.133-3.413,4.48-4.48,7.04s-1.707,5.333-1.707,8.107c0,5.76,2.133,11.093,6.187,15.147
			c4.053,4.032,9.387,6.187,15.147,6.187s11.093-2.155,15.147-6.187c4.053-4.053,6.187-9.387,6.187-15.147
			C149.333,381.227,148.907,378.453,147.627,375.893z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M384,362.667H213.333C201.557,362.667,192,372.203,192,384c0,11.797,9.557,21.333,21.333,21.333H384
			c11.776,0,21.333-9.536,21.333-21.333C405.333,372.203,395.776,362.667,384,362.667z"/>
            </g>
        </g>
    </svg>
);

const MapSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 485.213 485.212" fill="black">
        <g>
            <path d="M470.052,136.467c-62.343,22.953-109.84,39.299-151.629,90.978c-37.229,46.05-90.98,62.011-151.628,31.657v165.457h-30.329
		V15.163C136.465,6.781,143.247,0,151.628,0c8.38,0,15.166,6.781,15.166,15.163c33.67,48.953,92.189,59.795,151.628,60.653
		C409.398,77.146,470.052,136.467,470.052,136.467z M197.116,337.612v30.854c35.775,7.318,60.653,22.841,60.653,40.936
		c0,25.113-47.534,45.49-106.141,45.49c-58.609,0-106.139-20.377-106.139-45.495c0-18.09,24.875-33.612,60.649-40.931v-30.854
		c-55.081,9.535-90.978,36.249-90.978,71.784c0,44.631,56.121,75.816,136.467,75.816c80.344,0,136.468-31.186,136.468-75.816
		C288.097,373.861,252.197,347.147,197.116,337.612z"/>
        </g>
    </svg>
);

const PhotoSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 21 21" fill="black">
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Two-Tone" transform="translate(-850.000000, -2681.000000)">
                <g id="Image" transform="translate(100.000000, 2626.000000)">
                    <g id="Two-Tone-/-Image-/-add_photo_alternate" transform="translate(748.000000, 54.000000)">
                        <g>
                            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                            <path
                                d="M10.21,16.83 L12.96,13.29 L16.5,18 L5.5,18 L8.25,14.47 L10.21,16.83 Z M20,4 L23,4 L23,6 L20,6 L20,8.99 L18,8.99 L18,6 L15,6 L15,4 L18,4 L18,1 L20,1 L20,4 Z M18,20 L18,10 L20,10 L20,20 C20,21.1 19.1,22 18,22 L4,22 C2.9,22 2,21.1 2,20 L2,6 C2,4.9 2.9,4 4,4 L14,4 L14,6 L4,6 L4,20 L18,20 Z"
                                id="🔹-Primary-Color" fill="#1D1D1D"></path>
                            <path
                                d="M16.5,18 L5.5,18 L8.25,14.47 L10.21,16.83 L12.96,13.29 L16.5,18 Z M17,7 L14,7 L14,6 L4,6 L4,20 L18,20 L18,10 L17,10 L17,7 Z"
                                id="🔹-Secondary-Color" fill="#D0D0D0"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const BarcodeSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 24 24" fill="black">
        <path
            d="M4,6H6V18H4V6M7,6H8V18H7V6M9,6H12V18H9V6M13,6H14V18H13V6M16,6H18V18H16V6M19,6H20V18H19V6M2,4V8H0V4A2,2 0 0,1 2,2H6V4H2M22,2A2,2 0 0,1 24,4V8H22V4H18V2H22M2,16V20H6V22H2A2,2 0 0,1 0,20V16H2M22,20V16H24V20A2,2 0 0,1 22,22H18V20H22Z"/>
    </svg>
);

const QRCodeSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 512 512" fill="black">
        <polygon points="48 48 176 48 176 16 16 16 16 176 48 176 48 48" className="ci-primary"/>
        <path d="M176,176V80H80v96h96Zm-64-64h32v32H112Z" className="ci-primary"/>
        <polygon points="328 48 464 48 464 176 496 176 496 16 328 16 328 48" className="ci-primary"/>
        <path d="M432,176V80H336v96h96Zm-64-64h32v32H368Z" className="ci-primary"/>
        <polygon points="176 464 48 464 48 336 16 336 16 496 176 496 176 464" className="ci-primary"/>
        <path d="M176,336H80v96h96V336Zm-32,64H112V368h32Z" className="ci-primary"/>
        <polygon points="464 464 328 464 328 496 496 496 496 336 464 336 464 464" className="ci-primary"/>
        <polygon points="272 304 400 304 400 368 432 368 432 272 272 272 272 304" className="ci-primary"/>
        <polygon points="432 432 432 400 240 400 240 272 80 272 80 304 208 304 208 432 432 432" className="ci-primary"/>
        <rect width="32" height="96" x="208" y="80" className="ci-primary"/>
        <polygon points="80 240 304 240 304 80 272 80 272 208 80 208 80 240" className="ci-primary"/>
        <rect width="96" height="32" x="336" y="208" className="ci-primary"/>
        <rect width="32" height="32" x="336" y="336" className="ci-primary"/>
        <rect width="32" height="32" x="272" y="336" className="ci-primary"/>
    </svg>
);

const QuestionBlockSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 48 48" fill="black">
        <path
            d="m39,48h-30c-4.971,0-9-4.032-9-9v-30c0-4.971 4.029-9 9-9h30c4.971,0 9,4.029 9,9v30c0,4.968-4.029,9-9,9zm3-39c0-1.656-1.341-3-3-3h-30c-1.659,0-3,1.344-3,3v30c0,1.659 1.341,3 3,3h30c1.659,0 3-1.341 3-3v-30zm-18,9v-3h12v3h-12zm-12,9h9v9h-9v-9zm3,6h3v-3h-3v3zm-3-21h9v9h-9v-9zm3,6h3v-3h-3v3zm21,15h-12v-3h12v3z"/>
    </svg>
);

const VirtualSvg = () => (
    <svg version="1.1" width="2em" height="2em" viewBox="0 0 24 24" fill="black">
        <path
            d="M5,3C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3H5M6,9H7.5L8.5,12.43L9.5,9H11L9.25,15H7.75L6,9M13,9H16.5C17.35,9 18,9.65 18,10.5V11.5C18,12.1 17.6,12.65 17.1,12.9L18,15H16.5L15.65,13H14.5V15H13V9M14.5,10.5V11.5H16.5V10.5H14.5Z"/>
    </svg>
);
const UUIDSvg = () => (
    <svg version="1.1" width="2em" height="2em" style={{alignContent: 'center', verticalAlign: 'middle'}}
         viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d="M420.895856 288.04079c54.362628 0 98.600613-44.237985 98.600613-98.565336s-44.237985-98.565336-98.600613-98.565336c-54.32735 0-98.565336 44.237985-98.565336 98.565336s44.237985 98.565336 98.565336 98.565336zM367.132945 718.073518c24.69425 0 44.802425-20.108175 44.802426-44.802426V350.728908c0-24.69425-20.108175-44.802425-44.802426-44.802426s-44.802425 20.108175-44.802425 44.802426v322.542184c0 24.69425 20.108175 44.802425 44.802425 44.802426z"

            />
            <path
                d="M683.536845 305.891205h-161.394564c-24.165088 0-43.849933 18.90874-44.837703 44.202708-1.12888 29.597823-6.738003 185.206876-6.738002 339.581217 0 130.032866-51.117098 153.809901-151.728529 153.809901-120.437386 0-165.204534-41.698005-165.204533-153.809901V350.728908c0-24.69425-20.108175-44.802425-44.802426-44.802426s-44.802425 20.108175-44.802425 44.802426V689.67513c0 161.535674 85.759603 243.450029 254.844662 243.450029 103.610018 0 241.368657-25.223413 241.403934-243.450029 0-116.803803 3.24553-234.948152 5.185793-294.10852h118.109071c83.219623 0 186.829641 0 186.829641 215.474972 0 193.638199-109.395528 222.354084-227.469322 224.576567-11.959073 0.105833-23.177318 4.903573-31.573363 13.440727-8.396045 8.572433-12.911565 19.861233-12.805732 31.820305 0.211665 24.447308 20.31984 44.379095 45.966583 44.379096 209.336687-3.986358 315.486685-109.677748 315.486685-314.181418 0-305.185655-200.870086-305.185655-276.46977-305.185654z"

            />
        </g>
    </svg>
);

const TitleIcon = props => <Icon component={TitleSvg} {...props} />;
const TextIcon = props => <Icon component={TextSvg} {...props} />;
const TextAreaIcon = props => <Icon component={TextAreaSvg} {...props} />;
const NumberIntegerIcon = props => <Icon component={NumberIntegerSvg} {...props} />;
const NumberDecimalIcon = props => <Icon component={NumberDecimalSvg} {...props} />;
const DateIcon = props => <Icon component={DateSvg} {...props} />;
const DateTimeIcon = props => <Icon component={DateTimeSvg} {...props} />;
const TimeIcon = props => <Icon component={TimeSvg} {...props} />;
const CheckboxIcon = props => <Icon component={CheckboxSvg} {...props} />;
const CheckboxGroupIcon = props => <Icon component={CheckboxGroupSvg} {...props} />;
const RadioGroupIcon = props => <Icon component={RadioGroupSvg} {...props} />;
const ListIcon = props => <Icon component={ListSvg} {...props} />;
const MapIcon = props => <Icon component={MapSvg} {...props} />;
const PhotoIcon = props => <Icon component={PhotoSvg} {...props} />;
const BarcodeIcon = props => <Icon component={BarcodeSvg} {...props} />;
const QRCodeIcon = props => <Icon component={QRCodeSvg} {...props} />;
const QuestionBlockIcon = props => <Icon component={QuestionBlockSvg} {...props} />;
const VirtualIcon = props => <Icon component={VirtualSvg} {...props} />;
const UUIDIcon = props => <Icon component={UUIDSvg} {...props} />;

export {
    TitleIcon,
    TextIcon,
    TextAreaIcon,
    NumberIntegerIcon,
    NumberDecimalIcon,
    DateIcon,
    DateTimeIcon,
    TimeIcon,
    CheckboxIcon,
    CheckboxGroupIcon,
    RadioGroupIcon,
    ListIcon,
    MapIcon,
    PhotoIcon,
    BarcodeIcon,
    QRCodeIcon,
    QuestionBlockIcon,
    VirtualIcon,
    UUIDIcon,
}