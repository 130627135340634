import {Form, Input, Button, Row, Col, message, Divider, Modal, Select} from 'antd';
import {connect, useDispatch} from "react-redux";
import {selectError, selectIsAuthenticated} from "../../redux/auth/authSelector";
import {clearLoginError, login, registerUser, requestResetPassword} from "../../redux/auth/authActions";
import {getCurrentUser} from "../../redux/user/userActions";
import {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {getUserRegistrationRegions} from "../../utils/utilData";


const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

const Login = (props) => {

    const intl = useIntl();
    const dispatch = useDispatch();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalRegisterVisible, setIsModalRegisterVisible] = useState(false);
    const [geoRegions, setGeoRegions] = useState([{label: intl.formatMessage({id: 'label.select'}), value: null}])

    const [form] = Form.useForm();
    const [formRegister] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        try {
            setLoading(true);
            await form.validateFields();
            const response = await props.requestResetPassword(props.history, form.getFieldsValue());

            if (response && response?.message) {
                message.success(response?.message);
                form.resetFields();
                setIsModalVisible(false);
            }

        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showRegisterModal = () => {
        setIsModalRegisterVisible(true);
    };

    const handleRegisterOk = async () => {
        try {
            setLoading(true);
            await formRegister.validateFields();
            const projectCode = formRegister.getFieldValue('projectCode')
            const response = await props.registerUser(props.history, formRegister.getFieldsValue(), projectCode);

            if (response && response?.status === 201) {
                message.success(intl.formatMessage({id: 'label.user-registered'}));
                formRegister.resetFields();
                setIsModalRegisterVisible(false);
            }

        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const handleRegisterCancel = () => {
        setIsModalRegisterVisible(false);
    };


    useEffect(() => {
        if (props.isAuthenticated) {
            setLoading(false);
            props.getCurrentUser(props.history);
            props.history.push('/dashboard')
        }

        if (props.error) {
            const error = () => {
                if (props.error.error === 404) {
                    if (props.error.message) {
                        message.error(props.error.message).then(props.clearLoginError());
                    } else {
                        message.error(intl.formatMessage({id: 'label.invalid-username'})).then(props.clearLoginError());
                    }
                } else if (typeof props.error.errors === 'object') {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearLoginError())));
                } else {
                    message.error(intl.formatMessage({id: 'msg.invalid-credentials'})).then(props.clearLoginError());
                }
            };
            setLoading(false);
            error();

        }

        if (geoRegions.length === 1) {
            dispatch(getUserRegistrationRegions()).then((data) => {
                if (data !== undefined && geoRegions.length === 1) {
                    const loadedRegions = [...geoRegions];
                    data.map(geoRegion => loadedRegions.push({label: geoRegion.name, value: geoRegion.code})
                    )
                    setGeoRegions(loadedRegions);
                }

            })

        }

        // eslint-disable-next-line
    }, [props.isAuthenticated, props.error]);

    const onFinish = (values) => {
        setLoading(true);
        props.login(props.history, values);
    };

    const onFinishFailed = (errorInfo) => {
        setLoading(false);
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const onRegisterFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    return (
        <Row>
            <Col className={'login-center'}>
                <h1><FormattedMessage id="label.user-credentials" defaultMessage="User Credentials"/></h1>
                <Form
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label={<FormattedMessage id="label.username" defaultMessage="Username"/>}
                        name="username"
                        rules={[{
                            required: true,
                            message: intl.formatMessage({id: 'msg.input-username'})
                        }]}
                    >
                        <Input autoCapitalize="off"/>
                    </Form.Item>

                    <Form.Item
                        label={<FormattedMessage id="label.password" defaultMessage="Password"/>}
                        name="password"
                        rules={[{
                            required: true,
                            message: intl.formatMessage({id: 'msg.input-password'})
                        }]}
                    >
                        <Input.Password/>
                    </Form.Item>


                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            <FormattedMessage id="label.submit" defaultMessage="Submit"/>
                        </Button>
                    </Form.Item>

                    <Divider/>
                    <Link to='#' style={{float: 'left'}} onClick={showModal}>
                        <FormattedMessage id="label.forgot-password" defaultMessage="Forgot Password?"/>
                    </Link>
                    <Link to='#' style={{float: 'right'}} onClick={showRegisterModal}>
                        <FormattedMessage id="label.register-project-code" defaultMessage="Register with Project code"/>
                    </Link>
                </Form></Col>
            <Modal title={intl.formatMessage({id: 'label.request-password-reset'})} visible={isModalVisible}
                   onOk={handleOk} onCancel={handleCancel} centered confirmLoading={loading}>
                <Form layout="horizontal" name="request-password-reset" onFinishFailed={onFinishFailed} form={form}>
                    <Form.Item
                        label={<FormattedMessage id="label.username" defaultMessage="Username"/>}
                        name="username"
                        rules={[{
                            required: true,
                            message: intl.formatMessage({id: 'msg.input-username'})
                        }]}
                    >
                        <Input autoCapitalize="off"/>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title={intl.formatMessage({id: 'label.register-project-code'})} visible={isModalRegisterVisible}
                   onOk={handleRegisterOk} onCancel={handleRegisterCancel} centered confirmLoading={loading}>
                <Form layout="horizontal" name="register-user" onFinishFailed={onRegisterFinishFailed}
                      form={formRegister}
                      labelCol={{span: 7,}}>
                    <Form.Item
                        name="firstName"
                        label={intl.formatMessage({id: 'label.first-name'})}
                        rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        label={intl.formatMessage({id: 'label.last-name'})}
                        rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={intl.formatMessage({id: 'label.email'})}
                        rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                    >
                        <Input type='email'/>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="label.password" defaultMessage="Password"/>}
                        name="password"
                        rules={[{
                            required: true,
                            message: intl.formatMessage({id: 'msg.input-required'})
                        }]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        label={<FormattedMessage id="label.confirm-password" defaultMessage="Confirm password"/>}
                        name="password2"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({id: 'msg.input-required'}),
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(intl.formatMessage({id: 'label.mismatch-password'})));
                                },
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item name="area" label={intl.formatMessage({id: 'label.region'})}
                               rules={[{
                                   required: true,
                                   message: intl.formatMessage({id: 'msg.input-required'})
                               }]}>
                        <Select options={geoRegions}>

                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="projectCode"
                        label={intl.formatMessage({id: 'label.project-code'})}
                        rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                    >
                        <Input type='projectCode'/>
                    </Form.Item>
                    <Form.Item
                        name="job"
                        label={intl.formatMessage({id: 'label.job'})}
                        rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="company"
                        label={intl.formatMessage({id: 'label.company'})}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label={intl.formatMessage({id: 'label.phone'})}
                    >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: selectIsAuthenticated(state),
    error: selectError(state)
});

export default connect(mapStateToProps, {
    login,
    getCurrentUser,
    clearLoginError,
    requestResetPassword,
    registerUser
})(Login);
