import {useEffect, useState} from "react";
import {Button, Col, Input, Row} from "antd";
import {v4 as uuid} from "uuid";

const UUIDGenerator = props => {

    useEffect(() => {

        if (props.form.getFieldValue && uuidValue === null) {
            if (props.qbNameNormalized) {
                if (props.form.getFieldValue(props.qbNameNormalized) && props.form.getFieldValue(props.qbNameNormalized)[props.fieldKey] && props.form.getFieldValue(props.qbNameNormalized)[props.fieldKey][props.nameNormalized]) {
                    setUuidValue(props.form.getFieldValue(props.qbNameNormalized)[props.fieldKey][props.nameNormalized])
                }
            } else if (props.form.getFieldValue(props.nameNormalized)) {
                setUuidValue(props.form.getFieldValue(props.nameNormalized))
            }

        }
        // eslint-disable-next-line
    }, [props])

    const [uuidValue, setUuidValue] = useState(null);

    return <Row gutter={24}>
        <Col span={4}><Button type="primary" size={"large"} style={{marginTop: '1%'}} onClick={() => {
            const value = uuid()?.toString()
            if (props.qbNameNormalized) {
                let qbValues = props.form.getFieldValue(props.qbNameNormalized)
                if (!qbValues) {
                    qbValues = {}
                }
                if (!qbValues[props.fieldKey]) {
                    qbValues[props.fieldKey] = {}
                }
                qbValues[props.fieldKey][props.nameNormalized] = value
            } else {
                props.form.setFieldsValue({
                    [props.nameNormalized]: value,
                });
            }


            setUuidValue(value)
        }}>Generate UUID</Button></Col>
        <Col span={20}><Input value={uuidValue?.toString()} disabled={true}/></Col>
    </Row>

};

export default UUIDGenerator;