import {Breadcrumb, Layout, message, Table, Popconfirm, Button, Modal, Card, Row} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone, EyeTwoTone} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import Moment from 'react-moment';
import {connect} from "react-redux";
import {useEffect, useState} from "react";

import {selectLoading, selectError, selectDataSourceConfigurations,
selectCurrentDataSourceConfiguration} from "../../redux/dataSourceConfiguration/dataSourceConfigurationSelector";
import {
    listDataSourceConfigurations,
    deleteDataSourceConfiguration,
    clearDataSourceConfigurationError,
    getDataSourceConfiguration
} from '../../redux/dataSourceConfiguration/dataSourceConfigurationActions';
import {Link} from "react-router-dom";
import {selectCurrentUser} from "../../redux/user/userSelector";
import {selectIsAuthenticated} from "../../redux/auth/authSelector";


const {Content, Footer} = Layout;

const labelStyle = {width: '25%', textAlign: 'center'}
const valueStyle = {width: '75%', textAlign: 'left'}

const DataSourceConfigurations = (props) => {
    const intl = useIntl();
    const {dataSourceConfigurations, count, max, offset} = props.dataSourceConfigurations;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dataSourceConfigurationDetails, setDataSourceConfigurationDetails] = useState(<Card loading={true}> </Card>);

    const checkPermissions = (requiredPermission) => {
        return props.isAuthenticated && requiredPermission && props.currentUser && props.currentUser?.permissions?.find((permission) => permission === requiredPermission)
    }

    const showModal = (id) => {
        setIsModalVisible(true);
        props.getDataSourceConfiguration(props.history, id).then((data) => {
            setDataSourceConfigurationDetails(<Card>
                <Row gutter={16}>
                    <Card.Grid hoverable={false} style={labelStyle}>
                        <b><FormattedMessage id="label.name" defaultMessage="Name"/></b>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.name}
                    </Card.Grid>
                </Row>
                <Row gutter={16}>
                    <Card.Grid hoverable={false} style={labelStyle}>
                        <b><FormattedMessage id="label.description" defaultMessage="Description"/></b>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.description}
                    </Card.Grid>
                </Row>
                <Row gutter={16}>
                    <Card.Grid hoverable={false} style={labelStyle}>
                        <b><FormattedMessage id="label.active" defaultMessage="Active"/></b>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {(data.active ? intl.formatMessage({id: 'label.yes'}) : intl.formatMessage({id: 'label.no'}))}
                    </Card.Grid>
                </Row>
                <Row gutter={16}>
                    <Card.Grid hoverable={false} style={labelStyle}>
                        <b><FormattedMessage id="label.configuration" defaultMessage="Configuration"/></b>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {JSON.stringify(data.configuration)}
                    </Card.Grid>
                </Row>
                <Row gutter={16}>
                    <Card.Grid hoverable={false} style={labelStyle}>
                        <b><FormattedMessage id="label.last-updated" defaultMessage="Last Updated"/></b>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        <Moment format="YYYY-MM-DD HH:MM:SS">{data.lastUpdated}</Moment>
                    </Card.Grid>
                </Row>
            </Card>)
        })
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'label.id'}),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '5%',
            fixed: true,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: intl.formatMessage({id: 'label.actions'}),
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            fixed: true,
            render: (_, record) =>
                count >= 1 ? (
                    <div className="actionsIcons">
                        {checkPermissions('ROLE_ADMIN') ? <><Popconfirm
                            title={intl.formatMessage({id: 'msg.confirm-delete'})}
                            onConfirm={() => handleDelete(record.id)}>
                            <DeleteTwoTone twoToneColor="red" title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.dataSourceConfiguration'})} />
                        </Popconfirm>
                            <Link to={`/configuration/datasources/${record.id}`}><EditTwoTone title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.dataSourceConfiguration'})} /></Link></> : <></>}

                        <EyeTwoTone onClick={() => {
                            showModal(record.id)
                        }} twoToneColor="green" title={intl.formatMessage({id: 'title.label.actions.show'}) +' '+ intl.formatMessage({id: 'title.label.actions.dataSourceConfiguration'}) +' '+ intl.formatMessage({id: 'title.label.actions.details'})} />
                    </div>
                ) : null,
        },
        {
            title: intl.formatMessage({id: 'label.name'}),
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            fixed: false,
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('name'),
        },
        {
            title: intl.formatMessage({id: 'label.description'}),
            dataIndex: 'description',
            key: 'description',
            width: '25%',
            fixed: false,
            sorter: (a, b) => a.description - b.description,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('description'),
        },
        {
            title: intl.formatMessage({id: 'label.active'}),
            dataIndex: 'active',
            key: 'active',
            width: '10%',
            fixed: false,
            sorter: (a, b) => a.active - b.active,
            sortDirections: ['ascend', 'descend'],
            render: (value) => {
                return value ? intl.formatMessage({id: 'label.yes'}) : intl.formatMessage({id: 'label.no'})
            }
        },
        {
             title: intl.formatMessage({id: 'label.last-updated'}),
             dataIndex: 'lastUpdated',
             key: 'lastUpdated',
             width: '25%',
             fixed: false,
             sorter: (a, b) => a.lastUpdated - b.lastUpdated,
             sortDirections: ['ascend', 'descend'],
             filterMultiple: false,
             ...columnSearchProps('lastUpdated'),
             render: (value) => {
                 return <Moment format="YYYY-MM-DD HH:MM:SS">{value}</Moment>
             }
        }
    ];

    useEffect(() => {

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearDataSourceConfigurationError());
            };
            error();
        } else {
            props.listDataSourceConfigurations(props.history);
        }
        // eslint-disable-next-line
    }, [props.error]);

    const handleChange = (pagination, filters, sorter) => {
        props.listDataSourceConfigurations(props.history, pagination, filters, sorter);
    };

    const handleDelete = (id) => {
        props.deleteDataSourceConfiguration(props.history, id);
    };

    const [pagination, setPagination] = useState(null);
    useEffect(() => {

        if (offset === undefined || max === undefined || count === undefined) return;

        setPagination({
         current: offset / max + 1,
         showLessItems: true,
         pageSizeOptions: [10, 20, 50, 100],
         pageSize: max,
         defaultPageSize: max,
         total: count,
         showSizeChanger: true,
         showTotal: ((total, range) => intl.formatMessage({
           id: 'label.range-total'
         }, {one: range[0], two: range[1], total: total}))
       })
        //eslint-disable-next-line
    }, [offset, max, count, props.dataSourceConfigurations])

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.dataSourceConfigurations" defaultMessage="DataSourceConfigurations"/></span>
                    </Breadcrumb.Item>

                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    {checkPermissions('ROLE_ADMIN') ?
                        <Link to='/configuration/datasources/new'><Button type="primary" size={"large"} style={{marginBottom: 16}}>
                            <FormattedMessage id="label.add-dataSourceConfiguration" defaultMessage="Add a DataSourceConfiguration"/>
                        </Button></Link> : <></>}
                    <Table
                        bordered={true}
                        loading={props.loading}
                        rowKey={record => record.id}
                        responsive={true}
                         pagination={pagination}
                        size={'default'}
                        showHeader
                        columns={columns}
                        dataSource={dataSourceConfigurations}
                        scroll={{y: '65vh', x: '100vw'}}
                        onChange={handleChange}
                    />
                </div>
                <Modal title={intl.formatMessage({id: "label.dataSourceConfiguration-details"})} visible={isModalVisible}
                       onOk={handleOk} onCancel={handleCancel} centered width={720}>
                    {dataSourceConfigurationDetails}
                </Modal>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    dataSourceConfigurations: selectDataSourceConfigurations(state),
    dataSourceConfiguration: selectCurrentDataSourceConfiguration(state),
    currentUser: selectCurrentUser(state),
    isAuthenticated: selectIsAuthenticated(state)
});

export default connect(mapStateToProps, {
    listDataSourceConfigurations,
    deleteDataSourceConfiguration,
    clearDataSourceConfigurationError,
    getDataSourceConfiguration
})(DataSourceConfigurations);
