import {PERMISSION_ACTION_TYPES} from "../typesAction";


const initialState = {
    permissions: [],
    loading: false,
    error: null
}

const permissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case PERMISSION_ACTION_TYPES.LOAD_PERMISSION:
            return {
                ...state, loading: true
            }
        case PERMISSION_ACTION_TYPES.CLEAR_LOAD_PERMISSION:
            return {
                ...state, loading: false
            }
        case PERMISSION_ACTION_TYPES.ERROR_PERMISSION:
            return {
                ...state, error: action.payload, loading: false, current: null
            }
        case PERMISSION_ACTION_TYPES.CLEAR_PERMISSION_ERRORS:
            return {
                ...state,
                error: null, loading: false
            }
        case PERMISSION_ACTION_TYPES.LIST_PERMISSION:
            return {
                ...state, permissions: action.payload, loading: false, current: null
            }
        default:
            return state;
    }

};

export default permissionReducer;