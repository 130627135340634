import {Form, Input, Select, message, Breadcrumb, Layout, Card, Button, Radio} from 'antd';
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    setLoading,
    clearLoading,
    addLayer,
    getLayer,
    editLayer,
    clearLayerError
} from "../../redux/layer/layerActions";
import {HomeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {selectError, selectLoading, selectCurrentLayer} from "../../redux/layer/layerSelector";
import {getForms} from "../../utils/utilData";

const {Content, Footer} = Layout;


const LayersForm = (props) => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const {Option} = Select;
    const [layerCreated, setLayerCreated] = useState(false);
    const [edit, setEdit] = useState(props.location.pathname !== '/layers/points-of-interest/new');

    const [form] = Form.useForm();
    const onFinish = (values) => {
        if (edit) {
            props.editLayer(props.history, props.match.params.id, values, setLayerCreated);
        } else {
            props.addLayer(props.history, values, setLayerCreated);
        }

    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const [forms, setForms] = useState([]);

    useEffect(() => {
        if (forms.length === 0) {
            dispatch(setLoading());
            dispatch(getForms(props.history)).then(
                (data) => {
                    if (data !== undefined && forms.length === 0) {
                        const loadedForms = [...forms];
                        data.map(formLoaded => loadedForms.push({label: formLoaded.name, value: formLoaded.id})
                        );
                        setForms(loadedForms);
                    }
                    dispatch(clearLoading());
                }
            );
        }
        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearLayerError());
                } else {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearLayerError())));
                }

            };
            error();
        }

        if (edit && !layerCreated && props.error === null) {
            props.getLayer(props.history, props.match.params.id).then((data) => {
                if (data) {
                    form.setFieldsValue({
                        'name': data.name,
                        'type': data.type,
                        'active': data.active,
                        'recordCreation': data.recordCreation,
                        'color': data.color,
                        'description': data.description,
                        'form': data.form,
                    });
                } else {
                    setEdit(false);
                }
            })
        }

        if (layerCreated) {
            if (edit) {
                message.info(intl.formatMessage({id: 'label.layer-updated'}));
            } else {
                form.resetFields();
                message.info(intl.formatMessage({id: 'label.layer-created'}));
            }
            setLayerCreated(false);
        }
        // eslint-disable-next-line
    }, [props.error, layerCreated, edit])

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.layers" defaultMessage="Layers"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{edit ? (<FormattedMessage
                            id="label.edit" defaultMessage="Edit"/>) : (
                            <FormattedMessage id="label.new" defaultMessage="New"/>)}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Card type="inner"
                          title={edit ? <FormattedMessage id="label.edit-layer" defaultMessage="Edit Layer"/> :
                              <FormattedMessage id="label.create-layer" defaultMessage="Create Layer"/>}
                          extra={<Link to={'/layers/points-of-interest'}><FormattedMessage id="label.return"
                                                                                                defaultMessage="Return"/></Link>}>
                        <Form layout="horizontal" name="layer_form" size="large"
                              labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                              initialValues={{active: true, recordCreation: false}} form={form}
                              onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Form.Item
                                name="name"
                                label={intl.formatMessage({id: 'label.name'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name="type" label={intl.formatMessage({id: 'label.type'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>
                                <Select>
                                    <Select.Option value={1}><FormattedMessage id="label.points-of-interest"
                                                                               defaultMessage="Points of Interest"/></Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="color" label={intl.formatMessage({id: 'label.color'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>
                                <Input type={'color'}/>
                            </Form.Item>
                            <Form.Item name="active" label={intl.formatMessage({id: 'label.active'})}>
                                <Radio.Group>
                                    <Radio value={true}><FormattedMessage id="label.yes" defaultMessage="Yes"/></Radio>
                                    <Radio value={false}><FormattedMessage id="label.no" defaultMessage="No"/></Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item name="recordCreation" label={intl.formatMessage({id: 'label.record-creation'})}>
                                <Radio.Group>
                                    <Radio value={true}><FormattedMessage id="label.yes" defaultMessage="Yes"/></Radio>
                                    <Radio value={false}><FormattedMessage id="label.no" defaultMessage="No"/></Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={intl.formatMessage({id: 'label.description'})}
                                rules={[{required: false, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name="form" label={intl.formatMessage({id: 'label.form'})}
                                       rules={[{
                                           required: true,
                                           message: intl.formatMessage({id: 'msg.input-required'})
                                       }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {forms.map(form => {
                                        return (
                                            <Option key={form.value} value={form.value}>
                                                {form.label}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading}>
                                    {edit ? (<FormattedMessage id="label.update" defaultMessage="Update"/>) : (
                                        <FormattedMessage id="label.submit" defaultMessage="Submit"/>)}

                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    layer: selectCurrentLayer(state)
});

export default connect(mapStateToProps, {addLayer, getLayer, editLayer, clearLayerError})(LayersForm);
