import {Breadcrumb, Layout, message, Table, Popconfirm, Button, Modal, Card, Row, Divider} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone, EyeTwoTone} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import Moment from 'react-moment';
import {connect} from "react-redux";
import {useEffect, useState} from "react";

import {selectLoading, selectError, selectProjects, selectCurrentProject} from "../../redux/project/projectSelector";
import {
    listProjects,
    deleteProject,
    clearProjectError,
    getProject,
    inviteStaff
} from '../../redux/project/projectActions';
import {Link} from "react-router-dom";
import {selectCurrentUser} from "../../redux/user/userSelector";
import {selectIsAuthenticated} from "../../redux/auth/authSelector";


const {Content, Footer} = Layout;

const labelStyle = {width: '25%', textAlign: 'center'}
const valueStyle = {width: '75%', textAlign: 'left'}

const Projects = (props) => {
    const intl = useIntl();
    const {projects, count, max, offset} = props.projects;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [notifyLoading, setNotifyLoading] = useState(false);
    const [projectDetails, setProjectDetails] = useState(<Card loading={true}> </Card>);

    const checkPermissions = (requiredPermission) => {
        return props.isAuthenticated && requiredPermission && props.currentUser && props.currentUser?.permissions?.find((permission) => permission === requiredPermission)
    }

    const showModal = (id) => {
        setIsModalVisible(true);
        props.getProject(props.history, id).then((data) => {
            setProjectDetails(<Card>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.name" defaultMessage="Name"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.name}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.code" defaultMessage="Code"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.code}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.regionName" defaultMessage="Region Name"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.regionName}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.active" defaultMessage="Active"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {(data.active ? intl.formatMessage({id: 'label.yes'}) : intl.formatMessage({id: 'label.no'}))}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.dateStart" defaultMessage="dateStart"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        <Moment format="YYYY-MM-DD">{data.dateStart}</Moment>
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.dateEnd" defaultMessage="dateEnd"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        <Moment format="YYYY-MM-DD">{data.dateEnd}</Moment>
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.language" defaultMessage="language"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.language}
                    </Card.Grid></Row>
                <Row gutter={16}>
                    <Card.Grid hoverable={false} style={labelStyle}>
                        <b><FormattedMessage id="label.staff" defaultMessage="Staff"/></b>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.staff?.length} <Divider type='vertical'/>
                        {checkPermissions('ROLE_PERMISSION_PROJECT_INVITE_STAFF') ?
                            <Popconfirm title={intl.formatMessage({id: 'label.confirm-notify'})}
                                        onConfirm={() => inviteStaff(data.id)} disabled={data.staff?.length === 0}>
                                <Button type="danger" loading={notifyLoading} disabled={data.staff?.length === 0}>
                                    <FormattedMessage id="label.invite-staff" defaultMessage="Invite Staff"/>
                                </Button>
                            </Popconfirm> : <></>}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.last-updated" defaultMessage="Last Updated"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        <Moment format="YYYY-MM-DD HH:MM:SS">{data.lastUpdated}</Moment>
                    </Card.Grid></Row>


            </Card>)
        })
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'label.id'}),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '5%',
            fixed: true,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: intl.formatMessage({id: 'label.actions'}),
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            fixed: true,
            render: (_, record) =>
                count >= 1 ? (
                    <div className="actionsIcons">
                        {checkPermissions('ROLE_PERMISSION_PROJECT_EDIT') ? <><Popconfirm
                            title={intl.formatMessage({id: 'msg.confirm-delete'})}
                            onConfirm={() => handleDelete(record.id)}>
                            <DeleteTwoTone twoToneColor="red" title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.project'})} />
                        </Popconfirm>
                        <Link to={`/projects/${record.id}`}><EditTwoTone title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.project'})} /></Link></> : <></>}

                        <EyeTwoTone onClick={() => {
                            showModal(record.id)
                        }} twoToneColor="green" title={intl.formatMessage({id: 'title.label.actions.show'}) +' '+ intl.formatMessage({id: 'title.label.actions.project'}) +' '+ intl.formatMessage({id: 'title.label.actions.details'})} />
                    </div>
                ) : null,
        },
        {
            title: intl.formatMessage({id: 'label.name'}),
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            fixed: false,
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('name'),
        },
        {
            title: intl.formatMessage({id: 'label.code'}),
            dataIndex: 'code',
            key: 'code',
            width: '10%',
            fixed: false,
            sorter: (a, b) => a.code.length - b.code.length,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('code'),
        }, {
            title: intl.formatMessage({id: 'label.regionName'}),
            dataIndex: 'regionName',
            key: 'regionName',
            width: '15%',
            fixed: false,
            // sorter: (a, b) => a.regionName.length - b.regionName.length,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('regionName'),
        }, {
            title: intl.formatMessage({id: 'label.active'}),
            dataIndex: 'active',
            key: 'active',
            width: '6%',
            fixed: false,
            sorter: (a, b) => a.active - b.active,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            filters: [
                {
                    text: intl.formatMessage({id: 'label.yes'}),
                    value: true,
                },
                {
                    text: intl.formatMessage({id: 'label.no'}),
                    value: false,
                },
            ],
            render: (text) => (text ? intl.formatMessage({id: 'label.yes'}) : intl.formatMessage({id: 'label.no'}))
        }, {
            title: intl.formatMessage({id: 'label.dateStart'}),
            dataIndex: 'dateStart',
            key: 'dateStart',
            width: '8%',
            fixed: false,
            sorter: (a, b) => a.dateStart.length - b.dateStart.length,
            sortDirections: ['ascend', 'descend'],
            render: (text) => (<Moment format="YYYY-MM-DD">{text}</Moment>)
        }, {
            title: intl.formatMessage({id: 'label.dateEnd'}),
            dataIndex: 'dateEnd',
            key: 'dateEnd',
            width: '8%',
            fixed: false,
            sorter: (a, b) => a.dateEnd.length - b.dateEnd.length,
            sortDirections: ['ascend', 'descend'],
            render: (text) => (<Moment format="YYYY-MM-DD">{text}</Moment>)
        }, {
            title: intl.formatMessage({id: 'label.language'}),
            dataIndex: 'language',
            key: 'language',
            width: '8%',
            fixed: false,
            sorter: (a, b) => a.language.length - b.language.length,
            sortDirections: ['ascend', 'descend']
        }

    ];

    useEffect(() => {

        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearProjectError());
                } else {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearProjectError())));
                }

            };
            error();
        } else {
            if (notifyLoading === false) {
                props.listProjects(props.history);
            }

        }
        // eslint-disable-next-line
    }, [props.error, notifyLoading]);

    const handleChange = (pagination, filters, sorter) => {
        props.listProjects(props.history, pagination, filters, sorter);
    };

    const handleDelete = (id) => {
        props.deleteProject(props.history, id);
    };

    const inviteStaff = (id) => {
        setNotifyLoading(true);
        props.inviteStaff(props.history, id).then(() => {
            setNotifyLoading(false);
            message.info(intl.formatMessage({id: 'label.staff-invited'}));
        });
    };


    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.projects" defaultMessage="Projects"/></span>
                    </Breadcrumb.Item>

                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    {checkPermissions('ROLE_PERMISSION_PROJECT_EDIT') ?
                        <Link to='/projects/new'><Button type="primary" size={"large"} style={{marginBottom: 16}}>
                            <FormattedMessage id="label.add-project" defaultMessage="Add a Project"/>
                        </Button></Link> : <></>}
                    <Table
                        bordered={true}
                        loading={props.loading}
                        rowKey={record => record.id}
                        responsive={true}
                        pagination={{
                            current: offset / max + 1,
                            showLessItems: true, pageSizeOptions: [10, 20, 50, 100],
                            pageSize: max,
                            defaultPageSize: max, total: count, showSizeChanger: true,
                            showTotal: ((total, range) => intl.formatMessage({
                                id: 'label.range-total'
                            }, {one: range[0], two: range[1], total: total}))
                        }}
                        size={'default'}
                        showHeader
                        columns={columns}
                        dataSource={projects}
                        scroll={{y: '65vh', x: '100vw'}}
                        onChange={handleChange}
                    />
                </div>
                <Modal title={intl.formatMessage({id: 'label.project-details'})} visible={isModalVisible}
                       onOk={handleOk} onCancel={handleCancel} centered width={720} confirmLoading={notifyLoading}>
                    {projectDetails}
                </Modal>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    projects: selectProjects(state),
    project: selectCurrentProject(state),
    currentUser: selectCurrentUser(state),
    isAuthenticated: selectIsAuthenticated(state)
});

export default connect(mapStateToProps, {
    listProjects,
    deleteProject,
    clearProjectError,
    getProject,
    inviteStaff
})(Projects);