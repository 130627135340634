import {PROJECT_ACTION_TYPES} from "../typesAction";


const initialState = {
    projects: [],
    current: null,
    loading: false,
    error: null
}

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROJECT_ACTION_TYPES.LOAD_PROJECT:
            return {
                ...state, loading: true
            }
        case PROJECT_ACTION_TYPES.CLEAR_LOAD_PROJECT:
            return {
                ...state, loading: false
            }
        case PROJECT_ACTION_TYPES.ERROR_PROJECT:
            return {
                ...state, error: action.payload, loading: false, current: null
            }
        case PROJECT_ACTION_TYPES.CLEAR_PROJECT_ERRORS:
            return {
                ...state,
                error: null, loading: false
            }
        case PROJECT_ACTION_TYPES.LIST_PROJECT:
            return {
                ...state, projects: action.payload, loading: false, current: null
            }
        case PROJECT_ACTION_TYPES.ADD_PROJECT:
            return {
                ...state,
                loading: false
            }
        case PROJECT_ACTION_TYPES.SHOW_PROJECT:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case PROJECT_ACTION_TYPES.EDIT_PROJECT:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case PROJECT_ACTION_TYPES.DELETE_PROJECT:
            const updatedProjects = state.projects.projects.filter(project => action.payload !== project.id)
            const updatedCount = state.projects.count - 1;
            return {
                ...state, projects: {...state.projects, projects: updatedProjects, count: updatedCount}, loading: false
            }
        default:
            return state;
    }

};

export default projectReducer;