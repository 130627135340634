import {
    Breadcrumb, Layout, message, Card
} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import {FormattedMessage} from "react-intl";
import {connect, useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import './ReportView.css'
import {getReportShared} from "../../utils/utilData";

const {Content, Footer} = Layout;

const ReportSharedView = (props) => {

    const dispatch = useDispatch();
    const [cardLoading, setCardLoading] = useState(true);
    const [htmlContent, setHtmlContent] = useState(true);
    const [titleContent, seTitleContent] = useState('');


    useEffect(() => {
        dispatch(getReportShared(null, props.match.params.id, props.match.params.sharedToken)).then((data) => {
            if (data && data?.htmlContent) {
                setCardLoading(false)
                setHtmlContent(data.htmlContent)
                seTitleContent(data.reportName)
            }else if(data?.errors){
                message.error(data.errors)
            }
        })

        //eslint-disable-next-line
    }, []);

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports" defaultMessage="Reports"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports.view" defaultMessage="View"/></span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Card
                    type={'inner'} loading={cardLoading} title={titleContent}>
                    <iframe srcDoc={htmlContent?.toString()}
                            className={"iframe-html"}
                            title={"htmlContent"}>
                        <FormattedMessage id="label.report.view.renderingHtml.notCompatibleBrowserForIframe"/>
                    </iframe>
                </Card>

            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};


export default connect(null,
    {})(ReportSharedView);