/*
* VA Team
*/

import React, {useState} from "react";
import {Form, Select} from "antd";

import PlotPointMapWeatherStations from "./PlotPointMapWeatherStations";
import ActiveStationsTable from "./ActiveStationsTable";
import {FormItemsLocations} from "../../../utils/dataAnalysis/Locations/FormItemsLocations";

import './FormItemActiveWeatherStationsId.css';

/*
    This custom React component collects information for form field 'configuration.activeWeatherStationsId'.
    The component includes the following ones: 
    <PlotPointMapWeatherStations/>, <ActiveStationsTable /> and <FormItemsLocations />
    The form field 'configuration.activeWeatherStationsId' is always hidden.
*/
const FormItemActiveWeatherStationsId = (props) => {

    const {
        form,
        edit,
        changedFields,
        dataSourceConfiguration,
    } = props;

    const [activeWeatherStations, setActiveWeatherStations] = useState(null);
    const [disableStationId, setDisableStationId] = useState(null);

    //This state is set in FormItemsLocations and is used in PlotPointMapWeatherStations
    const [locationsObjectChanged, setLocationsObjectChanged] = useState();

  //jsx
  return (
    <div>
      <PlotPointMapWeatherStations    
            form={form}
            edit={edit}
            locationsObjectChanged={locationsObjectChanged}
            disableStationId={disableStationId}
            setActiveWeatherStations={setActiveWeatherStations}
      />
       {
        activeWeatherStations !== null
        ? <ActiveStationsTable
              activeWeatherStations={activeWeatherStations}
              setDisableStationId={setDisableStationId}
          /> : null
       }       
       <FormItemsLocations
             isDataSourceConfiguration={true}
             form={form}
             configEditFieldName={'configuration'}
             fieldsChanged={changedFields}
             configEdit={dataSourceConfiguration?.configuration}
             edit={edit}
             setLocationsObjectChanged={setLocationsObjectChanged}
       />
       <Form.Item      
           name={['configuration','activeWeatherStationsId']}
           hidden={true}
       >
           <Select mode={'multiple'} />
       </Form.Item>
       <Form.Item   //this field is used when calling form "onFinish" (to update weather stations in database)
          name={['configuration','updatedWeatherStations']}
          hidden={true}
       >
          <Select mode={'multiple'} />
       </Form.Item>
    </div>
  )
}

export default FormItemActiveWeatherStationsId;
