import './App.css';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import About from "./components/pages/About";
import Home from "./components/pages/Home";
import Navbar from "./components/layout/Navbar";
import { Layout } from "antd";
import Dashboard from "./components/pages/Dashboard";
import Profile from "./components/pages/Profile";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCurrentUser } from './redux/user/userActions'
import Login from "./components/pages/Login";
import { selectCurrentUser, selectAppLoading } from "./redux/user/userSelector";
import WithSpinner from "./components/layout/WithSpinner";
import { selectIsAuthenticated } from "./redux/auth/authSelector";
import PrivateRoute from "./components/routing/PrivateRoute";
import Projects from "./components/projects/Projects";
import ProjectForm from "./components/projects/ProjectForm";
import Permissions from "./components/permissions/Permissions";
import Roles from "./components/roles/Roles";
import Users from "./components/users/Users";
import SurveyForms from "./components/surveyForms/SurveyForms";
import SurveyFormForm from "./components/surveyForms/SurveyFormForm";
import SurveyFormsDataForm from "./components/surveyForms/SurveyFormsDataForm";
import Staff from "./components/staff/Staff";
import StaffForm from "./components/staff/StaffForm";
import AlertContact from "./components/alertContact/AlertContact";
import AlertContactForm from "./components/alertContact/AlertContactForm";
import UserForm from "./components/users/UserForm";
import RoleForm from "./components/roles/RoleForm";
import RecoverPassword from "./components/pages/RecoverPassword";

import Paths from "./components/paths/Paths";
import PathForm from "./components/paths/PathForm";
import SurveyFormsData from "./components/surveyForms/SurveyFormsData";
import Reports from "./components/reports/Reports";
import ReportForm from "./components/reports/ReportForm";
import ReportView from "./components/reports/ReportView";
import ReportCode from "./components/reports/ReportCode";
import ReportEditHtml from "./components/reports/ReportEditHtml";
import ReportFiles from "./components/reportFiles/ReportFiles";
import ReportFileForm from "./components/reportFiles/ReportFileForm";
import ReportParameters from "./components/reportParameters/ReportParameters";
import ReportParameterForm from "./components/reportParameters/ReportParameterForm";

import DataAnalysis from "./components/dataAnalysis/DataAnalysis";
import DataAnalysisForm from "./components/dataAnalysis/DataAnalysisForm";
import Layers from "./components/layers/Layers";
import LayersForm from "./components/layers/LayersForm";
import PointsOfInterest from "./components/layers/PointsOfInterest";
import PointsOfInterestForm from "./components/layers/PointsOfInterestForm";
import Jobs from "./components/jobs/Jobs";

import DataSourceConfigurations from "./components/dataSourceConfigurations/DataSourceConfigurations";
import DataSourceConfigurationForm from "./components/dataSourceConfigurations/DataSourceConfigurationForm";
import Subscriptions from "./components/subscriptions/Subscriptions";
import SubscriptionForm from "./components/subscriptions/SubscriptionForm";
import ReportSharedView from "./components/reports/ReportSharedView";
import Guide from "./components/pages/Guide";

import NotFound from "./components/fallback/NotFound";

const DashboardWithSpinner = WithSpinner(Dashboard);
const NavbarWithRouter = withRouter(Navbar);

const App = (props) => {

    return (
        <BrowserRouter>
            <Layout style={{ minHeight: '100vh' }}>
                <NavbarWithRouter />
                <Layout>
                    <Switch>
                        <Route exact path='/' component={Home}/>
                        <PrivateRoute exact path='/dashboard' component={DashboardWithSpinner} permission={"ROLE_PERMISSION_VIEW_DASHBOARD"}/>
                        <PrivateRoute exact path='/projects' component={Projects} permission={"ROLE_PERMISSION_PROJECT_VIEW"}/>
                        <PrivateRoute exact path='/profile' component={Profile} permission={"ROLE_PERMISSION_EDIT_PROFILE"}/>
                        <PrivateRoute exact path='/projects/new' component={ProjectForm} permission={"ROLE_PERMISSION_PROJECT_EDIT"}/>
                        <PrivateRoute exact path='/projects/:id' component={ProjectForm} permission={"ROLE_PERMISSION_PROJECT_EDIT"}/>
                        <PrivateRoute exact path='/staff' component={Staff}  permission={"ROLE_PERMISSION_STAFF_VIEW"}/>
                        <PrivateRoute exact path='/staff/new' component={StaffForm} permission={"ROLE_PERMISSION_STAFF_EDIT"}/>
                        <PrivateRoute exact path='/staff/:id' component={StaffForm} permission={"ROLE_PERMISSION_STAFF_EDIT"} />
                        <PrivateRoute exact path='/contacts' component={AlertContact}  permission={"ROLE_PERMISSION_ALERT_CONTACT_VIEW"}/>
                        <PrivateRoute exact path='/subscriptions' component={Subscriptions} permission={"ROLE_PERMISSION_ALERT_CONTACT_VIEW"}/>
                        <PrivateRoute exact path='/subscriptions/new' component={SubscriptionForm} permission={"ROLE_PERMISSION_ALERT_CONTACT_EDIT"}/>
                        <PrivateRoute exact path='/subscriptions/:id' component={SubscriptionForm} permission={"ROLE_PERMISSION_ALERT_CONTACT_EDIT"}/>
                        <PrivateRoute exact path='/contacts/new' component={AlertContactForm} permission={"ROLE_PERMISSION_ALERT_CONTACT_EDIT"}/>
                        <PrivateRoute exact path='/contacts/:id' component={AlertContactForm} permission={"ROLE_PERMISSION_ALERT_CONTACT_EDIT"} />
                        <PrivateRoute exact path='/configuration/paths' component={Paths} permission={"ROLE_PERMISSION_PATH_VIEW"}/>
                        <PrivateRoute exact path='/configuration/paths/new' component={PathForm} permission={"ROLE_PERMISSION_PATH_EDIT"}/>
                        <PrivateRoute exact path='/configuration/paths/:id' component={PathForm} permission={"ROLE_PERMISSION_PATH_EDIT"}/>
                        <PrivateRoute exact path='/configuration/reports/reportFiles' component={ReportFiles}  permission={"ROLE_PERMISSION_REPORT_VIEW"}/>
                        <PrivateRoute exact path='/configuration/reports/:report/reportFiles/new' component={ReportFileForm}  permission={"ROLE_PERMISSION_REPORT_EDIT"}/>
                        <PrivateRoute exact path='/configuration/reports/reportFiles/:id' component={ReportFileForm} permission={"ROLE_PERMISSION_REPORT_VIEW"}/>
                        <PrivateRoute exact path='/configuration/reports/reportParameters' component={ReportParameters} permission={"ROLE_PERMISSION_REPORT_VIEW"}/>
                        <PrivateRoute exact path='/configuration/reports/:report/reportParameters/new' component={ReportParameterForm} permission={"ROLE_PERMISSION_REPORT_EDIT"}/>
                        <PrivateRoute exact path='/configuration/reports/reportParameters/:id' component={ReportParameterForm} permission={"ROLE_PERMISSION_REPORT_VIEW"}/>
                        <PrivateRoute exact path='/configuration/reports' component={Reports} permission={"ROLE_PERMISSION_REPORT_VIEW"}/>
                        <PrivateRoute exact path='/configuration/reports/new' component={ReportForm} permission={"ROLE_PERMISSION_REPORT_EDIT"}/>
                        <PrivateRoute exact path='/configuration/reports/view/:id' component={ReportView} permission={"ROLE_PERMISSION_REPORT_VIEW"}/>
                        <PrivateRoute exact path='/configuration/reports/output/edit/:id' component={ReportEditHtml}
                            permission={"ROLE_PERMISSION_REPORT_EDIT"}/>
                        <PrivateRoute exact path='/configuration/reports/output/view/:id' component={ReportEditHtml}
                            permission={"ROLE_PERMISSION_REPORT_VIEW"}/>
                        <PrivateRoute exact path='/reports/output/view/:id' component={ReportEditHtml}
                            permission={"ROLE_PERMISSION_REPORT_VIEW"}/>
                        <PrivateRoute exact path='/configuration/reports/:id' component={ReportForm} permission={"ROLE_PERMISSION_REPORT_VIEW"}/>
                        <PrivateRoute exact path='/configuration/reports/code/:id' component={ReportCode} permission={"ROLE_PERMISSION_REPORT_VIEW"}/>
                        <PrivateRoute exact path='/reports' component={Reports} permission={"ROLE_PERMISSION_REPORT_VIEW"}/>
                        <PrivateRoute exact path='/reports/view/:id' component={ReportView} permission={"ROLE_PERMISSION_REPORT_VIEW"}/>
                        <PrivateRoute exact path='/admin/permissions' component={Permissions}  permission={"ROLE_PERMISSION_ADMIN_PERMISSION_VIEW"}/>
                        <PrivateRoute exact path='/admin/roles' component={Roles} permission={"ROLE_PERMISSION_ADMIN_ROLE_VIEW"}/>
                        <PrivateRoute exact path='/admin/roles/new' component={RoleForm} permission={"ROLE_PERMISSION_ADMIN_ROLE_EDIT"}/>
                        <PrivateRoute exact path='/admin/roles/:id' component={RoleForm} permission={"ROLE_PERMISSION_ADMIN_ROLE_EDIT"}/>
                        <PrivateRoute exact path='/admin/users' component={Users} permission={"ROLE_PERMISSION_ADMIN_USER_VIEW"}/>
                        <PrivateRoute exact path='/admin/users/new' component={UserForm} permission={"ROLE_PERMISSION_ADMIN_USER_EDIT"}/>
                        <PrivateRoute exact path='/admin/users/:id' component={UserForm} permission={"ROLE_PERMISSION_ADMIN_USER_EDIT"}/>
                        <PrivateRoute exact path='/admin/jobs' component={Jobs} permission={"ROLE_ADMIN"}/>

                        <PrivateRoute exact path='/form-templates/templates' component={SurveyForms} permission={"ROLE_PERMISSION_DATA_VIEW"}/>
                        <PrivateRoute exact path='/form-templates/templates/new' component={SurveyFormForm} permission={"ROLE_PERMISSION_CONFIG_FORM"}/>
                        <PrivateRoute exact path='/form-templates/templates/:id' component={SurveyFormForm} permission={"ROLE_PERMISSION_CONFIG_FORM"}/>

                        <PrivateRoute exact path='/forms/templates' component={SurveyForms} permission={"ROLE_PERMISSION_DATA_VIEW"}/>
                        <PrivateRoute exact path='/forms/templates/:id' component={SurveyFormForm} permission={"ROLE_PERMISSION_CONFIG_FORM"}/>

                        <PrivateRoute exact path='/my-query-form' component={SurveyForms} permission={"ROLE_PERMISSION_DATA_VIEW"}/>
                        <PrivateRoute exact path='/forms' component={SurveyForms} permission={"ROLE_PERMISSION_DATA_VIEW"}/>
                        <PrivateRoute exact path='/weather' component={SurveyForms} permission={"ROLE_PERMISSION_DATA_VIEW"}/>
                        <PrivateRoute exact path='/forms/new' component={SurveyFormForm} permission={"ROLE_PERMISSION_CONFIG_FORM"}/>
                        <PrivateRoute exact path='/forms/:id' component={SurveyFormForm} permission={"ROLE_PERMISSION_CONFIG_FORM"}/>
                        <PrivateRoute exact path='/forms/:id/data' component={SurveyFormsData} permission={"ROLE_PERMISSION_DATA_VIEW"}/>
                        <PrivateRoute exact path='/forms/:id/data/new' component={SurveyFormsDataForm} permission={"ROLE_PERMISSION_DATA_EDIT"}/>
                        <PrivateRoute exact path='/forms/:id/data/:data_id' component={SurveyFormsDataForm} permission={"ROLE_PERMISSION_DATA_EDIT"}/>

                        <PrivateRoute exact path='/configuration/dataAnalysis' component={DataAnalysis} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/configuration/dataAnalysis/new' component={DataAnalysisForm} permission={"ROLE_PERMISSION_DATA_ANALYSIS_EDIT"}/>
                        <PrivateRoute exact path='/configuration/dataAnalysis/:id' component={DataAnalysisForm} permission={"ROLE_PERMISSION_DATA_ANALYSIS_EDIT"}/>
                        <PrivateRoute exact path='/configuration/dataAnalysis/view/:id' component={DataAnalysisForm} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/configuration/dataAnalysis/query/view/:id' component={DataAnalysisForm} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/analysis/time-series' component={DataAnalysis} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/analysis/time-series/view/:id' component={DataAnalysisForm} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/analysis/bar-plots' component={DataAnalysis} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/analysis/bar-plots/view/:id' component={DataAnalysisForm} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/analysis/point-maps' component={DataAnalysis} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/analysis/point-maps/view/:id' component={DataAnalysisForm} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/analysis/heat-maps' component={DataAnalysis} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/analysis/heat-maps/view/:id' component={DataAnalysisForm} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/analysis/choropleth-maps' component={DataAnalysis} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/analysis/choropleth-maps/view/:id' component={DataAnalysisForm} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"}/>
                        <PrivateRoute exact path='/analysis/bubble-maps' component={DataAnalysis} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"} />
                        <PrivateRoute exact path='/analysis/bubble-maps/view/:id' component={DataAnalysisForm} permission={"ROLE_PERMISSION_DATA_ANALYSIS_VIEW"} />

                        <PrivateRoute exact path='/layers/points-of-interest' component={Layers} permission={"ROLE_PERMISSION_LAYER_EDIT"}/>
                        <PrivateRoute exact path='/layers/points-of-interest/new' component={LayersForm} permission={"ROLE_PERMISSION_LAYER_EDIT"}/>
                        <PrivateRoute exact path='/layers/points-of-interest/:id' component={LayersForm} permission={"ROLE_PERMISSION_LAYER_EDIT"}/>
                        <PrivateRoute exact path='/layers/points-of-interest/:id/data' component={PointsOfInterest} permission={"ROLE_PERMISSION_LAYER_EDIT"}/>
                        <PrivateRoute exact path='/layers/points-of-interest/:id/new' component={PointsOfInterestForm} permission={"ROLE_PERMISSION_LAYER_EDIT"}/>
                        <PrivateRoute exact path='/layers/points-of-interest/:id/data/:point_id' component={PointsOfInterestForm} permission={"ROLE_PERMISSION_LAYER_EDIT"}/>

                        <PrivateRoute exact path='/configuration/datasources' component={DataSourceConfigurations} permission={"ROLE_ADMIN"}/>
                        <PrivateRoute exact path='/configuration/datasources/new' component={DataSourceConfigurationForm} permission={"ROLE_ADMIN"}/>
                        <PrivateRoute exact path='/configuration/datasources/:id' component={DataSourceConfigurationForm} permission={"ROLE_ADMIN"}/>
                        <PrivateRoute exact path='/guide' component={Guide} permission={"ROLE_PERMISSION_GUIDE_VIEW"}/>

                        <Route exact path='/report-shared/:id/:sharedToken' component={ReportSharedView} />
                        <Route exact path='/about' component={About} />
                        <Route exact path='/recover/password' component={RecoverPassword} />
                        <Route exact path='/login'
                               render={(props) => props.isAuthenticated ? (<Redirect to='/dashboard' />) : (
                                   <Login {...props} />)} />
                        <Route exact path='/not-found' component={NotFound} />
                        <Route component={NotFound} />
                    </Switch>
                </Layout>
            </Layout>
        </BrowserRouter>
    );
};

const mapStateToProps = state => ({
    currentUser: selectCurrentUser(state),
    loading: selectAppLoading(state),
    isAuthenticated: selectIsAuthenticated(state),
});

export default connect(mapStateToProps, { getCurrentUser })(App);
