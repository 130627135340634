import {Breadcrumb, Layout, message, Table, Popconfirm, Button} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone, DatabaseTwoTone} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect} from "react-redux";
import React, {useEffect} from "react";


import {selectLoading, selectError, selectLayers, selectCurrentLayer} from "../../redux/layer/layerSelector";
import {
    listLayers,
    deleteLayer,
    clearLayerError,
    getLayer
} from '../../redux/layer/layerActions';
import {Link} from "react-router-dom";
import {selectCurrentUser} from "../../redux/user/userSelector";
import {selectIsAuthenticated} from "../../redux/auth/authSelector";
const {Content, Footer} = Layout;


const Layers = (props) => {
    const intl = useIntl();
    const {layers, count, max, offset} = props.layers;

    const checkPermissions = (requiredPermission) => {
        return props.isAuthenticated && requiredPermission && props.currentUser && props.currentUser?.permissions?.find((permission) => permission === requiredPermission)
    }

    const columns = [
        {
            title: intl.formatMessage({id: 'label.id'}),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '5%',
            fixed: true,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: intl.formatMessage({id: 'label.actions'}),
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            fixed: true,
            render: (_, record) =>
                count >= 1 ? (
                    <div className="actionsIcons">
                        {checkPermissions('ROLE_PERMISSION_LAYER_EDIT') ? <><Popconfirm
                            title={intl.formatMessage({id: 'msg.confirm-delete'})}
                            onConfirm={() => handleDelete(record.id)}>
                            <DeleteTwoTone twoToneColor="red" title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.layer'})} />
                        </Popconfirm>
                        <Link to={`/layers/points-of-interest/${record.id}`}><EditTwoTone title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.layer'})} /></Link>
                        <Link to={`/layers/points-of-interest/${record.id}/data`}><DatabaseTwoTone twoToneColor="#52c41a" title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.data'}) +' '+ intl.formatMessage({id: 'title.label.actions.layer'})} /></Link></> : <></>}

                    </div>
                ) : null,
        },
        {
            title: intl.formatMessage({id: 'label.name'}),
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            fixed: false,
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('name'),
        },
        {
            title: intl.formatMessage({id: 'label.type'}),
            dataIndex: 'type',
            key: 'type',
            width: '10%',
            fixed: false,
            sorter: (a, b) => a.type - b.type,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            filters: [
                {
                    text: intl.formatMessage({id: 'label.points-of-interest'}),
                    value: 1,
                }
            ],
            render: (text) => (text ? intl.formatMessage({id: 'label.points-of-interest'}) : intl.formatMessage({id: 'label.no'}))
        },{
            title: intl.formatMessage({id: 'label.active'}),
            dataIndex: 'active',
            key: 'active',
            width: '8%',
            fixed: false,
            sorter: (a, b) => a.active - b.active,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            filters: [
                {
                    text: intl.formatMessage({id: 'label.yes'}),
                    value: true,
                },
                {
                    text: intl.formatMessage({id: 'label.no'}),
                    value: false,
                },
            ],
            render: (text) => (text ? intl.formatMessage({id: 'label.yes'}) : intl.formatMessage({id: 'label.no'}))
        }, {
            title: intl.formatMessage({id: 'label.description'}),
            dataIndex: 'description',
            key: 'description',
            width: '30%',
            fixed: false,
            sorter: (a, b) => a.description - b.description,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false
        },
        {
            title: intl.formatMessage({id: 'label.form'}),
            dataIndex: 'formName',
            key: 'formName',
            width: '30%',
            fixed: false,
            sorter: (a, b) => a.formName - b.formName,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false
        }

    ];

    useEffect(() => {

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearLayerError());
            };
            error();
        } else {
            props.listLayers(props.history);
        }
        // eslint-disable-next-line
    }, [props.error]);

    const handleChange = (pagination, filters, sorter) => {
        props.listLayers(props.history, pagination, filters, sorter);
    };

    const handleDelete = (id) => {
        props.deleteLayer(props.history, id);
    };


    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.layers" defaultMessage="Layers"/></span>
                    </Breadcrumb.Item>

                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    {checkPermissions('ROLE_PERMISSION_LAYER_EDIT') ?
                        <Link to='/layers/points-of-interest/new'><Button type="primary" size={"large"}
                                                                          style={{marginBottom: 16}}>
                            <FormattedMessage id="label.add-layer" defaultMessage="Add Layer"/>
                        </Button></Link> : <></>}
                    <Table
                        bordered={true}
                        loading={props.loading}
                        rowKey={record => record.id}
                        responsive={true}
                        pagination={{
                            current: offset / max + 1,
                            showLessItems: true, pageSizeOptions: [10, 20, 50, 100],
                            pageSize: max,
                            defaultPageSize: max, total: count, showSizeChanger: true,
                            showTotal: ((total, range) => intl.formatMessage({
                                id: 'label.range-total'
                            }, {one: range[0], two: range[1], total: total}))
                        }}
                        size={'default'}
                        showHeader
                        columns={columns}
                        dataSource={layers}
                        scroll={{y: '65vh', x: '100vw'}}
                        onChange={handleChange}
                    />
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    layers: selectLayers(state),
    layer: selectCurrentLayer(state),
    currentUser: selectCurrentUser(state),
    isAuthenticated: selectIsAuthenticated(state)
});

export default connect(mapStateToProps, {
    listLayers,
    deleteLayer,
    clearLayerError,
    getLayer
})(Layers);
