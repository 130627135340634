export const FORM_FIELD_TYPES = {
    // <h2>Heading</h2>
    HEADING: 1,
    // <input type="text"/>
    TEXT: 2,
    // <textarea></textarea/>
    TEXT_AREA: 3,
    // <input type="number"/>
    NUMBER_INTEGER: 4,
    // <input type="number" step=".01"/>
    NUMBER_DECIMAL: 5,
    // <input type="date"/>
    DATE: 6,
    // <input type="datetime-local"/>
    DATETIME: 7,
    // <input type="time"/>
    TIME: 8,
    // <input type="checkbox"/>
    CHECKBOX: 9,
    CHECKBOX_GROUP: 10,
    RADIO_GROUP: 11,
    LIST: 12,
    MAP: 13,
    PHOTO: 14,
    BARCODE: 15,
    QRCODE: 16,
    QUESTION_BLOCK: 17,
    VIRTUAL: 18,
    UUID_GENERATOR: 19
}
