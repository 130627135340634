import axiosInstance from "../../utils/axiosInstance";
import {DATA_SOURCE_CONFIGURATION_ACTION_TYPES} from "../typesAction";


export const setLoading = () => {
    return {type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.LOAD_DATA_SOURCE_CONFIGURATION}
};

export const clearLoading = () => {
    return {type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.CLEAR_LOAD_DATA_SOURCE_CONFIGURATION}
};

export const clearDataSourceConfigurationError = () => {
    return {type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.CLEAR_DATA_SOURCE_CONFIGURATION_ERRORS}
};

export const listDataSourceConfigurations = (history, pagination, filters = {}, sorter) => async dispatch => {
    try {
        dispatch(setLoading());
        // Prepare all query parameters
        let queryParameters = {}
        Object.keys(filters).map(key => (
            queryParameters[key] = filters[key] == null ? filters[key] : filters[key][0])
        );
        if (sorter !== undefined) {
            queryParameters['sort'] = sorter['field']
            queryParameters['order'] = sorter['order'] === 'descend' ? 'desc' : 'asc'
        }
        if (pagination !== undefined) {
            queryParameters['offset'] = (pagination.current - 1) * pagination.pageSize
            queryParameters['max'] = pagination.pageSize
        }
        // Spread the parameters
        const params = {
            ...queryParameters
        };
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).get('/dataSourceConfiguration', {params});
        // Update state

        dispatch({
            type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.LIST_DATA_SOURCE_CONFIGURATION,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.ERROR_DATA_SOURCE_CONFIGURATION,
            payload: err.message
        });
    }
};

export const addDataSourceConfiguration = (history, dataSourceConfiguration, setDataSourceConfigurationCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).post('/dataSourceConfiguration', dataSourceConfiguration);
        // Update state
        dispatch({
            type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.ADD_DATA_SOURCE_CONFIGURATION,
            payload: data
        });
        setDataSourceConfigurationCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.ERROR_DATA_SOURCE_CONFIGURATION,
            payload: error
        });
    }
};

export const getDataSourceConfiguration = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        const {data} = await axiosInstance(history, dispatch).get(`/dataSourceConfiguration/${id}`);
        // Update state
        dispatch({
            type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.SHOW_DATA_SOURCE_CONFIGURATION,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.ERROR_DATA_SOURCE_CONFIGURATION,
            payload: err.message
        });
    }
};

export const editDataSourceConfiguration = (history, id, dataSourceConfiguration, setDataSourceConfigurationCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).patch(`/dataSourceConfiguration/${id}`, dataSourceConfiguration);
        // Update state
        dispatch({
            type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.EDIT_DATA_SOURCE_CONFIGURATION,
            payload: data
        });
        setDataSourceConfigurationCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.ERROR_DATA_SOURCE_CONFIGURATION,
            payload: error
        });
    }
};

export const deleteDataSourceConfiguration = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        await axiosInstance(history, dispatch).delete(`/dataSourceConfiguration/${id}`);
        // Update state
        dispatch({
            type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.DELETE_DATA_SOURCE_CONFIGURATION,
            payload: id
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.ERROR_DATA_SOURCE_CONFIGURATION,
            payload: err.message
        });
    }
};


//actions for api_forms
export const setLoadingApiForms = () => {
    return {type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.LOAD_API_FORMS}
};

export const clearLoadingApiForms = () => {
    return {type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.CLEAR_LOAD_API_FORMS}
};

export const clearApiFormsError = () => {
    return {type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.CLEAR_API_FORMS_ERRORS}
};

export const listApiForms = (history) => async dispatch => {
    try {
        dispatch(setLoadingApiForms());
        // Prepare all query parameters
        let queryParameters = {}
        //the value of the nameNormalized field of forms used to collect data from an api must follow
        //the pattern 'api_.*_data' in backend
        queryParameters['nameNormalized'] = 'api_.*_data';
        queryParameters['sort'] = 'name';
        queryParameters['order'] = 'asc';
        queryParameters['forConfiguration'] = true;

        // Spread the parameters
        const params = {
            ...queryParameters
        };

        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).get(`/form`, {params});

        dispatch({
            type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.LIST_API_FORMS,
            payload: data
        });
        // Update state
    } catch (err) {
        console.log(err);
        dispatch({
            type: DATA_SOURCE_CONFIGURATION_ACTION_TYPES.ERROR_API_FORMS,
            payload: err.message
        });
    }
};

