import {useEffect} from "react";
import {Form, message, Layout, Breadcrumb, Card, Button, Divider} from "antd";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import Editor from '@react-page/editor';
import '@react-page/editor/lib/index.css';

// The rich text area plugin (Slate)
import slate from '@react-page/plugins-slate';
import spacer from '@react-page/plugins-spacer';
import divider from '@react-page/plugins-divider';
import '@react-page/plugins-slate/lib/index.css';

import {HomeOutlined} from "@ant-design/icons";
import {selectLoading, selectError, selectCurrentReport} from "../../redux/report/reportSelector";
import {getReport, updateEditedReportOutput, clearReportError} from '../../redux/report/reportActions';

import outputHtmlContentPlugin from "./react-page-custom-plugins/outputHtmlContentPlugin.tsx";

// Define which plugins we want to use.
const cellPlugins = [outputHtmlContentPlugin, slate(),
    spacer, divider];


const ReportEditHtml = (props) => {
    const { Content, Footer } = Layout;
    const intl = useIntl();
    const [form] = Form.useForm();
    const regExpEdit = new RegExp('/configuration/reports/output/edit/[0-9]*','g');
    const edit = regExpEdit.test(props.match.url);
    const regExpConfig = new RegExp('/configuration/reports/output/(edit|view)/[0-9]*','g');
    const config = regExpConfig.test(props.match.url);

    const onFinish = (value) => {
        if (value) {
            const report = {'editor':JSON.stringify(value.editor)};
            props.updateEditedReportOutput(props.history, props.match.params.id, report).then(() => {
                message.info(intl.formatMessage({id: "message.report.ReportEditHtml.edited-report-output.updated"}));
                props.history.push(`/configuration/reports`);
            });
        }
    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    //loading current report to app state.
    //This state property will be used later by component ReportOutputHtmlContent
    useEffect(() => {
        props.getReport(props.history, props.match.params.id);

    //eslint-disable-next-line
    }, []);

    //loading data to editor
    useEffect(() => {
        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearReportError());
                } else {
                    if (props.error?.errors) {
                        props.error.errors?.map(errorMessage =>
                            (message.error(errorMessage.message).then(props.clearReportError())));
                    } else {
                        message.error(props.error).then(props.clearReportError());
                    }
                }
            };
            error();
        };

        if (props.error === null && props?.report?.editor) {
            form.setFieldsValue({
                editor: JSON.parse(props.report.editor)
            });
        };

        //eslint-disable-next-line
    }, [props.error, props.report]);

    return (
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '10px 0' }}>
                    <Breadcrumb.Item>
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.configuration" defaultMessage="Configuration"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports" defaultMessage="Reports" /></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports.output" defaultMessage="Output" /></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {edit
                            ? <span><FormattedMessage id="label.edit" defaultMessage="Edit" /></span>
                            : <span><FormattedMessage id="label.view" defaultMessage="View" /></span>
                        }
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{props?.report?.name}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Card type="inner"
                        title={edit
                            ? <FormattedMessage id="label.edit-report-output"
                            defaultMessage="Edit Report output" />
                            : <FormattedMessage id="label.view-report-output"
                                defaultMessage="View Report output" />
                        }
                          extra={
                                  <Link  to={config ? '/configuration/reports' : '/reports'}>
                                      <FormattedMessage id="label.return" defaultMessage="Back" />
                                  </Link>
                          }
                    >
                        <Form
                            layout="horizontal" name="report_form" size="large"
                            form={form}
                            onFinish={onFinish} onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                name="editor"
                            >
                                <Editor
                                    cellPlugins={cellPlugins}
                                    disabled={props.loading}
                                    readOnly={!edit}
                                />
                            </Form.Item>
                            <Divider />
                            <Form.Item
                                style={{display: !edit ? 'none' : null}}
                            >
                                <Button
                                    className={'ant-btn ant-btn-primary ant-btn-lg'}
                                    htmlType="submit"
                                    disabled={props?.loading}
                                >
                                    {intl.formatMessage({id:'label.report-output.button.save'})}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    error: selectError(state),
    loading: selectLoading(state),
    report: selectCurrentReport(state)
});

export default connect(mapStateToProps,
    {getReport,
    updateEditedReportOutput,
    clearReportError})(ReportEditHtml);
