import {Breadcrumb, Card, Layout, message, Modal, Row, Table} from "antd";
import {EyeTwoTone, HomeOutlined} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {selectLoading, selectError, selectJobs, selectCurrentJob} from "../../redux/job/jobSelector";
import {listJobs, getJob, clearJobError} from '../../redux/job/jobActions';
import Moment from "react-moment";


const {Content, Footer} = Layout;

const labelStyle = {width: '25%', textAlign: 'center'}
const valueStyle = {width: '75%', textAlign: 'left'}

const Jobs = (props) => {

    const intl = useIntl();
    const {jobs, count, max, offset} = props.jobs;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [jobDetails, setJobDetails] = useState(<Card loading={true}> </Card>);

    const showModal = (id) => {
        setIsModalVisible(true);
        props.getJob(props.history, id).then((data) => {
            setJobDetails(<Card>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.name" defaultMessage="Name"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.name}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.status" defaultMessage="Status"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.status}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.startDate" defaultMessage="Start Date"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.startDate}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.endDate" defaultMessage="End Date"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.endDate}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.logs" defaultMessage="Logs"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        <pre>{data.logs}</pre>
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.last-updated" defaultMessage="Last Updated"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        <Moment format="YYYY-MM-DD HH:MM:SS">{data.lastUpdated}</Moment>
                    </Card.Grid></Row>


            </Card>)
        })
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'label.id'}),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            fixed: false,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: intl.formatMessage({id: 'label.actions'}),
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            fixed: false,
            render: (_, record) =>
                count >= 1 ? (
                    <div className="actionsIcons">
                        <EyeTwoTone onClick={() => {
                            showModal(record.id)
                        }} twoToneColor="green" title={intl.formatMessage({id: 'title.label.actions.show'}) +' '+ intl.formatMessage({id: 'title.label.actions.job'}) +' '+ intl.formatMessage({id: 'title.label.actions.details'})} />
                    </div>
                ) : null,
        },
        {
            title: intl.formatMessage({id: 'label.name'}),
            dataIndex: 'name',
            key: 'name',
            fixed: false,
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('name'),
        },
        {
            title: intl.formatMessage({id: 'label.status'}),
            dataIndex: 'status',
            key: 'status',
            fixed: false,
            sorter: (a, b) => a.status - b.status,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            filters: [
                {
                    text: 'Started',
                    value: 'Started',
                },
                {
                    text: 'Completed',
                    value: 'Completed',
                },
                {
                    text: 'Failed',
                    value: 'Failed',
                }
            ]
        },{
            title: intl.formatMessage({id: 'label.startDate'}),
            dataIndex: 'startDate',
            key: 'startDate',
            fixed: false,
            sorter: (a, b) => a.startDate - b.startDate,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('startDate'),
        }, {
            title: intl.formatMessage({id: 'label.endDate'}),
            dataIndex: 'endDate',
            key: 'endDate',
            fixed: false,
            sorter: (a, b) => a.endDate - b.endDate,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('endDate'),
        }
    ];

    useEffect(() => {

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearJobError());
            };
            error();
        } else {
            props.listJobs(props.history);
        }
        // eslint-disable-next-line
    }, [props.error]);

    const handleChange = (pagination, filters, sorter) => {
        props.listJobs(props.history, pagination, filters, sorter);
    };

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.admin" defaultMessage="Admin"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.jobs" defaultMessage="Jobs"/></span>
                    </Breadcrumb.Item>

                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Table
                        bordered={true}
                        loading={props.loading}
                        rowKey={record => record.id}
                        responsive={true}
                        pagination={{
                            current: offset / max + 1,
                            showLessItems: true, pageSizeOptions: [10, 20, 50, 100],
                            pageSize: max,
                            defaultPageSize: max, total: count, showSizeChanger: true,
                            showTotal: ((total, range) => intl.formatMessage({
                                id: 'label.range-total'
                            }, {one: range[0], two: range[1], total: total}))
                        }}
                        size={'default'}
                        showHeader
                        columns={columns}
                        dataSource={jobs}
                        onChange={handleChange}
                    />
                </div>
                <Modal title={intl.formatMessage({id: 'label.user-details'})} visible={isModalVisible}
                       onOk={handleOk} onCancel={handleCancel} centered width={720}>
                    {jobDetails}
                </Modal>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    jobs: selectJobs(state),
    job: selectCurrentJob(state)
});

export default connect(mapStateToProps, {listJobs, getJob,clearJobError})(Jobs);