import {Fragment} from "react";

function About() {
    return (
        <Fragment>

        </Fragment>
    );
}

export default About;