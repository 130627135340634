import "./CustomMapboxControlByRadio.css";
import {Button} from "./Button/Button";
import ReactDOM from "react-dom";
import {RadioGroup} from "./CustomMapboxControlsUtils";
import { WIDTH_PLUS } from "../../utils/utils";

/**
  * Creates a custom mapbox control that is represented by a button on map. If the button is clicked then a list of
  * options is showed to user from which only one can be selected.
  *
  * The selected option is used to update the state `lastControlEvent`, which is defined in the react component that
  * contains the map. This way is handled the user interaction.
  *
*/
class CustomMapboxControlByRadio {
    radioGroupProps;
    maxHeight;

    /**
      * Class constructor
      *
      * @param {RadioGroupProps} radioGroupProps The object that contains the arguments for a {@link RadioGroup} object.
      * @param {Number} maxHeight The maximum height of the `<div>` html element that contains the radio group.
      *
    */
    constructor (radioGroupProps, maxHeight){
        this.radioGroupProps = radioGroupProps;
        this.maxHeight = maxHeight;
    };

    /**
      * Adds the control to `map`.
      * @param {mapboxgl.Map} [map] The instance of `mapboxgl.Map`.
    */
    onAdd(map){
        this.map = map;

        //container element
        this.containerElement = document.createElement('div');
        this.containerElement.classList.add('mapboxgl-ctrl', 'div-ctrl');

        //setting button element
        switch (this.radioGroupProps.controlName) {
            case 'layersControl':
                this.buttonElement = Button('map-layers');
                this.buttonElement.title = map.intl.formatMessage(
                    {id: "CustomMapboxControlByRadio.title.select.layers"});
                break;
            case 'stylesControl':
                this.buttonElement = Button('map-styles');
                this.buttonElement.title = map.intl.formatMessage(
                    {id: "CustomMapboxControlByRadio.title.select.map-style"});
                break;
            case 'colorSchemesControl':
                this.buttonElement = Button('map-color-schemes');
                this.buttonElement.title = map.intl.formatMessage(
                    {id: "CustomMapboxControlByRadio.title.select.colorscheme"});
                break;
            default:
                const msg = map.intl.formatMessage({id: "CustomMapboxControlByRadio.control.notImplemented"},
                                                    {controlName: this.radioGroupProps.controlName});
                throw new Error(msg);
        };

        //appending button element to container element
        this.containerElement.appendChild(this.buttonElement);

        //creating container for Radio.Group
        this.divRadioGroupElement = document.createElement('div');
        this.divRadioGroupElement.classList.add('map-control-div-radio-group');
        this.divRadioGroupElementId = 'custom-'.concat(this.radioGroupProps.controlName,'-radio-group-container');
        this.divRadioGroupElement.setAttribute('id', this.divRadioGroupElementId);
        //css style rules of divRadioGroupElement
        const maxNumChars =  Math.max(...(this.radioGroupProps.options.map(option =>
            option.label.length)));
        const widthPlus = WIDTH_PLUS;
        const width = (maxNumChars+widthPlus)+'ch';
        const height = 'auto';
        const heightTop = '40px';
        this.divRadioGroupElement.style.display = 'none';
        this.divRadioGroupElement.style.width = width;
        this.divRadioGroupElement.style.height = height;
        this.divRadioGroupElement.style.borderRadius = '6px';
        this.divRadioGroupElement.style.backgroundColor = '#fff';
        this.divRadioGroupElement.style.position = 'relative';
        this.divRadioGroupElement.style.right = width;
        this.divRadioGroupElement.style.top = '-'+heightTop;
        if (this.maxHeight !== null) {
          this.divRadioGroupElement.style.maxHeight = this.maxHeight+'px';
          this.divRadioGroupElement.style.overflowY = 'auto';
        }

        ReactDOM.render(
          <RadioGroup {...this.radioGroupProps}/>,
          this.divRadioGroupElement);

        this.containerElement.appendChild(this.divRadioGroupElement);

        //setting button element's behavior on click event
        this.buttonElement.addEventListener('click', () => {
            //displaying select element
            this.divRadioGroupElement.style.display = 'block';

            //do not display the other controls
            const controlsNames = ['layersControl','stylesControl','colorSchemesControl'];
            controlsNames.forEach(controlName => {
                if (controlName !== this.radioGroupProps.controlName) {
                    const divRadioGroupElementId = 'custom-'.concat(controlName,'-radio-group-container');
                    let containerElement = document.getElementById(divRadioGroupElementId);
                    if (containerElement) containerElement.style.display = 'none';
                };
            });
        });

        //setting divRadioGroupElement element's behavior on mouse leave event
        this.divRadioGroupElement.addEventListener('mouseleave', () => {
            //no displaying select element
            this.divRadioGroupElement.style.display = 'none';
        });

        return this.containerElement;
    }

    /**
      * Removes control from the map.
    */
    onRemove(){
        this.containerElement.parentNode?.removeChild(this.containerElement);
        this.map = undefined;
    }
}

export default CustomMapboxControlByRadio;
