import {SUBSCRIPTION_ACTION_TYPES} from "../typesAction";


const initialState = {
    subscriptions: [],
    current: null,
    loading: false,
    error: null
}

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTION_ACTION_TYPES.LOAD_SUBSCRIPTION:
            return {
                ...state, loading: true
            }
        case SUBSCRIPTION_ACTION_TYPES.CLEAR_LOAD_SUBSCRIPTION:
            return {
                ...state, loading: false
            }
        case SUBSCRIPTION_ACTION_TYPES.ERROR_SUBSCRIPTION:
            return {
                ...state, error: action.payload, loading: false, current: null
            }
        case SUBSCRIPTION_ACTION_TYPES.CLEAR_SUBSCRIPTION_ERRORS:
            return {
                ...state,
                error: null, loading: false
            }
        case SUBSCRIPTION_ACTION_TYPES.LIST_SUBSCRIPTION:
            return {
                ...state, subscriptions: action.payload, loading: false, current: null
            }
        case SUBSCRIPTION_ACTION_TYPES.ADD_SUBSCRIPTION:
            return {
                ...state,
                loading: false
            }
        case SUBSCRIPTION_ACTION_TYPES.SHOW_SUBSCRIPTION:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case SUBSCRIPTION_ACTION_TYPES.EDIT_SUBSCRIPTION:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case SUBSCRIPTION_ACTION_TYPES.DELETE_SUBSCRIPTION:
            const updatedSubscriptions = state.subscriptions.subscriptions.filter(subscription => action.payload !== subscription.id)
            const updatedCount = state.subscriptions.count - 1
            return {
                ...state, subscriptions: {...state.subscriptions, subscriptions: updatedSubscriptions, count: updatedCount}, loading: false
            }
        default:
            return state;
    }

};

export default subscriptionReducer;