import {Breadcrumb, Layout, message, Table, Popconfirm, Button, Tooltip} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone, FileUnknownTwoTone} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect} from "react-redux";
import {useEffect} from "react";

import {selectLoading, selectError, selectReportFiles, selectCurrentReportFile} from "../../redux/reportFile/reportFileSelector";
import {listReportFiles, deleteReportFile, clearReportFileError} from '../../redux/reportFile/reportFileActions';
import {Link} from "react-router-dom";

const {Content, Footer} = Layout;

const ReportFiles = (props) => {

    const intl = useIntl();
    const {reportFiles, count, max, offset} = props.reportFiles;

    const columns = [
        {
            title: intl.formatMessage({id: 'label.reportFile.id'}),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '5%',
            fixed: true,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: intl.formatMessage({id: 'label.reportFile.actions'}),
            dataIndex: 'actions',
            align: 'center',
            width: '7%',
            fixed: true,
            render: (_, record) =>
                count >= 1 ? (
                    record.type === 'report' ? (
                        <div className="actionsIcons">
                            <DeleteTwoTone twoToneColor="gray" style={{opacity: 0.0}} title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.reportFile'})} />
                            <Link to={{
                                pathname: `/configuration/reports/reportFiles/${record.id}`,
                                state: {'fromReportFiles': true}
                            }} >
                                <EditTwoTone title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.reportFile'})} />
                            </Link>
                        </div>
                        )
                            : (
                        <div className="actionsIcons">
                            <Popconfirm title={intl.formatMessage({id: 'msg.confirm-delete'})}
                                        onConfirm={() => handleDelete(record.id)}>
                                <DeleteTwoTone twoToneColor="red" title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.reportFile'})} />
                            </Popconfirm>
                            <Link to={{
                                pathname: `/configuration/reports/reportFiles/${record.id}`,
                                state: {'fromReportFiles': true}
                            }} >
                                <EditTwoTone title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.reportFile'})} />
                            </Link>
                        </div>)) : null
        },
        {
            title: intl.formatMessage({id: 'label.reportFile.exists'}),
            dataIndex: 'exists',
            key: 'exists',
            width: '5%',
            fixed: false,
            sorter: (a, b) => a.exists - b.exists,
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => {
                return (
                    record.exists ? (
                        <FormattedMessage id={'label.reportFile.exists.true'}  />
                    ) : (
                        <>
                            <FormattedMessage id={'label.reportFile.exists.false'}  />
                            <Tooltip
                                title={intl.formatMessage({id:'label.reportFile.missing.file'})}>
                                <FileUnknownTwoTone twoToneColor={'red'} />
                            </Tooltip>
                        </>

                    )
                )
            }
        },
        {
            title: intl.formatMessage({id: 'label.reportFile.filename'}),
            dataIndex: 'filename',
            key: 'filename',
            width: '25%',
            fixed: false,
            sorter: (a, b) => a.filename - b.filename,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('filename')
        },
        {
            title: intl.formatMessage({id: 'label.reportFile.description'}),
            dataIndex: 'description',
            key: 'description',
            width: '28%',
            fixed: false,
            sorter: (a, b) => a.description - b.description,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('description')
        },
        {
            title: intl.formatMessage({id: 'label.reportFile.type'}),
            dataIndex: 'type',
            key: 'type',
            width: '8%',
            fixed: false,
            sorter: (a, b) => a.type - b.type,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('type')
        },
        {
            title: intl.formatMessage({id: 'label.reportFile.report'}),
            dataIndex: 'reportname',
            key: 'reportname',
            width: '22%',
            fixed: false,
            sorter: (a, b) => a.report - b.report,
            sortDirections: ['ascend', 'descend']
        }
    ];

    useEffect(() => { //para cargar datos

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearReportFileError());
            };
            error();
        } else {
            props.listReportFiles(props.history);
        }
        // eslint-disable-next-line
    }, [props.error]);

    const handleChange = (pagination, filters, sorter) => {
        props.listReportFiles(props.history, pagination, filters, sorter);
    };

    const handleDelete = (id) => {
        props.deleteReportFile(props.history, id)
    };
    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.configuration" defaultMessage="Configuration"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reports" defaultMessage="Reports"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.reportFiles" defaultMessage="ReportFiles"/></span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <>
                        <Link to='/configuration/reports'><Button type="primary" style={{marginBottom: 16,
                        marginRight: 10}}>
                            <FormattedMessage id="label.report.reports" defaultMessage="Reports"/>
                        </Button></Link>
                        <Link to='/configuration/reports/reportParameters'><Button type="primary" style={{marginBottom: 16,
                        marginRight: 10}}>
                            <FormattedMessage id="label.reportParameter.reportParameters" defaultMessage="Report Parameters"/>
                        </Button></Link>
                    </>
                    <Table
                        bordered={true}
                        loading={props.loading}
                        rowKey={record => record.id}
                        responsive={true}
                        pagination={{
                            current: offset / max + 1,
                            showLessItems: true, pageSizeOptions: [10, 20, 50, 100],
                            pageSize: max,
                            defaultPageSize: max, total: count, showSizeChanger: true,
                            // showTotal: ((total, range) => `${range[0]}-${range[1]} of ${total}`)
                            showTotal: ((total, range) => intl.formatMessage({
                                id: 'label.range-total'
                            }, {one: range[0], two: range[1], total: total}))
                        }}
                        size={'default'}
                        showHeader
                        columns={columns}
                        dataSource={reportFiles}
                        scroll={{y: '65vh', x: '100vw'}}
                        onChange={handleChange}
                    />
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    reportFiles: selectReportFiles(state),
    reportFile: selectCurrentReportFile(state),
});

export default connect(mapStateToProps, {listReportFiles, deleteReportFile, clearReportFileError})(ReportFiles);