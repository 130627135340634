export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_LOADING = 'SET_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';

export const AUTH_ACTION_TYPES = {
    AUTH_ERROR: 'AUTH_ERROR',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGOUT: 'LOGOUT',
    LOGIN_FAIL: 'LOGIN_FAIL',
    CLEAR_AUTH_ERRORS: 'CLEAR_AUTH_ERRORS',
    USER_LOADED: 'USER_LOADED'
};


export const USER_ACTION_TYPES = {
    GET_CURRENT_USER: 'GET_CURRENT_USER',
    CLEAR_CURRENT_USER: 'CLEAR_CURRENT_USER',
    LIST_USER: 'LIST_USER',
    ADD_USER: 'ADD_USER',
    EDIT_USER: 'EDIT_USER',
    SHOW_USER: 'SHOW_USER',
    DELETE_USER: 'DELETE_USER',
    LOAD_USER: 'LOAD_USER',
    CLEAR_LOAD_USER: 'CLEAR_LOAD_USER',
    ERROR_USER: 'ERROR_USER',
    CLEAR_USER_ERRORS: 'CLEAR_USER_ERRORS',
    GET_GEO_AREA: 'GET_GEO_AREA',
    GET_GEO_REGIONS: 'GET_GEO_REGIONS'
};

export const PROJECT_ACTION_TYPES = {
    LIST_PROJECT: 'LIST_PROJECT',
    ADD_PROJECT: 'ADD_PROJECT',
    EDIT_PROJECT: 'EDIT_PROJECT',
    SHOW_PROJECT: 'SHOW_PROJECT',
    DELETE_PROJECT: 'DELETE_PROJECT',
    LOAD_PROJECT: 'LOAD_PROJECT',
    CLEAR_LOAD_PROJECT: 'CLEAR_LOAD_PROJECT',
    ERROR_PROJECT: 'ERROR_PROJECT',
    CLEAR_PROJECT_ERRORS: 'CLEAR_PROJECT_ERRORS'
};

export const PERMISSION_ACTION_TYPES = {
    LIST_PERMISSION: 'LIST_PERMISSION',
    LOAD_PERMISSION: 'LOAD_PERMISSION',
    CLEAR_LOAD_PERMISSION: 'CLEAR_LOAD_PERMISSION',
    ERROR_PERMISSION: 'ERROR_PERMISSION',
    CLEAR_PERMISSION_ERRORS: 'CLEAR_PERMISSION_ERRORS'
};

export const ROLE_ACTION_TYPES = {
    LIST_ROLE: 'LIST_ROLE',
    ADD_ROLE: 'ADD_ROLE',
    EDIT_ROLE: 'EDIT_ROLE',
    SHOW_ROLE: 'SHOW_ROLE',
    DELETE_ROLE: 'DELETE_ROLE',
    LOAD_ROLE: 'LOAD_ROLE',
    CLEAR_LOAD_ROLE: 'CLEAR_LOAD_ROLE',
    ERROR_ROLE: 'ERROR_ROLE',
    CLEAR_ROLE_ERRORS: 'CLEAR_ROLE_ERRORS'
};

export const SURVEY_FORM_ACTION_TYPES = {
    LIST_SURVEY_FORM: 'LIST_SURVEY_FORM',
    ADD_SURVEY_FORM: 'ADD_SURVEY_FORM',
    EDIT_SURVEY_FORM: 'EDIT_SURVEY_FORM',
    SHOW_SURVEY_FORM: 'SHOW_SURVEY_FORM',
    DELETE_SURVEY_FORM: 'DELETE_SURVEY_FORM',
    LOAD_SURVEY_FORM: 'LOAD_SURVEY_FORM',
    CLEAR_LOAD_SURVEY_FORM: 'CLEAR_LOAD_SURVEY_FORM',
    ERROR_SURVEY_FORM: 'ERROR_SURVEY_FORM',
    CLEAR_SURVEY_FORM_ERRORS: 'CLEAR_SURVEY_FORM_ERRORS'
};

export const FORM_FIELD_ACTION_TYPES = {
    LIST_FORM_FIELD: 'LIST_FORM_FIELD',
    EDIT_FORM_FIELD: 'EDIT_FORM_FIELD',
    SHOW_FORM_FIELD: 'SHOW_FORM_FIELD',
    LOAD_FORM_FIELD: 'LOAD_FORM_FIELD',
    CLEAR_FORM_FIELD: 'CLEAR_LOAD_FORM_FIELD',
    ERROR_FORM_FIELD: 'ERROR_FORM_FIELD',
    CLEAR_FORM_FIELD_ERRORS: 'CLEAR_FORM_FIELD_ERRORS'
};

export const FORM_DATA_ACTION_TYPES = {
    LIST_FORM_DATA: 'LIST_FORM_DATA',
    ADD_FORM_DATA: 'ADD_FORM_DATA',
    EDIT_FORM_DATA: 'EDIT_FORM_DATA',
    SHOW_FORM_DATA: 'SHOW_FORM_DATA',
    DELETE_FORM_DATA: 'DELETE_FORM_DATA',
    LOAD_FORM_DATA: 'LOAD_FORM_DATA',
    CLEAR_LOAD_FORM_DATA: 'CLEAR_LOAD_FORM_DATA',
    ERROR_FORM_DATA: 'ERROR_FORM_DATA',
    CLEAR_FORM_DATA_ERRORS: 'CLEAR_FORM_DATA_ERRORS',
    GET_FORM_CONFIGURATION: 'GET_FORM_CONFIGURATION'
};

export const PATH_ACTION_TYPES = {
    LIST_PATH: 'LIST_PATH',
    ADD_PATH: 'ADD_PATH',
    EDIT_PATH: 'EDIT_PATH',
    SHOW_PATH: 'SHOW_PATH',
    DELETE_PATH: 'DELETE_PATH',
    LOAD_PATH: 'LOAD_PATH',
    CLEAR_LOAD_PATH: 'CLEAR_LOAD_PATH',
    ERROR_PATH: 'ERROR_PATH',
    CLEAR_PATH_ERRORS: 'CLEAR_PATH_ERRORS',
    ACTIVATE_PATH: 'ACTIVATE_PATH'
};

export const REPORT_ACTION_TYPES = {
    LIST_REPORT: 'LIST_REPORT',
    ADD_REPORT: 'ADD_REPORT',
    EDIT_REPORT: 'EDIT_REPORT',
    SHOW_REPORT: 'SHOW_REPORT',
    DELETE_REPORT: 'DELETE_REPORT',
    LOAD_REPORT: 'LOAD_REPORT',
    CLEAR_LOAD_REPORT: 'CLEAR_LOAD_REPORT',
    ERROR_REPORT: 'ERROR_REPORT',
    CLEAR_REPORT_ERRORS: 'CLEAR_REPORT_ERRORS',
    VIEW_REPORT: 'VIEW_REPORT',
    SHOW_REPORT_CODE: 'SHOW_REPORT_CODE',
    EDIT_REPORT_CODE: 'EDIT_REPORT_CODE',
    GET_REPORT_OUTPUT: 'GET_REPORT_OUTPUT',
    UPDATE_EDITED_REPORT_OUTPUT: 'UPDATE_EDITED_REPORT_OUTPUT'
};

export const REPORT_FILE_ACTION_TYPES = {
    LIST_REPORT_FILE: 'LIST_REPORT_FILE',
    ADD_REPORT_FILE: 'ADD_REPORT_FILE',
    EDIT_REPORT_FILE: 'EDIT_REPORT_FILE',
    SHOW_REPORT_FILE: 'SHOW_REPORT_FILE',
    DELETE_REPORT_FILE: 'DELETE_REPORT_FILE',
    LOAD_REPORT_FILE: 'LOAD_REPORT_FILE',
    CLEAR_LOAD_REPORT_FILE: 'CLEAR_LOAD_REPORT_FILE',
    ERROR_REPORT_FILE: 'ERROR_REPORT_FILE',
    CLEAR_REPORT_FILE_ERRORS: 'CLEAR_REPORT_FILE_ERRORS'
};

export const REPORT_PARAMETER_ACTION_TYPES = {
    LIST_REPORT_PARAMETER: 'LIST_REPORT_PARAMETER',
    ADD_REPORT_PARAMETER: 'ADD_REPORT_PARAMETER',
    EDIT_REPORT_PARAMETER: 'EDIT_REPORT_PARAMETER',
    SHOW_REPORT_PARAMETER: 'SHOW_REPORT_PARAMETER',
    DELETE_REPORT_PARAMETER: 'DELETE_REPORT_PARAMETER',
    LOAD_REPORT_PARAMETER: 'LOAD_REPORT_PARAMETER',
    CLEAR_LOAD_REPORT_PARAMETER: 'CLEAR_LOAD_REPORT_PARAMETER',
    ERROR_REPORT_PARAMETER: 'ERROR_REPORT_PARAMETER',
    CLEAR_REPORT_PARAMETER_ERRORS: 'CLEAR_REPORT_PARAMETER_ERRORS'
};

export const STAFF_ACTION_TYPES = {
    LIST_STAFF: 'LIST_STAFF',
    ADD_STAFF: 'ADD_STAFF',
    EDIT_STAFF: 'EDIT_STAFF',
    SHOW_STAFF: 'SHOW_STAFF',
    DELETE_STAFF: 'DELETE_STAFF',
    LOAD_STAFF: 'LOAD_STAFF',
    CLEAR_LOAD_STAFF: 'CLEAR_LOAD_STAFF',
    ERROR_STAFF: 'ERROR_STAFF',
    CLEAR_STAFF_ERRORS: 'CLEAR_STAFF_ERRORS'
};

export const ALERT_CONTACT_ACTION_TYPES = {
    LIST_ALERT_CONTACT: 'LIST_ALERT_CONTACT',
    ADD_ALERT_CONTACT: 'ADD_ALERT_CONTACT',
    EDIT_ALERT_CONTACT: 'EDIT_ALERT_CONTACT',
    SHOW_ALERT_CONTACT: 'SHOW_ALERT_CONTACT',
    DELETE_ALERT_CONTACT: 'DELETE_ALERT_CONTACT',
    LOAD_ALERT_CONTACT: 'LOAD_ALERT_CONTACT',
    CLEAR_LOAD_ALERT_CONTACT: 'CLEAR_LOAD_ALERT_CONTACT',
    ERROR_ALERT_CONTACT: 'ERROR_ALERT_CONTACT',
    CLEAR_ALERT_CONTACT_ERRORS: 'CLEAR_ALERT_CONTACT_ERRORS'
};

export const SUBSCRIPTION_ACTION_TYPES = {
    LIST_SUBSCRIPTION: 'LIST_SUBSCRIPTION',
    ADD_SUBSCRIPTION: 'ADD_SUBSCRIPTION',
    EDIT_SUBSCRIPTION: 'EDIT_SUBSCRIPTION',
    SHOW_SUBSCRIPTION: 'SHOW_SUBSCRIPTION',
    DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',
    LOAD_SUBSCRIPTION: 'LOAD_SUBSCRIPTION',
    CLEAR_LOAD_SUBSCRIPTION: 'CLEAR_LOAD_SUBSCRIPTION',
    ERROR_SUBSCRIPTION: 'ERROR_SUBSCRIPTION',
    CLEAR_SUBSCRIPTION_ERRORS: 'CLEAR_SUBSCRIPTION_ERRORS'
};

export const DATA_ANALYSIS_ACTION_TYPES = {
    LIST_DATA_ANALYSIS: 'LIST_DATA_ANALYSIS',
    ADD_DATA_ANALYSIS: 'ADD_DATA_ANALYSIS',
    EDIT_DATA_ANALYSIS: 'EDIT_DATA_ANALYSIS',
    SHOW_DATA_ANALYSIS: 'SHOW_DATA_ANALYSIS',
    DELETE_DATA_ANALYSIS: 'DELETE_DATA_ANALYSIS',
    LOAD_DATA_ANALYSIS: 'LOAD_DATA_ANALYSIS',
    CLEAR_LOAD_DATA_ANALYSIS: 'CLEAR_LOAD_DATA_ANALYSIS',
    ERROR_DATA_ANALYSIS: 'ERROR_DATA_ANALYSIS',
    CLEAR_DATA_ANALYSIS_ERRORS: 'CLEAR_DATA_ANALYSIS_ERRORS',
    VIEW_DATA_ANALYSIS: 'VIEW_DATA_ANALYSIS',
};

export const LAYER_ACTION_TYPES = {
    LIST_LAYER: 'LIST_LAYER',
    ADD_LAYER: 'ADD_LAYER',
    EDIT_LAYER: 'EDIT_LAYER',
    SHOW_LAYER: 'SHOW_LAYER',
    DELETE_LAYER: 'DELETE_LAYER',
    LOAD_LAYER: 'LOAD_LAYER',
    CLEAR_LOAD_LAYER: 'CLEAR_LOAD_LAYER',
    ERROR_LAYER: 'ERROR_LAYER',
    CLEAR_LAYER_ERRORS: 'CLEAR_LAYER_ERRORS'
};


export const POINTS_OF_INTEREST_ACTION_TYPES = {
    LIST_POINTS_OF_INTEREST: 'LIST_POINTS_OF_INTEREST',
    ADD_POINTS_OF_INTEREST: 'ADD_POINTS_OF_INTEREST',
    EDIT_POINTS_OF_INTEREST: 'EDIT_POINTS_OF_INTEREST',
    SHOW_POINTS_OF_INTEREST: 'SHOW_POINTS_OF_INTEREST',
    DELETE_POINTS_OF_INTEREST: 'DELETE_POINTS_OF_INTEREST',
    LOAD_POINTS_OF_INTEREST: 'LOAD_POINTS_OF_INTEREST',
    CLEAR_LOAD_POINTS_OF_INTEREST: 'CLEAR_LOAD_POINTS_OF_INTEREST',
    ERROR_POINTS_OF_INTEREST: 'ERROR_POINTS_OF_INTEREST',
    CLEAR_POINTS_OF_INTEREST_ERRORS: 'CLEAR_POINTS_OF_INTEREST_ERRORS'
};

export const JOB_ACTION_TYPES = {
    LIST_JOB: 'LIST_JOB',
    SHOW_JOB: 'SHOW_JOB',
    LOAD_JOB: 'LOAD_JOB',
    CLEAR_LOAD_JOB: 'CLEAR_LOAD_JOB',
    ERROR_JOB: 'ERROR_JOB',
    CLEAR_JOB_ERRORS: 'CLEAR_JOB_ERRORS'
};

export const DATA_SOURCE_CONFIGURATION_ACTION_TYPES = {
    LIST_DATA_SOURCE_CONFIGURATION: 'LIST_DATA_SOURCE_CONFIGURATION',
    ADD_DATA_SOURCE_CONFIGURATION: 'ADD_DATA_SOURCE_CONFIGURATION',
    EDIT_DATA_SOURCE_CONFIGURATION: 'EDIT_DATA_SOURCE_CONFIGURATION',
    SHOW_DATA_SOURCE_CONFIGURATION: 'SHOW_DATA_SOURCE_CONFIGURATION',
    DELETE_DATA_SOURCE_CONFIGURATION: 'DELETE_DATA_SOURCE_CONFIGURATION',
    LOAD_DATA_SOURCE_CONFIGURATION: 'LOAD_DATA_SOURCE_CONFIGURATION',
    CLEAR_LOAD_DATA_SOURCE_CONFIGURATION: 'CLEAR_LOAD_DATA_SOURCE_CONFIGURATION',
    ERROR_DATA_SOURCE_CONFIGURATION: 'ERROR_DATA_SOURCE_CONFIGURATION',
    CLEAR_DATA_SOURCE_CONFIGURATION_ERRORS: 'CLEAR_DATA_SOURCE_CONFIGURATION_ERRORS',
    LIST_API_FORMS: 'LIST_API_FORMS',
    LOAD_API_FORMS: 'LOAD_API_FORMS',
    CLEAR_LOAD_API_FORMS: 'CLEAR_LOAD_API_FORMS',
    ERROR_API_FORMS: 'ERROR_API_FORMS',
    CLEAR_API_FORMS_ERRORS: 'CLEAR_API_FORMS_ERRORS'
};
