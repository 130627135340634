import axiosInstance from "../../utils/axiosInstance";
import {PROJECT_ACTION_TYPES, STAFF_ACTION_TYPES} from "../typesAction";


export const setLoading = () => {
    return {type: PROJECT_ACTION_TYPES.LOAD_PROJECT}
};

export const clearLoading = () => {
    return {type: PROJECT_ACTION_TYPES.CLEAR_LOAD_PROJECT}
};

export const clearProjectError = () => {
    return {type: PROJECT_ACTION_TYPES.CLEAR_PROJECT_ERRORS}
};

export const listProjects = (history, pagination, filters = {}, sorter) => async dispatch => {
    try {
        dispatch(setLoading());
        // Prepare all query parameters
        let queryParameters = {}
        Object.keys(filters).map(key => (
            queryParameters[key] = filters[key] == null ? filters[key] : filters[key][0])
        );
        if (sorter !== undefined) {
            queryParameters['sort'] = sorter['field']
            queryParameters['order'] = sorter['order'] === 'descend' ? 'desc' : 'asc'
        }
        if (pagination !== undefined) {
            queryParameters['offset'] = (pagination.current - 1) * pagination.pageSize
            queryParameters['max'] = pagination.pageSize
        }
        // Spread the parameters
         const params = {
            ...queryParameters
        };
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).get('/project', {params});
        // Update state

        dispatch({
            type: PROJECT_ACTION_TYPES.LIST_PROJECT,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: PROJECT_ACTION_TYPES.ERROR_PROJECT,
            payload: err.message
        });
    }
};

export const addProject = (history, project, setProjectCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).post('/project', project);
        // Update state
        dispatch({
            type: PROJECT_ACTION_TYPES.ADD_PROJECT,
            payload: data
        });
        setProjectCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: PROJECT_ACTION_TYPES.ERROR_PROJECT,
            payload: error
        });
    }
};

export const getProject = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        const {data} = await axiosInstance(history, dispatch).get(`/project/${id}`);
        // Update state
        dispatch({
            type: PROJECT_ACTION_TYPES.SHOW_PROJECT,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: PROJECT_ACTION_TYPES.ERROR_PROJECT,
            payload: err.message
        });
    }
};

export const editProject = (history, id, project, setProjectCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).patch(`/project/${id}`, project);
        // Update state
        dispatch({
            type: PROJECT_ACTION_TYPES.EDIT_PROJECT,
            payload: data
        });
        setProjectCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: PROJECT_ACTION_TYPES.ERROR_PROJECT,
            payload: error
        });
    }
};

export const deleteProject = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        await axiosInstance(history, dispatch).delete(`/project/${id}`);
        // Update state
        dispatch({
            type: PROJECT_ACTION_TYPES.DELETE_PROJECT,
            payload: id
        });
    } catch (err) {
        console.log(err);
        const error = await err?.response?.data;
        dispatch({
            type: PROJECT_ACTION_TYPES.ERROR_PROJECT,
            payload: error === null || error === undefined ? err.message : error
        });
    }
};

export const inviteStaff = (history, id) => async dispatch => {
    try {
        await axiosInstance(history, dispatch).post(`/project/${id}/invite_staff`);
    } catch (err) {
        console.log(err);
        dispatch({
            type: PROJECT_ACTION_TYPES.ERROR_PROJECT,
            payload: err.message
        });
    }
};

export const notifyStaff = (history, id, configuration) => async dispatch => {
    try {
        await axiosInstance(history, dispatch).post(`/project/${id}/send_notification`, configuration);
        return true
    } catch (err) {
        console.log(err);
        dispatch({
            type: STAFF_ACTION_TYPES.ERROR_STAFF,
            payload: err.message
        });
        return false
    }
};

export const notifyAlertContacts = (history, id, configuration) => async dispatch => {
    try {
        await axiosInstance(history, dispatch).post(`/project/${id}/send_alert_contacts_notification`, configuration);
        return true
    } catch (err) {
        console.log(err);
        dispatch({
            type: STAFF_ACTION_TYPES.ERROR_STAFF,
            payload: err.message
        });
        return false
    }
};