import {Breadcrumb, Layout, message, Table, Popconfirm, Button} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone, CheckCircleTwoTone} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect} from "react-redux";
import {useEffect} from "react";

import {selectLoading, selectError, selectPaths, selectCurrentPath} from "../../redux/path/pathSelector";
import {listPaths, deletePath, clearPathError, activatePath} from '../../redux/path/pathActions';
import {Link} from "react-router-dom";

const {Content, Footer} = Layout;

const Paths = (props) => {

    const intl = useIntl();
    const {paths, count, max, offset} = props.paths;

    const columns = [
        {
            title: intl.formatMessage({id: 'label.path.id'}),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '5%',
            fixed: true,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend']
        },
        {
            title: intl.formatMessage({id: 'label.path.actions'}),
            dataIndex: 'actions',
            align: 'center',
            width: '6%',
            fixed: true,
            render: (_, record) =>
                count >= 1 ? (
                    record.type === 'standard' ? (
                        record.name !== 'R auto path' ? (
                            <div className="actionsIcons">
                                <DeleteTwoTone twoToneColor="gray" style={{opacity: 0.0}} title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.path'})} />
                                <Link to={`/configuration/paths/${record.id}`}><EditTwoTone title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.path'})} /></Link>
                            </div>
                        ) : (
                            <div className="actionsIcons">
                                <DeleteTwoTone twoToneColor="gray" style={{opacity: 0.0}} title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.path'})} />
                                <EditTwoTone twoToneColor="gray" style={{opacity: 0.0}} title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.path'})} />
                            </div>
                        )
                    ) : (
                        <div className="actionsIcons">
                            <Popconfirm title={intl.formatMessage({id: 'msg.confirm-delete'})}
                                        onConfirm={() => handleDelete(record.id)}>
                                <DeleteTwoTone twoToneColor="red" title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.path'})} />
                            </Popconfirm>
                            <Link to={`/configuration/paths/${record.id}`}><EditTwoTone title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.path'})} /></Link>
                        </div>
                        )
                ) : null
        },
        {
            title: intl.formatMessage({id: 'label.path.selected'}),
            dataIndex: 'selected',
            key: 'selected',
            width: '6%',
            fixed: true,
            align: "center",
            sorter: (a, b) => a.selected- b.selected,
            sortDirections: 'descend',
            defaultSortOrder: 'descend',
            render: (_, record) =>
                record.selected ? (
                    <>
                        <CheckCircleTwoTone />
                    </>
                ) : (
                    <>
                        <Button type="link" onClick={() => handleActivate(record.id)}>
                            <CheckCircleTwoTone twoToneColor = '#eb2f96'/>
                        </Button>
                    </>
                    )
        },
        {
            title: intl.formatMessage({id: 'label.path.name'}),
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            fixed: false,
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('name')
        },
        {
            title: intl.formatMessage({id: 'label.path.description'}),
            dataIndex: 'description',
            key: 'description',
            width: '16%',
            fixed: false,
            sorter: (a, b) => a.description - b.description,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('description')
        },
        {
            title: intl.formatMessage({id: 'label.path.mode'}),
            dataIndex: 'mode',
            key: 'mode',
            width: '7%',
            fixed: false,
            sorter: (a, b) => a.mode - b.mode,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('mode')
        },
        {
            title: intl.formatMessage({id: 'label.path.path'}),
            dataIndex: 'path',
            key: 'path',
            width: '23%',
            fixed: false,
            sorter: (a, b) => a.path - b.path,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('path'),
        },
        {
            title: intl.formatMessage({id: 'label.path.app'}),
            dataIndex: 'app',
            key: 'app',
            width: '6%',
            fixed: false,
            sorter: (a, b) => a.app- b.app,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('app')
        },
        {
            title: intl.formatMessage({id: 'label.path.os'}),
            dataIndex: 'os',
            key: 'os',
            width: '8%',
            fixed: false,
            sorter: (a, b) => a.os- b.os,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('mode')
        },
        {
            title: intl.formatMessage({id: 'label.path.type'}),
            dataIndex: 'type',
            key: 'type',
            width: '8%',
            fixed: false,
            sorter: (a, b) => a.type- b.type,
            sortDirections: ['ascend', 'descend'],
            ...columnSearchProps('type'),
            render: (text) => {
                switch(text) {
                    case "standard":
                        return intl.formatMessage({id: 'label.path.type.standard'});
                    case "customized":
                        return intl.formatMessage({id: 'label.path.type.customized'});
                    default:
                        return intl.formatMessage({id: 'label.path.type.missing'});
                }
            }
        }
    ];

    useEffect(() => { //para cargar datos

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearPathError());
            };
            error();
        } else {
            props.listPaths(props.history);
        }
        // eslint-disable-next-line
    }, [props.error]);

    const handleChange = (pagination, filters, sorter) => {
        props.listPaths(props.history, pagination, filters, sorter);
    };

    const handleDelete = (id) => {
        props.deletePath(props.history, id);
    };

    const handleActivate = (id) => {
      props.activatePath(props.history, id);
    };


    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.configuration" defaultMessage="Configuration"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.paths" defaultMessage="Paths"/></span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Link to='/configuration/paths/new'><Button type="primary" style={{marginBottom: 16}}>
                        <FormattedMessage id="label.path.add-path" defaultMessage="Add a Path"/>
                    </Button></Link>
                    <Table
                        bordered={true}
                        loading={props.loading}
                        rowKey={record => record.id}
                        responsive={true}
                        pagination={{
                            current: offset / max + 1,
                            showLessItems: true, pageSizeOptions: [10, 20, 50, 100],
                            pageSize: max,
                            defaultPageSize: max, total: count, showSizeChanger: true,
                            // showTotal: ((total, range) => `${range[0]}-${range[1]} of ${total}`)
                            showTotal: ((total, range) => intl.formatMessage({
                                id: 'label.range-total'
                            }, {one: range[0], two: range[1], total: total}))
                        }}
                        size={'default'}
                        showHeader
                        columns={columns}
                        dataSource={paths}
                        scroll={{y: '65vh', x: '100vw'}}
                        onChange={handleChange}
                    />
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    paths: selectPaths(state),
    path: selectCurrentPath(state),
});

export default connect(mapStateToProps, {
    listPaths, deletePath, clearPathError, activatePath})(Paths);