import {createSelector} from "reselect";

const selectDataSourceConfiguration = state => state.dataSourceConfiguration;

export const selectLoading = createSelector(
    [selectDataSourceConfiguration],
    (dataSourceConfiguration) => dataSourceConfiguration.loading
);

export const selectError = createSelector(
    [selectDataSourceConfiguration],
    (dataSourceConfiguration) => dataSourceConfiguration.error
);

export const selectDataSourceConfigurations = createSelector(
    [selectDataSourceConfiguration],
    (dataSourceConfiguration) => dataSourceConfiguration.dataSourceConfigurations
);

export const selectCurrentDataSourceConfiguration = createSelector(
    [selectDataSourceConfiguration],
    (dataSourceConfiguration) => dataSourceConfiguration.current
);

export const selectLoadingApiForms = createSelector(
    [selectDataSourceConfiguration],
    (dataSourceConfiguration) => dataSourceConfiguration.selectLoadingApiForms
);

export const selectErrorApiForms = createSelector(
    [selectDataSourceConfiguration],
    (dataSourceConfiguration) => dataSourceConfiguration.errorApiForms
);

export const selectApiForms = createSelector(
    [selectDataSourceConfiguration],
    (dataSourceConfiguration) => dataSourceConfiguration.apiForms
);