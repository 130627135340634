import {createStore, applyMiddleware} from 'redux';
import logger from 'redux-logger';
import thunk from "redux-thunk";
import rootReducer from './rootReducer';
import {persistStore} from "redux-persist";

const middlewares = [thunk, logger];

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

export const persistor = persistStore(store);

export default store;