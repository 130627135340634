import {ALERT_CONTACT_ACTION_TYPES} from "../typesAction";


const initialState = {
    alertContacts: [],
    current: null,
    loading: false,
    error: null
}

const alertContactReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALERT_CONTACT_ACTION_TYPES.LOAD_ALERT_CONTACT:
            return {
                ...state, loading: true
            }
        case ALERT_CONTACT_ACTION_TYPES.CLEAR_LOAD_ALERT_CONTACT:
            return {
                ...state, loading: false
            }
        case ALERT_CONTACT_ACTION_TYPES.ERROR_ALERT_CONTACT:
            return {
                ...state, error: action.payload, loading: false, current: null
            }
        case ALERT_CONTACT_ACTION_TYPES.CLEAR_ALERT_CONTACT_ERRORS:
            return {
                ...state,
                error: null, loading: false
            }
        case ALERT_CONTACT_ACTION_TYPES.LIST_ALERT_CONTACT:
            return {
                ...state, alertContacts: action.payload, loading: false, current: null
            }
        case ALERT_CONTACT_ACTION_TYPES.ADD_ALERT_CONTACT:
            return {
                ...state,
                loading: false
            }
        case ALERT_CONTACT_ACTION_TYPES.SHOW_ALERT_CONTACT:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case ALERT_CONTACT_ACTION_TYPES.EDIT_ALERT_CONTACT:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case ALERT_CONTACT_ACTION_TYPES.DELETE_ALERT_CONTACT:
            const updatedAlertContact = state.alertContacts.alertContacts.filter(alertContact => action.payload !== alertContact.id)
            const updatedCount = state.alertContacts.count - 1
            return {
                ...state, alertContacts: {...state.alertContacts, alertContacts: updatedAlertContact, count: updatedCount}, loading: false
            }
        default:
            return state;
    }

};

export default alertContactReducer;
