import axiosInstance from "../../../utils/axiosInstance";
import { FORM_FIELD_ACTION_TYPES, PROJECT_ACTION_TYPES } from "../../typesAction";


export const setLoading = () => {
    return { type: FORM_FIELD_ACTION_TYPES.LOAD_FORM_FIELD }
};

export const clearLoading = () => {
    return { type: FORM_FIELD_ACTION_TYPES.CLEAR_LOAD_FORM_FIELD }
};

export const clearFormFieldError = () => {
    return { type: FORM_FIELD_ACTION_TYPES.CLEAR_FORM_FIELD_ERRORS }
};

export const listFormFields = (history, listProjects = true) => async dispatch => {
    try {
        dispatch(setLoading());
        // Prepare all query parameters
        let params = {
            max: 100,
            sort: 'id'
        };
        const { data: formFields } = await axiosInstance(history, dispatch).get('/formField', { params });

        // Update state
        dispatch({
            type: FORM_FIELD_ACTION_TYPES.LIST_FORM_FIELD,
            payload: formFields
        });

        if (listProjects) {
            params = {
                max: 99999
            };
            const { data: projects } = await axiosInstance(history, dispatch).get('/project', { params });

            // Update state
            dispatch({
                type: PROJECT_ACTION_TYPES.LIST_PROJECT,
                payload: projects
            });
        };

    } catch (err) {
        console.log(err);
        dispatch({
            type: FORM_FIELD_ACTION_TYPES.ERROR_FORM_FIELD,
            payload: err.message
        });
    }
};
