import axios from "axios";

const setAuthToken = accessToken => {
    if (accessToken){
        // axios.defaults.headers.common['X-Auth-Token'] = accessToken;
        localStorage.setItem('token', accessToken);
    }else{
        delete axios.defaults.headers.common['X-Auth-Token'];
    }
};

export default setAuthToken;
