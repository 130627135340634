/*
 https://react-page.github.io/docs/#/custom-cell-plugins
 */

import { CellPlugin } from '@react-page/editor';
import ReportOutputHtmlContent from './ReportOutputHtmlContent';

const outputHtmlContentPlugin: CellPlugin = {
  Renderer: () => (
    <ReportOutputHtmlContent />
  ),
  id: 'outputHtmlContentPlugin_1234',
  title: 'Report html output',
  description: 'Readonly output html content area',
  version: 1
};

export default outputHtmlContentPlugin