import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {store, persistor} from "./redux/store";
import {PersistGate} from 'redux-persist/integration/react';
import {IntlProvider} from "react-intl";
import English from './lang/en.json';
import Spanish from './lang/es.json';
import esES from 'antd/es/locale/es_ES';
import enUS from 'antd/es/locale/en_US';
import 'moment/locale/es';
import {ConfigProvider} from "antd";

//localstorage
function getInitialLocal() {
    // getting stored items
    let savedLocale = localStorage.getItem("language");
    if (savedLocale === undefined || savedLocale === null){
        savedLocale = process.env.REACT_APP_LOCALE;
        localStorage.setItem('language', savedLocale);
    }
    return savedLocale || 'en';
}

function getInitialLocalFile() {
    // getting stored items
    let language= localStorage.getItem("language")
    if (language === undefined || language === null){
        language = process.env.REACT_APP_LOCALE;
    }
    return language === 'es' ? Spanish : English;
}

function getInitialLocalFileAntd() {
    // getting stored items
    let language= localStorage.getItem("language")
    if (language === undefined || language === null){
        language = process.env.REACT_APP_LOCALE;
    }
    return language === 'es' ? esES : enUS;
}


ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <IntlProvider locale={getInitialLocal()} messages={getInitialLocalFile()} defaultLocale={'en'}>
                <ConfigProvider locale={getInitialLocalFileAntd()}>
                <App/>
                </ConfigProvider>
            </IntlProvider>
        </PersistGate>
    </Provider>
    // </React.StrictMode>,
    , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
