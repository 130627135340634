import { FORM_FIELD_ACTION_TYPES } from "../../typesAction";


const initialState = {
    formFields: [],
    loading: false,
    error: null
}

const formFieldReducer = (state = initialState, action) => {
    switch (action.type) {
        case FORM_FIELD_ACTION_TYPES.LOAD_FORM_FIELD:
            return {
                ...state, loading: true
            }
        case FORM_FIELD_ACTION_TYPES.CLEAR_LOAD_FORM_FIELD:
            return {
                ...state, loading: false
            }
        case FORM_FIELD_ACTION_TYPES.ERROR_FORM_FIELD:
            return {
                ...state, error: action.payload, loading: false
            }
        case FORM_FIELD_ACTION_TYPES.CLEAR_FORM_FIELD_ERRORS:
            return {
                ...state,
                error: null, loading: false
            }
        case FORM_FIELD_ACTION_TYPES.LIST_FORM_FIELD:
            return {
                ...state, formFields: action.payload, loading: false
            }
        default:
            return state;
    }

};

export default formFieldReducer;