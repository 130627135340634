import {REPORT_FILE_ACTION_TYPES} from "../typesAction";


const initialState = {
    reportFiles: [],
    current: null,
    loading: false,
    error: null

};

const reportFileReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORT_FILE_ACTION_TYPES.LOAD_REPORT_FILE:
            return {
                ...state, loading: true
            };
        case REPORT_FILE_ACTION_TYPES.CLEAR_LOAD_REPORT_FILE:
            return {
                ...state, loading: false
            };
        case REPORT_FILE_ACTION_TYPES.ERROR_REPORT_FILE:
            return {
                ...state,
                error: action.payload, loading: false, current: null
            };
        case REPORT_FILE_ACTION_TYPES.CLEAR_REPORT_FILE_ERRORS:
            return {
                ...state,
                error: null, loading: false
            };
        case REPORT_FILE_ACTION_TYPES.LIST_REPORT_FILE:
            return {
                ...state,
                reportFiles: action.payload, loading: false, current: null
            };
        case REPORT_FILE_ACTION_TYPES.ADD_REPORT_FILE:
            return {
                ...state, loading: false,
            };
        case REPORT_FILE_ACTION_TYPES.SHOW_REPORT_FILE:
            return {
                ...state, current: action.payload, loading: false
            };
        case REPORT_FILE_ACTION_TYPES.EDIT_REPORT_FILE:
            return {
                ...state,
                current: action.payload, loading: false
            };
        case REPORT_FILE_ACTION_TYPES.DELETE_REPORT_FILE:
            const updatedReportFiles = state.reportFiles.reportFiles.filter(reportFile => action.payload !== reportFile.id);
            const updatedCount = state.reportFiles.reportFiles.count - 1;
            return {
                ...state, reportFiles: {...state.reportFiles, reportFiles: updatedReportFiles,
                count: updatedCount}, loading: false
            };
        default:
            return state;
    }
};

export default reportFileReducer;