
import {Breadcrumb, Card, Layout, message} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import {FormattedMessage} from "react-intl";
import {getGuide} from "../../utils/utilData";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
const {Content, Footer} = Layout;




const Guide = (props) => {

    const dispatch = useDispatch();
    const [cardLoading, setCardLoading] = useState(true);
    const [htmlContent, setHtmlContent] = useState(true);


    useEffect(() => {
        dispatch(getGuide(props.history)).then((data) => {
            if (data && data?.htmlContent) {
                setCardLoading(false)
                setHtmlContent(data.htmlContent)
            }else if(data?.errors){
                message.error(data.errors)
            }
        })

        //eslint-disable-next-line
    }, []);

    return (
    <Layout className="site-layout">
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '10px 0'}}>
                <Breadcrumb.Item>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span><FormattedMessage id="menu.guide" defaultMessage="Guide"/></span>
                </Breadcrumb.Item>
            </Breadcrumb>

            <Card
                type={'inner'} loading={cardLoading}>
                <iframe srcDoc={htmlContent?.toString()}
                        className={"iframe-html"}
                        title={"htmlContent"}>
                    <FormattedMessage id="label.report.view.renderingHtml.notCompatibleBrowserForIframe"/>
                </iframe>
            </Card>
        </Content>
        <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
    </Layout>
    );
}

export default Guide;