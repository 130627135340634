import axiosInstance from "../../utils/axiosInstance";
import {POINTS_OF_INTEREST_ACTION_TYPES} from "../typesAction";


export const setLoading = () => {
    return {type: POINTS_OF_INTEREST_ACTION_TYPES.LOAD_POINTS_OF_INTEREST}
};

export const clearLoading = () => {
    return {type: POINTS_OF_INTEREST_ACTION_TYPES.CLEAR_LOAD_POINTS_OF_INTEREST}
};

export const clearPointsOfInterestError = () => {
    return {type: POINTS_OF_INTEREST_ACTION_TYPES.CLEAR_POINTS_OF_INTEREST_ERRORS}
};

export const addPointsOfInterest = (history, pointsOfInterest, setPointsOfInterestCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).post('/layer/point_of_interest', pointsOfInterest);
        // Update state
        dispatch({
            type: POINTS_OF_INTEREST_ACTION_TYPES.ADD_POINTS_OF_INTEREST,
            payload: data
        });
        setPointsOfInterestCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: POINTS_OF_INTEREST_ACTION_TYPES.ERROR_POINTS_OF_INTEREST,
            payload: error
        });
    }
};

export const getPointsOfInterest = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        const {data} = await axiosInstance(history, dispatch).get(`/layer/point_of_interest/${id}`);
        // Update state
        dispatch({
            type: POINTS_OF_INTEREST_ACTION_TYPES.SHOW_POINTS_OF_INTEREST,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: POINTS_OF_INTEREST_ACTION_TYPES.ERROR_POINTS_OF_INTEREST,
            payload: err.message
        });
    }
};

export const editPointsOfInterest = (history, id, pointsOfInterest, setPointsOfInterestCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).patch(`/layer/point_of_interest/${id}`, pointsOfInterest);
        // Update state
        dispatch({
            type: POINTS_OF_INTEREST_ACTION_TYPES.EDIT_POINTS_OF_INTEREST,
            payload: data
        });
        setPointsOfInterestCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: POINTS_OF_INTEREST_ACTION_TYPES.ERROR_POINTS_OF_INTEREST,
            payload: error
        });
    }
};

export const deletePointsOfInterest = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        await axiosInstance(history, dispatch).delete(`/layer/point_of_interest/${id}`);
        // Update state
        dispatch({
            type: POINTS_OF_INTEREST_ACTION_TYPES.DELETE_POINTS_OF_INTEREST,
            payload: id
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: POINTS_OF_INTEREST_ACTION_TYPES.ERROR_POINTS_OF_INTEREST,
            payload: err.message
        });
    }
};