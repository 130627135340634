import {DATA_ANALYSIS_ACTION_TYPES} from "../typesAction";


const initialState = {
    dataAnalysis: [],
    current: null,
    loading: false,
    error: null,
    view: null
};

const dataAnalysisReducer = (state = initialState, action) => {
    switch (action.type) {
        case DATA_ANALYSIS_ACTION_TYPES.LOAD_DATA_ANALYSIS:
            return {
                ...state, loading: true
            };
        case DATA_ANALYSIS_ACTION_TYPES.CLEAR_LOAD_DATA_ANALYSIS:
            return {
                ...state, loading: false
            };
        case DATA_ANALYSIS_ACTION_TYPES.ERROR_DATA_ANALYSIS:
            return {
                ...state, error: action.payload, loading: false, current: null
            };
        case DATA_ANALYSIS_ACTION_TYPES.CLEAR_DATA_ANALYSIS_ERRORS:
            return {
                ...state,
                error: null, loading: false
            };
        case DATA_ANALYSIS_ACTION_TYPES.LIST_DATA_ANALYSIS:
            return {
                ...state, dataAnalysis: action.payload, loading: false,
                current: null, view: null, code: null
            };
        case DATA_ANALYSIS_ACTION_TYPES.ADD_DATA_ANALYSIS:
            return {
                ...state,
                loading: false
            };
        case DATA_ANALYSIS_ACTION_TYPES.SHOW_DATA_ANALYSIS:
            return {
                ...state,
                current: action.payload, loading: false
            };
        case DATA_ANALYSIS_ACTION_TYPES.EDIT_DATA_ANALYSIS:
            return {
                ...state,
                current: action.payload, loading: false
            };
        case DATA_ANALYSIS_ACTION_TYPES.DELETE_DATA_ANALYSIS:
            const updatedDataAnalysis = state.dataAnalysis.dataAnalysis.filter(dataAnalysis =>
                action.payload !== dataAnalysis.id);
            const updatedCount = state.dataAnalysis.count - 1;
            return {
                ...state, dataAnalysis: {...state.dataAnalysis,
                    dataAnalysis: updatedDataAnalysis, count: updatedCount}, loading: false
            };
        case DATA_ANALYSIS_ACTION_TYPES.VIEW_DATA_ANALYSIS:
            return {
                ...state,
                view: action.payload, loading: false
            };
        default:
            return state;
    }
};

export default dataAnalysisReducer;