import {Breadcrumb, Layout, message, Table} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect} from "react-redux";
import {useEffect} from "react";
import {selectLoading, selectError, selectPermissions} from "../../redux/permission/permissionSelector";
import {listPermissions, clearPermissionError} from '../../redux/permission/permissionActions';


const {Content, Footer} = Layout;

const Permissions = (props) => {

    const intl = useIntl();
    const {permissions, count} = props.permissions;

    const columns = [
        {
            title: intl.formatMessage({id: 'label.id'}),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            fixed: false,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: intl.formatMessage({id: 'label.name'}),
            dataIndex: 'name',
            key: 'name',
            fixed: false,
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('name'),
        }
    ];

    useEffect(() => {

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearPermissionError());
            };
            error();
        } else {
            props.listPermissions(props.history);
        }
        // eslint-disable-next-line
    }, [props.error]);

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.admin" defaultMessage="Admin"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.permissions" defaultMessage="Permissions"/></span>
                    </Breadcrumb.Item>

                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Table
                        bordered={true}
                        loading={props.loading}
                        rowKey={record => record.id}
                        responsive={true}
                        pagination={{
                            showLessItems: true, pageSizeOptions: [10, 20, 50, 100],
                            defaultPageSize: 10, total: count, showSizeChanger: true,
                            showTotal: ((total, range) => intl.formatMessage({
                                id: 'label.range-total'
                            }, {one: range[0], two: range[1], total: total}))
                        }}
                        size={'default'}
                        showHeader
                        columns={columns}
                        dataSource={permissions}
                    />
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    permissions: selectPermissions(state)
});

export default connect(mapStateToProps, {listPermissions, clearPermissionError})(Permissions);