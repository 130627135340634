import {
  Form,
  Input,
  message,
  Breadcrumb,
  Layout,
  Card,
  Button,
  Select,
  Col,
  Row,
  Skeleton,
  TreeSelect,
} from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import React, {
  useEffect,
  useRef,
  useState,
  Suspense,
  StrictMode,
  useMemo,
} from "react";
import {
  addDataAnalysis,
  getDataAnalysis,
  editDataAnalysis,
  clearDataAnalysisError,
} from "../../redux/dataAnalysis/dataAnalysisActions";
import { EyeTwoTone, HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  selectError,
  selectLoading,
  selectCurrentDataAnalysis,
} from "../../redux/dataAnalysis/dataAnalysisSelector";

import moment from "moment";
import {
  listSurveyForms,
  getSurveyForm,
} from "../../redux/survey/form/surveyFormActions";
import { selectSurveyForms } from "../../redux/survey/form/surveyFormSelector";
import { listFormFields } from "../../redux/survey/formField/formFieldActions";
import { selectFormFields } from "../../redux/survey/formField/formFieldSelector";
import { selectProjects } from "../../redux/project/projectSelector";
import { listProjects } from "../../redux/project/projectActions";
import { useDispatch } from "react-redux";
import { getDataForAnalysis, getUserGeoArea } from "../../utils/utilData";
import ReactQuill from "react-quill";
import {
  getConfigViewForUser,
  updateConfigView,
  resetConfigView,
} from "../../utils/dataAnalysis/utils/helpersForConfigView";
import { selectGeoArea } from "../../redux/user/userSelector";
import "./DataAnalysisForm.css";
import { PlotChoroplethMapMapBoxGlJs } from "../../utils/dataAnalysis/Plots/PlotChoroplethMapMapBoxGlJs";

import {
  getDeepestPath,
  getFieldPath,
  getFieldPathString,
  getIsFieldChanged,
} from "../../utils/dataAnalysis/utils/utilsForm";

import _ from "lodash";
import {
  fieldPathMaxNumberOfBars_isPersisted,
  fieldPathReactivePersistenceMode,
} from "../../utils/dataAnalysis/PlotsOptions/BarPLotOptions";
import { getSelectedDataZourcesFromAPI_using_getSurveyForm } from "../../utils/dataAnalysis/DataSeries/HelpersForFormListDataSeries";

const FormItemsAccordingToAnalysisType = React.lazy(() =>
  import("../../utils/dataAnalysis/FormItemsAccordingToAnalysisType")
);
const PlotTimeSeries = React.lazy(() =>
  import("../../utils/dataAnalysis/Plots/PlotTimeSeries")
);
const PlotBarPlot = React.lazy(() =>
  import("../../utils/dataAnalysis/Plots/PlotBarPlot")
);
const PlotHeatMapMapBoxGlJs = React.lazy(() =>
  import("../../utils/dataAnalysis/Plots/PlotHeatMapMapBoxGlJs")
);
const PlotBubbleMapMapBoxGlJs = React.lazy(() =>
  import("../../utils/dataAnalysis/Plots/PlotBubbleMapMapBoxGlJs")
);
const PlotPointMapMapBoxGlJsSimple = React.lazy(() =>
  import("../../utils/dataAnalysis/Plots/PlotPointMapMapBoxGlJsSimple")
);
const PlotPointMapMapBoxGlJsCategorical = React.lazy(() =>
  import("../../utils/dataAnalysis/Plots/PlotPointMapMapBoxGlJsCategorical")
);

const { Content, Footer } = Layout;

//TODO: Improve Suspense behavior. Unexpected parcial rendering are showed.

export const DataAnalysisFormContext = React.createContext({});

const DataAnalysisForm = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [form] = Form.useForm();

  //-------------------------------------------------------
  //variables and functions from props and context - start
  //-------------------------------------------------------
  const analysisId = props.match.params?.id;
  const dataAnalysis = useMemo(
    () => props?.dataAnalysis,
    [props?.dataAnalysis]
  );
  const configEdit = useMemo(
    function setConfigEdit() {
      return dataAnalysis?.configEdit ? dataAnalysis.configEdit : [];
    },
    [dataAnalysis?.configEdit]
  );
  const dataZources = useMemo(
    () => props.surveyForms?.forms,
    [props.surveyForms?.forms]
  );
  const edit = props.location.pathname !== "/configuration/dataAnalysis/new";
  const formFields = props.formFields.formFields;
  const isBarPlots = props.location.pathname.includes("bar-plots");
  const isBubbleMaps = props.location.pathname.includes("bubble-maps");
  const isChoroplethMaps = props.location.pathname.includes("choropleth-maps");
  const isConfig = props.location.pathname.includes("configuration");
  const isHeatMaps = props.location.pathname.includes("heat-maps");
  const isNew = props.location.pathname.includes("new");
  const isPointMaps = props.location.pathname.includes("point-maps");
  const isQuery = props.location.pathname.includes("/query/view");
  const isTimeSeries = props.location.pathname.includes("time-series");
  const view = props.location.pathname.includes("view");

  const dateFormat = view ? "YYYY-MM-DD" : null;
  const timeFormat = view ? "HH:mm" : null;

  const unexpectedErrorMsg = intl.formatMessage(
    { id: "errorMsg.unexpectedError" }
  );

  //only those projects that currently have forms are included in projectOptions
  const projectOptions = useMemo(
    () => {
      if (props.projects?.projects === undefined) return [];

      const projects = props.projects?.projects;
      
      const hasFormsProjects = projects?.filter((project) => project.hasForms);
      if (hasFormsProjects?.length > 0) {
        return hasFormsProjects.map((project) => {
          return { label: project.name, value: project.id };
        });
      } else {
        const msgWarning = intl.formatMessage({
          id: "warningMsg.DataAnalysisForm.noProjectHasForms.cannotCreateAnalysis",
        });
        message.warning(msgWarning);
        console.log(msgWarning);
        return [];
      }
    },
    //eslint-disable-next-line
    [props.projects?.projects]
  );

  const { geoArea } = props;
  //-------------------------------------------------------
  //variables and functions from props and context - end
  //-------------------------------------------------------

  //-------------------------------------
  //component specific variables - start
  //-------------------------------------
  const configEditFieldName = "configEdit"; //name of the field that collects data in JSONb
  // for entity DataAnalysis in the API

  //root path in form for configEdit field
  const rootPathConfigEdit = [configEditFieldName];

  //-------------------------------------
  //component specific variables - end
  //-------------------------------------

  //--------------------------------------------
  //other variables and local functions - start
  //--------------------------------------------
  const antDividerBorderTopColor = "rgba(0,0,0,0.85)";

  const plotHeight = 500;

  //these values must be the same as the ones in DataAnalysis.groovy:
  //type inList: ['TimeSeries', 'BarPlot', 'SimplePointMap', 'CategoricalPointMap' , 'HeatMap', 'ChoroplethMap', 'BubbleMap']
  const treeDataAnalysisTypeField = [
    {
      label: intl.formatMessage({ id: "label.dataAnalysis.type.TimeSeries" }),
      value: "TimeSeries",
    },
    {
      label: intl.formatMessage({ id: "label.dataAnalysis.type.BarPlot" }),
      value: "BarPlot",
    },
    {
      label: intl.formatMessage({ id: "label.dataAnalysis.type.PointMap" }),
      value: "PointMap",
      selectable: false,
      children: [
        {
          label: intl.formatMessage({
            id: "label.dataAnalysis.type.CategoricalPointMap",
          }),
          value: "CategoricalPointMap",
        },
        {
          label: intl.formatMessage({
            id: "label.dataAnalysis.type.SimplePointMap",
          }),
          value: "SimplePointMap",
        },
      ].sort((a, b) =>
        a["label"] === b["label"] ? 0 : a["label"] < b["label"] ? -1 : 1
      ),
    },
    {
      label: intl.formatMessage({ id: "label.dataAnalysis.type.HeatMap" }),
      value: "HeatMap",
    },
    {
      label: intl.formatMessage({
        id: "label.dataAnalysis.type.ChoroplethMap",
      }),
      value: "ChoroplethMap",
    },
    {
      label: intl.formatMessage({ id: "label.dataAnalysis.type.BubbleMap" }),
      value: "BubbleMap",
    },
  ].sort((a, b) =>
    a["label"] === b["label"] ? 0 : a["label"] < b["label"] ? -1 : 1
  );

  const logChangedField = (changedField) => {
    console.log("\n\n");
    console.log("-------------------------------------------");
    console.log("changedField.name: ", changedField.name.join("_"));
    console.log("changedField.value: ", changedField.value);
    console.log("-------------------------------------------");
    console.log("\n\n");
  };

  const statusOptions = [
    { label: intl.formatMessage({ id: "label.draft" }), value: "0" },
    { label: intl.formatMessage({ id: "label.published" }), value: "1" },
    { label: intl.formatMessage({ id: "label.disabled" }), value: "2" },
  ];

  //helper - move plot element to the center of viewport, for better visualization, when it is rendered.
  const scrollToAnchor = (anchor) => {
    const parentElement = document.getElementById(anchor);
    const yPadding = 75;
    const y =
      parentElement.getBoundingClientRect().top + window.scrollY - yPadding;
    window.scrollTo({ top: y, behavior: "auto" });
  };

  //helper function to select plot
  const plotAnalysis = () => {
    let element;
    switch (analysisType) {
      case "TimeSeries":
        element = <PlotTimeSeries />;
        break;
      case "BarPlot":
        element = (
          <PlotBarPlot
            wasFormFieldPersisted={wasFormFieldPersisted}
            setWasFormFieldPersisted={setWasFormFieldPersisted}
          />
        );
        break;

      case "SimplePointMap":
        element = <PlotPointMapMapBoxGlJsSimple />;
        break;

      case "CategoricalPointMap":
        element = <PlotPointMapMapBoxGlJsCategorical />;
        break;

      case "HeatMap":
        element = <PlotHeatMapMapBoxGlJs />;
        break;

      case "BubbleMap":
        element = <PlotBubbleMapMapBoxGlJs />;
        break;

      case "ChoroplethMap":
        element = <PlotChoroplethMapMapBoxGlJs />;
        break;

      default:
        element = <p>{analysisType + " is not implemented yet!"}</p>;
    }
    return element;
  };

  const optionsConditionsLogicalOperator = [
    { title: intl.formatMessage({ id: "label.AND" }), value: "AND" },
    { title: intl.formatMessage({ id: "label.OR" }), value: "OR" },
  ];


  //--------------------------------------------
  //other variables and local functions - end
  //--------------------------------------------

  //----------------
  //states - start
  //----------------
  const areAllFormFieldsJustFilledByCodeRef = useRef(false);
  const [analysisProject, setAnalysisProject] = useState();
  const [analysisRawData, setAnalysisRawData] = useState([]);
  const [analysisType, setAnalysisType] = useState();
  const [dataAnalysisCreated, setDataAnalysisCreated] = useState(false);
  const [loadingAnalysis, setLoadingAnalysis] = useState(false);

  //selectedSataZources is an array of items so that each one takes the value returned by getSurveyForm whose id is equal to
  //the value of field dataZource in the corresponding dataSeries.
  const [selectedDataZources, setSelectedDataZources] = useState([]);
  const [selectedAdditionalDataZources, setSelectedAdditionalDataZources] = useState([]);

  //this state is set in FormListDataSeriesMaps and then used by MapOptions to
  //generate the categories for 'CategoricalPointMap'.
  const [categoriesByVariables, setCategoriesByVariables] = useState(null);

  /*
        changedField state - the last field that changed by user interaction!
        note: this state is passed as a context to children components and it gives the possibility
        to implement some logic in these components depending on changes of form fields by user interaction.
    */
  const [changedField, setChangedField] = useState();

  /*State used by BarPlot analysis, to manage persistence of form fields due to user interaction.*/
  const [wasFormFieldPersisted, setWasFormFieldPersisted] = useState(false);
  /*for BarPlotOptions*/
  const [maxNumberOfBarsSlider, setMaxNumberOfBarsSlider] = useState({
    min: 1,
    max: 30,
    step: 1,
    marks: {
      1: `1`,
      15: `15`,
      30: `30`,
    },
    isPersisted: false,
  });

  //used in MapOptions component, but initilized when loadingData here in DataAnalysisForm
  const [radiusDisplay, setRadiusDisplay] = useState(null);
  const [opacityDisplay, setOpacityDisplay] = useState(null);
  const [strokeColorDisplay, setStrokeColorDisplay] = useState(null);
  const [strokeWidthDisplay, setStrokeWidthDisplay] = useState(null);
  const [strokeOpacityDisplay, setStrokeOpacityDisplay] = useState(null);
  const [strokeColor, setStrokeColor] = useState(null);

  //used to indicate that required data is ready to render analysis fragment in form
  const [isReadyAnalysisFormData, setIsReadyAnalysisFormData] = useState(isNew);

  //handlers for show and save query
  const [queryAction, setQueryAction] = useState(null);

  //configView
  const [configView, setConfigView] = useState();

  //popup query
  const popupQueryRef = useRef(null);
  const [valuePopupQuery, setValuePopupQuery] = useState("The popup query ...");

  //main query
  const mainQueryRef = useRef(null);
  const [valueMainQuery, setValueMainQuery] = useState("The main query...");

  //----------------
  //states - end
  //----------------

  //-----------------
  //handlers - start
  //------------------
  const onFinish = (values) => {
    
    if (!values) {
      return;
    }

    if (
      edit ||
      isTimeSeries ||
      isBarPlots ||
      isHeatMaps ||
      isPointMaps ||
      isChoroplethMaps ||
      isBubbleMaps
    ) {
      if (view) {
        if (values[configEditFieldName].dateRange) {
          //converting dateRange from moment to string with dateFormat
          values[configEditFieldName].dateRange = [
            values[configEditFieldName].dateRange[0]?.format(dateFormat),
            values[configEditFieldName].dateRange[1]?.format(dateFormat),
          ];
        }

        if (values[configEditFieldName].timeRange) {
          //converting timeRange from moment to string with timeFormat
          values[configEditFieldName].timeRange = [
            values[configEditFieldName].timeRange[0]?.format(timeFormat),
            values[configEditFieldName].timeRange[1]?.format(timeFormat),
          ];
        }

        const isReactivePersistenceModeField = form.getFieldValue(
          fieldPathReactivePersistenceMode
        );
        if (isReactivePersistenceModeField) {
          /*keep always `reactivePersistenceMode` value to `false` when persist!*/
          values.configEdit.barPlotOptions.reactivePersistenceMode = false;
        }

        //barPLotOptions maxNumberOfBars_isPersisted - used to exclude the call to setWasFormFieldPersisted
        const maxNumberOfBars_isPersisted = form.getFieldValue(
          fieldPathMaxNumberOfBars_isPersisted
        );
        if (maxNumberOfBars_isPersisted) {
          values.configEdit.barPlotOptions.maxNumberOfBars_isPersisted = false;
        }

        let functionSelected;
        if (isReactivePersistenceModeField) {
          functionSelected = setWasFormFieldPersisted;
        } else if (maxNumberOfBars_isPersisted) {
          functionSelected = setMaxNumberOfBarsSlider;
        } else {
          functionSelected = setDataAnalysisCreated;
        }

        dispatch(
          updateConfigView(
            props.history,
            analysisId,
            { configViewUser: values[configEditFieldName] },
            functionSelected
          )
        );
      } else {
        props
          .editDataAnalysis(
            props.history,
            analysisId,
            {
              status: values.status,
              name: values.name,
              description: values.description,
              project: values.project,
              type: values.type,
              configEdit: values[configEditFieldName],
            },
            setDataAnalysisCreated
          )
          .then(() => dispatch(resetConfigView(props.history, analysisId)));
      }
    } else {
      props.addDataAnalysis(
        props.history,
        {
          status: values.status,
          name: values.name,
          description: values.description,
          project: values.project,
          type: values.type,
          configEdit: values[configEditFieldName],
          configView: null,
        },
        setDataAnalysisCreated
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    errorInfo.errorFields.map((error) => message.error(error.errors[0]));
  };

  const handleShowQuery = () => {
    setQueryAction("show");
    const btn = document.getElementById("form-submit-button");
    btn.click();
  };
  const handleBackFromQuery = () => {
    window.location.href = `/configuration/dataAnalysis/${analysisId}`;
  };

  const handleValuesChange = (changedValues) => {
    setChangedField({
      name: getDeepestPath(changedValues),
      value: _.get(changedValues, getDeepestPath(changedValues)),
    });
  };

  //-----------------
  //handlers - end
  //------------------

  //-----------------
  //effects - start
  //-----------------
  useEffect(
    function ue_setChangedField() {
      if (!changedField) return;

      //log changedField in console
      logChangedField(changedField);

      //if any field changed by user interaction, then set areAllFormFieldsJustFilledByCodeRef.current to false!
      if (areAllFormFieldsJustFilledByCodeRef.current) {
        areAllFormFieldsJustFilledByCodeRef.current = false;
      }

      //updating project and type
      if (changedField.name[0] === "project") {
        setAnalysisProject(changedField.value);
      } else if (changedField.name[0] === "type") {
        setAnalysisType(changedField.value);
      }
    },
    //eslint-disable-next-line
    [changedField]
  );

  useEffect(
    function useEffect_loadDataZources() {
      if (!analysisProject) return;

      //load dataZources - depends on project
      props.listSurveyForms(
        props.history,
        { current: 0, pageSize: 500 },
        { project: [analysisProject] },
        undefined
      );
    },
    //eslint-disable-next-line
    [analysisProject]
  );

  useEffect(function useEffect_listFormFields_and_listProjeccts() {
    //list formFields and set props.formFields
    props.listFormFields(props.history, false); //projects are not listed with this action!!;

    //list active projects that hasForms
    const filter = { active: [true], hasForms: [true], maxProjects: [999] };
    props.listProjects(props.history, undefined, filter);

    //eslint-disable-next-line
  }, []);

  useEffect(
    function ue_loadingData() {
      if (props.error) {
        const error = () => {
          if (typeof props.error == "string") {
            message.error(props.error).then(props.clearDataAnalysisError());
          } else {
            props.error.errors.map((errorMessage) =>
              message
                .error(errorMessage.message)
                .then(props.clearDataAnalysisError())
            );
          }
        };
        error();
      }

      if (configView === undefined && analysisId) {
        dispatch(getConfigViewForUser(props.history, analysisId)).then(
          (data) => {
            if (data) {
              setConfigView(data.configViewUser);
              setIsReadyAnalysisFormData(true);
            } else {
              const location = {
                pathname: "/not-found",
                state: {
                  from: props.location.pathname,
                },
              };
              props.history.push(location);
            }
          }
        );
      }

      if (
        edit &&
        !dataAnalysisCreated &&
        props.dataAnalysis === null &&
        props.error === null &&
        configView !== undefined
      ) {
        props.getDataAnalysis(props.history, analysisId).then((data) => {
          if (data) {
            let dataCloned = JSON.parse(JSON.stringify(data)); //https://www.w3docs.com/snippets/javascript/how-to-clone-a-javascript-object.html
            let mapDefaultLayer;

            if (view) {
              if (configView !== null) {
                //view and configView
                dataCloned.configView = configView;

                //a created and already viewed dataAnalysis
                //---------------------------------------------
                if (dataCloned.configView?.dateRange) {
                  //transforming dateRange from string with dateFormat to moment
                  dataCloned.configView.dateRange[0] = moment(
                    dataCloned.configView.dateRange[0],
                    dateFormat
                  );
                  dataCloned.configView.dateRange[1] = moment(
                    dataCloned.configView.dateRange[1],
                    dateFormat
                  );
                }

                if (dataCloned.configView?.timeRange) {
                  //transforming timeRange from string with timeFormat to moment
                  dataCloned.configView.timeRange[0] = moment(
                    dataCloned.configView.timeRange[0],
                    timeFormat
                  );
                  dataCloned.configView.timeRange[1] = moment(
                    dataCloned.configView.timeRange[1],
                    timeFormat
                  );
                }

                if (dataCloned.type === "BarPlot") {
                  setMaxNumberOfBarsSlider({
                    min: dataCloned.configView.barPlotOptions
                      .maxNumberOfBars_min,
                    max: dataCloned.configView.barPlotOptions
                      .maxNumberOfBars_max,
                    step: dataCloned.configView.barPlotOptions
                      .maxNumberOfBars_step,
                    marks: dataCloned.configView?.barPlotOptions
                      ?.maxNumberOfBars_marks
                      ? JSON.parse(
                          dataCloned.configView.barPlotOptions
                            .maxNumberOfBars_marks
                        )
                      : null,
                    isPersisted:
                      dataCloned.configView.barPlotOptions
                        .maxNumberOfBars_isPersisted,
                  });
                }

                if (["HeatMap", "BubbleMap"].includes(dataCloned.type)) {
                  mapDefaultLayer = dataCloned.configView.map.defaultLayer;
                  if (!Array.isArray(mapDefaultLayer)) {
                    dataCloned.configView.map.defaultLayer = [mapDefaultLayer];
                  }
                }
              } else {
                //view and not configView
                //setting condition.operator and condition.value fields to undefined in view mode
                const dataSeries =
                  dataCloned[configEditFieldName]["dataSeries"];
                const dataSeriesLength = dataSeries.length;
                if (dataSeriesLength > 0) {
                  for (
                    let nameDataSeries = 0;
                    nameDataSeries < dataSeriesLength;
                    nameDataSeries++
                  ) {
                    //setting conditions and evaluating if it has unique values or not
                    if (!!dataSeries[nameDataSeries]?.conditions) {
                      const conditions = dataSeries[nameDataSeries].conditions;
                      const conditionsLength = conditions.length;
                      if (conditionsLength > 0) {
                        for (let name = 0; name < conditionsLength; name++) {
                          const operatorField =
                            dataCloned[configEditFieldName]["dataSeries"][
                              nameDataSeries
                            ]["conditions"][name]["operator"];

                          //first variable to test
                          const operatorFieldWithOnlyOneValue =
                            operatorField?.length === 1;

                          //second variable to test
                          const valueField =
                            dataCloned[configEditFieldName]["dataSeries"][
                              nameDataSeries
                            ]["conditions"][name]["value"];
                          const valueFieldWithOnlyOneValue =
                            valueField !== undefined &&
                            valueField !== "" &&
                            ((Array.isArray(valueField) &&
                              valueField.length === 1) ||
                              !Array.isArray(valueField));

                          const areConditionsUnique =
                            operatorFieldWithOnlyOneValue &&
                            valueFieldWithOnlyOneValue;
                          if (areConditionsUnique) {
                            dataCloned[configEditFieldName]["dataSeries"][
                              nameDataSeries
                            ]["conditions"][name]["operator"] =
                              operatorField[0];
                            // dataCloned[configEditFieldName]["dataSeries"][
                            //   nameDataSeries
                            // ]["conditions"][name]["value"] = Array.isArray(
                            //   valueField
                            // )
                            //   ? valueField[0]
                            //   : valueField;
                          } else {
                            dataCloned[configEditFieldName]["dataSeries"][
                              nameDataSeries
                            ]["conditions"][name]["operator"] = undefined;
                            dataCloned[configEditFieldName]["dataSeries"][
                              nameDataSeries
                            ]["conditions"][name]["value"] = undefined;
                          }
                        }
                      }
                    }
                  }
                }

                //setting locations.geoRegions to undefined and
                //evaluating if it has unique values
                const geoRegions_regions =
                  dataCloned[configEditFieldName]["locations"][
                    "geoRegions_regions"
                  ];

                if (
                  (Array.isArray(geoRegions_regions) &&
                    geoRegions_regions.length === 1) ||
                  !Array.isArray(geoRegions_regions)
                ) {
                  dataCloned[configEditFieldName]["locations"][
                    "geoRegions_regions"
                  ] = Array.isArray(geoRegions_regions)
                    ? geoRegions_regions
                    : [geoRegions_regions];
                } else {
                  dataCloned[configEditFieldName]["locations"][
                    "geoRegions_regions"
                  ] = undefined;
                  dataCloned[configEditFieldName]["locations"][
                    "subGeoRegions_subRegions_idLevels"
                  ] = undefined;
                  dataCloned[configEditFieldName]["locations"][
                    "geoRegions_selectModeRegions"
                  ] = "selection";
                }

                //setting dateScale
                const dateScale = dataCloned[configEditFieldName]["dateScale"];
                if (dateScale?.length === 1) {
                  dataCloned[configEditFieldName]["dateScale"] = dateScale[0];
                } else {
                  dataCloned[configEditFieldName]["dateScale"] = undefined;
                }
              }

              if (dataCloned.type === "BubbleMap") {
                setRadiusDisplay(
                  dataCloned.configEdit.map["radius-showToUser"] ? null : "none"
                );
                setOpacityDisplay(
                  dataCloned.configEdit.map["opacity-showToUser"]
                    ? null
                    : "none"
                );
                setStrokeColorDisplay(
                  dataCloned.configEdit.map["stroke-color-showToUser"]
                    ? null
                    : "none"
                );
                setStrokeWidthDisplay(
                  dataCloned.configEdit.map["stroke-width-showToUser"]
                    ? null
                    : "none"
                );
                setStrokeOpacityDisplay(
                  dataCloned.configEdit.map["stroke-opacity-showToUser"]
                    ? null
                    : "none"
                );
              }
            } else {
              if (["HeatMap", "BubbleMap"].includes(dataCloned.type)) {
                mapDefaultLayer = dataCloned.configEdit.map.defaultLayer;
                if (!Array.isArray(mapDefaultLayer)) {
                  dataCloned.configEdit.map.defaultLayer = [mapDefaultLayer];
                }
              }
            }

            form.setFieldsValue({
              name: dataCloned.name,
              description: dataCloned.description,
              type: dataCloned.type,
              project: dataCloned.project,
              status: dataCloned.status,
              [configEditFieldName]: view
                ? configView !== null
                  ? dataCloned.configView
                  : dataCloned[configEditFieldName]
                : dataCloned[configEditFieldName],
            });

            areAllFormFieldsJustFilledByCodeRef.current = true;
            setAnalysisProject(dataCloned.project);
            setAnalysisType(dataCloned.type);
          } else {
            const errorMsg = intl.formatMessage(
              {
                id: "msg.Error.DataAnalysisForm.dataNotFoundForLoadingAnalysis",
              },
              { analysisId: analysisId }
            );
            message.error(errorMsg);
            props.history.push("/configuration/dataAnalysis");
          }
        });
      }

      if (dataAnalysisCreated) {
        if (edit) {
          setDataAnalysisCreated(false);
          if (!view) {
            if (queryAction === null) {
              message.info(
                intl.formatMessage({
                  id: "label.dataAnalysis.dataAnalysis-updated",
                })
              );
            } else {
              setQueryAction(null);
              window.location.href = `/configuration/dataAnalysis/query/view/${analysisId}`;
            }
          } else {
            //here goes the code for setting analysisData, that is passed to graphics
            setLoadingAnalysis(true);
            dispatch(getDataForAnalysis(analysisId)).then((data) => {
              if (data) {
                setAnalysisRawData(data);
                //managing when no data is available
                if (data.data.length === 0) {
                  setLoadingAnalysis(false);
                  if (data.errors.length === 1) {
                    const errorMsg = intl.formatMessage(
                      { id: "Error.unexpectedError.Please.contactAdmin" },
                      { errorMsg: data.errors[0] }
                    );
                    message.error(errorMsg);
                  }
                }
                if (isQuery) scrollToAnchor("queryAnchor");
              }
            });
          }
        } else {
          setDataAnalysisCreated(false);
          props.listSurveyForms(
            props.history,
            undefined,
            { status: -1 },
            undefined
          );
          setAnalysisProject();
          setAnalysisType();
          form.resetFields();
          message.info(
            intl.formatMessage({
              id: "label.dataAnalysis.dataAnalysis-created",
            })
          );
        }
      }
    },
    // eslint-disable-next-line
    [props.error, dataAnalysisCreated, edit, configView, queryAction]
  );

  //userGeoArea, used for maps, when no filters do not yield any data. used to calculate bbox.
  useEffect(
    function useEffect_setGeoArea() {
      if (view) {
        if (geoArea === undefined || geoArea === null) {
          props.getUserGeoArea(props.history);
        }
      }
    },
    //eslint-disable-next-line
    [view, geoArea]
  );

  useEffect(
    function useEffect_setSelectedDataZources() {
      //initialize selectedDataZources
      //it occurs when edit or view analysis and when configEdit.dataSeries has some value
      //it makes asynch requests to api, and the value is set by passing setSelectedDataZources hook.
      if (edit && configEdit?.dataSeries) {
        getSelectedDataZourcesFromAPI_using_getSurveyForm(
          configEdit.dataSeries,
          setSelectedDataZources,
          props.getSurveyForm,
          intl, 
          'dataZource'         
        );

        getSelectedDataZourcesFromAPI_using_getSurveyForm(
          configEdit.dataSeries,
          setSelectedAdditionalDataZources,
          props.getSurveyForm,
          intl, 
          'additionalDataZources'         
        );
      }
    },
    //eslint-disable-next-line
    [configEdit?.dataSeries, edit]
  );

  useEffect(function useEffect_setQueryRefs() {
    mainQueryRef.current?.getEditor().root.setAttribute("spellcheck", false);
    popupQueryRef.current?.getEditor().root.setAttribute("spellcheck", false);
  }, []);

  useEffect(
    function useEffect_setValueMainQuery() {
      if (analysisRawData?.query) {
        const queriesNumber = analysisRawData.query.length;
        let textToInsertArray = [];
        if (queriesNumber > 1) {
          textToInsertArray.push(
            "-- ".concat(
              "There are ",
              queriesNumber,
              " queries",
              " in this document"
            )
          );
          textToInsertArray.push(
            "-- ".concat("******************************************")
          );
        } else {
          textToInsertArray.push(
            "-- ".concat(
              "There is ",
              queriesNumber,
              " query",
              " in this document"
            )
          );
          textToInsertArray.push(
            "-- ".concat("**************************************")
          );
        }

        let queryFor;
        for (let i = 0; i < queriesNumber; i++) {
          if (analysisType === "TimeSeries" || analysisType === "BarPlot") {
            queryFor = analysisRawData.configEdit.dataSeries[i].yaxes
              .map((yaxis) => yaxis.title)
              .join(", ");
            textToInsertArray.push(
              "-- ".concat(
                "Query for DataSeries with yAxis Title / yAxes Titles: ",
                queryFor
              )
            );
          } else if (
            analysisType === "HeatMap" ||
            analysisType === "BubbleMap" ||
            ["CategoricalPointMap", "SimplePointMap"].includes(analysisType)
          ) {
            queryFor = analysisRawData.configEdit.dataSeries[i].variables
              .map((varObj) => varObj.alias)
              .join(", ");
            textToInsertArray.push(
              "-- ".concat(
                "Query for DataSeries with Variable Alias / Variables Aliases: ",
                queryFor
              )
            );
          }
          textToInsertArray.push(analysisRawData.query[i]);
          textToInsertArray.push(
            "-- ".concat("******************************************")
          );
        }

        const textToInsert = textToInsertArray.join("\n");
        const editorDelta = {
          //inserting a Delta as value to show text with format
          ops: [{ insert: textToInsert }],
        };
        setValueMainQuery(editorDelta);
      }
      //eslint-disable-next-line
    },
    //eslint-disable-next-line
    [analysisRawData]
  );

  //-----------------
  //effects - end
  //-----------------

  //-----------------------
  //jsx variables - start
  //-----------------------
  const breadcrumbHome = (
    <Breadcrumb.Item>
      <HomeOutlined />
    </Breadcrumb.Item>
  );

  const breadcrumbAnalysisConfigurationBasic = (
    <>
      <Breadcrumb.Item>
        <FormattedMessage
          id="menu.configuration"
          defaultMessage="Configuration"
        />
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <FormattedMessage
          id="menu.dataAnalysis"
          defaultMessage="Data Analysis"
        />
      </Breadcrumb.Item>
    </>
  );

  const breadcrumbAnalysisConfigurationCasesEdit = useMemo(
    () => {
      if (!dataAnalysis?.name) return null;

      let breadcrumb = null;
      if (view) {
        breadcrumb = (
          <>
            <Breadcrumb.Item>
              <FormattedMessage
                id={intl.formatMessage({ id: "label.dataAnalysis.view" })}
                defaultMessage="View"
              />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{dataAnalysis.name}</Breadcrumb.Item>
          </>
        );
      } else if (!view && edit) {
        breadcrumb = (
          <>
            <Breadcrumb.Item>
              <FormattedMessage
                id={intl.formatMessage({ id: "label.dataAnalysis.edit" })}
                defaultMessage="Edit"
              />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{dataAnalysis.name}</Breadcrumb.Item>
          </>
        );
      }

      return breadcrumb;
    },
    //eslint-disable-next-line
    [dataAnalysis?.name, view, edit]
  );

  const breadcrumbAnalysisConfigurationCasesNew = useMemo(
    () => {
      let breadcrumb = null;
      if (isNew) {
        breadcrumb = (
          <Breadcrumb.Item>
            <FormattedMessage
              id={intl.formatMessage({ id: "label.new" })}
              defaultMessage="New"
            />
          </Breadcrumb.Item>
        );
      }

      return breadcrumb;
    },
    //eslint-disable-next-line
    [isNew]
  );

  const breadcrumbQuery = useMemo(
    () =>
      isQuery && (
        <Breadcrumb.Item>
          <FormattedMessage
            id="label.dataAnalysis.Query"
            defaultMessage="Query"
          />
        </Breadcrumb.Item>
      ),
    [isQuery]
  );

  const breadcrumbAnalysisConfig = useMemo(
    () => {
      let breadcrumb = null;

      if (edit && !!dataAnalysis?.name) {
        breadcrumb = (
          <>
            {breadcrumbHome}
            {breadcrumbAnalysisConfigurationBasic}
            {breadcrumbAnalysisConfigurationCasesEdit}
            {breadcrumbQuery}
          </>
        );
      } else if (isNew) {
        breadcrumb = (
          <>
            {breadcrumbHome}
            {breadcrumbAnalysisConfigurationBasic}
            {breadcrumbAnalysisConfigurationCasesNew}
            {breadcrumbQuery}
          </>
        );
      }

      return breadcrumb;
    },
    //eslint-disable-next-line
    [dataAnalysis?.name, edit, isNew, isQuery]
  );
  const breadcrumbAnalysisNotConfig = useMemo(
    () => {
      if (!dataAnalysis?.name) return null;

      let idPart, msgDefault;
      let breadcrumb = null;

      if (isTimeSeries) {
        idPart = "TimeSeries";
        msgDefault = "Time Series";
      }
      if (isBarPlots) {
        idPart = "BarPlots";
        msgDefault = "Bar Plots";
      }
      if (isHeatMaps) {
        idPart = "HeatMaps";
        msgDefault = "Heat Maps";
      }
      if (isPointMaps) {
        idPart = "PointMaps";
        msgDefault = "Point Maps";
      }
      if (isChoroplethMaps) {
        idPart = "ChoroplethMaps";
        msgDefault = "Choropleth Maps";
      }
      if (isBubbleMaps) {
        idPart = "BubbleMaps";
        msgDefault = "Bubble Maps";
      }

      if (idPart) {
        breadcrumb = (
          <>
            {breadcrumbHome}
            <Breadcrumb.Item>
              <FormattedMessage id="menu.Analysis" defaultMessage="Analysis" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <FormattedMessage
                id={"menu.Analysis." + idPart}
                defaultMessage={msgDefault}
              />
            </Breadcrumb.Item>
            <Breadcrumb.Item>{dataAnalysis.name}</Breadcrumb.Item>
          </>
        );
      }

      return breadcrumb;
    },
    //eslint-disable-next-line
    [
      dataAnalysis?.name,
      isTimeSeries,
      isBarPlots,
      isPointMaps,
      isBubbleMaps,
      isChoroplethMaps,
      isHeatMaps,
    ]
  );

  const suspenseEdit = useMemo(
    () => (
      <>
        <Suspense fallback={<Skeleton active={true}/>}>
          <Form
            layout={view ? "vertical" : "horizontal"}
            name="dataAnalysis_form"
            size="large"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 14 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={handleValuesChange}
            initialValues={{ status: "0" }}
            form={form}
          >
            <Form.Item
              name="status"
              label={intl.formatMessage({
                id: "label.dataAnalysis.status",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.status",
                  }),
                },
              ]}
              hidden={view}
            >
              <Select
                allowClear={false}
                options={statusOptions}
                placeholder={intl.formatMessage({
                  id: "label.dataAnalysis.status.select",
                })}
                disabled={!statusOptions || statusOptions?.length === 0}
              />
            </Form.Item>
            <Form.Item
              name="name"
              label={intl.formatMessage({
                id: "label.dataAnalysis.name",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.name",
                  }),
                },
              ]}
              hidden={view}
              validateTrigger={"onBlur"}
            >
              <Input allowClear={true} />
            </Form.Item>
            <Form.Item
              name="description"
              label={intl.formatMessage({
                id: "label.dataAnalysis.description",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.description",
                  }),
                },
              ]}
              hidden={view}
              validateTrigger={"onBlur"}
            >
              <Input allowClear={true} />
            </Form.Item>
            <Form.Item
              name="project"
              label={intl.formatMessage({
                id: "label.dataAnalysis.project",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.project",
                  }),
                },
              ]}
              hidden={view}
            >
              <Select
                allowClear={true}
                options={projectOptions}
                placeholder={intl.formatMessage({
                  id: "label.dataAnalysis.project.select",
                })}
                disabled={!projectOptions || projectOptions?.length === 0}
              />
            </Form.Item>
            <Form.Item
              name="type"
              label={intl.formatMessage({
                id: "label.dataAnalysis.type",
              })}
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: "msg.input-required.Analysis.type",
                  }),
                },
              ]}
              hidden={view}
            >
              <TreeSelect
                treeDefaultExpandAll={true}
                allowClear={true}
                multiple={false}
                treeData={treeDataAnalysisTypeField}
                placeholder={intl.formatMessage({
                  id: "label.dataAnalysis.type.select",
                })}
                disabled={
                  !treeDataAnalysisTypeField ||
                  treeDataAnalysisTypeField?.length === 0
                }
              />
            </Form.Item>

            <FormItemsAccordingToAnalysisType />

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={props.loading || loadingAnalysis}
                disabled={!view && !props.surveyForms}
                id={"form-submit-button"}
                className={view ? "form-submit-button-view" : null}
              >
                {isQuery ? (
                  <FormattedMessage
                    id="label.dataAnalysis.getQuery"
                    defaultMessage="Get Query!"
                  />
                ) : view ? (
                  <FormattedMessage
                    id="label.dataAnalysis.view.applyFilters"
                    defaultMessage="Apply Filters"
                  />
                ) : edit ? (
                  <FormattedMessage id="label.update" defaultMessage="Update" />
                ) : (
                  <FormattedMessage id="label.submit" defaultMessage="Submit" />
                )}
              </Button>
            </Form.Item>
          </Form>
        </Suspense>
      </>
    ),
    //eslint-disable-next-line
    [edit, isQuery, loadingAnalysis, props.loading, props.surveyForms, view]
  );

  const suspenseNew = useMemo(
    () => (
      <>
        <Form
          layout={view ? "vertical" : "horizontal"}
          name="dataAnalysis_form"
          size="large"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 14 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={handleValuesChange}
          initialValues={{ status: "0" }}
          form={form}
        >
          <Form.Item
            name="status"
            label={intl.formatMessage({
              id: "label.dataAnalysis.status",
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "msg.input-required.Analysis.status",
                }),
              },
            ]}
            hidden={view}
          >
            <Select
              allowClear={false}
              options={statusOptions}
              placeholder={intl.formatMessage({
                id: "label.dataAnalysis.status.select",
              })}
              disabled={!statusOptions || statusOptions?.length === 0}
            />
          </Form.Item>
          <Form.Item
            name="name"
            label={intl.formatMessage({
              id: "label.dataAnalysis.name",
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "msg.input-required.Analysis.name",
                }),
              },
            ]}
            hidden={view}
          >
            <Input allowClear={true} />
          </Form.Item>
          <Form.Item
            name="description"
            label={intl.formatMessage({
              id: "label.dataAnalysis.description",
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "msg.input-required.Analysis.description",
                }),
              },
            ]}
            hidden={view}
          >
            <Input allowClear={true} />
          </Form.Item>
          <Form.Item
            name="project"
            label={intl.formatMessage({
              id: "label.dataAnalysis.project",
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "msg.input-required.Analysis.project",
                }),
              },
            ]}
            hidden={view}
          >
            <Select
              allowClear={true}
              options={projectOptions}
              placeholder={intl.formatMessage({
                id: "label.dataAnalysis.project.select",
              })}
              disabled={!projectOptions || projectOptions?.length === 0}
            />
          </Form.Item>
          <Form.Item
            name="type"
            label={intl.formatMessage({
              id: "label.dataAnalysis.type",
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: "msg.input-required.Analysis.type",
                }),
              },
            ]}
            hidden={view}
          >
            <TreeSelect
              treeDefaultExpandAll={true}
              allowClear={true}
              multiple={false}
              treeData={treeDataAnalysisTypeField}
              placeholder={intl.formatMessage({
                id: "label.dataAnalysis.type.select",
              })}
              disabled={
                !treeDataAnalysisTypeField ||
                treeDataAnalysisTypeField?.length === 0
              }
            />
          </Form.Item>
          <Suspense fallback={<Skeleton active={true} />}>
            <FormItemsAccordingToAnalysisType />
          </Suspense>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={props.loading || loadingAnalysis}
              disabled={!view && !props.surveyForms}
              id={"form-submit-button"}
              className={view ? "form-submit-button-view" : null}
            >
              {isQuery ? (
                <FormattedMessage
                  id="label.dataAnalysis.getQuery"
                  defaultMessage="Get Query!"
                />
              ) : view ? (
                <FormattedMessage
                  id="label.dataAnalysis.view.applyFilters"
                  defaultMessage="Apply Filters"
                />
              ) : edit ? (
                <FormattedMessage id="label.update" defaultMessage="Update" />
              ) : (
                <FormattedMessage id="label.submit" defaultMessage="Submit" />
              )}
            </Button>
          </Form.Item>
        </Form>
      </>
    ),
    //eslint-disable-next-line
    [
      edit,
      isQuery,
      loadingAnalysis,
      props.loading,
      props.surveyForms,
      view,
      projectOptions,
    ]
  );
  //-----------------------
  //jsx variables - end
  //-----------------------

  //jsx
  return (
    <StrictMode>
      <DataAnalysisFormContext.Provider
        value={{            
          aliasMaxLength: 20, //Constant. The maximum number of characters of variables' alias.
          analysisId: analysisId,
          analysisProject: analysisProject,
          analysisRawData: analysisRawData,
          analysisType: analysisType,
          antDividerBorderTopColor: antDividerBorderTopColor,
          areAllFormFieldsJustFilledByCodeRef: areAllFormFieldsJustFilledByCodeRef,
          categoriesByVariables: categoriesByVariables,
          changedField: changedField,
          optionsConditionsLogicalOperator: optionsConditionsLogicalOperator,
          configEdit: configEdit,
          configEditFieldName: configEditFieldName,
          configView: configView,
          dataZources: dataZources,
          dateFormat: dateFormat,
          edit: edit,
          form: form,
          formFields: formFields,
          getFieldPath: getFieldPath,
          getFieldPathString: getFieldPathString,
          getIsFieldChanged: getIsFieldChanged,
          getSurveyForm: props.getSurveyForm,
          isNew: isNew,
          isQuery: isQuery,
          loadingAnalysis: loadingAnalysis,
          maxNumberOfBarsSlider: maxNumberOfBarsSlider,
          opacityDisplay: opacityDisplay,
          plotHeight: plotHeight,
          radiusDisplay: radiusDisplay,
          rootPathConfigEdit: rootPathConfigEdit, //only for FormListDataSeries and FormListDataSeriesMaps
          scrollToAnchor: scrollToAnchor,
          selectedAdditionalDataZources: selectedAdditionalDataZources, 
          selectedDataZources: selectedDataZources,
          setCategoriesByVariables: setCategoriesByVariables,
          setLoadingAnalysis: setLoadingAnalysis,
          setMaxNumberOfBarsSlider: setMaxNumberOfBarsSlider,
          setOpacityDisplay: setOpacityDisplay,
          setRadiusDisplay: setRadiusDisplay,
          setSelectedAdditionalDataZources: setSelectedAdditionalDataZources,
          setSelectedDataZources: setSelectedDataZources,
          setStrokeColor: setStrokeColor,
          setStrokeColorDisplay: setStrokeColorDisplay,
          setStrokeOpacityDisplay: setStrokeOpacityDisplay,
          setStrokeWidthDisplay: setStrokeWidthDisplay,
          setValuePopupQuery: setValuePopupQuery,
          strokeColor: strokeColor,
          strokeColorDisplay: strokeColorDisplay,
          strokeOpacityDisplay: strokeOpacityDisplay,
          strokeWidthDisplay: strokeWidthDisplay,
          unexpectedErrorMsg: unexpectedErrorMsg,
          userGeoArea: props.geoArea,
          view: view,
        }}
      >
        <Layout className="site-layout">
          <Content style={{ margin: "0 16px" }}>
            <Breadcrumb style={{ margin: "10px 0" }}>
              {isConfig
                ? breadcrumbAnalysisConfig
                : breadcrumbAnalysisNotConfig}
            </Breadcrumb>

            {isReadyAnalysisFormData ? (
              <>
                <div
                  className="site-layout-background"
                  style={{ padding: 24, minHeight: 360 }}
                >
                  <Card
                    type="inner"
                    title={
                      isQuery ? (
                        <FormattedMessage
                          id={"label.dataAnalysis.view.Query"}
                        />
                      ) : view ? (
                        analysisType ? (
                          <FormattedMessage
                            id={"label.dataAnalysis.view." + analysisType}
                          />
                        ) : (
                          <FormattedMessage id={"label.dataAnalysis.view"} />
                        )
                      ) : edit ? (
                        <FormattedMessage
                          id="label.dataAnalysis.edit-dataAnalysis"
                          defaultMessage="Edit Data Analysis"
                        />
                      ) : (
                        <FormattedMessage
                          id="label.dataAnalysis.create-dataAnalysis"
                          defaultMessage="Create a DataAnalysis"
                        />
                      )
                    }
                    extra={(() => {
                      let linkTo;
                      if (isQuery) {
                        return (
                          <Button type={"link"} onClick={handleBackFromQuery}>
                            <FormattedMessage
                              id="label.return"
                              defaultMessage="Return"
                            />
                          </Button>
                        );
                      }
                      if (isConfig && !isQuery)
                        linkTo = "/configuration/dataAnalysis";
                      if (isTimeSeries) linkTo = "/analysis/time-series";
                      if (isBarPlots) linkTo = "/analysis/bar-plots";
                      if (isHeatMaps) linkTo = "/analysis/heat-maps";
                      if (isPointMaps) linkTo = "/analysis/point-maps";
                      if (isChoroplethMaps)
                        linkTo = "/analysis/choropleth-maps";
                      if (isBubbleMaps) linkTo = "/analysis/bubble-maps";
                      return (
                        <Link to={{ pathname: linkTo }}>
                          <FormattedMessage
                            id="label.return"
                            defaultMessage="Return"
                          />
                        </Link>
                      );
                    })()}
                  >
                    {
                      //plot
                      !isQuery &&
                        view &&
                        analysisRawData?.data?.length > 0 &&
                        !!analysisType && (
                          <Suspense fallback={<Skeleton active={true} />}>
                            <Row gutter={16} style={{ marginBottom: "20px" }}>
                              <Col span={24}>
                                <Card id={"plots"}>
                                  <Col
                                    style={{
                                      height:
                                        analysisType === "BarPlot"
                                          ? plotHeight - 25
                                          : plotHeight,
                                      padding: 0,
                                    }}
                                  >
                                    {plotAnalysis()}
                                  </Col>
                                </Card>
                              </Col>
                            </Row>
                          </Suspense>
                        )
                    }
                    {isNew ? suspenseNew : suspenseEdit}
                    {isConfig ? (
                      <div
                        hidden={!(view && !!analysisRawData?.query)}
                        id={"queryAnchor"}
                      >
                        <ReactQuill
                          theme="snow"
                          id={"editorMainQuery"}
                          value={valueMainQuery}
                          onChange={setValueMainQuery}
                          spellCheck={false}
                          modules={{ toolbar: null }}
                          format={null}
                          style={{ width: "100%" }}
                          ref={mainQueryRef}
                        />
                        {[
                          "CategoricalPointMap",
                          "SimplePointMap",
                          "ChoroplethMap",
                          "HeatMap",
                          "BubbleMap",
                        ].includes(analysisType) ? (
                          <ReactQuill
                            theme="snow"
                            id={"editorPopupQuery"}
                            value={valuePopupQuery}
                            onChange={setValuePopupQuery}
                            spellCheck={false}
                            modules={{ toolbar: null }}
                            format={null}
                            style={{ width: "100%" }}
                            ref={popupQueryRef}
                          />
                        ) : null}
                      </div>
                    ) : null}
                  </Card>
                </div>
                {edit && !view ? (
                  <div
                    id={"query-bar"}
                    style={{
                      position: "fixed",
                      bottom: "25px",
                      right: "40px",
                      border: "1px solid #e6e6ff",
                      backgroundColor: "#fafafa",
                      borderRadius: "6px",
                    }}
                  >
                    <Button
                      icon={<EyeTwoTone twoToneColor="#364fc7" />}
                      title={intl.formatMessage({
                        id: "label.dataAnalysis.show-query",
                      })}
                      style={{
                        margin: "10px 10px 10px 10px",
                        borderRadius: "6px",
                      }}
                      onClick={handleShowQuery}
                    />
                  </div>
                ) : null}
              </>
            ) : null}
          </Content>
          <Footer style={{ textAlign: "center" }}>
            ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </DataAnalysisFormContext.Provider>
    </StrictMode>
  );
};

const mapStateToProps = (state) => ({
  loading: selectLoading(state),
  error: selectError(state),
  dataAnalysis: selectCurrentDataAnalysis(state),
  geoArea: selectGeoArea(state),
  surveyForms: selectSurveyForms(state),
  formFields: selectFormFields(state),
  projects: selectProjects(state),
});

export default connect(mapStateToProps, {
  addDataAnalysis,
  getDataAnalysis,
  editDataAnalysis,
  clearDataAnalysisError,
  getUserGeoArea,
  listSurveyForms,
  getSurveyForm,
  listFormFields,
  listProjects,
})(DataAnalysisForm);
