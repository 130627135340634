import axiosInstance from "../../utils/axiosInstance";
import {PERMISSION_ACTION_TYPES} from "../typesAction";


export const setLoading = () => {
    return {type: PERMISSION_ACTION_TYPES.LOAD_PERMISSION}
};

export const clearLoading = () => {
    return {type: PERMISSION_ACTION_TYPES.CLEAR_LOAD_PERMISSION}
};

export const clearPermissionError = () => {
    return {type: PERMISSION_ACTION_TYPES.CLEAR_PERMISSION_ERRORS}
};

export const listPermissions = (history) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).get('/permission');
        // Update state
        dispatch({
            type: PERMISSION_ACTION_TYPES.LIST_PERMISSION,
            payload: data
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: PERMISSION_ACTION_TYPES.ERROR_PERMISSION,
            payload: err.message
        });
    }
};
