import {
  Breadcrumb,
  Layout,
  message,
  Table,
  Popconfirm,
  Dropdown,
  Menu,
  Card,
} from "antd";
import {
  HomeOutlined,
  DeleteTwoTone,
  EditTwoTone,
  DatabaseTwoTone,
  ShareAltOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import columnSearchProps from "../columnSearchProps";
import { connect, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import {
  selectLoading,
  selectErrorForm,
  selectSurveyForms,
} from "../../redux/survey/form/surveyFormSelector";

import {
  listSurveyForms,
  deleteSurveyForm,
  clearSurveyFormError,
  getSurveyForm,
  clearLoading,
  addSurveyForm,
} from "../../redux/survey/form/surveyFormActions";

import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "./SurveyFormsStyles.css";

import { ImArrowUpRight2, ImArrowDownRight2 } from "react-icons/im";

//https://www.npmjs.com/package/write-excel-file
import writeXlsxFile from "write-excel-file";

import { FORM_FIELD_TYPES } from "../../utils/formTypes";
import { selectCurrentUser } from "../../redux/user/userSelector";
import { selectIsAuthenticated } from "../../redux/auth/authSelector";

const { Content, Footer } = Layout;

const SurveyForms = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  //local states for SurveyFormForm as being in the role of FormTemplateForm
  const regExpFormTemplates = RegExp("^/forms/templates.*$", "g");
  const isFormTemplate = regExpFormTemplates.test(props.location.pathname);
  const regExpConfigurationFormTemplates = RegExp(
    "^/form-templates/templates.*$",
    "g"
  );
  const isConfigurationFormTemplates = regExpConfigurationFormTemplates.test(
    props.location.pathname
  );
  const entityUrl =
    isFormTemplate || isConfigurationFormTemplates ? "formTemplate" : "form";

  const { forms, count, max, offset } = props.forms;

  const formView = props.match.path === "/forms";
  const weatherView = props.match.path === "/weather";

  //defining columns of table
  const allColumns = {
    id: {
      title: intl.formatMessage({ id: "label.id" }),
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "10%",
      fixed: true,
      sorter: (a, b) => a.id - b.id,
      sortDirections: ["ascend", "descend"],
    },
    actionsForm: {
      title: intl.formatMessage({ id: "label.actions" }),
      dataIndex: "actions",
      align: "center",
      width: "10%",
      fixed: true,
      render: (_, record) =>
        count >= 1 ? (
          <div className="actionsIcons">
            {formView &&
            (nonAPIForm(record) || checkPermissions("ROLE_ADMIN")) ? (
              <>
                <Popconfirm
                  title={intl.formatMessage({ id: "msg.confirm-delete" })}
                  onConfirm={() => handleDelete(record.id)}
                >
                  <DeleteTwoTone
                    twoToneColor="red"
                    title={
                      intl.formatMessage({ id: "title.label.actions.remove" }) +
                      " " +
                      intl.formatMessage({ id: "title.label.actions.form" })
                    }
                  />
                </Popconfirm>
                <Link to={`/forms/${record.id}`}>
                  <EditTwoTone
                    title={
                      intl.formatMessage({ id: "title.label.actions.edit" }) +
                      " " +
                      intl.formatMessage({ id: "title.label.actions.form" })
                    }
                  />
                </Link>
              </>
            ) : (
              <></>
            )}
            <Link to={`/forms/${record.id}/data`}>
              <DatabaseTwoTone
                twoToneColor="#52c41a"
                title={
                  intl.formatMessage({ id: "title.label.actions.view" }) +
                  " " +
                  intl.formatMessage({ id: "title.label.actions.data.records" })
                }
              />
            </Link>
            {!weatherView &&
            (nonAPIForm(record) || checkPermissions("ROLE_ADMIN")) ? (
              <>
                <Popconfirm
                  title={intl.formatMessage({ id: "label.confirm-export" })}
                  onConfirm={() => handleExport(record.id)}
                >
                  <ImArrowUpRight2
                    color="orange"
                    title={
                      intl.formatMessage({ id: "title.label.actions.export" }) +
                      " " +
                      intl.formatMessage({ id: "title.label.actions.form" })
                    }
                  />
                </Popconfirm>
                <Popconfirm
                  title={intl.formatMessage({ id: "label.confirm-share" })}
                  onConfirm={() => handleShare(record.id)}
                >
                  <ShareAltOutlined
                    style={{ color: "green" }}
                    title={
                      intl.formatMessage({ id: "title.label.actions.share" }) +
                      " " +
                      intl.formatMessage({ id: "title.label.actions.form" })
                    }
                  />
                </Popconfirm>
                <Popconfirm
                  title={intl.formatMessage({
                    id: "label.confirm-export-xlsx-template",
                  })}
                  onConfirm={() => handleExportTemplate(record.id)}
                >
                  <ImArrowDownRight2
                    color="lime"
                    title={
                      intl.formatMessage({ id: "title.label.actions.export" }) +
                      " " +
                      intl.formatMessage({
                        id: "title.label.actions.form.export-xlsx-template",
                      })
                    }
                  />
                </Popconfirm>
              </>
            ) : (
              <></>
            )}
          </div>
        ) : null,
    },
    actionsFormTemplate: {
      title: intl.formatMessage({ id: "label.actions" }),
      dataIndex: "actions",
      align: "center",
      width: "10%",
      fixed: true,
      render: (_, record) =>
        count >= 1 ? (
          <div className="actionsIcons">
            {isConfigurationFormTemplates ? (
              <>
                <Popconfirm
                  title={intl.formatMessage({ id: "msg.confirm-delete" })}
                  onConfirm={() => handleDelete(record.id)}
                >
                  <DeleteTwoTone
                    twoToneColor="red"
                    title={
                      intl.formatMessage({ id: "title.label.actions.remove" }) +
                      " " +
                      intl.formatMessage({ id: "title.label.actions.template" })
                    }
                  />
                </Popconfirm>
                <Link to={`/form-templates/templates/${record.id}`}>
                  <EditTwoTone
                    title={
                      intl.formatMessage({ id: "title.label.actions.edit" }) +
                      " " +
                      intl.formatMessage({ id: "title.label.actions.template" })
                    }
                  />
                </Link>
              </>
            ) : null}
            <EyeTwoTone
              twoToneColor="green"
              onClick={() => handleView(record.id)}
              title={
                intl.formatMessage({ id: "title.label.actions.show" }) +
                " " +
                intl.formatMessage({ id: "title.label.actions.template" })
              }
            />
            {isConfigurationFormTemplates ? null : (
              <Popconfirm
                title={intl.formatMessage({ id: "label.confirm-import" })}
                onConfirm={() => handleImport(record.id)}
              >
                <ImArrowDownRight2
                  color="orange"
                  title={
                    intl.formatMessage({ id: "title.label.actions.import" }) +
                    " " +
                    intl.formatMessage({ id: "title.label.actions.template" })
                  }
                />
              </Popconfirm>
            )}
          </div>
        ) : null,
    },
    name: {
      title: intl.formatMessage({ id: "label.name" }),
      dataIndex: "name",
      key: "name",
      width: "20%",
      fixed: false,
      sorter: (a, b) => a.title - b.title,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      ...columnSearchProps("name"),
    },
    project: {
      title: intl.formatMessage({ id: "label.project" }),
      dataIndex: "projectName",
      key: "projectName",
      width: "45%",
      fixed: false,
      sorter: (a, b) => a.projectName - b.projectName,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      ...columnSearchProps("projectName"),
    },
    status: {
      title: intl.formatMessage({ id: "label.status" }),
      dataIndex: "status",
      key: "status",
      width: "20%",
      fixed: false,
      sorter: (a, b) => a.status - b.status,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      filters: [
        {
          text: intl.formatMessage({ id: "label.draft" }),
          value: 0,
        },
        {
          text: intl.formatMessage({ id: "label.published" }),
          value: 1,
        },
        {
          text: intl.formatMessage({ id: "label.hidden" }),
          value: 2,
        },
      ],
      render: (text) => {
        switch (text) {
          case 0:
            return intl.formatMessage({ id: "label.draft" });
          case 1:
            return intl.formatMessage({ id: "label.published" });
          case 2:
            return intl.formatMessage({ id: "label.hidden" });
          default:
            return intl.formatMessage({ id: "label.draft" });
        }
      },
    },
    description: {
      title: intl.formatMessage({ id: "label.description" }),
      dataIndex: "description",
      key: "description",
      width: "40%",
      fixed: false,
      sorter: (a, b) => a.title - b.title,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      ...columnSearchProps("description"),
      render: (text, record, index) => {
        return <ReactQuill value={text} readOnly={true} theme={"bubble"} />;
      },
    },
    language: {
      title: intl.formatMessage({ id: "label.language" }),
      dataIndex: "language",
      key: "language",
      width: "20%",
      fixed: false,
      sorter: (a, b) => a.title - b.title,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      ...columnSearchProps("language"),
      render: (text) => {
        switch (text) {
          case "EN":
            return intl.formatMessage({ id: "label.EN" });
          case "ES":
            return intl.formatMessage({ id: "label.ES" });
          default:
            return null;
        }
      },
    },
  };

  const columnsForms = [
    allColumns.id,
    allColumns.actionsForm,
    allColumns.name,
    allColumns.project,
    allColumns.status,
  ];
  const columnsFormTemplates = [
    allColumns.id,
    allColumns.actionsFormTemplate,
    allColumns.name,
    allColumns.description,
    allColumns.language,
  ];
  const columns = isConfigurationFormTemplates
    ? columnsFormTemplates
    : isFormTemplate
    ? columnsFormTemplates
    : columnsForms;

  const fixToWeather = (filters) => {
    // This is default name for the weather form
    if (weatherView) {
      filters["nameNormalized"] = ["api_open_weather_data"];
      filters["status"] = [2];
    }
    return filters;
  };

  const checkPermissions = (requiredPermission) => {
    return (
      props.isAuthenticated &&
      requiredPermission &&
      props.currentUser &&
      props.currentUser?.permissions?.find(
        (permission) => permission === requiredPermission
      )
    );
  };

  const nonAPIForm = (record) => {
    // Forms with 'api_' are not open to be edited/deleted
    return !record?.nameNormalized?.includes("api_");
  };

  useEffect(() => {
    if (props.error) {
      const error = () => {
        if (props.error.error) {
          message.error(props.error).then(props.clearSurveyFormError());
        }
      };
      error();
    } else {
      props.listSurveyForms(
        props.history,
        undefined,
        fixToWeather({}),
        undefined,
        entityUrl,
        formView
      );
    }
    // eslint-disable-next-line
  }, [props.error, entityUrl, formView, weatherView]);

  const handleChange = (pagination, filters, sorter) => {
    props.listSurveyForms(
      props.history,
      pagination,
      fixToWeather(filters),
      sorter,
      entityUrl,
      formView
    );
  };

  const handleDelete = (id) => {
    props.deleteSurveyForm(props.history, id, entityUrl);
  };

  //code for handleExport
  //---------------------
  const handleExport = (id) => {
    props.getSurveyForm(props.history, id, "form").then(async (exportJSON) => {
      const fileName = exportJSON?.name;
      const json = JSON.stringify(exportJSON);
      const blob = new Blob([json], { type: "application/json" });
      const href = await URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = fileName + ".json";
      document.body.appendChild(link);
      await link.click();
      document.body.removeChild(link);
      dispatch(clearLoading());
    });
  };

  //code for handleShare
  //--------------------
  const [surveyFormShared, setSurveyFormShared] = useState(false);
  const handleShare = (id) => {
    const formToShare = {
      ...props.forms.forms.filter((form) => form.id === id)[0],
    };
    delete formToShare["id"];
    delete formToShare["_links"];
    delete formToShare["nameNormalized"];
    delete formToShare["project"];
    props.addSurveyForm(
      props.history,
      formToShare,
      setSurveyFormShared,
      "formTemplate"
    );
  };
  useEffect(() => {
    if (surveyFormShared) {
      message.info(intl.formatMessage({ id: "label.surveyForm-shared" }));
      setSurveyFormShared(false);
    }
    //eslint-disable-next-line
  }, [surveyFormShared]);

  //code for handleView
  //------------------------
  const handleView = (id) => {
    const location = {
      pathname: isConfigurationFormTemplates
        ? `/form-templates/templates/${id}`
        : `/forms/templates/${id}`,
      state: {
        isTemplateView: true,
      },
    };
    props.history.push(location);
  };

  //code for handleImport
  //---------------------------
  const handleImport = (id) => {
    const location = {
      pathname: "/forms/template",
      state: {
        templateId: id,
      },
    };
    props.history.push(location);
  };

  //code for handleExportTemplate
  //-----------------------------
  const handleExportTemplate = (id) => {
    props.getSurveyForm(props.history, id, "form").then(async (exportXlsx) => {
      const fileName = "template_" + exportXlsx?.name + ".xlsx";

      const formFields = exportXlsx.configuration.formFields;
      const formNamesInFormFields = formFields.map(
        (formField) => formField.name
      );
      const columnFormNames = exportXlsx.dataSchemas[0].columnFormNames;
      const selectedColumnFormNames = columnFormNames.filter(
        (columnFormName) =>
          ![...formNamesInFormFields, "Last Updated"].includes(columnFormName)
      );

      const QB_backgroundColor = "#ffff00";
      const non_QB_backgroundColor = "#40ff00";

      const columnMinWidth = 10;
      let HEADER_ROW = [];
      let columnsStyle = [];
      selectedColumnFormNames.forEach((item) => {
        HEADER_ROW.push({
          value: item,
          fontWeight: "bold",
          align: "center",
          backgroundColor: non_QB_backgroundColor,
        });

        columnsStyle.push({ width: Math.max(columnMinWidth, item.length + 2) });
      });

      formFields.forEach((formField) => {
        switch (formField.type) {
          case FORM_FIELD_TYPES.QUESTION_BLOCK:
            const formFieldsQB = formField.formFields;
            formFieldsQB.forEach((formFieldQB) => {
              HEADER_ROW.push({
                value: formFieldQB.name,
                fontWeight: "bold",
                align: "center",
                backgroundColor: QB_backgroundColor,
              });

              columnsStyle.push({
                width: Math.max(columnMinWidth, formFieldQB.name.length + 2),
              });
            });
            break;
          default:
            HEADER_ROW.push({
              value: formField.name,
              fontWeight: "bold",
              align: "center",
              backgroundColor: non_QB_backgroundColor,
            });

            columnsStyle.push({
              width: Math.max(columnMinWidth, formField.name.length + 2),
            });
        }
      });

      const data = [HEADER_ROW];

      const columns = columnsStyle;

      await writeXlsxFile(data, {
        columns,
        fileName: fileName,
        sheet: "data",
        stickyRowsCount: 1,
        stickyColumnsCount: 1,
      });
      dispatch(clearLoading());
    });
  };

  //jsx element
  return (
    <Layout className="site-layout">
      <Content style={{ margin: "0 16px" }}>
        <Breadcrumb style={{ margin: "10px 0" }}>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          {isConfigurationFormTemplates ? (
            <></>
          ) : (
            <Breadcrumb.Item>
              <span>
                {formView ? (
                  <FormattedMessage id="menu.forms" defaultMessage="Forms" />
                ) : (
                  <FormattedMessage id="label.data" defaultMessage="Data" />
                )}
              </span>
            </Breadcrumb.Item>
          )}
          {isConfigurationFormTemplates || isFormTemplate ? (
            <Breadcrumb.Item>
              <span>
                <FormattedMessage
                  id="menu.formTemplates"
                  defaultMessage="Form Templates"
                />
              </span>
            </Breadcrumb.Item>
          ) : null}
        </Breadcrumb>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <Card
            type={"inner"}
            title={
              isConfigurationFormTemplates ? (
                <Dropdown.Button
                  type="primary"
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <Link to={"/form-templates/templates/file"}>
                          <FormattedMessage
                            id="label.from-file"
                            defaultMessage="From File"
                          />
                        </Link>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Link to="/form-templates/templates/new">
                    <FormattedMessage
                      id="label.add-template"
                      defaultMessage="Add a Form Template"
                    />
                  </Link>
                </Dropdown.Button>
              ) : isFormTemplate ? (
                <FormattedMessage
                  id="label.view.import-template"
                  defaultMessage="View/Import Form Template"
                />
              ) : formView ? (
                <Dropdown.Button
                  type="primary"
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <Link to={"/forms/file"}>
                          <FormattedMessage
                            id="label.from-file"
                            defaultMessage="From File"
                          />
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <Link to={`/forms/templates`}>
                          <FormattedMessage
                            id="label.from-template"
                            defaultMessage="From Template"
                          />
                        </Link>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Link to="/forms/new">
                    <FormattedMessage
                      id="label.add-surveyForm"
                      defaultMessage="Add a Form"
                    />
                  </Link>
                </Dropdown.Button>
              ) : (
                <span />
              )
            }
            extra={
              isConfigurationFormTemplates ? null : isFormTemplate ? (
                <Link to="/forms">
                  <FormattedMessage id="label.return" defaultMessage="Return" />
                </Link>
              ) : null
            }
          >
            <Table
              bordered={true}
              loading={props.loading}
              rowKey={(record) => record.id}
              responsive={true}
              pagination={{
                current: offset / max + 1,
                showLessItems: true,
                pageSizeOptions: [10, 20, 50, 100],
                pageSize: max,
                defaultPageSize: max,
                total: count,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  intl.formatMessage(
                    {
                      id: "label.range-total",
                    },
                    { one: range[0], two: range[1], total: total }
                  ),
              }}
              size={"default"}
              showHeader
              columns={columns}
              dataSource={forms}
              scroll={{ y: "65vh", x: "100vw" }}
              onChange={handleChange}
            />
          </Card>
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  loading: selectLoading(state),
  error: selectErrorForm(state),
  forms: selectSurveyForms(state),
  currentUser: selectCurrentUser(state),
  isAuthenticated: selectIsAuthenticated(state),
});

export default connect(mapStateToProps, {
  listSurveyForms,
  deleteSurveyForm,
  clearSurveyFormError,
  getSurveyForm,
  addSurveyForm,
})(SurveyForms);
