import {Form, InputNumber} from "antd";
import React from "react";
import {useIntl} from "react-intl";


export const GeometryAccuracy = (props) => {

    const {
        formItemName,
        initialValue,
        hidden,
        dependencies,
        style
    } = props;

    const intl = useIntl();

    //jsx
    return (
        <Form.Item
            label={hidden ? null : intl.formatMessage({id: "label.MapOptions.GeometryAccuracy"})}
            name={formItemName}
            initialValue={initialValue ? initialValue : 0.0001}
            rules={[{
                required: true,
                message: intl.formatMessage({id: "msg.input-required.MapOptions.GeometryAccuracy"})}]}
            dependencies={dependencies}
            style={
                {
                    height: hidden ? 0 : null,
                    margin: hidden ? 0 : null,
                    display: style?.display
                }
            }
        >
            <InputNumber
                placeholder={intl.formatMessage({id: "label.MapOptions.GeometryAccuracy.InputNumber.placeholder"})}
                maxLength={15}
                style={{width: hidden ? 0 : '50%', margin: hidden ? 0 : null}}
                stringMode={true}
                hidden={hidden}
            />
        </Form.Item>
    )
};

export default GeometryAccuracy;
