import {
    Form,
    Input,
    InputNumber,
    DatePicker,
    TimePicker,
    Switch,
    Checkbox,
    Radio,
    Select,
    message,
    Breadcrumb,
    Layout,
    Card,
    Button, Col, Row, Modal, Divider, Typography, Tabs
} from 'antd';

import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    getFormConfiguration, addFormData, getFormData, editFormData, clearFormDataError, clearLoading
} from '../../redux/survey/data/formDataActions';
import {HomeOutlined, MinusCircleOutlined, PlusOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import moment from 'moment';
import {
    selectLoading,
    selectError,
    selectCurrentFormData, selectFormConfiguration
} from "../../redux/survey/data/formDataSelector";
import {FORM_FIELD_TYPES} from '../../utils/formTypes'
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as turf from "@turf/turf";
import {selectGeoArea} from "../../redux/user/userSelector";
import {getUserGeoArea} from "../../utils/utilData";
import "./SurveyFormsDataStyles.css";
import {getLayer, listLayers} from "../../redux/layer/layerActions";
import StylesControlByRadio
    from "../../utils/dataAnalysis/Plots/CustomMapboxControls/StylesControl/StylesControlByRadio";

import {getPopupDOMContentForPointsOfInterest} from "./getPopupDOMContentForPointsOfInterest";
import UUIDGenerator from "./elements/UUIDGenerator";
import GoogleMapReact from "google-map-react";

const {TextArea} = Input;
const CheckboxGroup = Checkbox.Group;
const {Content, Footer} = Layout;
const {Title} = Typography
const optionsValuesStylesControl = [
    "streets",
    "satellite-streets"
]
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

const SurveyFormsDataForm = (props) => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const mapContainerRef = useRef();
    // const [googleMap, setGoogleMap] = useState(null);
    // const [googleMaps, setGoogleMaps] = useState(null);
    const [lng, setLng] = useState(props.geoArea != null ? props.geoArea.lon : null);
    const [lat, setLat] = useState(props.geoArea != null ? props.geoArea.lat : null);
    const [layers, setLayers] = useState(null);
    const [mapStyle] = useState({
        label: 'Satellite streets',
        value: 'satellite-streets',
        url: "mapbox://styles/mapbox/satellite-streets-v11"
    });
    const [stylesControl, setStylesControl] = useState(new StylesControlByRadio(optionsValuesStylesControl, mapStyle, null, null));

    const formId = props.match.params.id;
    const id = props.match.params.data_id;

    const [formDataCreated, setSurveyFormsDataCreated] = useState(false);
    const [edit, setEdit] = useState(props.location.pathname !== `/forms/${formId}/data/new`);

    const [form] = Form.useForm();
    const [fields, setFields] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const onFinish = (values) => {
        const formFields = props.formConfiguration.formFields;
        for (let i = 0; i < formFields.length; i++) {
            const fieldKey = formFields[i].nameNormalized
            if (values[fieldKey]) {
                switch (formFields[i].type) {
                    case FORM_FIELD_TYPES.DATE:
                        values[fieldKey] = values[fieldKey].format('YYYY-MM-DD HH:mm:ss');
                        break
                    case FORM_FIELD_TYPES.DATETIME:
                        values[fieldKey] = values[fieldKey].format('YYYY-MM-DD HH:mm:ss');
                        break
                    case FORM_FIELD_TYPES.TIME:
                        values[fieldKey] = values[fieldKey].format('YYYY-MM-DD HH:mm:ss');
                        break
                    case FORM_FIELD_TYPES.CHECKBOX_GROUP:
                        if (typeof values[fieldKey] == "string")
                            values[fieldKey] = [values[fieldKey]];
                        break
                    case FORM_FIELD_TYPES.RADIO_GROUP:
                        if (typeof values[fieldKey] == "string")
                            values[fieldKey] = [values[fieldKey]];
                        break
                    case FORM_FIELD_TYPES.LIST:
                        if (typeof values[fieldKey] == "string")
                            values[fieldKey] = [values[fieldKey]];
                        break
                    case FORM_FIELD_TYPES.PHOTO:
                        let img = document.getElementById(fieldKey);
                        if (img && typeof img.src == "string"){
                            values[fieldKey] = img.src;
                        }
                        break
                    case FORM_FIELD_TYPES.QUESTION_BLOCK:
                        const formFieldsQB = formFields[i].formFields;
                        for (let j = 0; j < formFieldsQB.length; j++) {
                            const fieldKeyQB = formFieldsQB[j].nameNormalized
                            for (let valuesQB in values[fieldKey]) {
                                let fieldValueQB = values[fieldKey][valuesQB];
                                for (let valuesKeyQB in fieldValueQB) {
                                    if (valuesKeyQB === fieldKeyQB && fieldValueQB[valuesKeyQB]) {
                                        switch (formFieldsQB[j].type) {
                                            case FORM_FIELD_TYPES.DATE:
                                                fieldValueQB[valuesKeyQB] = fieldValueQB[valuesKeyQB].format('YYYY-MM-DD HH:mm:ss');
                                                break
                                            case FORM_FIELD_TYPES.DATETIME:
                                                fieldValueQB[valuesKeyQB] = fieldValueQB[valuesKeyQB].format('YYYY-MM-DD HH:mm:ss');
                                                break
                                            case FORM_FIELD_TYPES.TIME:
                                                fieldValueQB[valuesKeyQB] = fieldValueQB[valuesKeyQB].format('YYYY-MM-DD HH:mm:ss');
                                                break
                                            case FORM_FIELD_TYPES.CHECKBOX_GROUP:
                                                if (typeof fieldValueQB[valuesKeyQB] == "string")
                                                    fieldValueQB[valuesKeyQB] = [fieldValueQB[valuesKeyQB]];
                                                break
                                            case FORM_FIELD_TYPES.RADIO_GROUP:
                                                if (typeof fieldValueQB[valuesKeyQB] == "string")
                                                    fieldValueQB[valuesKeyQB] = [fieldValueQB[valuesKeyQB]];
                                                break
                                            case FORM_FIELD_TYPES.LIST:
                                                if (typeof fieldValueQB[valuesKeyQB] == "string")
                                                    fieldValueQB[valuesKeyQB] = [fieldValueQB[valuesKeyQB]];
                                                break
                                            case FORM_FIELD_TYPES.PHOTO:
                                                let img = document.getElementById(valuesQB+"_"+valuesKeyQB);
                                                if (img && typeof img.src == "string"){
                                                    fieldValueQB[valuesKeyQB] = img.src;
                                                }
                                                break
                                            default:
                                                break
                                        }
                                    }
                                }

                            }

                        }
                        break
                    default:
                        break
                }
            }

        }
        if (edit) {
            form.resetFields();
            props.editFormData(props.history, formId, id, values, setSurveyFormsDataCreated).then(() => {
                props.history.push(`/forms/${formId}/data`);
            });
        } else {
            props.addFormData(props.history, values, setSurveyFormsDataCreated, formId);
        }
    };


    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const showLocationModal = () => {
        setIsModalVisible(true);
    };

    const handleModalLocationOk = () => {
        setIsModalVisible(false);
    };

    const handleModalLocationCancel = () => {
        setIsModalVisible(false);
    };

    const handleApiLoaded = (map, maps, longitude, latitude) => {
        map.setMapTypeId('hybrid')
        let {lat, lon, geojson} = props.geoArea;
        const bounds = new maps.LatLngBounds();
        const bbox = turf.bbox(geojson);
        bounds.extend(new maps.LatLng(
            bbox[1],
            bbox[0],
        ));
        bounds.extend(new maps.LatLng(
            bbox[3],
            bbox[2],
        ));

        map.fitBounds(bounds, {padding: 20})

        const marker = new maps.Marker({
            position: new maps.LatLng(
                edit && latitude ? latitude : lat,
                edit && longitude ? longitude : lon
            ),
            map,
            title: 'Location',
            anchor: map,
            draggable: true,
            icon: {
                path: "M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z",
                fillColor: 'blue',
                fillOpacity: 1,
                strokeWeight: 0.3,
                scale: 0.8,
                anchor: new maps.Point(0, 20),
            },
        })
        maps.event.addListener(marker, 'dragend', function (marker) {
            const latLng = marker.latLng;
            form.setFieldsValue({
                'latitude': latLng.lat(),
                'longitude': latLng.lng()
            });
            setLng(latLng.lng());
            setLat(latLng.lat());
        });
        // setGoogleMaps(maps)
        // setGoogleMap(map)
    };

    function createMapOptions(maps) {
        return {
            zoomControlOptions: {
                style: maps.ZoomControlStyle.SMALL
            },
            mapTypeControlOptions: {
                position: maps.ControlPosition.TOP_RIGHT
            },
            mapTypeControl: true

        };
    }

    useEffect(() => {

        if (!layers) {
            let filters = {
                type: [1],
                form: [formId],
                active: [true]
            }
            let pagination = {
                current: 1,
                pageSize: 99
            }

            const getLayers = async () => {
                let layers = []
                let data = await props.listLayers(props.history, pagination, filters);
                for (const layer of data?.layers) {
                    let layerDetails = await props.getLayer(props.history, layer.id);
                    layers.push(layerDetails)
                }
                setLayers(layers);

            }
            getLayers()
        }

        if (props.geoArea === undefined || props.geoArea === null) {
            props.getUserGeoArea(props.history)
        } else {
            if (isModalVisible && mapContainerRef.current) {
                let {geojson} = props.geoArea;
                let map = new mapboxgl.Map({
                    container: mapContainerRef.current,
                    style: mapStyle.url,
                    center: [lng, lat],
                    zoom: 2
                });

                // Add navigation control (the +/- zoom buttons)
                map.addControl(new mapboxgl.NavigationControl(), 'top-right');
                map.addControl(new mapboxgl.FullscreenControl(), 'top-right');
                map.addControl(stylesControl, 'top-right');
                const geolocate = new mapboxgl.GeolocateControl({
                    positionOptions: {
                        enableHighAccuracy: true
                    },
                    trackUserLocation: true
                })
                map.addControl(geolocate);
                map.on('load', () => {
                    map.resize();
                    map.addSource('region', {type: 'geojson', data: geojson});
                    map.addLayer({
                        'id': 'region',
                        'type': 'line',
                        'source': 'region',
                        'layout': {
                            'visibility': 'visible'
                        },
                        'paint': {
                            'line-color': 'green',
                            'line-width': 2,
                        }
                    });

                    if (layers) {
                        const layerIds = []
                        map.loadImage('/configurable/contents/images/marker.png', (error, image) => {
                            if (error) throw error;
                            map.addImage('marker-icon', image, {'sdf': true});
                            for (const layer of layers) {
                                let pointsLayer = {
                                    'type': 'FeatureCollection',
                                    'features': []
                                }
                                layer.data.forEach(point => {
                                    pointsLayer['features'].push({
                                        'type': 'Feature',
                                        'properties': {
                                            'name': point.name,
                                            'description': point.description,
                                        },
                                        'geometry': {
                                            'type': 'Point',
                                            'coordinates': [point.lon, point.lat]
                                        }
                                    })
                                })
                                layerIds.push(`points-of-interest-${layer.id}`)
                                map.addLayer({
                                    'id': `points-of-interest-${layer.id}`,
                                    'type': 'symbol',
                                    'source': {
                                        'type': 'geojson',
                                        'data': pointsLayer
                                    },
                                    'layout': {
                                        "icon-image": "marker-icon",
                                        "icon-size": 2,
                                        'icon-allow-overlap': true
                                    },
                                    'paint': {
                                        'icon-color': layer.color,
                                        'icon-halo-color': 'black',
                                        'icon-halo-width': 7
                                    }
                                });

                                // Change the cursor to a pointer when the mouse is over the layer.
                                map.on('mouseenter', `points-of-interest-${layer.id}`, () => {
                                    map.getCanvas().style.cursor = 'pointer';
                                });

                                // Change it back to a pointer when it leaves.
                                map.on('mouseleave', `points-of-interest-${layer.id}`, () => {
                                    map.getCanvas().style.cursor = '';
                                });
                            }
                        });

                        const popup = new mapboxgl.Popup();
                        const optionDist = {
                            units: 'kilometers'
                        };
                        const optionDistMiles = {
                            units: 'miles'
                        };
                        map.on('click', (event) => {
                            const features = map.queryRenderedFeatures(event.point, {
                                layers: layerIds
                            });
                            if (!features.length) {
                                popup.remove();
                                return;
                            }

                            const feature = features[0];
                            let distance = turf.distance(feature.geometry.coordinates, [form.getFieldValue('longitude'), form.getFieldValue('latitude')], optionDist);
                            let distanceMiles = turf.distance(feature.geometry.coordinates, [form.getFieldValue('longitude'), form.getFieldValue('latitude')], optionDistMiles);
                            popup
                                .setLngLat(feature.geometry.coordinates)
                                .setDOMContent(getPopupDOMContentForPointsOfInterest(feature, distance, distanceMiles))
                                .addTo(map);
                        });
                    }

                    const marker = new mapboxgl.Marker({color: 'blue', draggable: true})
                        .setLngLat([lng, lat])
                        .addTo(map);

                    function onDragEnd() {
                        var lngLat = marker.getLngLat();
                        setLng(lngLat.lng);
                        setLat(lngLat.lat);
                        form.setFieldsValue({
                            'latitude': lngLat.lat,
                            'longitude': lngLat.lng
                        });
                    }

                    marker.on('dragend', onDragEnd);

                    geolocate.on('geolocate', function (e) {
                        let lon = e.coords.longitude;
                        let lat = e.coords.latitude;
                        var position = [lon, lat];
                        marker.setLngLat(position);
                        setLng(lng);
                        setLat(lat);
                        form.setFieldsValue({
                            'latitude': lat,
                            'longitude': lon
                        });
                    });

                    const bbox = turf.bbox(geojson);
                    map.fitBounds(bbox, {padding: 20});
                });

                return () => map.remove();
            }
        }


        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearFormDataError());
                } else if (props.error.hasOwnProperty('message')) {
                    message.error(props.error.message).then(props.clearFormDataError());
                } else {
                    props.error.errors.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearFormDataError())));
                }

            };
            error();
        }

        if (fields.length === 0) {
            props.getFormConfiguration(props.history, formId).then((formConfiguration) => {
                const formFields = formConfiguration.formFields;
                const newFields = [];
                for (let i = 0; i < formFields.length; i++) {
                    switch (formFields[i].type) {
                        case FORM_FIELD_TYPES.HEADING:
                            let textLevel = 1;
                            if (formFields[i].fontSize === 'medium')
                                textLevel = 2
                            else if (formFields[i].fontSize === 'small')
                                textLevel = 3
                            newFields.push(<Title level={textLevel}
                                                  key={formFields[i].nameNormalized}
                                                  name={formFields[i].nameNormalized}
                                                  title={formFields[i].name}
                                                  style={{textAlign: 'left'}}>{formFields[i].name}
                            </Title>)
                            break
                        case FORM_FIELD_TYPES.TEXT:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <Input/>
                            </Form.Item>)
                            break
                        case FORM_FIELD_TYPES.TEXT_AREA:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <TextArea allowClear/>
                            </Form.Item>)
                            break
                        case FORM_FIELD_TYPES.NUMBER_INTEGER:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <InputNumber/>
                            </Form.Item>)
                            break
                        case FORM_FIELD_TYPES.NUMBER_DECIMAL:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <InputNumber step="0.01"/>
                            </Form.Item>)
                            break
                        case FORM_FIELD_TYPES.DATE:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <DatePicker/>
                            </Form.Item>)
                            break
                        case FORM_FIELD_TYPES.DATETIME:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <DatePicker showTime/>
                            </Form.Item>)
                            break
                        case FORM_FIELD_TYPES.TIME:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <TimePicker/>
                            </Form.Item>)
                            break
                        case FORM_FIELD_TYPES.CHECKBOX:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren={<CheckOutlined/>}
                                    unCheckedChildren={<CloseOutlined/>}/>
                            </Form.Item>)
                            break
                        case FORM_FIELD_TYPES.CHECKBOX_GROUP:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <CheckboxGroup className="checkBoxGroup" options={formFields[i].optionValues.values}/>
                            </Form.Item>)
                            break
                        case FORM_FIELD_TYPES.RADIO_GROUP:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <Radio.Group className="radioButton" options={formFields[i].optionValues.values}/>
                            </Form.Item>)
                            break
                        case FORM_FIELD_TYPES.LIST:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <Select mode={formFields[i].multipleValues ? "multiple" : ""}
                                        options={formFields[i].optionValues.values.map((value) => ({
                                            label: value,
                                            value: value
                                        }))}/>
                            </Form.Item>)
                            break
                        case FORM_FIELD_TYPES.QUESTION_BLOCK:
                            newFields.push(
                                <Form.List name={formFields[i].nameNormalized} key={formFields[i].nameNormalized}>
                                    {(fields, {add, remove}) => (
                                        <>
                                            {fields.map(({key, name, fieldKey, ...restField}) => (
                                                <Card className="questionBlock" key={key}
                                                      title={`QB (${fieldKey + 1}): ${formFields[i].name}`}
                                                      extra={<MinusCircleOutlined onClick={() => remove(name)}/>}>
                                                    {createQB(formFields, i, restField, name, fieldKey, intl, form, formFields[i].nameNormalized)}
                                                </Card>
                                            ))}
                                            <Form.Item className="buttonBlock">
                                                <Button ref={simulateClick} type="dashed" onClick={() => add()} block
                                                        icon={<PlusOutlined/>}>
                                                    {formFields[i].name}
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>)
                            break
                        case FORM_FIELD_TYPES.PHOTO:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <div className='photoInput'>
                                    <Input type='file' multiple={false} onChange={(event) => {
                                        const imgElement = event.target.parentElement.lastChild;
                                        const archivo = event.target.files[0];
                                        const lector = new FileReader();
                                        
                                        lector.addEventListener("load", () => {
                                            // Actualizamos la vista previa de la imagen y el valor del input para después recuperarlo
                                            imgElement.setAttribute("src", lector.result);
                                            imgElement.setAttribute("alt", archivo.name);
                                            form.setFieldValue(imgElement.id, lector.result);
                                        }, imgElement, archivo);
                                        
                                        if (archivo) {
                                            // Leemos el archivo seleccionado como un URL de datos
                                            lector.readAsDataURL(archivo);
                                        }
                                    }}/>
                                    <img id={formFields[i].nameNormalized} className="imgPreview" src="" alt=""/>
                                </div>
                            </Form.Item>)
                        break
                        case FORM_FIELD_TYPES.UUID_GENERATOR:
                            form.getFieldsValue(formFields[i].nameNormalized)?.toString()
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <UUIDGenerator form={form} nameNormalized={formFields[i].nameNormalized}/>
                            </Form.Item>)
                            break
                        default:
                            newFields.push(<Form.Item
                                key={formFields[i].nameNormalized}
                                name={formFields[i].nameNormalized}
                                label={formFields[i].name}
                                rules={[{
                                    required: formFields[i].required,
                                    message: intl.formatMessage({id: 'msg.input-required'})
                                }]}
                            >
                                <Input/>
                            </Form.Item>)
                            break
                    }
                }
                setFields(newFields);
                dispatch(clearLoading())

            });
        }

        if (edit && !formDataCreated && props.formData === null && props.error === null) {
            props.getFormData(props.history, formId, id).then((data) => {
                if (data) {
                    let values = data.data;
                    setLat(data.data.latitude);
                    setLng(data.data.longitude);
                    const formFields = data.formConfiguration.formFields;
                    for (let i = 0; i < formFields.length; i++) {
                        const fieldKey = formFields[i].nameNormalized
                        if (values[fieldKey]) {
                            switch (formFields[i].type) {
                                case FORM_FIELD_TYPES.DATE:
                                    values[fieldKey] = moment(values[fieldKey]);
                                    break
                                case FORM_FIELD_TYPES.DATETIME:
                                    values[fieldKey] = moment(values[fieldKey]);
                                    break
                                case FORM_FIELD_TYPES.TIME:
                                    values[fieldKey] = moment(values[fieldKey]);
                                    break
                                case FORM_FIELD_TYPES.CHECKBOX:
                                    break
                                case FORM_FIELD_TYPES.CHECKBOX_GROUP:
                                    values[fieldKey] = JSON.parse(values[fieldKey].value);
                                    break
                                case FORM_FIELD_TYPES.RADIO_GROUP:
                                    const radioValue = JSON.parse(values[fieldKey].value)
                                    values[fieldKey] = radioValue != null && radioValue.length > 0 ? radioValue[0] : null;
                                    break
                                case FORM_FIELD_TYPES.LIST:
                                    values[fieldKey] = JSON.parse(values[fieldKey].value);
                                    break
                                case FORM_FIELD_TYPES.PHOTO:
                                    let img = document.getElementById(fieldKey);
                                    if (img){
                                        img.src = values[fieldKey];
                                    }
                                    break
                                case FORM_FIELD_TYPES.QUESTION_BLOCK:
                                    const formFieldsQB = formFields[i].formFields;
                                    for (let j = 0; j < formFieldsQB.length; j++) {
                                        const fieldKeyQB = formFieldsQB[j].nameNormalized
                                        for (let valuesQB in values[fieldKey]) {
                                            let fieldValueQB = values[fieldKey][valuesQB];
                                            for (let valuesKeyQB in fieldValueQB) {
                                                if (valuesKeyQB === fieldKeyQB && fieldValueQB[valuesKeyQB]) {
                                                    switch (formFieldsQB[j].type) {
                                                        case FORM_FIELD_TYPES.DATE:
                                                            fieldValueQB[valuesKeyQB] = moment(fieldValueQB[valuesKeyQB]);
                                                            break
                                                        case FORM_FIELD_TYPES.DATETIME:
                                                            fieldValueQB[valuesKeyQB] = moment();
                                                            break
                                                        case FORM_FIELD_TYPES.TIME:
                                                            fieldValueQB[valuesKeyQB] = moment(fieldValueQB[valuesKeyQB]);
                                                            break
                                                        case FORM_FIELD_TYPES.CHECKBOX_GROUP:
                                                            fieldValueQB[valuesKeyQB] = JSON.parse(fieldValueQB[valuesKeyQB].value);
                                                            break
                                                        case FORM_FIELD_TYPES.RADIO_GROUP:
                                                            const radioValue = JSON.parse(fieldValueQB[valuesKeyQB].value)
                                                            fieldValueQB[valuesKeyQB] = radioValue != null && radioValue.length > 0 ? radioValue[0] : null;
                                                            break
                                                        case FORM_FIELD_TYPES.LIST:
                                                            fieldValueQB[valuesKeyQB] = JSON.parse(fieldValueQB[valuesKeyQB].value);
                                                            break
                                                        case FORM_FIELD_TYPES.PHOTO:
                                                            let img = document.getElementById(valuesQB+"_"+valuesKeyQB);
                                                            if (img){
                                                                img.src = fieldValueQB[valuesKeyQB];
                                                            }
                                                            break
                                                        default:
                                                            break
                                                    }
                                                }
                                            }

                                        }

                                    }
                                    break
                                default:
                                    break
                            }
                        }

                    }
                    form.setFieldsValue(values);
                } else {
                    setEdit(false);
                }
            })
        }

        if (formDataCreated) {
            if (edit) {
                message.info(intl.formatMessage({id: 'label.record-updated'}));
            } else {
                form.resetFields();
                message.info(intl.formatMessage({id: 'label.record-created'}));
            }
            setSurveyFormsDataCreated(false);
        }
        // eslint-disable-next-line
    }, [props.error, props.geoArea, formDataCreated, edit, isModalVisible, stylesControl])


    useEffect(() => {
        setStylesControl(new StylesControlByRadio(optionsValuesStylesControl, mapStyle, null, null))
        //eslint-disable-next-line
    }, [mapStyle]);

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><Link to='/forms'><FormattedMessage id="menu.forms" defaultMessage="Forms"/></Link></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><Link to={`/forms/${formId}`}>{formId}</Link></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><Link to={`/forms/${formId}/data`}><FormattedMessage
                            id="label.data" defaultMessage="Data"/></Link></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{edit ? (<FormattedMessage
                            id="label.edit" defaultMessage="Edit"/>) : (
                            <FormattedMessage id="label.new" defaultMessage="New"/>)}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Card type="inner" loading={props.loading}
                          title={edit ?
                              <FormattedMessage id="label.edit-record" defaultMessage="Edit Record"/> :
                              <FormattedMessage id="label.create-record"
                                                defaultMessage="Create a Record"/>}
                          extra={<Link to={`/forms/${formId}/data`}><FormattedMessage id="label.return"
                                                                                      defaultMessage="Return"/></Link>}>
                        <Form layout="horizontal" name="record_form" size="large" labelAlign="right"
                              labelCol={{span: 24}} wrapperCol={{span: 24}}
                              initialValues={{active: true}} form={form}
                              onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Row gutter={16}>
                                <Col span={10}>
                                    <Form.Item
                                        name="latitude" initialValue={lat}
                                        label={intl.formatMessage({id: 'label.latitude'})}
                                        rules={[{
                                            required: true,
                                            message: intl.formatMessage({id: 'msg.input-required'})
                                        }]}

                                    >
                                        <Input type="number"/>
                                    </Form.Item></Col>
                                <Col span={10}>
                                    <Form.Item
                                        name="longitude" initialValue={lng}
                                        label={intl.formatMessage({id: 'label.longitude'})}
                                        rules={[{
                                            required: true,
                                            message: intl.formatMessage({id: 'msg.input-required'})
                                        }]}

                                    >
                                        <Input type="number"/>
                                    </Form.Item></Col>
                                <Col span={4}>
                                    <Button type="primary" size={"large"} onClick={showLocationModal}
                                            style={{marginTop: 50}}>
                                        <FormattedMessage id="label.location" defaultMessage="Location"/>
                                    </Button></Col>
                            </Row>
                            <Modal title={intl.formatMessage({id: 'label.location'})}
                                   open={isModalVisible}
                                   onOk={handleModalLocationOk} onCancel={handleModalLocationCancel} width={600}>
                                <Tabs
                                    type="card"
                                    items={[
                                        {
                                            label: `Mapbox`,
                                            key: 'mapboxTab',
                                            children: <div className="map-container" ref={mapContainerRef}
                                                           style={{height: 500}}/>,
                                        },
                                        {
                                            label: `GoogleMaps`,
                                            key: 'googleMapTab',
                                            children: <div style={{height: 500, width: '100%'}}>
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_KEY}}
                                                    defaultCenter={{
                                                        lat: 0,
                                                        lng: 0
                                                    }}
                                                    options={createMapOptions}
                                                    defaultZoom={9}
                                                    yesIWantToUseGoogleMapApiInternals
                                                    onGoogleApiLoaded={({
                                                                            map,
                                                                            maps
                                                                        }) => handleApiLoaded(map, maps, lng, lat)}
                                                >

                                                </GoogleMapReact>
                                            </div>,
                                        }
                                    ]}
                                />
                            </Modal>
                            <Divider/>
                            <Col className="formData">
                                {fields}
                            </Col>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading}>
                                    {edit ? (<FormattedMessage id="label.update" defaultMessage="Update"/>) : (
                                        <FormattedMessage id="label.submit" defaultMessage="Submit"/>)}

                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const createQB = (formFields, i, restField, name, fieldKey, intl, form, qbNameNormalized) => {
    const qbFormFields = formFields[i].formFields;
    let newFields = [];
    for (let j = 0; j < qbFormFields.length; j++) {
        switch (qbFormFields[j].type) {
            case FORM_FIELD_TYPES.HEADING:
                let textLevel = 1;
                if (formFields[i].fontSize === 'medium')
                    textLevel = 2
                else if (formFields[i].fontSize === 'small')
                    textLevel = 3
                newFields.push(<Title level={textLevel}
                                      {...restField}
                                      key={[fieldKey, qbFormFields[j].nameNormalized]}
                                      name={[name, qbFormFields[j].nameNormalized]}
                                      title={qbFormFields[j].name} style={{textAlign: 'left'}}>
                </Title>)
                break
            case FORM_FIELD_TYPES.TEXT:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >
                    <Input/>
                </Form.Item>)
                break
            case FORM_FIELD_TYPES.TEXT_AREA:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >
                    <TextArea allowClear/>
                </Form.Item>)
                break
            case FORM_FIELD_TYPES.NUMBER_INTEGER:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >

                    <InputNumber precision={0}/>
                    {/*<InputNumber />*/}
                </Form.Item>)
                break
            case FORM_FIELD_TYPES.NUMBER_DECIMAL:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >
                    <InputNumber step="0.01"/>
                </Form.Item>)
                break
            case FORM_FIELD_TYPES.DATE:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >
                    <DatePicker/>
                </Form.Item>)
                break
            case FORM_FIELD_TYPES.DATETIME:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >
                    <DatePicker showTime/>
                </Form.Item>)
                break
            case FORM_FIELD_TYPES.TIME:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >
                    <TimePicker/>
                </Form.Item>)
                break
            case FORM_FIELD_TYPES.CHECKBOX:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                    valuePropName="checked"
                >
                    <Switch
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}/>
                </Form.Item>)
                break
            case FORM_FIELD_TYPES.CHECKBOX_GROUP:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >
                    <CheckboxGroup className="checkBoxGroup" options={qbFormFields[j].optionValues.values}/>
                </Form.Item>)
                break
            case FORM_FIELD_TYPES.RADIO_GROUP:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >
                    <Radio.Group className="radioButton" options={qbFormFields[j].optionValues.values}/>
                </Form.Item>)
                break
            case FORM_FIELD_TYPES.LIST:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >
                    <Select mode={qbFormFields[j].multipleValues ? "multiple" : ""}
                            options={qbFormFields[j].optionValues.values.map((value) => ({
                                label: value,
                                value: value
                            }))}/>
                </Form.Item>)
                break
            case FORM_FIELD_TYPES.PHOTO:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >
                    <div className='photoInput'>                    
                        <Input type='file' multiple={false} onChange={(event) => {
                            const imgElement = event.target.parentElement.lastChild;
                            const archivo = event.target.files[0];
                            const lector = new FileReader();

                            lector.addEventListener("load", () => {
                                // Actualizamos la vista previa de la imagen y el src para después recuperarlo 
                                imgElement.setAttribute("src", lector.result);
                                imgElement.setAttribute("alt", archivo.name);
                                //form.setFieldValue(imgElement.id, lector.result);
                                //form.getFieldValue(qbNameNormalized)[0][imgElement.id] = lector.result;
                            }, qbNameNormalized, imgElement, archivo);
                            
                            if (archivo) {
                            // Leemos el archivo seleccionado como un URL de datos
                            lector.readAsDataURL(archivo);
                            }
                        }}/>
                        <img id={[fieldKey+"_"+qbFormFields[j].nameNormalized]} className="imgPreview" src="" alt=""/>
                    </div>
                </Form.Item>)
            break
            case FORM_FIELD_TYPES.UUID_GENERATOR:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >
                    <UUIDGenerator form={form} nameNormalized={qbFormFields[j].nameNormalized}
                                   qbNameNormalized={qbNameNormalized} fieldKey={fieldKey}/>
                </Form.Item>)
                break

            default:
                newFields.push(<Form.Item
                    {...restField}
                    key={[fieldKey, qbFormFields[j].nameNormalized]}
                    name={[name, qbFormFields[j].nameNormalized]}
                    label={qbFormFields[j].name}
                    rules={[{
                        required: qbFormFields[j].required,
                        message: intl.formatMessage({id: 'msg.input-required'})
                    }]}
                >
                    <Input/>
                </Form.Item>)
                break
        }
    }
    return newFields;
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    formData: selectCurrentFormData(state),
    formConfiguration: selectFormConfiguration(state),
    geoArea: selectGeoArea(state)
});

const simulateClick = (e) => {
    const buttonBlock = document.getElementsByClassName("buttonBlock");
    const questionBlock = document.getElementsByClassName("questionBlock");
    if (buttonBlock.length === 1 && questionBlock.length === 0) {
        try {
            e?.click();
        } catch (e) {
            console.log("Cannot open QB")
        }
    }
}

export default connect(mapStateToProps, {
    getFormConfiguration,
    addFormData,
    getFormData,
    editFormData,
    clearFormDataError,
    getUserGeoArea,
    listLayers,
    getLayer
})(SurveyFormsDataForm);
