import { createSelector } from "reselect";

const selectFormField = state => state.formField;

export const selectLoading = createSelector(
    [selectFormField],
    (formField) => formField.loading
);

export const selectError = createSelector(
    [selectFormField],
    (formField) => formField.error
);

export const selectFormFields = createSelector(
    [selectFormField],
    (formField) => formField.formFields
);
