import {CLEAR_LOADING, SET_LOADING, USER_ACTION_TYPES} from "../typesAction";
import axiosInstance from "../../utils/axiosInstance";

export const getCurrentUser = (history) => async dispatch => {
    try {
        dispatch(setAppLoading());
        const {data} = await axiosInstance(history, dispatch).get(`/user/get_profile`);

        dispatch({
            type: USER_ACTION_TYPES.GET_CURRENT_USER,
            payload: data
        });

    } catch (err) {
        console.log(err);
        dispatch({
            type: USER_ACTION_TYPES.ERROR_USER,
            payload: err.message
        });
    }
};

export const setAppLoading = () => {
    return {type: SET_LOADING}
};

export const clearAppLoading = () => {
    return {type: CLEAR_LOADING}
};

export const setLoading = () => {
    return {type: USER_ACTION_TYPES.LOAD_USER}
};

export const clearLoading = () => {
    return {type: USER_ACTION_TYPES.CLEAR_LOAD_USER}
};

export const clearUserError = () => {
    return {type: USER_ACTION_TYPES.CLEAR_USER_ERRORS}
};

export const listUsers = (history, pagination, filters = {}, sorter) => async dispatch => {
    try {
        dispatch(setLoading());
        // Prepare all query parameters
        let queryParameters = {}
        Object.keys(filters).map(key => (
            queryParameters[key] = filters[key] == null ? filters[key] : filters[key][0])
        );
        if (sorter !== undefined) {
            queryParameters['sort'] = sorter['field']
            queryParameters['order'] = sorter['order'] === 'descend' ? 'desc' : 'asc'
        }
        if (pagination !== undefined) {
            queryParameters['offset'] = (pagination.current - 1) * pagination.pageSize
            queryParameters['max'] = pagination.pageSize
        }
        // Spread the parameters
        const params = {
            ...queryParameters
        };
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).get('/user', {params});
        // Update state
        dispatch({
            type: USER_ACTION_TYPES.LIST_USER,
            payload: data
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: USER_ACTION_TYPES.ERROR_USER,
            payload: err.message
        });
    }
};

export const addUser = (history, user, setUserCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).post('/user', user);
        // Update state
        dispatch({
            type: USER_ACTION_TYPES.ADD_USER,
            payload: data
        });
        setUserCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: USER_ACTION_TYPES.ERROR_USER,
            payload: error
        });
    }
};

export const getUser = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        const {data} = await axiosInstance(history, dispatch).get(`/user/${id}`);
        // Update state
        dispatch({
            type: USER_ACTION_TYPES.SHOW_USER,
            payload: data
        });
        return data;
    } catch (err) {
        console.log(err);
        dispatch({
            type: USER_ACTION_TYPES.ERROR_USER,
            payload: err.message
        });
    }
};

export const editUser = (history, id, user, setUserCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).patch(`/user/${id}`, user);
        // Update state
        dispatch({
            type: USER_ACTION_TYPES.EDIT_USER,
            payload: data
        });
        setUserCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: USER_ACTION_TYPES.ERROR_USER,
            payload: error
        });
    }
};

export const deleteUser = (history, id) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request
        await axiosInstance(history, dispatch).delete(`/user/${id}`);
        // Update state
        dispatch({
            type: USER_ACTION_TYPES.DELETE_USER,
            payload: id
        });
    } catch (err) {
        console.log(err);
        dispatch({
            type: USER_ACTION_TYPES.ERROR_USER,
            payload: err.message
        });
    }
};


export const updateProfile = (history, user, setUserCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).patch(`/user/update_profile`, user);
        // Update state
        dispatch({
            type: USER_ACTION_TYPES.GET_CURRENT_USER,
            payload: data
        });
        setUserCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: USER_ACTION_TYPES.ERROR_USER,
            payload: error
        });
    }
};
export const updatePassword = (history, user, setUserCreated) => async dispatch => {
    try {
        dispatch(setLoading());
        // Send request and get data
        const {data} = await axiosInstance(history, dispatch).patch(`/user/update_password`, user);
        // Update state
        dispatch({
            type: USER_ACTION_TYPES.GET_CURRENT_USER,
            payload: data
        });
        setUserCreated(true);
    } catch (err) {
        console.log(err);
        const error = await err.response.data;
        dispatch({
            type: USER_ACTION_TYPES.ERROR_USER,
            payload: error
        });
    }
};