import {LAYER_ACTION_TYPES} from "../typesAction";


const initialState = {
    layers: [],
    current: null,
    loading: false,
    error: null
}

const layerReducer = (state = initialState, action) => {
    switch (action.type) {
        case LAYER_ACTION_TYPES.LOAD_LAYER:
            return {
                ...state, loading: true
            }
        case LAYER_ACTION_TYPES.CLEAR_LOAD_LAYER:
            return {
                ...state, loading: false
            }
        case LAYER_ACTION_TYPES.ERROR_LAYER:
            return {
                ...state, error: action.payload, loading: false, current: null
            }
        case LAYER_ACTION_TYPES.CLEAR_LAYER_ERRORS:
            return {
                ...state,
                error: null, loading: false
            }
        case LAYER_ACTION_TYPES.LIST_LAYER:
            return {
                ...state, layers: action.payload, loading: false, current: null
            }
        case LAYER_ACTION_TYPES.ADD_LAYER:
            return {
                ...state,
                loading: false
            }
        case LAYER_ACTION_TYPES.SHOW_LAYER:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case LAYER_ACTION_TYPES.EDIT_LAYER:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case LAYER_ACTION_TYPES.DELETE_LAYER:
            const updatedLayers = state.layers.layers.filter(layer => action.payload !== layer.id)
            const updatedCount = state.layers.count - 1;
            return {
                ...state, layers: {...state.layers, layers: updatedLayers, count: updatedCount}, loading: false
            }
        default:
            return state;
    }

};

export default layerReducer;