import {CLEAR_LOADING, SET_LOADING, USER_ACTION_TYPES} from "../typesAction";

const initialState = {
    users: [],
    current: null,
    currentUser: null,
    loading: false,
    appLoading: false,
    error: null,
    geoArea: null,
    geoRegions: null
}


const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_ACTION_TYPES.CLEAR_CURRENT_USER:
            return {
                ...state, currentUser: null, geoArea: null, geoRegions: null, users: [], error: null
            }
        case USER_ACTION_TYPES.GET_CURRENT_USER:
            return {
                ...state, currentUser: action.payload, appLoading: false
            }
        case SET_LOADING:
            return {
                ...state, appLoading: true
            }
        case CLEAR_LOADING:
            return {
                ...state, appLoading: false
            }
        case USER_ACTION_TYPES.LOAD_USER:
            return {
                ...state, loading: true
            }
        case USER_ACTION_TYPES.CLEAR_LOAD_USER:
            return {
                ...state, loading: false
            }
        case USER_ACTION_TYPES.ERROR_USER:
            return {
                ...state, error: action.payload, loading: false, current: null
            }
        case USER_ACTION_TYPES.CLEAR_USER_ERRORS:
            return {
                ...state,
                error: null, loading: false, appLoading: false
            }
        case USER_ACTION_TYPES.LIST_USER:
            return {
                ...state, users: action.payload, loading: false, current: null
            }
        case USER_ACTION_TYPES.ADD_USER:
            return {
                ...state,
                loading: false
            }
        case USER_ACTION_TYPES.SHOW_USER:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case USER_ACTION_TYPES.EDIT_USER:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case USER_ACTION_TYPES.DELETE_USER:
            const updatedUsers = state.users.users.filter(user => action.payload !== user.id)
            const updatedCount = state.users.count - 1
            return {
                ...state, users: {...state.users, users: updatedUsers, count: updatedCount}, loading: false
            }
        case USER_ACTION_TYPES.GET_GEO_AREA:
            return {
                ...state,
                geoArea: action.payload
            }
        case USER_ACTION_TYPES.GET_GEO_REGIONS:
            return {
                ...state,
                geoRegions: action.payload
            }
        default:
            return state;
    }
};

export default userReducer;
