import {createSelector} from "reselect";

const selectPath = state => state.path;

export const selectLoading = createSelector(
    [selectPath],
    (path) => path.loading
);

export const selectError = createSelector(
    [selectPath],
    (path) => path.error
);

export const selectPaths = createSelector(
    [selectPath],
    (path) => path.paths
);

export const selectCurrentPath = createSelector(
    [selectPath],
    (path) => path.current
);