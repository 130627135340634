import {createSelector} from "reselect";

const selectPointsOfInterest = state => state.pointsOfInterest;

export const selectLoading = createSelector(
    [selectPointsOfInterest],
    (pointsOfInterest) => pointsOfInterest.loading
);

export const selectError = createSelector(
    [selectPointsOfInterest],
    (pointsOfInterest) => pointsOfInterest.error
);

export const selectPointsOfInterests = createSelector(
    [selectPointsOfInterest],
    (pointsOfInterest) => pointsOfInterest.pointsOfInterests
);

export const selectCurrentPointsOfInterest = createSelector(
    [selectPointsOfInterest],
    (pointsOfInterest) => pointsOfInterest.current
);