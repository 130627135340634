
export const getJSXFormIdWithStyle = (formId) => {
  const grayColor = "#b3b3b3";
  return (
    <span
      style={{
        color: grayColor,
        borderColor: grayColor,
        backgroundColor: "transparent",
        border: "solid",
        borderWidth: "1px",
        padding: "1px",
        margin: "2px 5px 2px 0",
        height: "50% !important",
      }}
    >
      {formId}
    </span>
  );
};

/**
 * TreeData for dataZource form item
 * @param dataZources
 * @param intl
 * @return treeData
 */
export const getTreeDataDataZource = (dataZources, intl) => {
  let treeData = [
    {
      title: intl.formatMessage({
        id: "label.FormListDataSeries.treeData.title.forms",
      }),
      value: "forms",
      selectable: false,
      children: [],
    },
  ];
  dataZources.forEach((form) => {
    treeData[0].children.push({
      title: (
        <span>
          {getJSXFormIdWithStyle(form.id)}
          <span>{form.name}</span>
        </span>
      ),
      value: form.id,
    });
  });
  return treeData;
};

/**
 * TreeData for TreeSelect component.
 * @param selectedDataZource
 * @param formFields
 * @param view
 * @param analysisType
 * @param is_x_variable
 * @returns treeData
 */
export const getTreeDataForVariables = (
  selectedDataZource,
  formFields,
  view,
  analysisType,
  is_x_variable = false
) => {
  let treeData = [];

  if (!selectedDataZource || !formFields || !analysisType) {
    return treeData;
  }

  const titleCategoricalSuffix = " (Categorical)";

  //treeData - locations field
  const treeDataItemLocations =
    ["BarPlot"].includes(analysisType) && is_x_variable
      ? {
          title: "X Locations" + titleCategoricalSuffix,
          value: "locations",
        }
      : null;
  if (treeDataItemLocations !== null) {
    treeData.push(treeDataItemLocations);
  }

  //treeData - other fields
  const columnNormalizedNamesToExclude = [
    "last_updated",
    "deleted",
    !selectedDataZource?.saveUserName ? "user_name" : null,
  ].filter((item) => item !== null);

  const dataSchemas = selectedDataZource?.dataSchemas;

  const getTreeDataItem = (
    columnNormalizedName,
    index,
    dataSchema,
    formConfigurationFormFields,
    formFields,
    view,
    blockCase = "nonQB"
  ) => {
    let title, value;
    //title
    const columnFormFieldType = dataSchema.columnFormFieldTypes[index];
    const columnFormFieldSqlType = formFields.find(
      (formField) => formField.type === columnFormFieldType
    )?.sqlType;
    const isCategoricalField =
      formConfigurationFormFields.find(
        (field) => field.nameNormalized === columnNormalizedName
      )?.optionValues?.values?.length > 0;

    if (blockCase === "QB") {
      switch (columnNormalizedName) {
        case "id":
        case "date_created":
          title = view
            ? `${dataSchema.columnFormNames[index]} QB`
            : `${dataSchema.columnFormNames[index]} QB (${columnFormFieldSqlType})`;
          value = `${columnNormalizedName}QB`;
          break;
        default:
          //example of match: p_1_f_39_tcbldhpqrw_5629697856_v_0_id
          const regExp = new RegExp(
            "^p_[0-9]*_f_[0-9]*_[a-z]*_[0-9]*_v_0_id$",
            "g"
          );
          const isNonQBId = regExp.test(columnNormalizedName);
          title = view
            ? isNonQBId
              ? "Id non-QB"
              : dataSchema.columnFormNames[index]
            : isNonQBId
            ? `Id non-QB (${columnFormFieldSqlType})`
            : `${
                dataSchema.columnFormNames[index]
              } (${columnFormFieldSqlType})${
                isCategoricalField ? titleCategoricalSuffix : ""
              }`;
          value = columnNormalizedName;
      }
    } else {
      title = view
        ? dataSchema.columnFormNames[index]
        : `${dataSchema.columnFormNames[index]} (${columnFormFieldSqlType})${
            isCategoricalField || columnNormalizedName === "user_name"
              ? titleCategoricalSuffix
              : ""
          }`;
      value = columnNormalizedName;
    }

    return { title: title, value: value };
  };

  dataSchemas?.[0]?.columnNormalizedNames.forEach(
    (columnNormalizedName, index) => {
      //loop for non question block

      //fields to include in treeData
      if (!columnNormalizedNamesToExclude.includes(columnNormalizedName)) {
        let treeDataItem = getTreeDataItem(
          columnNormalizedName,
          index,
          dataSchemas[0],
          selectedDataZource.configuration.formFields,
          formFields,
          view
        );

        treeData.push(treeDataItem);
      }
    }
  );

  //question block if exists
  const fieldQB = selectedDataZource?.configuration?.formFields?.find(
    (field) => !!field?.formFields
  );
  if (fieldQB) {
    let treeDataItemQB = {
      title: fieldQB.name,
      value: fieldQB.nameNormalized,
      selectable: false,
      children: dataSchemas[1].columnNormalizedNames
        .map((columnNormalizedNameQB, indexQB) => {
          //loop for non question block

          //fields to include in treeDataQB
          if (
            !columnNormalizedNamesToExclude.includes(columnNormalizedNameQB)
          ) {
            const formConfigurationFormFieldsQB =
              selectedDataZource.configuration.formFields.find(
                (field) => !!field?.formFields
              ).formFields;
            return getTreeDataItem(
              columnNormalizedNameQB,
              indexQB,
              dataSchemas[1],
              formConfigurationFormFieldsQB,
              formFields,
              view,
              "QB"
            );
          } else {
            return null;
          }
        })
        .filter((item) => item !== null),
    };

    treeData.push(treeDataItemQB);
  }

  return treeData;
};

/**
    Parse selectedDataZource formFields to extract those that have categories.
    @param selectedDataZource
    @return An array of objects with keys 'nameNormalized' and 'categories'. 
*/
export const getCategoriesByNameNormalizedInForm = (selectedDataZource) => {
  if (!selectedDataZource) return [];

  let categoriesByVariables_ = [];

  //categoriesByVariables_
  selectedDataZource.configuration.formFields.forEach((formField) => {
    if (formField?.optionValues?.values?.length > 0) {
      //main block categories
      categoriesByVariables_.push({
        nameNormalized: formField.nameNormalized,
        categories: formField.optionValues.values,
      });
    }

    if (formField?.formFields) {
      //question block
      formField.formFields.forEach((formFieldQB) => {
        if (formFieldQB?.optionValues?.values?.length > 0) {
          //question block categories
          categoriesByVariables_.push({
            nameNormalized: formFieldQB.nameNormalized,
            categories: formFieldQB.optionValues.values,
          });
        }
      });
    }
  });

  if (selectedDataZource?.saveUserName) {
    categoriesByVariables_.push({
      nameNormalized: "user_name",
      categories: selectedDataZource.usersNames,
    });
  }

  return categoriesByVariables_;
};

/**
    Sets the state 'selectedDataZources'
    @param dataSeries Array with the dataSeries that contains the dataZource ids..    
    @param setValue Hook to set the state of seleectedDataZources.
*/
export const getSelectedDataZourcesFromAPI_using_getSurveyForm = (
  dataSeries,
  setSelectedDataZources,
  getSurveyForm,
  intl,
  dataZourceType
) => {
  if (!dataSeries || !Array.isArray(dataSeries)) {
    const errorMsg = intl.formatMessage({
      id: "errorMsg.getSelectedDataZourcesFromAPI_using_getSurveyForm.invalidArgument.dataSeries",
    });
    throw new Error(errorMsg);
  }

  const selectedDataZourcesId = dataSeries
    ?.map((dataSeriesItem) => dataSeriesItem[dataZourceType])
    .filter((item) => !!item || item?.length > 0)
    .flat(2);

  if (selectedDataZourcesId?.length >= 1) {
    Promise.allSettled(
      selectedDataZourcesId.map((selectedDataZourceId) => {
        return getSurveyForm(null, selectedDataZourceId);
      })
    ).then((results) => {
      if (results.some((result) => result.status !== "fulfilled")) {
        const notLoadedDataZources = results
          .map((result, index) => {
            if (result.status !== "fullfiled") {
              return index;
            } else {
              return null;
            }
          })
          .filter((item) => item !== null);

        const errorMsg = intl.formatMessage(
          {
            id: "errorMsg.getSelectedDataZourcesFromAPI_using_getSurveyForm.notLoaded",
          },
          { notLoadedDataZources: `[${notLoadedDataZources.join(", ")}]` }
        );

        console.log(errorMsg);
        throw new Error(errorMsg);
      } else {
        setSelectedDataZources(() => {
          const values = results.map((result) => result.value);

          if (dataZourceType === "dataZource") {
            return values;
          }

          if (dataZourceType === "additionalDataZources") {
            const selectedAdditionalDataZourcesId = dataSeries?.map(
              (dataSeriesItem) => dataSeriesItem[dataZourceType]
            );

            let additionalValues = [];
            selectedAdditionalDataZourcesId.forEach((idArray, index) => {
              let valueArray = [];
              if (idArray && idArray?.length > 0) {
                idArray.forEach((id) => {
                  valueArray.push(values.find((item) => item.id === id));
                });

                additionalValues[index] = valueArray;
              }
            });

            return additionalValues;
          }
        });
      }
    });
  } else {
    setSelectedDataZources([]);
  }
};

export const getColumnInfo = (
  selectedDataZource,
  columnNormalizedName,
  unexpectedErrorMsg,
  intl
) => {
  if (!selectedDataZource || !columnNormalizedName) return null;

  const columnNormalizedNameSplitted = columnNormalizedName.split('.');
  const actualColumnNormalizedName = columnNormalizedNameSplitted.length === 2 
  ? columnNormalizedNameSplitted[1]
  : columnNormalizedNameSplitted[0]

let columnInfo = {};
  let columnNormalizedNames, columnFormNames, columnFormFieldTypes, dataTypes;

  const dataSchemas = selectedDataZource?.dataSchemas;
  let schemaIndex = 0;
  columnNormalizedNames = dataSchemas[schemaIndex].columnNormalizedNames;
  let index = columnNormalizedNames.indexOf(actualColumnNormalizedName);

  if (index === -1 && dataSchemas.length === 2) {
    schemaIndex = 1;
    columnNormalizedNames = dataSchemas[schemaIndex].columnNormalizedNames;
    index = columnNormalizedNames.indexOf(actualColumnNormalizedName);
  }

  if (index !== -1) {
    //columnNormalizedName is in dataSchemas[0]
    columnFormNames = dataSchemas[schemaIndex].columnFormNames;
    columnFormFieldTypes = dataSchemas[schemaIndex].columnFormFieldTypes;
    dataTypes = dataSchemas[schemaIndex].dataTypes;

    columnInfo.columnFormName = columnFormNames[index];
    columnInfo.columnFormFieldType = columnFormFieldTypes[index];
    columnInfo.dataType = dataTypes[index];
    columnInfo.tableType = "m";

    return columnInfo;
  }

  if (index === -1) {
    //columnNormalizedName is not in dataSchemas[0] and there is not dataSchemas[1]
    const currentErrorMsg = intl.formatMessage(
      {
        id: "HelpersForFormListDataSeries.getColumnInfo.invalid.columnNormalizedName",
      },
      { columnNormalizedName: columnNormalizedName }
    );
    const errorMsg = `${unexpectedErrorMsg}. ${currentErrorMsg}`;
    console.log(errorMsg);
    throw new Error(errorMsg);
  }
};
