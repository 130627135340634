import {ROLE_ACTION_TYPES} from "../typesAction";


const initialState = {
    roles: [],
    current: null,
    loading: false,
    error: null
}

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case ROLE_ACTION_TYPES.LOAD_ROLE:
            return {
                ...state, loading: true
            }
        case ROLE_ACTION_TYPES.CLEAR_LOAD_ROLE:
            return {
                ...state, loading: false
            }
        case ROLE_ACTION_TYPES.ERROR_ROLE:
            return {
                ...state, error: action.payload, loading: false, current: null
            }
        case ROLE_ACTION_TYPES.CLEAR_ROLE_ERRORS:
            return {
                ...state,
                error: null, loading: false
            }
        case ROLE_ACTION_TYPES.LIST_ROLE:
            return {
                ...state, roles: action.payload, loading: false, current: null
            }
        case ROLE_ACTION_TYPES.ADD_ROLE:
            return {
                ...state,
                loading: false
            }
        case ROLE_ACTION_TYPES.SHOW_ROLE:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case ROLE_ACTION_TYPES.EDIT_ROLE:
            return {
                ...state,
                current: action.payload,
                loading: false
            }
        case ROLE_ACTION_TYPES.DELETE_ROLE:
            const updatedRoles = state.roles.roles.filter(role => action.payload !== role.id)
            const updatedCount = state.roles.count - 1
            return {
                ...state, roles: {...state.roles, roles: updatedRoles, count: updatedCount}, loading: false
            }
        default:
            return state;
    }

};

export default roleReducer;