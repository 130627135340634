import {createSelector} from "reselect";

const selectSurveyForm = state => state.surveyForm;

export const selectLoading = createSelector(
    [selectSurveyForm],
    (surveyForm) => surveyForm.loading
);

export const selectErrorForm = createSelector(
    [selectSurveyForm],
    (surveyForm) => surveyForm.error
);

export const selectSurveyForms = createSelector(
    [selectSurveyForm],
    (surveyForm) => surveyForm.surveyForms
);

export const selectCurrentSurveyForm = createSelector(
    [selectSurveyForm],
    (surveyForm) => surveyForm.current
);