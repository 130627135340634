import { message, Breadcrumb, Layout, Card, Row, Col, Button, Form, Input, Select, Popconfirm, Checkbox, InputNumber, Modal, DatePicker, Radio, Tabs, Upload } from 'antd';
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactQuill from 'react-quill';
import { MobileOutlined, FormOutlined, HomeOutlined, DeleteTwoTone, EditTwoTone, MenuOutlined, CopyOutlined, DownCircleOutlined, UpCircleOutlined, QuestionCircleOutlined, QrcodeOutlined, BarcodeOutlined, CameraOutlined, InboxOutlined } from "@ant-design/icons";
import {
    TitleIcon,
    TextIcon,
    TextAreaIcon,
    NumberIntegerIcon,
    NumberDecimalIcon,
    DateIcon,
    DateTimeIcon,
    TimeIcon,
    CheckboxIcon,
    CheckboxGroupIcon,
    RadioGroupIcon,
    ListIcon,
    MapIcon,
    PhotoIcon,
    BarcodeIcon,
    QRCodeIcon,
    QuestionBlockIcon,
    VirtualIcon,
    UUIDIcon
} from "../../utils/icons/CustomIcons"

import { selectError, selectLoading, selectFormFields } from "../../redux/survey/formField/formFieldSelector";
import { listFormFields, clearFormFieldError } from '../../redux/survey/formField/formFieldActions';
import { addSurveyForm, editSurveyForm, getSurveyForm } from '../../redux/survey/form/surveyFormActions';
import { selectCurrentSurveyForm, selectErrorForm } from "../../redux/survey/form/surveyFormSelector";
import { selectProjects } from "../../redux/project/projectSelector";
import { listProjects } from "../../redux/project/projectActions";

import 'react-quill/dist/quill.snow.css';
import "./SurveyFormsStyles.css";
import PreviewForm from '../../utils/preview/mobilePreview'

const { Content, Footer } = Layout;

let _formFieldsGetForm = [];
let _formFields = [];
let _formFieldsEdit = {};
let hasBlock = false;
let blockElementIndex = 0;
const SurveyFormForm = (props) => {

    //const { TabPane } = Tabs;
    const intl = useIntl();
    const [form] = Form.useForm();
    const [formItems, setFormItems] = useState([]);
    const [formItemsEdit, setFormItemsEdit] = useState([]);
    const [formItemsBlock, setFormItemsBlock] = useState([]);
    const blockElement = "QUESTION_BLOCK";
    const [keyCallapse, setKeyCallapse] = useState(1);

    const { formFields } = props.formFields;
    const { projects } = props.projects;

    //defines the role that component SurveyFormForm is going to play:
    // as Form or as Configuration/FormTemplate
    const regExpConfigurationFormTemplates = RegExp('^/form-templates/templates.*$','g');
    const isConfigurationFormTemplates = regExpConfigurationFormTemplates.test(props.location.pathname);

    //used when creating a new form from template
    const templateId = props.location.state?.templateId ? props.location.state.templateId: null;

    //defines templates view action mode
    const isTemplateView = props.location.state?.isTemplateView ? props.location.state.isTemplateView : false;

    //defines what entity is going to be used to get data from API: Form or FormTemplate
    let entityUrl = isConfigurationFormTemplates
        ?  'formTemplate'
        : templateId !== null || isTemplateView ? 'formTemplate' : 'form';

    const statusOptions = [
        {label: intl.formatMessage({id: 'label.select'}), value: null},
        {label: intl.formatMessage({id: 'label.draft'}), value: 0},
        {label: intl.formatMessage({id: 'label.published'}), value: 1},
        {label: intl.formatMessage({id: 'label.hidden'}), value: 2}
    ];

    const [surveyFormCreated, setSurveyFormCreated] = useState(false);
    const formId = props.match.params.id;

    const [isModified, setIsModified] = useState(false);
    const isEditForm = props.location.pathname !== '/forms/new'
        && props.location.pathname !== '/form-templates/templates/new';
    const [edit, setEdit] = useState(isEditForm);
    const { Dragger } = Upload;

    const getIconByType = (item) => {
        let text = item.titleName;
        let icon;
        switch (item.type) {
            case 1: icon = <TitleIcon />; break;
            case 2: icon = <TextIcon />; break;
            case 3: icon = <TextAreaIcon />; break;
            case 4: icon = <NumberIntegerIcon />; break;
            case 5: icon = <NumberDecimalIcon />; break;
            case 6: icon = <DateIcon />; break;
            case 7: icon = <DateTimeIcon />; break;
            case 8: icon = <TimeIcon />; break;
            case 9: icon = <CheckboxIcon />; break;
            case 10: icon = <CheckboxGroupIcon />; break;
            case 11: icon = <RadioGroupIcon />; break;
            case 12: icon = <ListIcon />; break;
            case 13: icon = <MapIcon />; break;
            case 14: icon = <PhotoIcon />; break;
            case 15: icon = <BarcodeIcon />; break;
            case 16: icon = <QRCodeIcon />; break;
            case 17: icon = <QuestionBlockIcon />; break;
            case 18: icon = <VirtualIcon />; break;
            case 19: icon = <UUIDIcon />; break;
            default: break;
        }
        return (<>{icon} {text}</>);
    }
    const getHelpPreview = (type) => {
        let helpPreview;
        switch (type) {
            case 1: helpPreview = <h1>Species data</h1>; break;
            case 2: helpPreview = <Input placeholder="Text" />; break;
            case 3: helpPreview = <Input.TextArea rows={4} defaultValue="Text" />; break;
            case 4: helpPreview = <InputNumber min={1} max={10} defaultValue={3} step={1} precision={0} />; break;
            case 5: helpPreview = <InputNumber min={1} max={10} step={0.1} defaultValue={3} />; break;
            case 6: helpPreview = <DatePicker />; break;
            case 7: helpPreview = <DateTimeIcon />; break;
            case 8: helpPreview = <TimeIcon />; break;
            case 9: helpPreview = <Checkbox>Checkbox</Checkbox>; break;
            case 10: helpPreview = <><Checkbox>A</Checkbox><Checkbox>B</Checkbox><Checkbox>C</Checkbox><Checkbox>D</Checkbox></>; break;
            case 11: helpPreview = <Radio.Group value={1}><Radio value={1}>A</Radio><Radio value={2}>B</Radio><Radio value={3}>C</Radio><Radio value={4}>D</Radio></Radio.Group>; break;
            case 12: helpPreview = <Select defaultValue="A"><Select.Option value="A">A</Select.Option><Select.Option value="B">B</Select.Option></Select>; break;
            case 13: helpPreview = <MapIcon />; break;
            case 14: helpPreview = <Button icon={<CameraOutlined />}>Take a photo</Button>; break;
            case 15: helpPreview = <Button icon={<BarcodeOutlined />}>Barcode</Button>; break;
            case 16: helpPreview = <Button icon={<QrcodeOutlined />}>QR code</Button>; break;
            case 17: helpPreview = <QuestionBlockIcon />; break;
            case 18: helpPreview = <VirtualIcon />; break;
            case 19: helpPreview = <Button icon={<UUIDIcon /> } style={{margin: '0 auto'}}>UUID Generator</Button>; break;
            default: break;
        }
        return (<>{helpPreview}</>);
    }
    const Copyable = (props) => {
        return (
            <Droppable droppableId={props.droppableId} isDropDisabled={true} key="copyable">
                {(provided, snapshot) => (
                    <ul ref={provided.innerRef} className={props.className}>
                        {props.items.map((item, index) => (
                            <Draggable key={item.uuid} draggableId={item.uuid} index={index} isDragDisabled={item.isDragDisabled} >
                                {(provided, snapshot) => (
                                    <>
                                        <li
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={provided.draggableProps.style}
                                            className={snapshot.isDragging ? "dragging" : ""}
                                        >
                                            {getIconByType(item)}
                                        </li>
                                        {snapshot.isDragging && (
                                            <div className="form-fields-items-copy">
                                                {getIconByType(item)}
                                            </div>
                                        )}
                                    </>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        );
    }

    const FormFieldsItems = (props) => {
        return <Copyable droppableId="FORMFIELDS" className="form-fields-items" items={props.items} />;
    }

    const getFormFieldByType = (optionType) => {
        const _formfield = _formFields.filter(formField => formField.type === optionType);
        return _formfield.length > 0 ? { ..._formfield[0] } : null;
    }

    const getFormFieldsInputs = (item, index, isBlock) => {
        if (item.name.length > 0 && item.name !== item.typeName) {
            onChangeFormItem(`${item.uuid}__name`, item.name);
        }

        const getSelectOption = (options) => {
            const childrenCompatibleTypes = [];
            options.forEach((option) => {
                const formField = getFormFieldByType(option);
                if (formField) {
                    childrenCompatibleTypes.push(<Select.Option key={option}>{formField.titleName}</Select.Option>);
                }
            }, _formFields)
            return childrenCompatibleTypes;
        }

        const type = item.type;
        const getOptionValues = (options) => {
            let values = [];
            if (options && Object.prototype.hasOwnProperty.call(options, "values")) {
                values = options.values
            } else {
                values = options;
            }

            onChangeFormItem(`${item.uuid}__optionValues`, values);
            return values;
        }

        return (
            <>
                <Row className="info-row">
                    {item.compatibleTypes && item.compatibleTypes.length > 0 && (
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }} name={item.uuid + "__compatibleTypes"}
                                label={intl.formatMessage({ id: 'label.form.compatibleTypes' })}>
                                <Select defaultValue={0} onChange={(value) => {
                                    if (isBlock) {
                                        setFormItemsBlock(state => {
                                            const list = onChangeCompatibleType(state, item, index, value)
                                            return editItem(list, index);
                                        }, item, index, value, form)
                                    } else {
                                        !isBlock &&
                                            setFormItems(state => {
                                                const list = onChangeCompatibleType(state, item, index, value)
                                                return editItem(list, index);
                                            }, item, index, value, form)
                                    }
                                }}>
                                    <Select.Option value={0} disabled><FormattedMessage id="label.select"
                                        defaultMessage="Select one..." /></Select.Option>
                                    {getSelectOption(item.compatibleTypes)}
                                </Select>
                            </Form.Item>
                        </Col>)
                    }
                    {item.typeName === "HEADING" &&
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }} name={item.uuid + "__fontSize"} label={intl.formatMessage({ id: 'label.form.fontSize' })}>
                                <Select defaultValue={item.fontSize ? item.fontSize : "medium"} onChange={(value) => {
                                    const itemId = `${item.uuid}__fontSize`;
                                    const targetEvents = {
                                        target: { id: itemId, value: value }
                                    }
                                    if (isBlock) {
                                        setFormItemsBlock(state => {
                                            return onChange(state, index, targetEvents)
                                        })
                                    } else {
                                        setFormItems(state => {
                                            return onChange(state, index, targetEvents)
                                        })
                                    }
                                }}>
                                    <Select.Option value="small"><FormattedMessage id="label.form.fontSize.small"
                                        defaultMessage="Small" /></Select.Option>
                                    <Select.Option value="medium"><FormattedMessage id="label.form.fontSize.medium"
                                        defaultMessage="Medium" /></Select.Option>
                                    <Select.Option value="large"><FormattedMessage id="label.form.fontSize.large"
                                        defaultMessage="Large" /></Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    {item.typeName !== "HEADING" &&
                        <Col xs={8} sm={8} md={6} lg={6} xl={4}>
                            <Form.Item
                                labelCol={{ span: 18 }}
                                wrapperCol={{ span: 4 }} name={item.uuid + "__required"} label={intl.formatMessage({ id: 'label.form.required' })}>
                                <Checkbox onChange={events => {
                                    if (isBlock) {
                                        setFormItemsBlock(state => {
                                            return onChange(state, index, events, true)
                                        }, events, form)
                                    } else {
                                        setFormItems(state => {
                                            return onChange(state, index, events, true)
                                        }, events, form)
                                    }
                                }}
                                    checked={item.required} />
                            </Form.Item>
                        </Col>
                    }
                    {[12, 13].includes(type) &&
                        <Col xs={8} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                labelCol={{ span: 18 }}
                                wrapperCol={{ span: 4 }} name={item.uuid + "__multipleValues"} label={intl.formatMessage({ id: 'label.form.multiple' })}>
                                <Checkbox onChange={events => {
                                    if (isBlock) {
                                        setFormItemsBlock(state => {
                                            return onChange(state, index, events, true)
                                        }, events)
                                    } else {
                                        setFormItems(state => {
                                            return onChange(state, index, events, true)
                                        }, events)
                                    }
                                }}
                                    checked={item.multipleValues} />
                            </Form.Item>
                        </Col>
                    }
                    {type === 5 &&
                        <Col xs={8} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                labelCol={{ span: 18 }}
                                wrapperCol={{ span: 4 }} name={item.uuid + "__decimal"} label={intl.formatMessage({ id: 'label.form.decimalPlace' })}
                                onBlur={() => {
                                    const itemId = `${item.uuid}__decimal`;
                                    const values = form.getFieldValue()[itemId];
                                    const targetEvents = {
                                        target: { id: itemId, value: values }
                                    }
                                    if (isBlock) {
                                        setFormItemsBlock(state => {
                                            return onChange(state, index, targetEvents);
                                        })
                                    } else {
                                        setFormItems(state => {
                                            return onChange(state, index, targetEvents);
                                        })
                                    }
                                }} >
                                <InputNumber min={1} max={5} precision={0} defaultValue={item.decimal ? item.decimal : 2} />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                {
                    [10, 11, 12, 13].includes(type) &&
                    <Row className="info-row">
                        <Col xs={24} xl={24}>
                            <Form.Item name={item.uuid + "__optionValues"}
                                label={intl.formatMessage({ id: 'label.form.formValues' })}
                                validateTrigger={['onBlur', 'onChange']}
                                rules={[{ required: true, message: intl.formatMessage({ id: 'msg.input-required' }) }]}>
                                <Select mode="tags" tokenSeparators={["|"]} autoFocus onChange={() => {
                                    const itemId = `${item.uuid}__optionValues`;
                                    const values = form.getFieldValue()[itemId];
                                    const targetEvents = {
                                        target: { id: itemId, value: values }
                                    }
                                    if (isBlock) {
                                        setFormItemsBlock(state => {
                                            return onChange(state, index, targetEvents);
                                        }, form)
                                    } else {
                                        setFormItems(state => {
                                            return onChange(state, index, targetEvents);
                                        }, form)
                                    }
                                }}
                                    defaultValue={getOptionValues(item.optionValues)}
                                    placeholder={intl.formatMessage({ id: 'label.form.formValuesPlaceholder' })}>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                }
            </>
        )
    }

    const collapseGeneralInfo = () => {
        setKeyCallapse(keyCallapse === 1 ? 0 : 1);
    };

    const RequiredFormItems = () => {
        return (
            <div className="collapse-panel">
                <p onClick={() => collapseGeneralInfo()}>
                    {keyCallapse ? < UpCircleOutlined /> : <DownCircleOutlined />}
                    {intl.formatMessage({ id: 'label.form.info' })}
                </p>
                <div className={keyCallapse ? "" : "generalInfoCollapsed"}>
                    <Row>
                        <Col xs={24} xl={16}>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 23 }}
                                name="name"
                                label={intl.formatMessage({ id: 'label.form-title' })}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[{ required: true, message: intl.formatMessage({ id: 'msg.input-required' }) }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 23 }}
                                name="language"
                                label={intl.formatMessage({ id: 'label.language' })}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[{ required: true, message: intl.formatMessage({ id: 'msg.input-required' }) }]}>
                                <Select>
                                    <Select.Option value={0} disabled><FormattedMessage id="label.select"
                                        defaultMessage="Select one..." /></Select.Option>
                                    <Select.Option value="EN"><FormattedMessage id="label.english"
                                        defaultMessage="English" /></Select.Option>
                                    <Select.Option value="ES"><FormattedMessage id="label.spanish"
                                        defaultMessage="Spanish" /></Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                        <Row>
                        <Col xs={24} xl={8}>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 23 }}
                                name="project"
                                label={intl.formatMessage({ id: 'label.project-title' })}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[{
                                    required: !isConfigurationFormTemplates,
                                    message: intl.formatMessage({ id: 'msg.input-required' })
                                }]}
                                hidden={isConfigurationFormTemplates}
                            >
                                <Select>
                                    <Select.Option value={0} disabled><FormattedMessage id="label.select"
                                        defaultMessage="Select one..." /></Select.Option>
                                    {projects && projects.map(item => (
                                        <Select.Option value={item.id.toString()} key={item.id}>{item.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} xl={8}>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 23 }}
                                name="status"
                                label={intl.formatMessage({ id: 'label.status' })}
                                rules={[{
                                    required: !isConfigurationFormTemplates,
                                    message: intl.formatMessage({ id: 'msg.input-required' })
                                }]}
                                hidden={isConfigurationFormTemplates}
                            >
                                <Select defaultValue={null}>
                                    {statusOptions.map(item => (
                                        <Select.Option value={item.value} key={item.value+item.label}>{item.label}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                            <Col xs={24} xl={8}>
                                <Form.Item
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 23 }}
                                    name="saveUserName"
                                    label={intl.formatMessage({ id: 'label.save-user-name' })}
                                    hidden={isConfigurationFormTemplates}
                                >
                                    <Radio.Group>
                                        <Radio value={true}><FormattedMessage id="label.yes" defaultMessage="Yes"/></Radio>
                                        <Radio value={false}><FormattedMessage id="label.no" defaultMessage="No"/></Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                    </Row>
                    <Row>
                        <Col xs={24} xl={24}>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                name="description"
                                label={intl.formatMessage({ id: 'label.description' })}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[{ required: false, message: intl.formatMessage({ id: 'msg.input-required' }) }]}
                            >
                                <ReactQuill theme="snow" />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    };

    const FormItemsBlock = (props) => {
        return (
            <Droppable droppableId="FORM_ITEMS_BLOCK" key="form-items-block">
                {(provided, snapshot) => (
                    <ul ref={provided.innerRef} className="form-items-block" id="items-block">
                        {props.items.length === 0 &&
                            <li className="drag-item-msg">{intl.formatMessage({ id: `msg.drag-box-block-item` })}</li>
                        }
                        {props.items && props.items.map((item, index) => {
                            return (
                                <Draggable
                                    key={`${item.uuid}${index}`}
                                    draggableId={`${item.uuid}${index}`}
                                    index={index}
                                >{
                                        (provided, snapshot) => (
                                            <li
                                                className="draggable-item"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={provided.draggableProps.style}
                                            >
                                                <Row>
                                                    <Col xs={2} md={2} xl={1} className="draggable-item-row draggable-item-row-icon" {...provided.dragHandleProps}>
                                                        <MenuOutlined />
                                                    </Col>
                                                    <Col xs={24} md={12} xl={14}>
                                                        <Form.Item
                                                            name={item.uuid + "__name"}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            rules={[{ required: true, message: intl.formatMessage({ id: 'msg.input-required' }) }]}
                                                            onBlur={(events) => {
                                                                onChangeFormItem(`${item.uuid}__name`, events.target.value);
                                                                setFormItemsBlock(state => {
                                                                    return onChangeName(state, index, events.target.value)
                                                                })
                                                            }}>
                                                            <Input /*autoFocus*/ placeholder={item.name} defaultValue={item.name.length > 0 && item.name !== item.typeName ? item.name : ""} />
                                                        </Form.Item >
                                                    </Col>
                                                    <Col xs={4} md={10} xl={5} className="draggable-item-row textAlign-left">
                                                        {getIconByType(item)}
                                                    </Col>
                                                    <Col xs={24} md={24} xl={4} className="draggable-item-row draggable-item-row-icon draggable-item-row-actions">
                                                        <QuestionCircleOutlined onClick={() => { openHelpItem(item) }} title={intl.formatMessage({id: 'title.label.actions.help'}) +' '+ intl.formatMessage({id: 'title.label.actions.item'})} />
                                                        <Popconfirm title={intl.formatMessage({ id: 'msg.confirm-delete' })}
                                                            onConfirm={() => {
                                                                setFormItemsBlock(state => {
                                                                    return deleteItem(state, index);
                                                                })
                                                            }}>
                                                            <DeleteTwoTone twoToneColor="red" title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.block'}) +' '+ intl.formatMessage({id: 'title.label.actions.item'})} />
                                                        </Popconfirm>
                                                        <EditTwoTone id={item.uuid + "__edit"} className={item.editing ? "isEditing" : ""} onClick={() => {
                                                            setFormItemsBlock(state => {
                                                                return editItem(state, index);
                                                            })
                                                        }} title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.block'}) +' '+ intl.formatMessage({id: 'title.label.actions.item'})} />
                                                        <CopyOutlined onClick={() => {
                                                            setFormItemsBlock(state => duplicateItem(state, index));
                                                        }} title={intl.formatMessage({id: 'title.label.actions.copy'}) +' '+ intl.formatMessage({id: 'title.label.actions.block'}) +' '+ intl.formatMessage({id: 'title.label.actions.item'})} />
                                                    </Col>
                                                </Row>

                                                <Row className={item.editing ? "editing-item-row draggable-item-row-info" : "draggable-item-row-info"}>
                                                    <Col xs={24}>
                                                        {getFormFieldsInputs(item, index, true)}
                                                    </Col>
                                                </Row>
                                            </li>
                                        )
                                    }
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </ul>
                )
                }
            </Droppable >
        );
    };

    const FormItems = (props) => {
        return (
            <Droppable droppableId="FORM_ITEMS" key="form-items">
                {(provided, snapshot) => (
                    <>
                        <ul ref={provided.innerRef} className="form-items">
                            {props.items.length === 0 &&
                                <li className="drag-item-msg">{intl.formatMessage({ id: `msg.drag-box-item` })}</li>
                            }
                            {props.items.map((item, index) => (
                                <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
                                    {
                                        (provided, snapshot) => (
                                            <li
                                                className={item.typeName === blockElement ? "draggable-item-block" : "draggable-item"}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={provided.draggableProps.style}
                                            >
                                                <Row>
                                                    <Col xs={2} md={2} xl={1} className={item.typeName !== blockElement ? "draggable-item-row draggable-item-row-icon" : "draggable-item-row-block"}  {...provided.dragHandleProps}>
                                                        <MenuOutlined />
                                                    </Col>
                                                    <Col xs={24} md={12} xl={14}>
                                                        <Form.Item
                                                            className={item.typeName === blockElement ? "draggable-item-block-input draggable-item-block-label" : ""}
                                                            name={item.uuid + "__name"}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            rules={[{ required: true, message: intl.formatMessage({ id: 'msg.input-required' }) }]}
                                                            onBlur={(events) => {
                                                                onChangeFormItem(`${item.uuid}__name`, events.target.value);
                                                                setFormItems(state => {
                                                                    return onChangeName(state, index, events.target.value)
                                                                })
                                                            }}>
                                                            <Input /*autoFocus*/ placeholder={item.name} defaultValue={item.name.length > 0 && item.name !== item.typeName ? item.name : ""} />
                                                        </Form.Item >
                                                    </Col>
                                                    <Col xs={4} md={10} xl={5} className={item.typeName !== blockElement ? "draggable-item-row textAlign-left" : "draggable-item-block-label"}>
                                                        {getIconByType(item)}
                                                    </Col>
                                                    {item.typeName === blockElement && (
                                                        <Col xs={24} md={23} xl={20}>
                                                            <FormItemsBlock items={formItemsBlock} />
                                                        </Col>
                                                    )}
                                                    <Col xs={3} md={24} xl={4} className="draggable-item-row draggable-item-row-icon draggable-item-row-actions">
                                                        <QuestionCircleOutlined onClick={() => { openHelpItem(item) }} title={intl.formatMessage({id: 'title.label.actions.help'}) +' '+ intl.formatMessage({id: 'title.label.actions.item'})}/>
                                                        <Popconfirm title={intl.formatMessage({ id: 'msg.confirm-delete' })}
                                                            onConfirm={() => {
                                                                setFormItems(state => {
                                                                    return deleteItem(state, index, item.type);
                                                                })
                                                            }}>
                                                            <DeleteTwoTone twoToneColor="red" title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.item'})} />
                                                        </Popconfirm>
                                                        {item.typeName !== blockElement && (
                                                            <EditTwoTone id={item.uuid + "__edit"} className={item.editing ? "isEditing" : ""} onClick={() => {
                                                                setFormItems(state => {
                                                                    return editItem(state, index);
                                                                }, index)
                                                            }} title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.item'})} />
                                                        )}
                                                        {item.typeName !== blockElement && (
                                                            <CopyOutlined onClick={() => {
                                                                setFormItems(state => duplicateItem(state, index));
                                                            }}  title={intl.formatMessage({id: 'title.label.actions.copy'}) +' '+ intl.formatMessage({id: 'title.label.actions.item'})}/>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className={item.editing ? "editing-item-row draggable-item-row-info" : "draggable-item-row-info"}>
                                                    <Col xs={24}>
                                                        {getFormFieldsInputs(item, index)}
                                                    </Col>
                                                </Row>
                                            </li>
                                        )
                                    }
                                </Draggable>
                            ))}
                            {provided.placeholder}

                            {props.items.length > 0 &&
                                <li className="drag-item-buttons">
                                    <Button type="primary" htmlType="submit" loading={props.loading}>
                                        {edit && formId !== 'file' && formId !== 'template' ? (<FormattedMessage id="label.update-form" defaultMessage="Update Form" />) : (
                                            <FormattedMessage id="label.create-form" defaultMessage="Create Form" />)}
                                    </Button>
                                </li>
                            }
                        </ul>
                    </>
                )
                }
            </Droppable >
        );
    }

    const reorderItems = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed);
        return list;
    };

    const copyItem = (source, destination, droppableSource, droppableDestination) => {
        const item = source[droppableSource.index];
        const itemStructure = {
            editing: false,
        }
        const newItem = { ...item, uuid: uuid(), ...itemStructure };
        if (item.typeName !== blockElement || (item.typeName === blockElement && !hasBlock)) {
            destination.splice(droppableDestination.index, 0, newItem);
        }
        if (item.typeName === blockElement) {
            _formFields[blockElementIndex].isDragDisabled = true;
            hasBlock = true;
        }
        return destination;
    };

    const moveItem = (source, destination, droppableSource, droppableDestination) => {
        const item = source[droppableSource.index];
        destination.splice(droppableDestination.index, 0, { ...item });
        return destination;
    };

    const duplicateItem = (source, index) => {
        const item = source[index];
        const newItem = { ...item, name: `${item.name} -- copy`, uuid: uuid() };
        let list = [...source];
        list.splice((index + 1), 0, newItem);
        return list;
    };

    const editItem = (state, index) => {
        const list = state.filter((item, indexState) => {
            if (index === indexState) {
                item.editing = !item.editing
            }
            return item
        })
        return list;
    };

    const openHelpItem = (item) => {
        Modal.info({
            className: "modalInfo-preview-container",
            title: getIconByType(item),
            content: (
                <div className="modalInfo-preview">
                    <div>
                        <h3>{intl.formatMessage({ id: `label.modal.definition` })}</h3>
                        <p>{intl.formatMessage({ id: `label.modal.content.${item.typeName}` })}</p>
                    </div>
                    {item.typeName !== blockElement && (
                        <div>
                            <h3>{intl.formatMessage({ id: `label.modal.preview` })}</h3>
                            <div className="textAlign-center">{getHelpPreview(item.type)}</div>
                        </div>
                    )}
                </div>
            ),
            onOk() { },
        });
    };

    const deleteItem = (state, index, type) => {
        const list = state.filter((item, indexState) => {
            return indexState !== index
        })
        if (type === 17) {
            _formFields[blockElementIndex].isDragDisabled = false;
            hasBlock = false;
            setFormItemsBlock([]);
        }

        return list;
    };

    const onChange = (state, index, events, isCheckbox = false) => {
        const _target = events.target;
        const _prop = _target.id.split("__").pop(); // Get the property name
        const _value = isCheckbox ? _target.checked : _target.value;
        const list = state.filter((item, indexState) => {
            if (index === indexState) {
                item[_prop] = _value;
            }
            return item
        }, _prop, _value)
        return list;
    }

    const onChangeName = (state, index, value) => {
        const item = state[index];
        const newItem = { ...item, name: value };
        state.splice(index, 1, newItem);
        return state;
    }

    const onChangeFormItem = (item, value) => {
        if (value) {
            form.setFieldsValue({ [item]: value });
        }
    }

    const onChangeCompatibleType = (state, item, index, value) => {
        const newItem = getFormFieldByType(parseInt(value));
        if (newItem)
            Object.keys(item).forEach(function (key) {
                if (key === "uuid" || key === "name") {
                    newItem[key] = item[key];
                }
                newItem[key] = newItem[key] ?? item[key];
            }, newItem)
        state.splice(index, 1);
        state.splice(index, 0, newItem);
        const itemUuid = `${newItem.uuid}__compatibleTypes`;
        onChangeFormItem(itemUuid, 0);
        return state;
    }

    const generateFormFields = (formFields) => {
        formFields.forEach((element, index) => {
            element.uuid = uuid();
            element.typeName = element.name;
            element.titleName = intl.formatMessage({ id: `label.${element.name}` });
            element.isDragDisabled = false;

            if (element.name === blockElement) {
                blockElementIndex = index;
            }

            if (isEditForm) {
                _formFieldsEdit[element.type] = {
                    titleName: element.titleName,
                    typeName: element.typeName
                };
            }
        });
        return formFields;
    }

    if (formFields && _formFields.length === 0) {
        _formFields = generateFormFields(formFields);
        setFormItemsEdit(_formFieldsEdit);
    }

    const prepareFormFieldItems = (formFieldsItems) => {
        const _formFieldItems = [];
        formFieldsItems.forEach((formField) => {
            const newItem = { ...formField, uuid: uuid(), typeName: _formFieldsEdit[formField.type].typeName, titleName: _formFieldsEdit[formField.type].titleName }

            if (newItem.typeName === blockElement) {
                setFormItemsBlock(state => {
                    return prepareFormFieldItems(formField.formFields)
                });
                hasBlock = true;
                _formFields[blockElementIndex].isDragDisabled = true;
            }
            _formFieldItems.push(newItem);
        });
        return _formFieldItems;
    }

    const prepareFormFieldItemsV1 = (formFieldsItems) => {
        const _formFieldItems = [];
        formFieldsItems.sort((a, b) => (a.row > b.row) ? 1 : ((b.row > a.row) ? -1 : 0));
        formFieldsItems.forEach((formField, index) => {
            const oldType = {
                3: { "type": 1 },
                1: { "type": 2 },
                5: { "type": 3 },
                6: { "type": 4 },
                7: { "type": 5 },
                8: { "type": 6 },
                19: { "type": 9 },
                4: { "type": 10 },
                16: { "type": 11 },
                2: { "type": 12 },
                11: { "type": 14 },
                12: { "type": 15 },
                13: { "type": 16 },
                14: { "type": 17 }
            }
            const newType = oldType[formField.type].type;

            const newItem = {
                uuid: uuid(),
                type: newType,
                name: formField.lkey,
                required: formField.isRequired ? formField.isRequired : false,
                position_row: formField.row ? formField.row : index + 1,
                previousType: newType,
                multipleValues: formField.isMultiple ? formField.isMultiple : false,
                position_column: formField.column ? formField.column : 1,
                typeName: _formFieldsEdit[newType].typeName,
                titleName: _formFieldsEdit[newType].titleName
            };
            if (newItem.typeName === "HEADING") {
                newItem.fontSize = formField.fontSize ? formField.fontSize : "medium"
            } else if ((newItem.typeName === "CHECKBOX_GROUP" || newItem.typeName === "RADIO_GROUP" || newItem.typeName === "LIST") && formField.elements) {
                var oldValues = [];
                formField.elements.forEach((element) => {
                    oldValues.push(element.name)
                });
                newItem.optionValues = {
                    "values": oldValues
                }
            } else if (newItem.typeName === blockElement || (formField.items && formField.items.length > 0)) {
                setFormItemsBlock(state => {
                    return prepareFormFieldItemsV1(formField.items)
                });
                hasBlock = true;
                _formFields[blockElementIndex].isDragDisabled = true;
            }
            _formFieldItems.push(newItem);
        });
        return _formFieldItems;
    }

    const onDragEnd = useCallback(
        result => {
            const { source, destination } = result;
            if (!destination) {
                return;
            }
            if (source.droppableId !== "FORMFIELDS" && source.droppableId !== destination.droppableId) {
                switch (source.droppableId) {
                    case "FORM_ITEMS_BLOCK":
                        setFormItems(state =>
                            moveItem(formItemsBlock, state, source, destination)
                        );
                        setFormItemsBlock(state =>
                            deleteItem(state, source.index)
                        );
                        break;
                    case "FORM_ITEMS":
                        setFormItemsBlock(state =>
                            moveItem(formItems, state, source, destination)
                        );
                        setFormItems(state =>
                            deleteItem(state, source.index)
                        );
                        break;
                    default:
                        break;
                }
            } else {
                switch (source.droppableId) {
                    case "FORM_ITEMS_BLOCK":
                        setFormItemsBlock(state =>
                            reorderItems(state, source.index, destination.index)
                        );
                        break;
                    case "FORM_ITEMS":
                        setFormItems(state =>
                            reorderItems(state, source.index, destination.index)
                        );
                        break;
                    case "FORMFIELDS":
                        if (destination.droppableId === "FORM_ITEMS_BLOCK") {
                            setFormItemsBlock(state =>
                                copyItem(_formFields, state, source, destination)
                            );
                        } else {
                            setFormItems(state =>
                                copyItem(_formFields, state, source, destination)
                            );
                        }
                        break;
                    default:
                        break;
                }
            }
        }, [formItems, formItemsBlock]
    );

    const onFinish = (values) => {
        if (hasBlock && formItemsBlock.length === 0 && document.querySelector(".form-items .draggable-item-block")) {
            message.error(intl.formatMessage({ id: `label.emptyQuestionBLock` }));
            return false;
        }
        const getFormItem = (items) => {
            const _formFields = [];
            items.forEach((element, index) => {
                const _item = {
                    "name": element.name ? element.name : "",
                    "type": element.type ? element.type : 0,
                    "previousType": element.previousType ? element.previousType : element.type,
                    "required": element.required ? element.required : false,
                    "multipleValues": element.multipleValues ? element.multipleValues : false,
                    "position_row": index,
                    "position_column": element.position_column ? element.position_column : 1,
                }
                if (element.optionValues && Object.keys(element.optionValues).length !== 0) {
                    let values = element.optionValues;
                    if (element.optionValues.hasOwnProperty("values")) {
                        values = element.optionValues.values;
                    }
                    _item.optionValues = values.length > 0 ? { values: values } : {};
                }
                if (element.nameNormalized && element.nameNormalized.length > 0) {
                    _item.nameNormalized = element.nameNormalized;
                }
                if (element.typeName === "NUMBER_DECIMAL") {
                    _item.decimal = element.decimal ? element.decimal : 2;
                } else if (element.typeName === "HEADING") {
                    _item.fontSize = element.fontSize ? element.fontSize : 'small';
                } else if (element.typeName === blockElement) {
                    _item.formFields = element.typeName === blockElement ? getFormItem(formItemsBlock) : null;
                }
                _formFields.push(_item);
            });
            return _formFields;
        }

        const _newFormFields = getFormItem(formItems);

        const newForm = {
            "name": values.name ? values.name : "",
            "language": values.language ? values.language : "EN",
            "description": values.description ? values.description : "",
            "saveUserName": values.saveUserName ? values.saveUserName : false,
            "project": values.project ? parseInt(values.project) : "",
            "status": values.status ? parseInt(values.status) : 0,
            "configuration": {
                "formFields": _newFormFields
            }
        }

        if (edit && formId !== 'file' && formId !== 'template') {
            props.editSurveyForm(props.history, formId, newForm, setSurveyFormCreated, entityUrl);
        } else {
            if (formId === 'template') {
                entityUrl = 'form'
            }
            props.addSurveyForm(props.history, newForm, setSurveyFormCreated, entityUrl);
        }
    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.forEach((error) => {
            const name = error.name[0];
            /*if (name.indexOf("__name") !== -1) {

            } else */if (name.indexOf("__optionValues") !== -1) {
                const element = document.getElementById(name.replace("__optionValues", "__edit"));
                if (!element.classList.contains("isEditing")) {
                    element.click();
                }
            } else if (["name", "project", "language", "description"].includes(name)) {
                setKeyCallapse(1);
            }
            /*return (message.error(error.errors[0]))*/
        });
        form.validateFields();
    };

    const createExistingForm = (props) => {
        const prepareExistingForm = (data) => {
            if (data) {
                form.setFieldsValue({
                    'name': data.name ? data.name : (data.title ? data.title : ""),
                    'project': "",
                    'status': path === 'file' || path === 'template' ? 0 : data.status, //setting status to default value 0 if form is from file or template
                    'language': data.language ? data.language.toUpperCase() : "",
                    'description': data.description ? data.description : "",
                    'saveUserName': data.saveUserName ? data.saveUserName : false,
                });
                if (entityUrl === 'form'){
                    form.setFieldsValue({
                        'project': data.project ? data.project.toString() : "",
                    })
                }
                if (data.configuration && data.configuration.formFields) {
                    _formFieldsGetForm = data.configuration.formFields;
                    if (_formFieldsEdit && Object.keys(_formFieldsEdit).length !== 0) {
                        setIsModified(true);
                        setFormItems(prepareFormFieldItems(_formFieldsGetForm));
                    }
                } else if (data.items) {
                    _formFieldsGetForm = data.items;
                    if (_formFieldsEdit && Object.keys(_formFieldsEdit).length !== 0) {
                        setIsModified(true);
                        setFormItems(prepareFormFieldItemsV1(_formFieldsGetForm));
                    }
                }
            } else {
                setEdit(false);
                return false;
            }
            return true;
        }

        const path = formId ? formId : props.location.pathname.replace("/forms/");
        if (path === 'file') {
            var theModal;
            function leerArchivo(e) {
                if (e.target && e.target.files && e.target.files.length > 0) {
                    var archivo = e.target.files[0];
                    if (!archivo) {
                        //redirect to forms or configuration/forms
                        message.error(intl.formatMessage({id: 'label.uploadFile.ko.no-file-loaded'}))
                        isConfigurationFormTemplates
                            ? window.location.href = '/form-templates/templates'
                            : window.location.href = '/forms'
                    }
                    var lector = new FileReader();
                    lector.onload = function (e) {
                        var contenido = e.target.result;
                        e.preventDefault();
                        try {
                            prepareExistingForm(JSON.parse(contenido));
                        } catch (e) {
                            message.error(`${intl.formatMessage({ id: 'label.uploadFile.ko' })}`)
                        }
                        theModal.destroy();
                    };
                    lector.readAsText(archivo);
                }
            }
            const obj = {
                name: 'file',
                accept: '.json',
                multiple: false,
                action: null,
                onChange(info) {
                    const { status } = info.file;
                    console.log(status)
                    if (status !== 'uploading') {
                        console.log(info.file, info.fileList);
                    }
                    if (status === 'done') {
                        theModal.destroy();
                        message.success(`${info.file.name} ${intl.formatMessage({ id: 'label.uploadFile.ok' })}`);
                    } //else if (status === 'error') {
                    //     message.error(`${info.file.name} ${intl.formatMessage({ id: 'label.uploadFile.ko' })}`);
                    // }
                },
                onDrop(e) {
                    console.log('Dropped files', e.dataTransfer.files);
                },
            };
            theModal = Modal.info({
                okText: `${intl.formatMessage({ id: "label.uploadFile.buttonOk" })}`,
                width: "50%",
                className: "modalInfo-file-container",
                content: (
                    <Dragger {...obj} id="file-input" openFileDialogOnClick={true}>
                        <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                        <p className="ant-upload-text">{intl.formatMessage({ id: 'label.uploadFile.text' })}</p>
                        <p className="ant-upload-hint">{intl.formatMessage({ id: 'label.uploadFile.hint' })}</p>
                    </Dragger>),
                onOk() {
                    theModal.destroy();
                    isConfigurationFormTemplates
                        ? window.location.href = '/form-templates/templates'
                        : window.location.href = '/forms'
                },
                onCancel() {
                    theModal.destroy();
                    isConfigurationFormTemplates
                        ? window.location.href = '/form-templates/templates'
                        : window.location.href = '/forms'
                }
            });

            setTimeout(() => {
                // Lanzamos el evento de cambio en el input
                document.getElementById('file-input').addEventListener('change', leerArchivo, false);
                //document.querySelector('.modalInfo-file-container .ant-modal-confirm-content *').addEventListener('drop', leerArchivo, false);
            }, 500);

        } else if (path === 'template') {
            //included to create a new form from template
            if (templateId) {
                props.getSurveyForm(props.history, templateId, entityUrl).then( (data) => {
                    if (data) {
                        prepareExistingForm(data);
                    } else {
                        message.error(intl.formatMessage({id: 'label.surveyForm-create-from-template-error-no-data'})).then(
                            () => window.location.href = '/forms/templates'
                        )
                    }
                })
            } else {
                message.error(intl.formatMessage({id: 'label.surveyForm-create-from-template-error-no-templateId'})).then(
                    () => window.location.href = '/forms/templates'
                )
            }
        } else {
            if (formId){
                props.getSurveyForm(props.history, formId, entityUrl).then((data) => {
                    prepareExistingForm(data);
                })
            }

        }
    }

    useEffect(() => {
        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearFormFieldError());
            };
            error();
        } else {
            props.listFormFields(props.history);
        }
        // eslint-disable-next-line
    }, [props.error])

    useEffect(() => {
        if (props.errorSurvey && props.errorSurvey.hasOwnProperty("errors")) {
            message.error(props.errorSurvey.errors[0].message);
        }
        // eslint-disable-next-line
    }, [props.errorSurvey]);

    /**
     * This async function is introduced to guarantee that 'update' or 'created' messages are issued and visible
     * before the redirection to forms or configuration/forms/templates
     * @returns {Promise<void>}
     */
    const mngSurveyFormCreatedAsync = async () => {
        if (edit) {
            formId === 'file' || formId === 'template'
                ? message.info(intl.formatMessage({ id: 'label.surveyForm-created' }))
                : message.info(intl.formatMessage({
                    id: isConfigurationFormTemplates
                        ? 'label.formTemplate.formTemplate-updated'
                        :'label.surveyForm-updated'
                }))
        } else {
            setFormItems([]);
            setFormItemsBlock([]);
            form.resetFields();
            _formFieldsGetForm = [];
            message.info(intl.formatMessage({
                id: isConfigurationFormTemplates
                    ? 'label.formTemplate.formTemplate-created'
                    :'label.surveyForm-created'
            }));
        }
        setSurveyFormCreated(false);
    };

    useEffect(() => {
        if (surveyFormCreated) {
            mngSurveyFormCreatedAsync().then(() => {
                //Redirect to forms or configuration/forms/templates
                window.location.href = isConfigurationFormTemplates
                    ? "/form-templates/templates"
                    : "/forms"
            })
        } else {
            _formFieldsGetForm = [];
            if ((edit || templateId !== null) && props.form === null && props.error === null) {
                createExistingForm(props);
            }
        }
        // eslint-disable-next-line
    }, [surveyFormCreated, formId]);

    useEffect(() => {
        if (_formFields && _formFields.length > 0 &&
            _formFieldsGetForm && _formFieldsGetForm.length > 0 &&
            !isModified) {
            setFormItems(prepareFormFieldItems(_formFieldsGetForm));
        }
        // eslint-disable-next-line
    }, [formItemsEdit]);

    return (
        <Layout className="site-layout">
            <Content style={{ margin: '0 16px' }}>
                <Breadcrumb style={{ margin: '10px 0' }}>
                    <Breadcrumb.Item>
                        <HomeOutlined />
                    </Breadcrumb.Item>
                    {
                        isConfigurationFormTemplates
                            ? <>
                                <Breadcrumb.Item>
                                    <span><FormattedMessage id="menu.formTemplates" defaultMessage="Form Templates" /></span></Breadcrumb.Item></>
                            : isTemplateView ? <>
                                <Breadcrumb.Item>
                                    <span><FormattedMessage id="menu.forms" defaultMessage="Forms" /></span></Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <span><FormattedMessage id="menu.formTemplates" defaultMessage="Form Templates" /></span></Breadcrumb.Item>
                            </> : <Breadcrumb.Item>
                                <span><FormattedMessage id="menu.forms" defaultMessage="Forms" /></span></Breadcrumb.Item>
                    }
                    <Breadcrumb.Item>
                        <span><FormattedMessage id={
                            edit
                                ? formId === 'file' || formId === 'template' ? "label.new" : isTemplateView ? "label.view" : "label.edit"
                                : "label.new"
                        }/></span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Card type="inner"
                        title={<FormattedMessage
                            id={edit
                                ? isConfigurationFormTemplates
                                    ? formId === 'file' || formId === 'template' ? "label.create-surveyFormTemplate"
                                        : isTemplateView ? "label.view-surveyFormTemplate" :"label.edit-surveyFormTemplate"
                                    : formId === 'file' || formId === 'template' ? "label.create-surveyForm"
                                        : isTemplateView ? "label.view-surveyFormTemplate" : "label.edit-surveyForm"
                                : isConfigurationFormTemplates
                                    ? "label.create-surveyFormTemplate"
                                    : "label.create-surveyForm"
                            }
                        />}
                          extra={<Link to={
                              isConfigurationFormTemplates
                                  ? '/form-templates/templates'
                                  : isTemplateView ? '/forms/templates' : '/forms'
                          }
                        >
                            <FormattedMessage id="label.return" defaultMessage="Return" />
                        </Link>}>
                        <div id={'no-templateView'} hidden={isTemplateView}>
                            <Row className="dragAndDropForm">
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Col xs={12} md={6} xl={4}>
                                        {_formFields &&
                                        <FormFieldsItems items={_formFields} />
                                        }
                                    </Col>
                                    <Col xs={24} md={18} xl={20}>

                                        <Form layout="horizontal" name="form" size="large"
                                              labelCol={{ span: 3, }} wrapperCol={{ span: 23 }}
                                              initialValues={{ active: false, project: 0, language: 0 }} form={form}
                                              onFinish={onFinish} onFinishFailed={onFinishFailed}
                                        >
                                            <RequiredFormItems />
                                            <Row>
                                                <Col xs={24} xl={24}>
                                                    <Tabs defaultActiveKey="1">
                                                        <Tabs.TabPane
                                                            key="1"
                                                            tab={<span><FormOutlined />{intl.formatMessage({ id: 'label.surveyForm-form' })}</span>}
                                                        >
                                                            <FormItems items={formItems} />
                                                        </Tabs.TabPane>
                                                        <Tabs.TabPane
                                                            key="2"
                                                            tab={<span><MobileOutlined />{intl.formatMessage({ id: 'label.surveyForm-preview' })}</span>}
                                                        >
                                                            <PreviewForm form={form} formItems={formItems} formItemsBlock={formItemsBlock} />
                                                        </Tabs.TabPane>
                                                    </Tabs>
                                                </Col>
                                            </Row>

                                        </Form>
                                    </Col>
                                </DragDropContext>
                            </Row>
                        </div>
                        <div id={'templateView'} hidden={!isTemplateView}>
                            <PreviewForm form={form} formItems={formItems} formItemsBlock={formItemsBlock} />
                        </div>
                    </Card>
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    errorSurvey: selectErrorForm(state),
    formFields: selectFormFields(state),
    form: selectCurrentSurveyForm(state),
    projects: selectProjects(state),
});

export default connect(mapStateToProps, { listFormFields, clearFormFieldError, listProjects, addSurveyForm, editSurveyForm, getSurveyForm })(SurveyFormForm);
