import {Breadcrumb, Layout, message, Table, Popconfirm, Button, Card, Row, Modal} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone, EyeTwoTone, PlaySquareTwoTone} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect} from "react-redux";
import {useEffect, useState} from "react";

import {
    selectLoading, selectError, selectSubscriptions, selectCurrentSubscription
} from "../../redux/subscription/subscriptionSelector";
import {
    listSubscriptions, deleteSubscription, testSubscription, clearSubscriptionError, getSubscription
} from '../../redux/subscription/subscriptionActions';
import {Link} from "react-router-dom";
import cronstrue from 'cronstrue';

const {Content, Footer} = Layout;

const labelStyle = {width: '25%', textAlign: 'center'}
const valueStyle = {width: '75%', textAlign: 'left'}

const Subscriptions = (props) => {

    const intl = useIntl();
    const {subscriptions, count, max, offset} = props.subscriptions;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [subscriptionDetails, setSubscriptionDetails] = useState(<Card loading={true}> </Card>);

    const showModal = (id) => {
        setIsModalVisible(true);
        props.getSubscription(props.history, id).then((data) => {
            setSubscriptionDetails(<Card>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.name" defaultMessage="Name"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.name}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.active" defaultMessage="Active"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {(data.active ? intl.formatMessage({id: 'label.yes'}) : intl.formatMessage({id: 'label.no'}))}
                    </Card.Grid>
                </Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.project" defaultMessage="Project"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.projectName}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.contacts" defaultMessage="Contacts"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        <ul>
                            {(data.subscribers ? data.subscribers.map((subscriber) => (
                                <li key={subscriber}>{subscriber}</li>)) : '')}
                        </ul>
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.report" defaultMessage="Report"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.reportName}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.frequency" defaultMessage="Frequency"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {cronstrue.toString(data.frequency)}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.execution-on" defaultMessage="Execution on"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.lastExecution ? data.lastExecution : ''}

                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.last-updated" defaultMessage="Last Updated"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.lastUpdated}
                    </Card.Grid></Row>


            </Card>)
        })
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [{
        title: intl.formatMessage({id: 'label.id'}),
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: '10%',
        fixed: false,
        sorter: (a, b) => a.id - b.id,
        sortDirections: ['ascend', 'descend'],
    }, {
        title: intl.formatMessage({id: 'label.actions'}),
        dataIndex: 'actions',
        align: 'center',
        width: '10%',
        fixed: false,
        render: (_, record) => count >= 1 ? (<div className="actionsIcons">
            <Popconfirm title={intl.formatMessage({id: 'msg.confirm-delete'})}
                        onConfirm={() => handleDelete(record.id)}>
                <DeleteTwoTone twoToneColor="red" title={intl.formatMessage({id: 'title.label.actions.remove'}) +' '+ intl.formatMessage({id: 'title.label.actions.subscription'})} />
            </Popconfirm>
            <Link to={`/subscriptions/${record.id}`}><EditTwoTone title={intl.formatMessage({id: 'title.label.actions.edit'}) +' '+ intl.formatMessage({id: 'title.label.actions.subscription'})} /></Link>
            <EyeTwoTone onClick={() => {
                showModal(record.id)
            }} twoToneColor="green" title={intl.formatMessage({id: 'title.label.actions.show'}) +' '+ intl.formatMessage({id: 'title.label.actions.subscription'}) +' '+ intl.formatMessage({id: 'title.label.actions.details'})} />
            <PlaySquareTwoTone onClick={() => {
                handleTestSubscription(record.id)
            }} twoToneColor="blue" loop={true} title={intl.formatMessage({id: 'title.label.actions.try'}) +' '+ intl.formatMessage({id: 'title.label.actions.subscription'})} />
        </div>) : null,
    }, {
        title: intl.formatMessage({id: 'label.active'}),
        dataIndex: 'active',
        key: 'active',
        width: '6%',
        fixed: false,
        sorter: (a, b) => a.active - b.active,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false,
        filters: [
            {
                text: intl.formatMessage({id: 'label.yes'}),
                value: true,
            },
            {
                text: intl.formatMessage({id: 'label.no'}),
                value: false,
            },
        ],
        render: (text) => (text ? intl.formatMessage({id: 'label.yes'}) : intl.formatMessage({id: 'label.no'}))
    }, {
        title: intl.formatMessage({id: 'label.name'}),
        dataIndex: 'name',
        key: 'name',
        fixed: false,
        sorter: (a, b) => a.name - b.name,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false, ...columnSearchProps('name'),
    }, {
        title: intl.formatMessage({id: 'label.frequency'}),
        dataIndex: 'frequency',
        key: 'frequency',
        fixed: false,
        sorter: (a, b) => a.frequency - b.frequency,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false,
        render: (_, record) => count >= 1 ? (<>
            {cronstrue.toString(record?.frequency)}
        </>) : null,
    }, {
        title: intl.formatMessage({id: 'label.project'}),
        dataIndex: 'projectName',
        key: 'projectName',
        fixed: false,
        sorter: (a, b) => a.projectName - b.projectName,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false, ...columnSearchProps('projectName')
    }, {
        title: intl.formatMessage({id: 'label.contacts'}),
        dataIndex: 'subscribers',
        key: 'subscribers',
        fixed: false,
        sorter: (a, b) => a.subscribers?.length - b.subscribers?.length,
        sortDirections: ['ascend', 'descend'],
        filterMultiple: false,
        render: (_, record) => count >= 1 ? (<>
            {record?.subscribers?.length}
        </>) : null,
    }];

    useEffect(() => {

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearSubscriptionError());
            };
            error();
        } else {
            props.listSubscriptions(props.history);
        }
        // eslint-disable-next-line
    }, [props.error]);

    const handleChange = (pagination, filters, sorter) => {
        props.listSubscriptions(props.history, pagination, filters, sorter);
    };

    const handleDelete = (id) => {
        props.deleteSubscription(props.history, id);
    };

    const handleTestSubscription = (id) => {
        // TODO: REPLACE WITH VALID MESSAGES BEFORE FINAL DEPLOYMENT
        message.loading(intl.formatMessage({id: 'label.testing-subscription'}))

        props.testSubscription(props.history, id).then((data) => {
            message.info('Testing Completed\n' + JSON.stringify(data, undefined, 4))
        });
    }

    return (<Layout className="site-layout">
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '10px 0'}}>
                <Breadcrumb.Item>
                    <HomeOutlined/>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span><FormattedMessage id="label.contacts" defaultMessage="Contacts"/></span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span><FormattedMessage id="menu.subscriptions" defaultMessage="Subscriptions"/></span>
                </Breadcrumb.Item>

            </Breadcrumb>
            <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                <Link to='/subscriptions/new'><Button type="primary" style={{marginBottom: 16}}>
                    <FormattedMessage id="label.add-subscription" defaultMessage="Add a Subscription"/>
                </Button></Link>
                <Table
                    bordered={true}
                    loading={props.loading}
                    rowKey={record => record.id}
                    responsive={true}
                    pagination={{
                        current: offset / max + 1,
                        showLessItems: true,
                        pageSizeOptions: [10, 20, 50, 100],
                        pageSize: max,
                        defaultPageSize: max,
                        total: count,
                        showSizeChanger: true,
                        showTotal: ((total, range) => intl.formatMessage({
                            id: 'label.range-total'
                        }, {one: range[0], two: range[1], total: total}))
                    }}
                    size={'default'}
                    showHeader
                    columns={columns}
                    dataSource={subscriptions}
                    onChange={handleChange}
                />
            </div>
            <Modal title={intl.formatMessage({id: 'label.user-details'})} visible={isModalVisible}
                   onOk={handleOk} onCancel={handleCancel} centered width={720}>
                {subscriptionDetails}
            </Modal>
        </Content>
        <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
    </Layout>);
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    subscriptions: selectSubscriptions(state),
    subscription: selectCurrentSubscription(state),
});

export default connect(mapStateToProps, {
    listSubscriptions, deleteSubscription, testSubscription, clearSubscriptionError, getSubscription
})(Subscriptions);