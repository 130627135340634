import {createSelector} from "reselect";

const selectLayer = state => state.layer;

export const selectLoading = createSelector(
    [selectLayer],
    (layer) => layer.loading
);

export const selectError = createSelector(
    [selectLayer],
    (layer) => layer.error
);

export const selectLayers = createSelector(
    [selectLayer],
    (layer) => layer.layers
);

export const selectCurrentLayer = createSelector(
    [selectLayer],
    (layer) => layer.current
);