import {Form, Input, message, Breadcrumb, Layout, Card, Button, Tabs} from 'antd';
import {FormattedMessage, useIntl} from "react-intl";
import {useEffect, useRef, useState} from "react";
import {
    addPointsOfInterest,
    getPointsOfInterest,
    editPointsOfInterest,
    clearPointsOfInterestError
} from "../../redux/pointsOfInterest/pointsOfInterestActions";
import {HomeOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {
    selectError,
    selectLoading,
    selectCurrentPointsOfInterest
} from "../../redux/pointsOfInterest/pointsOfInterestSelector";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as turf from "@turf/turf";
import {selectGeoArea} from "../../redux/user/userSelector";
import {getUserGeoArea} from "../../utils/utilData";
import StylesControlByRadio
    from "../../utils/dataAnalysis/Plots/CustomMapboxControls/StylesControl/StylesControlByRadio";
import GoogleMapReact from "google-map-react";

const {Content, Footer} = Layout;

const optionsValuesStylesControl = [
    "streets",
    "satellite-streets"
]

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
const PointsOfInterestForm = (props) => {

    const intl = useIntl();
    const mapContainerRef = useRef();
    // const [googleMap, setGoogleMap] = useState(null);
    // const [googleMaps, setGoogleMaps] = useState(null);
    const [pointsOfInterestCreated, setPointsOfInterestCreated] = useState(false);
    const [edit, setEdit] = useState(props.match.path !== '/layers/points-of-interest/:id/new');
    const [mapStyle] = useState({
        label: 'Satellite streets',
        value: 'satellite-streets',
        url: "mapbox://styles/mapbox/satellite-streets-v11"
    });
    const [stylesControl, setStylesControl] = useState(new StylesControlByRadio(optionsValuesStylesControl, mapStyle, null, null));


    const [form] = Form.useForm();
    const onFinish = (values) => {
        if (edit) {
            props.editPointsOfInterest(props.history, props.match.params.point_id, values, setPointsOfInterestCreated);
        } else {
            values['layer'] = props.match.params.id
            props.addPointsOfInterest(props.history, values, setPointsOfInterestCreated);
        }

    };

    const onFinishFailed = (errorInfo) => {
        errorInfo.errorFields.map(error => (message.error(error.errors[0])));
    };

    const prepareMap = (point) => {
        let {lat, lon, geojson} = props.geoArea;
        if (point) {
            lat = point.lat
            lon = point.lon
        }
        let map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: mapStyle.url,
            center: [lon, lat],
            zoom: 2
        });

        // Add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl(), 'top-right');
        map.addControl(new mapboxgl.FullscreenControl(), 'top-right');
        map.addControl(stylesControl, 'top-right');
        const geolocate = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        })
        map.addControl(geolocate);
        map.on('load', () => {
            map.resize();
            map.addSource('region', {type: 'geojson', data: geojson});
            map.addLayer({
                'id': 'region',
                'type': 'line',
                'source': 'region',
                'layout': {
                    'visibility': 'visible'
                },
                'paint': {
                    'line-color': 'green',
                    'line-width': 2,
                }
            });

            const marker = new mapboxgl.Marker({color: 'blue', draggable: true})
                .setLngLat([lon, lat])
                .addTo(map);

            function onDragEnd() {
                const lngLat = marker.getLngLat();
                form.setFieldsValue({
                    'lat': lngLat.lat,
                    'lon': lngLat.lng
                });
            }

            marker.on('dragend', onDragEnd);

            geolocate.on('geolocate', function (e) {
                let lon = e.coords.longitude;
                let lat = e.coords.latitude;
                var position = [lon, lat];
                marker.setLngLat(position);
                form.setFieldsValue({
                    'lat': lat,
                    'lon': lon
                });
            });

            if (!edit) {
                const bbox = turf.bbox(geojson);
                map.fitBounds(bbox, {padding: 20});
            } else {
                map.flyTo({
                    center: [lon, lat],
                    zoom: 15,
                    essential: true // this animation is considered essential with respect to prefers-reduced-motion
                })
            }
        });

        return () => map.remove();
    }

    function createMapOptions(maps) {
        return {
            zoomControlOptions: {
                style: maps.ZoomControlStyle.SMALL
            },
            mapTypeControlOptions: {
                position: maps.ControlPosition.TOP_RIGHT
            },
            mapTypeControl: true

        };
    }

    const handleApiLoaded = (map, maps, pointsOfInterest) => {
        map.setMapTypeId('hybrid')
        let {lat, lon, geojson} = props.geoArea;
        const bounds = new maps.LatLngBounds();
        const bbox = turf.bbox(geojson);
        bounds.extend(new maps.LatLng(
            bbox[1],
            bbox[0],
        ));
        bounds.extend(new maps.LatLng(
            bbox[3],
            bbox[2],
        ));

        map.fitBounds(bounds, {padding: 20})

            const marker = new maps.Marker({
                position: new maps.LatLng(
                    edit && pointsOfInterest?.lat ? pointsOfInterest.lat : lat,
                    edit && pointsOfInterest?.lon ? pointsOfInterest.lon : lon
                ),
                map,
                title: 'Location',
                anchor: map,
                draggable: true,
                icon: {
                    path: "M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z",
                    fillColor: 'blue',
                    fillOpacity: 1,
                    strokeWeight: 0.3,
                    scale: 0.8,
                    anchor: new maps.Point(0, 20),
                },
            })
        maps.event.addListener(marker, 'dragend', function(marker){
            const latLng = marker.latLng;
            form.setFieldsValue({
                'lat': latLng.lat(),
                'lon': latLng.lng()
            });
        });
        // setGoogleMaps(maps)
        // setGoogleMap(map)
    };

    useEffect(() => {
        setStylesControl(new StylesControlByRadio(optionsValuesStylesControl, mapStyle, null, null))
        //eslint-disable-next-line
    }, [mapStyle]);

    useEffect(() => {

        if (props.error) {
            const error = () => {
                if (typeof props.error == "string") {
                    message.error(props.error).then(props.clearPointsOfInterestError());
                } else {
                    props.error.errors?.map(errorMessage =>
                        (message.error(errorMessage.message).then(props.clearPointsOfInterestError())));
                }

            };
            error();
        }

        if (props.geoArea === undefined || props.geoArea === null) {
            props.getUserGeoArea(props.history)
        }

        if (edit && !pointsOfInterestCreated && props.error === null && props.geoArea) {
            props.getPointsOfInterest(props.history, props.match.params.point_id).then((data) => {
                if (data) {
                    prepareMap(data)
                    form.setFieldsValue({
                        'name': data.name,
                        'description': data.description,
                        'lat': data.lat,
                        'lon': data.lon,
                        'layer': data.layer,
                    });
                } else {
                    setEdit(false);
                }
            })
        }

        if (!edit && props.geoArea) {
            let {lat, lon} = props.geoArea;

            let point = {
                lat: lat,
                lon: lon,
                name: intl.formatMessage({id: 'label.new-points-of-interest'})
            }
            prepareMap(point);
        }

        if (pointsOfInterestCreated) {
            if (edit) {
                message.info(intl.formatMessage({id: 'label.points-of-interest-updated'}));
            } else {
                form.resetFields();
                message.info(intl.formatMessage({id: 'label.points-of-interest-created'}));
            }
            setPointsOfInterestCreated(false);
        }
        // eslint-disable-next-line
    }, [props.error, pointsOfInterestCreated, edit, props.geoArea, stylesControl])

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={'/layers/points-of-interest'}><FormattedMessage id="menu.layers"
                                                                                  defaultMessage="Layers"/></Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="label.points-of-interest"
                                                defaultMessage="Points of Interest"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span>{edit ? (<FormattedMessage
                            id="label.edit" defaultMessage="Edit"/>) : (
                            <FormattedMessage id="label.new" defaultMessage="New"/>)}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Card type="inner"
                          title={edit ?
                              <FormattedMessage id="edit-points-of-interest" defaultMessage="Edit Point of Interest"/> :
                              <FormattedMessage id="label.create-points-of-interest"
                                                defaultMessage="Create Points of Interest"/>}
                          extra={<Link to={`/layers/points-of-interest/${props.match.params.id}/data`}><FormattedMessage
                              id="label.return"
                              defaultMessage="Return"/></Link>}>
                        <Form layout="horizontal" name="pointsOfInterest_form" size="large"
                              labelCol={{span: 3,}} wrapperCol={{span: 14,}}
                              initialValues={{active: true}} form={form}
                              onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Form.Item
                                name="name"
                                label={intl.formatMessage({id: 'label.name'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}

                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={intl.formatMessage({id: 'label.description'})}
                                rules={[{required: false, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Input/>

                            </Form.Item>
                            <Form.Item
                                name="lat"
                                label={intl.formatMessage({id: 'label.latitude'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Input type="number"/>
                            </Form.Item>
                            <Form.Item
                                name="lon"
                                label={intl.formatMessage({id: 'label.longitude'})}
                                rules={[{required: true, message: intl.formatMessage({id: 'msg.input-required'})}]}
                            >
                                <Input type="number"/>
                            </Form.Item>
                            <Tabs
                                type="card"
                                items={[
                                    {
                                        label: `Mapbox`,
                                        key: 'mapboxTab',
                                        children: <div className="map-container" ref={mapContainerRef}
                                                       style={{ height: 500, width: '100%', marginBottom: 20 }} />,
                                    },
                                    {
                                        label: `GoogleMaps`,
                                        key: 'googleMapTab',
                                        children: <div style={{ height: 500, width: '100%' }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
                                                defaultCenter={{
                                                    lat: 0,
                                                    lng: 0
                                                }}
                                                options={createMapOptions}
                                                defaultZoom={9}
                                                yesIWantToUseGoogleMapApiInternals
                                                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, props.pointsOfInterest)}
                                            >

                                            </GoogleMapReact>
                                        </div>,
                                    }
                                ]}
                            />
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={props.loading}>
                                    {edit ? (<FormattedMessage id="label.update" defaultMessage="Update"/>) : (
                                        <FormattedMessage id="label.submit" defaultMessage="Submit"/>)}

                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
};

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    geoArea: selectGeoArea(state),
    pointsOfInterest: selectCurrentPointsOfInterest(state)
});

export default connect(mapStateToProps, {
    addPointsOfInterest,
    getPointsOfInterest,
    editPointsOfInterest,
    clearPointsOfInterestError,
    getUserGeoArea,
})(PointsOfInterestForm);
